import React from "react";
import useAppState from "../providers/AppProvider";

export default function Logo() {
  const { byId } = useAppState();
  const data = byId(1);
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 348 241.2"
      enableBackground="new 0 0 348 241.2;"
      aria-label="the inn at Pound Ridge logo"
    >
      <g>
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M84.9,201.3h-1.6l-1.8-3.1h-8.6l-1.8,3.1h-1.6l7.1-12.5h1.4L84.9,201.3z M77.2,190.4l-3.4,6.1h6.7L77.2,190.4z
	"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M93.4,201.3h-1.6v-10.8h-5v-1.6h11.6v1.6h-5V201.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M113,201.3h-1.6v-12.5h7.6c2,0,4.4,0.7,4.4,3.9c0,3.2-2.5,3.8-4.4,3.8h-6V201.3z M119,194.9
	c2,0,2.8-0.7,2.8-2.2c0-1.6-0.8-2.3-2.8-2.3h-6v4.5H119z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M135.2,201.7c-4.3,0-7.5-2.7-7.5-6.6c0-4,3.2-6.6,7.5-6.6c4.2,0,7.5,2.6,7.5,6.6
	C142.7,199,139.4,201.7,135.2,201.7z M141,195c0-2.6-2.3-5-5.9-5c-3.6,0-5.9,2.3-5.9,5c0,2.6,2.3,5,5.9,5
	C138.7,200,141,197.7,141,195z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M160.3,196.5c0,4-2.9,5.2-6,5.2c-3.1,0-6-1.2-6-5.2v-7.7h1.6v7.7c0,2.1,1.1,3.5,4.4,3.5c3.3,0,4.4-1.4,4.4-3.5
	v-7.7h1.6V196.5z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M179.4,201.3h-1.6l-8.7-10.2v10.2h-1.6v-12.5h1.6l8.7,10.2v-10.2h1.6V201.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M192.6,188.8c4,0,6.1,2.8,6.1,6.2c0,3.4-2,6.2-6.1,6.2h-7v-12.5H192.6z M192.6,199.6c2.7,0,4.4-1.9,4.4-4.6
	c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2H192.6z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M225.7,201.3h-2l-3.5-5.1h-5.5v5.1H213v-12.5h7.7c2.3,0,4.7,0.9,4.7,3.7c0,2.3-2,3.4-3.4,3.6L225.7,201.3z
	M220.7,194.6c1.6,0,3.1-0.4,3.1-2.1c0-1.8-1.7-2.1-3.1-2.1h-6.1v4.1H220.7z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M233.3,201.3h-1.6v-12.5h1.6V201.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M246.5,188.8c4,0,6.1,2.8,6.1,6.2c0,3.4-2,6.2-6.1,6.2h-7v-12.5H246.5z M246.5,199.6c2.7,0,4.5-1.9,4.5-4.6
	c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2H246.5z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M271.1,199.3c-0.4,0.3-2.6,2.3-6.4,2.3c-4.5,0-7.4-3-7.4-6.6c0-3.9,3.4-6.6,7.6-6.6c3.2,0,5,1.4,5.8,2
	l-0.9,1.4c-1-0.7-2.4-1.7-4.9-1.7c-3.4,0-6,2-6,5c0,2.7,2.1,5,5.9,5c2.4,0,3.9-0.8,4.7-1.4v-2.5h-4.1v-1.6h5.7V199.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M286.9,201.3h-11.2v-12.5h10.9v1.6h-9.3v3.6h5.3v1.6h-5.3v3.9h9.6V201.3z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M93.2,232.7l0.1,2.8h-1.1v-19.5h1.1V227c0.8-1.7,2.4-2.9,4.7-2.9c3.2,0,5.5,2.5,5.5,5.7s-2.3,5.8-5.5,5.8
	C95.8,235.6,94,234.4,93.2,232.7z M102.3,229.9c0-2.7-1.8-4.8-4.5-4.8c-2.8,0-4.6,2.1-4.6,4.8c0,2.7,1.8,4.8,4.6,4.8
	C100.6,234.7,102.3,232.6,102.3,229.9z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M110.4,234.6l-4.9-10.2h1.1l3.9,8.2c0.2,0.5,0.4,1.2,0.4,1.3c0-0.1,0.2-0.8,0.4-1.3l3.5-8.2h1.1l-7.2,16.8
	h-1.1L110.4,234.6z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M122,240.5l0.6-0.7c0.4,0.4,0.8,0.7,1.5,0.7c0.9,0,1.5-0.5,1.5-2.1v-14.1h1.1v14.1c0,2-1.1,3-2.5,3
	C123.1,241.4,122.4,241,122,240.5z M125.3,219.6c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9
	C125.7,220.5,125.3,220.1,125.3,219.6z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M136.2,234.7c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
	c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C131.6,232.8,133.3,234.7,136.2,234.7z M140.3,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
	c-2.5,0-4.2,1.8-4.4,4.2H140.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M152,228.5c0-2.5-1.1-3.4-3-3.4c-1.7,0-2.8,0.7-3.6,1.9l-0.8-0.5c0.9-1.4,2.5-2.3,4.4-2.3c2.3,0,4,1.4,4,4.2v7
	h-1l0-1.7c-0.7,1-1.9,1.9-3.8,1.9c-2.5,0-4.2-1.2-4.2-3.4c0-2.2,1.9-3.2,4-3.2h3.9V228.5z M148.3,229.9c-1.7,0-3.1,0.7-3.1,2.3
	c0,1.7,1.1,2.5,3.2,2.5c1.8,0,3.7-1.1,3.7-2.8v-2H148.3z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M157.5,224.4h1.1l0,2.5c0.7-1.7,2.3-2.7,4.2-2.7c2.5,0,4.1,1.6,4.1,4.4v6.9h-1.1v-6.7c0-2.3-1.1-3.6-3.2-3.6
	c-2.4,0-4.1,1.6-4.1,4.1v6.2h-1.1V224.4z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M170.4,228.8h4.7v1h-4.7V228.8z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M178.4,238.2l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7v-3.1c-0.8,1.6-2.6,2.9-4.7,2.9
	c-3.2,0-5.5-2.5-5.5-5.8s2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l0-2.7h1v11.6c0,3.8-2.1,5.5-5.4,5.5
	C181.2,241.5,179.3,240.1,178.4,238.2z M188.2,229.9c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8c0,2.7,1.8,4.8,4.5,4.8
	C186.4,234.7,188.2,232.6,188.2,229.9z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M198.7,234.7c1.8,0,3-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
	c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6H194C194.1,232.8,195.8,234.7,198.7,234.7z M202.8,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
	c-2.5,0-4.2,1.8-4.4,4.2H202.8z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M206.6,229.9c0-3.2,2.5-5.7,5.8-5.7c3.2,0,5.7,2.6,5.7,5.7c0,3.2-2.5,5.8-5.7,5.8
	C209.2,235.6,206.6,233,206.6,229.9z M217,229.9c0-2.7-1.9-4.8-4.6-4.8c-2.8,0-4.7,2.1-4.7,4.8c0,2.7,1.9,4.8,4.7,4.8
	C215.1,234.7,217,232.6,217,229.9z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M221.8,224.4h1.1l0,2.2c0.6-1.6,1.8-2.4,3.2-2.4c0.9,0,1.5,0.2,2,0.6l-0.5,0.9c-0.3-0.2-0.8-0.5-1.6-0.5
	c-1.6,0-3.1,1.3-3.1,4v6.3h-1.1V224.4z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M230.2,238.2l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7v-3.1c-0.8,1.6-2.6,2.9-4.7,2.9
	c-3.2,0-5.5-2.5-5.5-5.8s2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l-0.1-2.7h1.1v11.6c0,3.8-2.1,5.5-5.4,5.5
	C233,241.5,231.2,240.1,230.2,238.2z M240.1,229.9c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8c0,2.7,1.8,4.8,4.5,4.8
	C238.3,234.7,240.1,232.6,240.1,229.9z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M250.6,234.7c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
	c0-3.2,2.4-5.8,5.5-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C246,232.8,247.7,234.7,250.6,234.7z M254.7,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
	c-2.5,0-4.2,1.8-4.4,4.2H254.7z"
        />
        <path
          fill="#FFFFFF"
          stroke="#FFFFFF"
          strokeWidth=".6"
          strokeMiterlimit="6"
          d="M258.4,232.9l0.9-0.4c0.6,1.3,1.7,2.2,3.5,2.2c1.7,0,2.8-0.8,2.8-2.1c0-1.5-1.1-1.9-3.1-2.5
	c-1.9-0.5-3.4-1.2-3.4-3.2c0-1.6,1.5-2.8,3.3-2.8c1.7,0,2.8,0.8,3.5,2.1l-0.8,0.5c-0.5-0.9-1.4-1.7-2.7-1.7c-1.4,0-2.3,0.7-2.3,1.8
	c0,1.3,0.7,1.7,2.8,2.3c2,0.6,3.7,1.3,3.7,3.4c0,1.8-1.4,3.1-3.8,3.1C260.5,235.6,259,234.4,258.4,232.9z"
        />
      </g>
      <path
        fill="none"
        stroke={data.h4 !== "" ? data.h4 : "#86981f"}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M217.3,69.6c0.4,0.5,0.5,0.4,0.7,1c0.3,0.5,0.3,1,0.4,1.6c0.1,1.1,0.3,2.3-0.2,3.3c-0.9,1.8-2.7,3.1-3.4,5
c-0.1,0.3-0.4,1.5,0.1,1.4"
      />
      <g>
        <path
          fill={data.h4 !== "" ? data.h4 : "#86981f"}
          d="M299,11.5c-0.3-0.6-0.5-0.1-0.5,0c0.3,0.2,0.1,0.9,0,1.2c-0.3,0.7-0.9,0.1-1.4-0.1c-0.6-0.3-1.3-0.4-2-0.6
	c-1.1-0.3-2.2-0.5-3.3-0.7c1-0.1,2.1,0,3.3,0.1c0.8,0.1,1.9,0,2.6,0.4c0.1-0.1,0.2-0.2,0.2-0.3c-0.8-0.5-1.9-0.4-2.8-0.5
	c-1-0.1-2.1-0.2-3.1-0.2c-1,0-2.1-0.1-3.2,0.1c-0.3,0-2,0.2-2.1,0.3c-0.1,0.3-1.4,0.4-1.7,0.4c-0.5,0.1-0.9,0.2-1.4,0.3
	c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.2,0.1-0.3,0.1
	c-0.2,0-0.4,0.1-0.5,0.1c-1.3,0.3-2.5,0.7-3.8,1.2c-0.8,0.3-1.7,0.5-2.5,0.9c-0.3,0.2-0.6,0.2-0.9,0.4c-0.4,0.1-0.7,0.5-1,0.6
	c-1.8,0.9-3.5,1.6-5.3,2.3c-1.9,0.8-3.7,1.5-5.7,1.8c-0.5,0.1-1,0.2-1.4,0.2c-0.4,0.1-0.5-0.1-0.9-0.2c-0.5-0.2-1.4,0.1-1.8,0.4
	c0.1-0.2,0.2-0.3,0.3-0.5c-1.7,0.9-3.7,1-5.6,1.2c-0.6,0.1-1.2,0.2-1.7,0.4c-0.3,0.1-0.3-0.1-0.6,0c-0.2,0-0.4,0.2-0.5,0.4
	c-0.2,0.1-0.3,0.1-0.5,0.1c-0.4,0.1-0.9,0.4-1.3,0.6c-2.2,1-4.1,2.5-6,4c-0.7,0.6-1.3,1.3-1.7,2.1c-0.3,0.7-0.9,1.4-1.1,2.2
	c0.2,0.4-0.4,0.3-0.4,0.3c-0.2,0.2-0.2,0.3,0,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.4-0.8,0.6-1,1.1c-0.2,0.6-0.7,1-1,1.6
	c-0.3,0.7-0.7,1.4-0.8,2.2c-0.2,1.5-0.6,3.2-0.4,4.7c0.1,0.9,0.3,1.7,0.6,2.5c0.2,0.6,0.5,1.2,0.3,1.8c1.1,0.1,0.4,1.9,1,2.5
	c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.2,0.6,0.8,0.3,0.9c-0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.1-0.1,0.2-0.1c0.6,1,1.3,2,1.8,3.1
	c0.5,1,1.1,1.8,1.1,2.9c0.9-0.2,1.6,3.2,1.7,3.9c0.2,1.7,0.2,3.4,0.1,5.1c-0.1,1.7-0.3,3.4-0.4,5.1c-0.1,1.7-0.3,3.5-0.2,5.3
	c0,0.5,0.1,0.9,0.1,1.4c0,0.2,0,0.4,0,0.5c0.1,0.8,0.2,1.7,0.3,2.5c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3c0.1,0.7,0.4,1.4,0.6,2
	c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.7,0.7,1.3,1,2c1.6,2.7,3.8,5.3,6.6,6.9c1.3,0.7,1.2-1.8,2.1-1.4c0.1-0.1,0.2-0.3,0.1-0.4
	c0.3-0.3,0.7-0.6,1-1c0.2-0.2,0.2-0.7,0.6-0.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0.1-0.3,0.3-0.1,0.4-0.3
	c0.1-0.2-0.1-0.1-0.1-0.2c-0.2-0.2,0.2-0.6,0.3-0.7c0.2-0.4,0.4-0.9,0.7-1.4c0.2-0.4,0.3-1.3,0.7-1.4c-0.1-0.2-0.1-0.6,0.1-1
	c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.4c0,0,0,0,0,0
	c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0.3,0.1,0.6-0.1,0.8-0.2c-0.4-0.2-0.7-0.2-0.7-0.7c0.1-0.3,0-1.5,0.3-1.6
	c0.3-0.1-0.5-0.9,0.1-0.8c0-0.2,0.2-0.5-0.1-0.4c0-0.3-0.1-1.8,0.1-1.9c0.1-0.1,0.4-0.1,0.6-0.1c-0.2-0.1-0.5-0.2-0.7-0.3
	c0-0.2,0.1-0.6,0.1-0.7c0,0-0.2-0.1-0.2-0.2c0-0.3-0.1-0.5-0.1-0.8c0-0.4-0.4-1.2,0.1-1.3c-0.7,0-0.2-0.7,0.1-0.8
	c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.2-0.1-0.3,0-0.5c0,0,0.1-0.1,0.1-0.1c0-0.2-0.2-0.3-0.3-0.6c-0.1-0.5-0.5-1.6,0-1.9
	c-0.1,0-0.2-0.2-0.3-0.2c0.2-0.2-0.2-0.4-0.2-0.4c0.2-0.7-0.8-1.4-0.2-1.9c-0.1-0.2-0.2-0.3-0.4-0.2c-0.2-0.6-0.6-1.3,0-1.7
	c-0.4,0.2-0.7-0.3-0.5-0.6c-0.5-0.6-0.7-2-1-2.7c0.3,0,0.2-0.2,0.1-0.4c-0.6-0.2,0.2-0.8-0.4-0.9c0,0,0,0.1,0,0.1
	c-0.6-0.2,0.2-0.7,0.3-0.9c-0.3,0-0.5-0.2-0.6,0.1c-0.1,0-0.3-0.5-0.3-0.6c-0.2-0.5-0.1-0.2,0.2-0.5c0.2-0.1-0.2-0.4-0.4-0.4
	c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3-0.4-0.3-0.5c0.3,0.1,0.5,0,0.8-0.1c-0.2-0.3-0.5-0.3-0.8-0.3c-0.2-0.1-0.2-0.2-0.4-0.5
	c0.2,0.1,0.3,0.1,0.4-0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.6-0.4-1.2-0.6-1.7c-0.1-0.5-0.7-1.5-0.4-1.9
	c-0.6-0.3-0.5-1.4-0.5-2c0-1.1-0.1-2.2,0-3.3c0.1,0.1,0.3-0.1,0.4-0.2c-0.2-0.2-0.6-0.7-0.1-1c-0.7-0.6,0.3-3.3,0.4-4.1
	c0.1-1.3-0.5-2.7-1.5-3.6c0.2,0,0.4,0,0.6,0c-0.3,0.4,0.7,0.4,0.9,0.5c1.1,0.1,2-0.2,2.8-0.8c0.7-0.5,1.4-0.9,2-1.4
	c0.5-0.3,1.8-1.6,2.3-1.4c0.1-0.3,1.9-1.1,1.6-0.5c0.2,0.2,0.5,0.1,0.7,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0.4-0.1,0.7-0.2,1.1-0.1
	c0.2,0,0-0.1,0.1-0.1c0.6-0.1,1.2-0.1,1.7,0.1c0.4,0.1,1,0.2,1.5,0.2c-0.1,0,0.4-0.2,0.4-0.2c0.3,0,0.6-0.1,1-0.1
	c-0.1,0.3,0.5,0.4,0.7,0.6c0-0.2-0.1-0.4-0.2-0.6c0.6,0,0.7-0.1,0.9,0.5c0.1,0.1-0.4-0.2,0,0.3c0.2,0.2,0.5,0.3,0.7,0.4
	c-0.2-0.3-0.3-0.7-0.5-1c0.6,0.1,1.5,0.3,2,0.7c0-0.1,0-0.2,0-0.3c0.6-0.1,0.9,0.1,1.5,0.2c0-0.1,0-0.1-0.1-0.2
	c1.5,0.1,3.1,0,4.5,0.1c0.4,0,0.3,0.1,0.7,0c0.4-0.1,0.8-0.2,1.2-0.3c0.6-0.1,1.4-0.5,1.8,0c-0.3-0.6,0.4-0.4,0.6-0.5
	c0.4-0.2,1.1-0.3,1.6-0.4c0.1,0.4,0.6,0.4,0.4-0.1c0.7-0.1,1.3-0.2,2-0.3c0.6-0.1,0.7,0,1.2,0.2c-0.1-0.1-0.1-0.3-0.1-0.5
	c0.4-0.1,0.7,0.1,1.1,0.3c-0.1-0.2-0.2-0.4-0.3-0.6c0.3-0.1,0.8-0.4,0.7,0.1c0.2,0,0.4,0.3,0.1,0.2c0.3,0.1,0.5,0.5,0.8,0.6
	c-0.1-0.6-0.3-0.8-0.7-1.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2,0.3-0.4-0.1,0.3,0.3c0.2,0,0.3-0.3,0.2-0.4c0.1,0,1.2-0.3,1.2-0.4
	c0.1,0,0.3,0.3,0.4,0.4c-0.1-0.9,0.2-0.8,1-1.2c0.2,0.2,1.5-0.9,1.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.3-0.3,0.5-0.5
	c0.3-0.3,0.5-0.6,0.8-0.9c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.3-0.5,0.6-1.2,1.3-1c0-0.3-0.1-0.5-0.3-0.7
	c0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.3,0.4-0.1,0.6-0.5c-0.1-0.1-0.1-0.1-0.2-0.1c0.4-0.7,0.5-1.6,1-2c-0.2-0.2-0.1-0.8,0.4-0.6
	c0.1,0,0.3,0,0.3-0.2c-0.1-0.1-0.5-0.3-0.5-0.3c0.1-0.2,0-0.6,0.3-0.5c0,0,0.2,0,0.3-0.1c0-0.1-0.5-0.9,0.1-0.8
	c0.3,0.1,0.5,0.4,0.9,0.1c0.2-0.3-0.2-0.4-0.4-0.6c-0.5-0.3-0.3-0.3-0.1-0.9c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.5,0-1.3,0.7-1.4
	c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.2,0.2,0.1,0.4-0.3c0.1-0.2-0.3,0,0-0.6C299,12.4,299.2,12,299,11.5z M249.9,37.1
	C250,37.3,249.9,37.1,249.9,37.1L249.9,37.1z M249.9,43c-0.8-0.9-0.1-2,0.1-3c0.2-0.8,0.4-1.7,0.1-2.5c0.5,1.2,0.3,2.4,0,3.6
	C250,41.6,249.9,42.3,249.9,43z M249.9,43.9c-0.3-0.3-0.7-0.7-0.6-1.1c0.1,0.2,0.6,0.6,0.6,0.8C249.9,43.7,249.9,43.8,249.9,43.9z
	M249.8,45c-0.3-0.3-1.2-1.1-0.7-1.5c0.2,0.3,0.5,0.6,0.8,0.9C249.8,44.6,249.8,44.8,249.8,45z M249.8,46.3
	c-0.3-0.2-0.6-0.6-0.8-0.9c-0.3-0.5-0.6-0.6-0.3-1.2c0.3,0.5,1.1,1,1.1,1.5C249.7,45.9,249.8,46.1,249.8,46.3z M249.8,47.4
	c-0.5-0.3-0.9-0.7-1.3-1.2c-0.4-0.5-0.5-0.5-0.2-1.2c0.5,0.6,0.7,1.4,1.5,1.8C249.8,47,249.8,47.2,249.8,47.4z M249.8,48.4
	c-0.6-0.4-2.4-1.5-1.9-2.5c0.3,0.4,0.6,0.9,1.1,1.3C249.5,47.7,249.8,47.7,249.8,48.4z M249.9,49.5c-0.7-0.4-2.9-2-2.4-2.9
	c0.5,0.9,1.2,1.8,2.2,2.2c0,0,0-0.1,0.1-0.2C249.8,49,249.9,49.3,249.9,49.5z M250.3,50.9c-1-0.5-1.8-1.2-2.5-2.1
	c-0.2-0.3-1.1-1.4-0.8-1.6c0.7,1.1,1.8,2.2,3,2.8C250.1,50.3,250.2,50.6,250.3,50.9z M250.9,52.6c-0.4-0.3-0.8-0.4-1.1-0.6
	c-0.5-0.4-0.9-0.9-1.4-1.3c-0.4-0.3-1.9-2.6-1.7-2.9c0.4,0.3,0.6,0.9,0.8,1.3c0.3,0.4,0.6,0.8,1,1.1c0.6,0.5,1.9,0.9,2.1,1.7
	C250.7,52.1,250.8,52.4,250.9,52.6z M249.7,53.3c-0.7-0.4-1.6-1.1-2.2-1.8c-0.4-0.5-1.9-2.6-1.3-3.4c0.5,0.9,1.2,2.2,2,2.9
	c0.4,0.3,1.2,1.4,1.6,1.3c0.1,0.2,0.9,0.6,1.1,0.8c0.2,0.2,0.3,0.6,0.4,1C250.9,53.9,250.3,53.6,249.7,53.3z M246.4,51.6
	c-0.4-0.5-1.5-2.3-0.6-2.7c0.1,1.4,1.6,3.6,2.9,4.2c0.5,0.3,0.9,0.6,1.5,0.9c0.7,0.3,1.4,0.5,1.7,1.1c-0.4-0.3-1-0.3-1.5-0.4
	C248.8,54.2,247.3,52.9,246.4,51.6z M247.4,53.9c-0.8-0.5-1.3-1-1.9-1.9c-0.3-0.5-0.9-1.7-0.4-2.2c0.4,1.8,2,3.1,3.3,4.2
	c0.5,0.4,1.5,0.8,2.1,1c0.8,0.2,1.4,0.2,1.7,1C250.5,55.7,248.9,54.9,247.4,53.9z M247,55c-1-0.6-3.3-3-2.4-4.3
	c0.3,1.9,1.8,3.1,3.3,4.1c0.8,0.5,1.8,1.1,2.8,1.4c0.5,0.1,1,0.2,1.4,0.3c0.5,0.1,0.5,0.4,0.7,0.9C250.7,57,248.8,56.1,247,55z
	M247.6,56.4c-1.2-0.6-2.2-1.6-3-2.7c-0.5-0.7-1.1-1.5-0.6-2.3c0.5,2.1,2,3.6,3.8,4.5c1,0.5,2,1,3.1,1.4c0.7,0.3,2,0.2,2.3,0.9
	C251.1,58.4,249.3,57.2,247.6,56.4z M248.1,58c-1.6-0.8-4.8-3.1-4.5-5.1c0.7,1.1,1.5,2.4,2.7,3.2c0.4,0.2,0.7,0.5,1.1,0.7
	c0.3,0.1,0.5,0.3,0.8,0.4c0.7,0.3,1.3,0.7,2,0.9c0.6,0.2,1.3,0.5,2,0.5c0.6,0,1.1-0.1,1.3,0.4C251.6,59.2,249.7,58.8,248.1,58z
	M252.5,59.5c0.6,0.1,1-0.3,1.2,0.3C253.2,59.9,253,59.7,252.5,59.5z M251,59.3c1,0.2,1.9,1.2,2.8,0.9c0.1,0.2,0.1,0.4,0.2,0.7
	c-0.1,0,0,0.1-0.2,0C252.6,60.5,251.9,60.3,251,59.3z M250.1,59.1c0.3,0.1,0.7,0.6,1,0.9c0.4,0.4,0.8,0.7,1.4,1
	c0.4,0.2,0.9,0.2,1.3,0.3c0.6,0.1,0.3,0.5,0.6,0.7C253.6,63,250.5,59.6,250.1,59.1z M253.2,63.1c-0.6-0.1-1.1-0.6-1.5-1.1
	c-0.8-1.1-1.8-2.3-2.8-3.2c0.6,0.1,0.9,0.6,1.2,1c0.5,0.6,1.1,1.2,1.7,1.6c0.5,0.4,1.2,0.7,1.8,0.9c0.6,0.2,0.8-0.2,1,0.5
	c-0.1,0-0.1,0-0.2,0C254.4,63.2,253.6,63.2,253.2,63.1z M250.8,62.2c-1.3-1.4-2.6-2.8-3.8-4.3c0.3,0.2,0.8,0.5,1,0.7
	c0.2,0.1,0.5,0.4,0.6,0.5c0.7,0.5,1.4,1.4,1.9,2.1c0.6,0.8,1.3,1.9,2.3,2.2c0.4,0.2,1.5,0.2,1.9-0.1c0.1,0.4,0.2,0.8,0.3,1.2
	C253.4,64.9,251.8,63.2,250.8,62.2z M255.1,65.6c-1.2,0.1-2.4-0.8-3.4-1.3c-1.2-0.6-2.2-2-3.2-3c-1.1-1.2-2-2.5-2.9-3.8
	c-0.7-0.9-1.6-1.9-1.9-3c0.7,1.2,1.8,1.9,2.6,3c0.5,0.6,1,1.3,1.5,1.9c0.4,0.5,1,0.9,1.3,1.4c0.8,1.2,2.1,2.5,3.3,3.3
	c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,1.8,0.1,1.9,0.5C255.1,65.1,255.2,65.6,255.1,65.6z M244.1,56.2c-0.2-0.2-0.1,0-0.2-0.3
	C244,56,244,56.1,244.1,56.2z M254.2,66.9c-0.7-0.2-1.3-0.4-1.9-0.7c-1-0.5-2-1.6-2.7-2.4c-1.9-2.2-4.1-4.4-5.2-7.2
	c1.7,2.4,3.4,5,5.7,6.9c0.9,0.8,4.2,3.5,5.4,2.4c0.1,0.3,0.1,0.6,0.2,0.9c0,0-0.1,0-0.1,0.1C255,67,254.8,67.1,254.2,66.9z
	M254.3,67.8c-0.9,0-1.4,0.2-2.2-0.3c-1.4-0.8-2.4-1.7-3.5-2.9c-0.7-0.7-1.4-1.4-1.9-2.3c-0.4-0.7-1.1-1.3-1.4-2.1
	c-0.2-0.6-0.5-1.3-0.8-1.8c-0.3-0.4-0.3-0.8-0.4-1.4c0.5,1.4,1.5,2.8,2.4,4c1.1,1.3,2.2,2.6,3.4,3.8c0.8,0.8,1.7,1.7,2.8,2.2
	c0.5,0.3,1.7,0.8,2.3,0.6c0.3-0.1,0.6-0.2,1-0.3C255.9,67.5,254.6,67.8,254.3,67.8z M256.1,69.5c-0.2-0.1-0.4-0.2-0.6-0.4
	c0.3-0.1,0.5-0.3,0.7-0.5C256.1,68.9,256.2,69.2,256.1,69.5z M254.5,69.6c0.4-0.1,0.4-0.3,0.7-0.1c0.2,0.1,0.4,0.2,0.6,0.3
	c0.3,0.2,0.4,0,0.5,0.4c0.1,0.2,0,0.3-0.2,0.2C255.5,70.4,255,69.9,254.5,69.6z M250.6,67.7c-1.2-0.7-2.1-1.8-3-2.9
	c-1.5-1.7-2.8-3.4-3.2-5.7c1.2,2.7,3.1,5.2,5.3,7.1c0.8,0.7,2,1.5,2.9,1.8c1.1,0.4,2.3,0,3.3-0.5c0.1,0.5,0.2,0.3-0.2,0.4
	c0.4,0.8-1.3,0.6-1.1,1.1c-0.4,0.2-0.9,0.1-1.3,0C252.4,68.9,251.4,68.2,250.6,67.7z M253.3,69.5c0.7,0.2,1.1,0.5,1.8,0.9
	c0.4,0.2,0.9,0.5,1.3,0.4c0,0.1,0,0.2,0,0.3C255.4,71.8,253.8,70.3,253.3,69.5z M254,71.2c-0.6-0.7-1.4-1.4-1.9-2.3
	c0.8,0.4,1,1.3,1.8,1.8c0.6,0.4,1.8,1.4,2.6,0.8c0.1,0.6,0.2,1.2,0.2,1.8c-0.1,0-0.2,0-0.3,0C255.3,73.4,254.6,72,254,71.2z
	M256.3,74.4c-1.2-0.1-2.4-1.4-3-2.3c-0.9-1.3-1.8-2.6-2.7-3.9c1.1,0.6,1.6,1.8,2.5,2.8c1,1,1.8,3,3.5,2.8
	C256.8,74.2,256.8,74.5,256.3,74.4z M254.2,74.7c-0.8-0.6-1.4-1.4-2-2.1c-1.2-1.6-1.8-3.6-2.9-5.2c0.2,0.1,0.8,0.5,0.8,0.7
	c-0.1,0.2,0.4,0.6,0.5,0.8c0.6,0.9,1.3,1.7,1.8,2.6c0.8,1.3,2.5,3.8,4.4,3.3c0,0.2,0,0.5,0,0.7c-0.3-0.1-0.4,0.1-0.7-0.1
	C255.5,75.1,254.8,75.1,254.2,74.7z M255.3,76.1c-2.2-1.1-3.7-2.7-4.9-4.7c-0.8-1.2-1.3-2.6-1.9-3.9c-0.5-1-1.1-2-1.7-3
	c0.6,0.7,1.4,1.4,1.8,2.1c0.3,0.1,0.8,1.5,0.9,1.7c1,1.6,1.6,3.3,2.7,4.9c0.5,0.6,1.1,1.3,1.8,1.7c0.4,0.3,0.8,0.5,1.2,0.6
	c0,0,0.6,0.2,0.6,0.1c0.4,0.1,1,0,0.9,0.5C256.7,77,255.7,76.3,255.3,76.1z M253.3,76c-1.5-1.2-3-2.8-3.9-4.5
	c-1-1.8-1.6-3.7-2.7-5.4c-0.5-0.7-1-1.5-1.3-2.4c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.4-0.2-0.9-0.3-1.4c0.9,1.4,1.4,2.8,2.3,4.3
	c1,1.6,1.7,3.4,2.6,5.1c0.5,0.9,1,1.6,1.7,2.4c0.4,0.6,0.9,1.4,1.5,1.7c0.8,0.4,1.5,1.1,2.3,1.5c0.4,0.2,0.9,0.4,1.4,0.5
	c0.4,0.1,0.3,0.1,0.2,0.5C255.4,77.5,254.2,76.8,253.3,76z M255.2,78.1c-1.5-0.8-2.9-1.5-3.9-2.9c-1-1.3-1.8-2.7-2.8-4.1
	c-0.9-1.3-1.6-2.8-2.3-4.2c-0.3-0.5-0.6-1.2-0.9-1.7c-0.5-0.7-0.4-1-0.4-1.8c0.4,0.8,0.6,1.6,1.1,2.4c0.5,0.7,1,1.4,1.4,2.2
	c0.7,1.5,1.3,3.1,2.2,4.4c0.9,1.4,2.2,2.7,3.5,3.8c0.1,0.5,1.3,1,1.7,1.2c0.6,0.3,1.4,0.4,2,0.4c0,0.2-0.1,0.5-0.1,0.7
	c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.1,0,0.1,0,0.1C256,78.4,255.6,78.3,255.2,78.1z M256,79.6c-2.7-1.3-5-3.1-6.7-5.7
	c-1-1.5-1.9-3-2.6-4.7c-0.6-1.3-1.6-2.4-1.8-3.8c0.4,0.5,0.6,1.1,0.9,1.7c0.4,0.8,0.8,1.6,1.2,2.4c0.8,1.6,1.8,3.1,2.8,4.5
	c0.9,1.4,2,2.6,3.4,3.6c0.6,0.4,1.7,1.1,2.5,1.2c0.3,0,0.6,0.3,0.9,0.2c0,0.3-0.1,0.5-0.1,0.8C256.3,79.7,256.2,79.7,256,79.6z
	M255,79.5c0.3,0.1,1.4,0.6,1.4,0.8c-0.6-0.3-1.2-0.6-1.9-0.9c-2.3-1.1-4.2-2.9-5.8-4.9c-1.1-1.4-1.9-3.1-2.7-4.7
	c-0.3-0.7-0.6-1.4-0.9-2.1c-0.3-0.6-0.8-1.3-0.6-2c0.3,1.5,1.4,3,2.1,4.3c0.9,1.7,1.9,3.4,3,5c0.8,1.2,2.3,2.7,3.6,3.3
	C253.9,78.8,254.4,79.3,255,79.5z M256.2,81.3c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0.5-1.3-0.4-1.7-0.6
	c0,0,0,0,0,0c-0.4-0.2-0.9-0.5-1.2-0.8c-1.3-0.6-2.1-1.9-3-3c-1.5-1.6-2.7-3.1-3.5-5.2c-0.3-0.8-0.7-1.7-1.2-2.4
	c-0.2-0.3-0.3-0.6-0.5-0.9c-0.2-0.4-0.1-0.6-0.1-1c1.2,2.3,2.1,4.6,3.4,6.8c0.7,1.2,1.7,2.1,2.6,3.1c0.3,0.4,1.9,1.8,2.2,1.7
	c0,0.4,1.7,1.1,2,1.3c0.4,0.2,0.8,0.4,1.2,0.6c0,0,0.1,0,0.1,0.1c0.5,0.2,0.5,0.2,0.5,0.7C256.2,81.2,256.2,81.2,256.2,81.3z
	M255.9,82.8c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2
	c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.2-0.8-1.8-1.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.6-0.5-0.9-0.7c0,0,0,0,0,0
	c-0.2-0.2-0.4-0.4-0.6-0.6c-1-0.9-1.9-2-2.8-3.1c-1.1-1.2-2.2-2.8-2.9-4.3c-0.4-0.7-0.7-1.4-1.2-2.1c-0.5-0.7-0.2-1.1-0.1-1.9
	c1,1.8,1.7,3.8,2.8,5.5c0.6,1,1.4,1.9,2.2,2.7c0.7,0.7,1.3,1.5,2,2.2c0.5,0.4,1,0.9,1.6,1.2c0.4,0.2,0.7,0.5,1.1,0.6l0,0
	c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.6,0.4,0.9,0.3,0.9,0.7c0,0.1,0,0.2-0.1,0.4
	C255.9,82.6,255.9,82.7,255.9,82.8z M255.6,83.6c-0.4-0.2-0.9-0.5-1.4-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.4-1.4-0.8-2.1-1.3
	c-0.2-0.1-0.3-0.3-0.5-0.4c-0.5-0.4-1.1-0.8-1.5-1.3c-0.2-0.2-0.4-0.4-0.5-0.6c-0.3-0.4-0.6-0.8-0.9-1.2c-0.8-1.3-1.5-2.6-2.4-3.9
	c-0.5-0.8-1-1.6-1.5-2.4c-0.3-0.5-0.7-0.7-0.6-1.3c0.9,1.4,1.5,2.9,2.5,4.2c1,1.3,1.9,2.6,3,3.8c0.4,0.4,0.8,0.8,1.2,1.2
	c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.7,0.6,1,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.4,1.1,0.6,1.6,1
	c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4C255.7,83.2,255.8,83.4,255.6,83.6
	C255.6,83.7,255.6,83.6,255.6,83.6z M255.2,84.8c-0.2-0.2-0.6-0.6-0.8-0.4c-0.7-0.5-1.5-0.7-2.1-1.2c-0.1,0-0.1-0.1-0.2-0.1
	c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.4-1-0.9-1.4-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.6-0.5-1.1-1.1-1.7-1.8
	c-0.2-0.2-0.3-0.4-0.4-0.6c0-0.1-0.1-0.1-0.2-0.2c-0.8-1.2-1.4-2.5-2-3.7c-0.3-0.7-0.7-1.3-1.2-1.9c-0.5-0.6-0.8-0.7-0.7-1.6
	c1.9,2.3,2.9,5.3,4.7,7.6c0,0.1,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.3,0.4,0.5c0.5,0.5,1,1.1,1.6,1.5c0.2,0.1,0.3,0.3,0.5,0.4
	c0.4,0.3,0.7,0.6,1.1,0.8c0.2,0.1,0.6,0.3,1.1,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.9,0.5,1.9,1,1.8,1.2
	C255.3,84.4,255.2,84.6,255.2,84.8z M253.3,85c-0.5-0.3-1.1-0.5-1.6-0.8c-0.4-0.3-0.9-0.6-1.2-0.9c-0.1-0.1-0.3-0.3-0.4-0.4
	c-0.5-0.5-0.9-1-1.4-1.6c-0.1-0.2-0.2-0.3-0.4-0.4c0,0-0.1-0.1-0.1-0.1c-0.5-0.6-0.9-1.2-1.3-1.9c-0.1-0.2-0.2-0.4-0.3-0.6
	c-0.3-0.6-0.7-1.3-1-1.9c-0.4-0.8-0.8-1.7-1.3-2.4c-0.2-0.3-0.6-1-0.9-1c0,0,0.1-0.5,0.1-0.5c1.5,1.8,2.3,4,3.6,6
	c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0.6,0.7,0.9,1c0.3,0.3,0.6,0.6,0.9,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.5,0.5,1,1,1.5,1.5
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.6,0.5,1.3,0.9,2,1.2c0.4,0.2,0.9,0.8,1.3,0.8c-0.1,0.2-0.2,0.6-0.4,0.5
	C254,85.7,253.7,85.3,253.3,85z M252.9,86.2c-0.5-0.2-1.2-0.6-1.7-1c-0.8-0.6-1.5-1.2-2.2-1.9c-0.2-0.2-0.3-0.3-0.4-0.4
	c-0.3-0.3-0.7-0.7-1-1.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.5c-0.4-0.7-0.8-1.5-1.2-2.3c-0.1-0.2-0.2-0.4-0.3-0.6
	c-0.1-0.2-0.2-0.4-0.3-0.5c-0.6-1.1-1.9-2.6-1.7-3.9c0.9,1.5,1.8,3.1,2.7,4.7c0.1,0.2,0.2,0.4,0.4,0.6c0.4,0.7,0.9,1.4,1.4,2.1
	c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.7,0.9,1.1,1.3c0.1,0.1,0.3,0.3,0.4,0.4c0.6,0.6,1.3,1.1,2.1,1.5
	c0.6,0.3,1.1,0.5,1.6,0.9c0.2,0.2,0.6,0.4,0.9,0.4c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1-0.1-0.2-0.2-0.4-0.2
	C253.7,86.9,253.2,86.3,252.9,86.2z M252.8,86.9c-0.7-0.3-1.4-0.6-2-1c-1.1-0.6-2.2-1.7-3.1-2.7c0,0,0,0,0-0.1
	c-0.1-0.1-0.2-0.3-0.4-0.4c-0.5-0.5-1-1.1-1.2-1.8v0c-0.1-0.1-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.8-0.6-1.1c-0.1-0.2-0.4-0.8-0.7-1.4
	c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-1-1-1.9-1.3-1.7c0.1-0.3,0.1-0.7,0.2-1c0.5,1,1.1,2,1.7,3c0.1,0.2,0.2,0.4,0.3,0.6
	c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.5,0.5,1.1,0.8,1.6c0.1,0.2,0.2,0.3,0.3,0.5c0.4,0.6,0.8,1.2,1.3,1.7c0,0,0,0,0.1,0.1
	c0.1,0.1,0.2,0.3,0.4,0.4c1,1,2.1,2.1,3.4,2.7c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.2,1.3,0.5,1.1,0.9C253.5,87.3,253.1,87,252.8,86.9z
	M253.4,88.2c-2.5-1.3-4.6-3-6.5-5.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.5-0.8-1-1.2-1.6
	c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.3-0.4-0.5c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-0.5-0.9-1.4-1.1-2.2
	c0-0.2-0.1-0.4-0.1-0.5c0-0.3,0-0.6,0-0.8c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0.2,0.3,0.5,0.4,0.7c0.5,0.7,0.9,1.5,1.1,2.3
	c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.4,0.8,0.6,1.1c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.2,0.3,0.3,0.4,0.5
	c0.2,0.3,0.5,0.6,0.7,0.8c1.6,1.8,3.7,2.8,5.8,3.8C253.6,87.9,253.5,88.1,253.4,88.2z M242.6,80.1c0.3,0.7,0.6,1.4,1.1,2.1
	c0.1,0.2,0.2,0.3,0.3,0.4c0.7,1.1,1.5,2,2.2,3.1c1.1,1.5,2.5,3.1,4.1,4c0,0,0-0.1,0.1-0.1c0.1,0.3,0.8,0.7,1.1,0.9
	c0,0.1-0.3,0.6-0.3,0.6c-0.1-0.2-1-0.6-1.3-0.8c-0.5-0.4-0.9-0.7-1.4-1.1c-0.9-0.7-1.8-1.5-2.6-2.5c-0.7-0.9-1.4-2-1.9-3
	c-0.1-0.4-0.3-0.7-0.5-1c-0.1-0.1-0.1-0.3-0.2-0.4C243,81.5,242.7,80.8,242.6,80.1z M242.7,78.9c0.1,0.2,0.2,0.5,0.3,0.7
	c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.4,0.7,0.9,0.9,1.4c0.2,0.3,0.3,0.5,0.5,0.8c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.5,0.7,0.8,1.1
	c0.6,0.8,1.2,1.6,1.9,2.2c0.5,0.4,1.1,1.4,1.8,1.6c0,0.4,2.2,1.6,2.5,1.7c-0.2,0.2-0.3,0.4-0.4,0.5c-0.8-0.3-1.9-1.1-2.6-1.7
	c-1-1-1.9-2.1-2.8-3.2c-0.6-0.8-1.2-1.6-1.8-2.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.5-0.7-1.1-0.9-1.7
	c-0.1-0.2-0.1-0.3-0.2-0.5C242.8,79.3,242.7,79.2,242.7,78.9z M242.7,77.3c0.4,0.9,0.9,1.9,1.4,2.7c0.1,0.2,0.2,0.3,0.3,0.5
	c0.4,0.7,0.9,1.4,1.4,2c0.1,0.2,0.3,0.3,0.4,0.5c1.9,2.3,4.2,4.2,6.9,5.5c-0.4,0.3-0.6,0.8-1.1,0.5c-2-1-3.9-2.8-5.2-4.5
	c-0.4-0.5-0.7-1.1-1.1-1.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c-0.4-0.5-0.6-1.1-1-1.6c-0.1-0.1-0.2-0.3-0.3-0.4
	c-0.1-0.2-0.2-0.4-0.4-0.6C243,79,242.6,78,242.7,77.3z M240.9,54.1c0.2-0.6,0.5-1.6,0.8-2.2c0.4-0.8,0.8-1.7,1.3-2.5
	c0.3-0.4,0.5-0.7,0.9-1c0.3-0.3,0.5-0.8,0.8-1.1c0.5-0.5,0.9-0.6,1.3-1.3c0.7-1.4,1.8-2.6,2.4-4.1c0.6-1.4,1.1-3.8,0.7-5.4
	c0.7,0.6,1,1.8,0.8,2.6c-0.3,1-0.5,2.2-0.9,3.2c-0.5,1.4-1.1,3-1.9,4.3c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.4-0.3,1.1-0.8,1.2
	c0,0.1-0.2,0.8-0.4,0.9c-0.5,0.2-0.4,0.7-0.8,1c0,0-0.5,0.5-0.5,0.4c-0.1,0.1,0,0.3-0.1,0.4c0.1,0-0.2,0.3-0.4,0.3
	c0,0.5-0.5,0.8-0.6,1.2c-0.1,0.1-0.1,1.1-0.1,1.4c-0.3-0.1,0,1.1,0,1.2c0.2,0.9,0.4,1.7,0.5,2.6c0.3,2.2,0.7,4.1,0.7,6.4
	c0,0.9,0,1.6-0.3,2.5c-0.1,0.5-0.2,1.1-0.3,1.6c-0.2-0.1-0.3,1.3-0.3,1.6c-0.3-0.2-0.4,0.2-0.1,0.4c0.3,0.2-0.2,3.2-0.3,3.9
	c-0.1,0.6-0.1,0.2-0.2,0.7c-0.1,0.2,0.1,0.2,0.1,0.5c-0.1,0.7-0.2,1.4-0.4,2c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.1,0.4
	c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4-0.1-0.5c-0.1-0.8-0.2-1.6-0.2-2.4c0-1,0.2-2,0.2-3c0-1.9,0.2-3.8,0.3-5.7c0.2-2,0.3-4.1,0-6.1
	c-0.1-1-0.4-1.8-0.8-2.7C241.4,55.8,240.7,54.8,240.9,54.1z M239.1,48.7c0.2-1,1-1.5,1.4-2.4c0.7-1.5,2.2-2.5,2.8-3.9
	c1.3-2.8,2.1-5.8,0.5-8.6c0.5,0.3,1.1,0.6,1.6,0.8c0.5,0.2,1.3,0.3,1.6,0.7c0.6,0.8,0.5,2,0.6,2.9c0.1,2.3-1,4.5-2.1,6.5
	c-0.3,0.7-0.8,1.2-1.3,1.7c-0.4,0.3-0.6,0.9-1,1.2c-0.2,0.1-0.4,0.6-0.6,0.7c-0.4,0.4-0.9,1-1.3,1.4c-0.9,0.7-1.2,1.7-1.6,2.7
	c-0.2-0.5-0.5-0.8-0.8-1.3C239.2,50.7,239,49.3,239.1,48.7z M237.2,46.6c0.2-0.3,0.3-0.7,0.5-1c0.4-0.6,1.1-1.3,1.7-1.7
	c0.8-0.7,1.9-1.8,2-2.9c0.1-0.6,0.4-1.2,0.6-1.8c0.1-0.4,0.3-0.8,0.3-1.2c-0.1-0.5-0.2-1.1-0.3-1.6c-0.3-1-0.5-1.9-1-2.8
	c-0.4-0.7-0.9-1.3-1.2-2.1c1.6,0.7,2.6,2.8,3,4.4c0.3,1.4,0.4,3-0.1,4.4c-0.6,1.6-1.6,3.1-3,4.1c-1.1,0.9-2.1,2.3-2.5,3.6
	C237,47.5,236.9,47.3,237.2,46.6z M238.6,31.9c0.7,1.2,1.4,2.3,1.7,3.6c0.3,1,0.6,2.7,0.2,3.7c-0.5,1.2-0.7,2.2-1.8,3.1
	c-0.9,0.8-2.1,1.6-2.7,2.6c-0.4-1.3-0.8-2.6-0.9-4c-0.1-1.2,0.3-2.4,0.4-3.7c0.1-2.1,1.5-3.6,2.7-5C238.4,32.2,238.5,32,238.6,31.9
	z M238.5,31.1C238.6,31.1,238.6,31.2,238.5,31.1L238.5,31.1z M239.9,32.8c0.5,0.8,1,1.5,1.3,2.4c0.5,1.5,1.1,3.4,0.2,4.9
	c-0.3,0.6-0.4,1.2-0.7,1.8c-0.3,0.6-0.9,0.9-1.1,1.4c-0.2,0.5-1.1,1-1.5,1.4c-0.6,0.6-1,1.3-1.3,2.1c-0.3-0.8-0.6-1.3,0-2
	c0.6-0.6,1.1-1.2,1.7-1.7c1.3-1.1,2.2-2.3,2.5-4c0.3-1.5-0.1-3.3-0.6-4.8c-0.3-0.9-0.7-1.7-1.3-2.4c-0.2-0.3-0.1-0.7-0.5-0.8
	c0.1,0,0.2,0,0.3,0.1C239.4,31,239.7,32.4,239.9,32.8z M241.4,32.3c1,0.5,1.8,1,2.3,1.9c0.4,0.8,0.8,1.7,0.8,2.7
	c0,1.6-0.7,3.2-1.2,4.7c-0.1,0.4-0.4,1-0.7,1.3c-0.7,0.8-1.3,1.6-1.9,2.4c-0.5,0.7-1.1,1.5-1.5,2.2c-0.6,1-0.6,2-0.6,3.1
	c-0.3-0.5-0.6-1.1-0.9-1.6c0-0.4-0.1-0.6,0.1-1c0.2-0.3,0.4-0.7,0.6-1c0.4-0.8,0.8-1.2,1.4-1.8c0.5-0.6,1.1-1,1.6-1.6
	c0.6-0.7,1-1.4,1.4-2.2C244.3,38.3,243.3,34.9,241.4,32.3z M247.9,35.6c1.5,1.2,0.7,3.6,0.4,5.3c-0.4,1.9-1.7,3.1-2.5,4.8
	c-0.3,0.7-0.7,0.9-1.3,1.4c-0.3,0.3-0.5,0.8-0.8,1.1c-0.4,0.3-0.7,0.7-1,1.1c-0.9,1.4-1.7,3.1-2.2,4.7c-0.2-0.4-0.4-0.7-0.6-1.1
	c0.4-0.7,0.6-1.6,1.1-2.3c0.5-0.7,1.2-1.3,1.7-1.9c0.4-0.1,0.3-0.5,0.6-0.7c0.4-0.2,0.6-0.5,0.8-0.9c0.3-0.4,0.7-0.8,1.1-1.2
	c0.6-0.5,0.9-1.4,1.2-2.1c0.5-1,0.9-2,1.2-3c0.5-1.6,0.6-3.9-0.1-5.4C247.7,35.3,247.8,35.4,247.9,35.6z M292.3,20.5
	c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-1.2-0.8-2.4-1.7-3.6-2.6c-0.6-0.5-1.2-0.9-1.8-1.3c-0.6-0.4-1.3-0.8-1.9-1.1
	c-1.2-0.6-2.5-1-3.7-1.2c0.5-0.2,1.2,0.2,1.7,0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.2,1.1,0.4c0,0,0.1,0.1,0.2,0.1
	c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.7,0.3c0.4,0.2,0.8,0.3,1,0.2c0,0,0.1,0,0.1,0c0,0.3,0.9,0.7,1.7,1.1
	c0.5,0.2,1,0.4,1.2,0.6c0.9,0.6,1.7,1.2,2.6,1.8c0.9,0.6,2.2,1.4,3.3,1.6c0.6,0.1,0.7,0,0.5,0.7c-0.1,0.4-0.4,1.3-0.9,1.2
	C294,21.9,293.2,21.1,292.3,20.5z M294.4,23.6c-0.5-0.2-0.9-0.5-1.4-0.8c-0.2-0.1-0.4-0.2-0.5-0.3c-1.6-1.1-3-2.3-4.5-3.5
	c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.6-0.5-1-0.7c-0.4-0.2-0.7-0.5-1-0.7c-0.6-0.5-1.2-1-1.9-1.4c-1.5-0.8-2.8-1.3-4.4-1.7
	c1.2-0.5,3.2,0.6,4.3,1.1c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,1.1,0.6,1.6,1c0.3,0.2,0.6,0.5,0.9,0.7c1.5,1.2,3,2.3,4.6,3.4
	c0,0,0.1,0,0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.3,0.9,0.5,1.3,0.8c0.4,0.2,0.7,0.4,1.1,0.5C295.1,22.6,294.5,23.7,294.4,23.6z
	M293.8,24.9c-0.1,0.1-0.2,0.3-0.3,0.3c0,0,0,0,0,0c-0.3-0.2-0.2-0.2-0.4-0.2c-1-0.4-1.9-1.2-2.7-1.9c-0.3-0.2-0.5-0.4-0.7-0.6
	c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6c-0.9-0.7-1.8-1.4-2.6-2.2c-2.3-2.1-4.9-4.2-8.1-4.8
	c1.5-0.7,3.4,0.5,4.8,1.2c1.6,0.8,3,1.9,4.4,3c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.2,0.5,0.4,0.8,0.6c1.3,1.1,2.5,2.2,3.9,3
	c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.3,0.2,0.7,0.4,1,0.6C294.5,24.1,294.1,24.5,293.8,24.9z M292.7,26.2
	c-0.1,0.1-0.3,0.3-0.4,0.4c0,0-0.1,0.1-0.1,0.1c-0.2-0.3-1-0.7-1.4-1.1c-0.6-0.7-1.2-1.4-1.8-2c-1.2-1.3-2.6-2.5-3.9-3.7
	c-2.3-2.1-5-4.3-8.1-4.7c1.1-0.5,2.9,0.4,3.9,1c1.7,0.9,3.2,2.2,4.7,3.5c1.2,1,2.3,2,3.5,3c0.2,0.2,0.5,0.4,0.7,0.6
	c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.6,1.1,0.9c0.6,0.5,1.2,0.9,2,1.3C293,25.8,292.9,26,292.7,26.2z
	M290.4,27.3c-0.2-0.3-0.4-0.6-0.6-0.8c-0.6-0.7-1.1-1.3-1.6-2c-1-1.4-2.4-2.5-3.6-3.7c-1.2-1.2-2.6-2.6-4.1-3.4
	c-1.3-0.7-2.9-1.7-4.4-1.7c1-0.6,2.9,0.4,3.8,0.8c1.1,0.5,2,1,2.9,1.8c3.2,2.7,6.1,5.8,9.1,8.7c-0.1,0.1-0.2,0.1-0.3,0.2
	C291.1,27.4,290.7,27.7,290.4,27.3z M289,28.1c-0.3-0.2-0.4-0.6-0.6-0.8c-0.7-0.8-1.3-1.7-2-2.5c-1.3-1.4-2.6-2.9-4-4.2
	c-1.2-1.1-2.6-2.2-4-3.1c-1.1-0.7-2.4-1-3.6-1.3c1.5-0.8,3.2,0.4,4.6,1c1.5,0.7,2.7,1.8,4,2.9c1.3,1.2,2.6,2.3,3.7,3.6
	c1.1,1.3,2,2.6,3.1,3.9c-0.1,0,0,0-0.1,0.1C289.7,28,289.3,28.4,289,28.1z M288.3,28.7c-0.2-0.5-0.5-0.7-0.9-1.1
	c-0.6-0.7-1-1.5-1.6-2.1c-1.2-1.4-2.4-2.8-3.7-4c-1.1-1.1-2.4-1.8-3.5-2.8c-1.3-1-2.6-1.7-4.2-2c0.7-0.3,2.7,0.6,3.4,1
	c1.4,0.7,2.6,1.8,3.8,2.8c1.5,1.2,2.7,2.7,4,4c1.2,1.2,2.1,2.7,3.3,4c-0.1,0.1-0.1,0.1-0.2,0.1C288.5,28.6,288.4,28.7,288.3,28.7z
	M286.4,27.2c-0.5-0.7-1.1-1.4-1.8-2c-1.1-0.9-2.2-2-3.3-3c-2.4-2-4.7-4.8-8.1-5c1-0.7,2.8,0.3,3.8,0.8c1.2,0.6,2.4,1.8,3.5,2.6
	c1.6,1.3,2.9,2.7,4.3,4.2c0.6,0.7,1.1,1.3,1.6,2c0.4,0.7,1.1,1.3,1.5,1.9C287.2,28.9,286.7,27.7,286.4,27.2z M285.9,28.6
	c-0.3-0.4-0.7-0.8-1.1-1.3c-0.6-0.7-1.3-1.4-2-2c-0.7-0.7-1.4-1.3-2.1-1.9c-0.6-0.5-1.2-1.3-1.9-1.6c-0.7-0.3-1.2-1-1.9-1.4
	c-0.5-0.4-1-0.8-1.6-1.1c-0.6-0.3-1-0.7-1.8-0.9c-0.6-0.1-1.1-0.5-1.7-0.6c3.2-1.1,6.6,2.4,8.8,4.3c1.2,1,2.2,2.1,3.4,3.1
	c1.3,1.1,2,2.4,3,3.7C286.3,29.2,286.5,29.2,285.9,28.6z M283.9,27.9c-0.6-0.7-1.4-1.3-2.1-2c-1.2-1.1-2.4-2.2-3.6-3.2
	c-1-0.8-2.1-1.4-3.1-2.1c-0.7-0.6-1.6-1-2.5-1.4c-0.7-0.4-1.5-0.4-2.2-0.7c0.7-0.3,1-0.4,1.8-0.2c0.3,0.1,0.8,0.3,1,0.3
	c0.1,0.2,0.9,0.4,1.3,0.6c0.4,0.3,1,0.5,1.3,0.8c1.1,0.8,2.4,1.6,3.4,2.6c2.4,2.2,5,4.2,6.9,6.9c-0.1,0.1-0.3,0.1-0.4,0.1
	C285.2,29.6,284.2,28.3,283.9,27.9z M283,28.2c-0.6-0.7-1.4-1.4-2.1-2c-1.5-1.3-3-2.4-4.6-3.7c-0.4-0.4-0.9-0.7-1.4-1
	c-0.6-0.4-1.3-1-1.9-1c-0.8-0.1-1.4-0.9-2.2-0.9c-0.5,0-1.1-0.4-1.7-0.5c1.6-0.7,3.8,0.8,5.1,1.6c1.1,0.6,2.1,1.4,3.2,2.1
	c1.5,1,2.8,2.3,4.1,3.5c0.7,0.6,1.4,1.2,2,1.9c0.4,0.4,0.7,0.8,1.1,1.1c0.2,0.3-0.6-0.2,0.1,0.4C284.3,29.9,283.4,28.5,283,28.2z
	M282.9,30.1c0.1,0,0.2-0.1,0.3-0.2c-0.5-0.9-1.1-1.6-1.9-2.3c-0.7-0.6-1.2-1.4-2-1.9c-0.8-0.5-1.5-1.3-2.3-1.9
	c-0.4-0.4-1-0.6-1.5-0.9c-0.6-0.3-1.1-0.7-1.7-1c-0.7-0.3-1.2-0.8-1.9-1.1c-1.5-0.6-2.9-1-4.5-1.1c0.7-0.2,1-0.4,1.7-0.3
	c0.3,0,0.8,0.1,1.1,0.3c0.7,0.3,1.4,0.5,2.1,0.9c0.3,0.2,0.6,0.2,1,0.3c0.4,0.1,0.7,0.5,1,0.7c0.4,0.2,0.8,0.6,1.3,0.8
	c0.8,0.3,1.6,1.1,2.2,1.6c0.9,0.6,1.6,1.4,2.4,2.1c1.5,1.2,2.7,2.6,4,3.9c-0.2,0-0.5,0.1-0.7,0.1C283.2,30,283,30.1,282.9,30.1z
	M268.3,20.2c0,0,0,0.1-0.1,0.1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.4,0-0.2,0C267.6,20.1,267.9,20.1,268.3,20.2z M270.2,20.8
	c-0.5-0.3-1.3-0.3-1.7-0.6c1.3,0.1,2.4,0.6,3.5,1.1c0.5,0.2,0.9,0.6,1.4,0.9c0.5,0.2,1.1,0.4,1.5,0.8c-0.1,0-0.1-0.1-0.2-0.1
	c0,0-0.1,0.2-0.1,0.3c-0.7-0.4-1.3-0.7-1.9-1.2c-0.2-0.2-0.4-0.2-0.6,0c-0.2-0.1-0.3-0.2-0.5-0.2C271.3,21.4,270.8,21.1,270.2,20.8
	z M281.5,30c-0.2-0.4-0.5-0.8-0.7-1.2c-0.4-0.8-1-1.4-1.6-2c-1.2-1.3-2.8-2.3-3.9-3.7c0.3,0.3,1.1,0.7,1.5,0.9
	c0.8,0.4,1.4,1.2,2.1,1.7c1.5,1.1,3.2,2.6,4,4.2C282.3,30.2,281.7,30.5,281.5,30z M280,30.7c0,0-1.4-2.6-1.6-2.8
	c-0.7-1-1.2-2.1-1.9-3c1.9,1.4,3.7,3.4,4.7,5.5C280.8,30.5,280.4,30.6,280,30.7z M278.6,30.4c-0.3-0.4-0.4-0.8-0.6-1.2
	c-0.4-0.8-1-1.5-1.4-2.4c-0.6-1.5-1.8-2.5-2.7-3.7c1.8,0.9,2.4,2.5,3.6,4c0.6,0.7,1,1.6,1.5,2.4c0.3,0.5,0.3,0.8,0.5,1.3
	C278.9,31,279,30.8,278.6,30.4z M277.6,30.4c-0.1-0.3-0.3-0.5-0.4-0.8c-0.3-0.9-1-1.6-1.4-2.4c-1-1.8-2.2-3.6-3.7-4.9
	c0.6,0.3,1,0.5,1.4,1c0.3,0.4,0.6,0.7,0.9,1.1c0.5,0.6,1,1.2,1.5,1.9c0.6,0.7,1,1.7,1.5,2.5c0.5,0.7,0.8,1.6,1.3,2.3
	C278,31.2,277.8,31,277.6,30.4z M276.8,31.1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3-0.5-0.6-0.9-0.9-1.4c-0.6-0.9-1.1-1.9-1.7-2.8
	c-1.4-2-2.5-3.9-4.4-5.4c2.3,1,3.9,3.3,5.2,5.4c0.6,1,1.4,2,1.9,3.1c0.3,0.6,0.5,1.3,0.9,1.8C277.1,31.3,277,31.6,276.8,31.1z
	M276.1,31.6c0,0-1.9-3.5-2.1-3.8c-0.7-1-1.4-2-2.1-3c-1.6-2.3-3.8-3.9-6.5-4.5c1.2-0.2,2.8,0.1,3.8,0.7c1.5,0.9,2.4,2.3,3.4,3.7
	c1,1.4,1.8,2.8,2.7,4.2C275.4,29.5,277.1,31.6,276.1,31.6z M274.7,30.5c-0.4-0.7-0.8-1.3-1.2-1.9c-0.9-1.2-1.9-2.4-2.9-3.5
	c-1.8-2.1-3.8-3.8-6.6-4.4c1.2-0.2,2.8,0.5,3.9,1c1.2,0.6,2.5,2,3.3,3.1c0.9,1.2,1.8,2.4,2.6,3.6c0.4,0.7,0.8,1.3,1.2,2
	c0.2,0.3,0.4,0.6,0.5,0.9c0,0.3-0.4-0.1-0.1,0.3C275.1,31.6,274.9,30.8,274.7,30.5z M274.8,31.6c-0.2,0-0.9-1.3-1.1-1.6
	c-0.4-0.6-0.9-1.1-1.3-1.7c-1.1-1.4-2.2-2.8-3.5-4c-2.1-2-4.3-2.9-7.1-3.4c1.3-0.3,2.8,0,4,0.7c0.7,0.4,1.5,0.7,2.1,1.2
	c0.6,0.4,1,1,1.5,1.5c1.2,1.2,2.3,2.6,3.3,3.9c0.5,0.7,1,1.5,1.5,2.2C274.4,30.7,274.8,31.6,274.8,31.6z M273.5,31.7
	c-0.6-1.5-1.8-2.8-2.9-4c-1.1-1.1-2.1-2.4-3.4-3.3c-1-0.7-1.8-1.5-2.9-1.8c-1.2-0.7-3.2-1-4.6-0.8c0.8-0.8,1.9-0.5,2.8-0.3
	c0.6,0.1,1.3,0.2,1.8,0.4c0.3,0.1,0.8,0.3,1,0.3c0.2,0.4,1.5,0.9,1.8,1.1c1.1,0.8,2.1,1.9,3,2.9c0.9,1,1.9,2.1,2.6,3.3
	c0.4,0.6,1.5,1.5,1.5,2.2C274,31.7,273.7,31.7,273.5,31.7z M271.6,31.1c-0.2-0.2-0.3-0.5-0.4-0.7c-0.4-0.8-1.2-1.5-1.8-2.1
	c-1.1-1.3-2.4-2.4-3.8-3.4c-0.5-0.4-1-0.8-1.6-1.1c-0.4-0.2-0.9-0.3-1.3-0.5c-0.6-0.3-1.5-0.4-2.1-0.6c-0.8-0.2-1.4,0.1-2.2,0.2
	c0.6-1.1,2.7-0.6,3.7-0.4c1.5,0.3,3.1,0.8,4.3,1.9c1.3,1.1,2.5,2.3,3.7,3.5c1.2,1.2,2.1,2.6,2.9,3.9c-0.1,0-0.3,0-0.4,0
	C272,31.5,271.9,31.6,271.6,31.1z M269.7,29.9c-0.5-0.6-1.1-1.1-1.6-1.7c-0.6-0.7-1.3-1.4-2-2c-0.5-0.4-1.1-0.7-1.6-1.1
	c-0.7-0.6-2.1-1.3-3-1.4c-0.7-0.1-1.4-0.2-2.1-0.1c-0.6,0-1-0.3-1.6-0.1c1.1-1.1,3-0.3,4.3-0.1c0.4,0.1,0.9,0.3,1.4,0.5
	c0.4,0.1,1.1,0.5,1.4,0.8c0.6,0.6,1.4,1.2,2.1,1.6c0.7,0.4,1.3,1.2,1.9,1.8c0.7,0.7,1.2,1.4,1.9,2.2c0.4,0.4,0.3,1,0.5,1.2
	c-0.1,0-0.1,0-0.2,0C270.4,31.5,270.1,30.3,269.7,29.9z M269.3,31.2c-0.1-0.3-0.4-0.5-0.6-0.7c-0.5-0.5-1-1.1-1.6-1.7
	c-1-1-2.3-2.3-3.6-3c-0.4-0.2-0.8-0.4-1.3-0.5c-0.1,0-1-0.3-0.9-0.3c-1.3-0.2-2.7-0.9-4.1-0.6c0.3-0.6,0.8-0.7,1.5-0.5
	c0.6,0.2,1.4,0.1,2,0.2c0.9,0.1,2.7,0.4,3.4,1.1c0.4,0.4,1,0.7,1.5,1c0.6,0.4,1.2,1,1.7,1.6c0.6,0.7,1.3,1.3,1.9,2
	c0.5,0.5,0.7,1.1,1,1.6C269.7,31.4,269.5,31.7,269.3,31.2z M266.8,30.3c-0.3-0.5-0.6-0.9-1-1.3c-0.8-0.8-1.8-1.4-2.5-2.3
	c-0.7-0.9-2-1.2-3.1-1.5c-1.1-0.3-2.3-0.4-3.3,0c0.2-0.7,0.8-0.6,1.4-0.6c0.6,0,1.4,0.2,1.9,0.3c0.3,0.1,2.2,0.8,2.4,0.6
	c0,0.3,0.8,0.5,1.1,0.8c0.6,0.5,1.2,0.8,1.7,1.3c0.7,0.7,1.4,1.3,2.1,2c0.4,0.5,1.1,1,1.4,1.6c-0.4-0.1-0.7-0.2-1.1-0.3
	C267.3,31,267.1,30.9,266.8,30.3z M265.7,30.2c-0.3-0.5-0.6-0.9-1-1.3c-0.9-0.8-1.9-1.6-3-2.2c-1.3-0.6-4-1.5-5.2-0.4
	c0.9-1.7,3.9-0.7,5.2-0.1c1,0.4,1.8,1.3,2.7,2.1c0.5,0.4,0.9,0.8,1.3,1.3c0.4,0.4,0.6,1,1.1,1.4C266.1,31,266.1,30.9,265.7,30.2z
	M264.7,31.1c0.7-0.2-0.7-1.3-0.9-1.5c-0.7-0.9-1.5-1.5-2.5-2c-1.5-0.8-3.3-1-4.9-0.5c0.6-1.4,3.6-0.8,4.7-0.3
	c1.9,1,3.6,2.3,4.6,4.2C265.4,31,265,31.1,264.7,31.1z M261.1,28.3c-1-0.5-1.7-0.8-2.8-0.8c-0.7,0-1.5,0.1-2.2,0.2
	c0-0.5,1.9-0.6,2.4-0.6c0.8,0,1.7,0.5,2.4,0.5c0.3,0.5,1.4,0.8,1.9,1.2c0.4,0.3,0.7,0.7,1,1.1C263,29.3,261.9,28.7,261.1,28.3z
	M264.2,30.9c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0.2,0.3,0.3,0.4,0.5C264.3,30.8,264.3,30.9,264.2,30.9z M264.3,31
	c0.1,0.1,0.2,0.1,0.3,0.1C264.4,31.2,264.3,31.2,264.3,31z M263.1,31.1c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.2-0.5-0.4-0.7-0.7
	c-0.5-0.6-1.1-0.8-1.9-1c-1.2-0.3-2.3-0.9-3.6-0.8c0.9-0.1,1.9-0.3,2.7-0.1c0.6,0.2,1.3,0.6,1.9,0.9c0.8,0.4,2.4,1.3,2.6,2.3
	C263.6,31.1,263.3,31.1,263.1,31.1z M260.4,30.1c-0.3-0.3-0.8-0.5-1.3-0.7c-1-0.4-1.8-0.7-2.8-0.8c1.1-0.4,2.6,0.3,3.6,0.6
	c1.1,0.4,1.9,0.9,2.7,1.8c-0.4-0.1-0.8-0.1-1.1,0C260.9,31,260.8,30.5,260.4,30.1z M256.1,28.2C256.1,28.2,256.1,28.2,256.1,28.2
	c0.1-0.1,0.3-0.1,0.4-0.1C256.3,28.1,256.2,28.2,256.1,28.2z M256.2,28.6c-0.2,0-0.3,0-0.1-0.3C256.1,28.4,256.1,28.5,256.2,28.6z
	M259.8,31.3c-0.1-0.2-0.3-0.4-0.4-0.5c-0.3-0.3-0.7-0.6-1.1-0.9c-0.7-0.5-1.6-0.7-2.5-0.7c0.3-0.6,2.3,0.2,2.8,0.5
	c0.9,0.4,1.5,0.7,2.1,1.4C260.4,31.1,260.1,31.2,259.8,31.3z M258.4,31.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.3-0.3-0.7-0.5-1-0.7
	c-0.5-0.3-1-0.6-1.5-0.7c0.3-0.4,1.8,0.2,2.2,0.4c0.6,0.3,1.3,0.8,1.7,1.3C258.9,31.6,258.7,31.9,258.4,31.5z M257.4,31.6
	c-0.2-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-1-0.6-1.5-0.7c0.7-1,2.8,1.4,3.2,1.6C257.7,32.2,257.8,32,257.4,31.6z M256.9,32.6
	c-0.2-0.8-1.5-1.5-2.2-1.8c0.2-0.4,1.1,0.3,1.3,0.3c0.2,0.4,1.1,0.7,1.4,1.2C257.3,32.4,257.1,32.5,256.9,32.6z M255.3,33.7
	c-0.3-0.6-0.9-1-1.4-1.3c0.3-0.4,0.5-0.8,0.7-1.3c0.3,0.1,0.6,0.3,0.9,0.5c0.4,0.3,0.8,0.9,1.2,1.1c-0.2,0.1-0.4,0.3-0.6,0.4
	c0-0.4-1.2-1.4-1.6-1.5c-0.5,0.7,1.1,1.1,1.3,1.7C255.6,33.5,255.5,33.6,255.3,33.7z M254.4,34.3c-0.1-0.4-0.5-0.7-0.9-0.9
	c-0.3-0.2,0-0.5,0.2-0.8c-0.2,0.2,1,1,1.2,1.3C254.8,34.1,254.6,34.2,254.4,34.3z M253.7,34.8c0-0.3-0.6-0.6-0.9-0.8
	c0.1-0.1,0.2-0.3,0.3-0.4c0.4,0.2,0.8,0.6,1,0.9C254,34.6,253.8,34.7,253.7,34.8z M250.3,35.7c1.1-0.1,1.9-0.8,2.6-1.6
	c-0.4,0.4,0.4,0.9,0.7,0.9c-0.2,0.1-0.3,0.2-0.5,0.3C252.3,35.8,251.2,35.9,250.3,35.7z M245.7,33.8c0.6-0.2,1.3-0.9,1.8-1.2
	c0.5-0.3,0.7-0.9,1.2-1.2c0.6-0.4,1.5-1.1,1.9-1.8c0.3-0.5,1-0.7,1.1-1.3c0.2-1.1,0.9-2.1,1.4-3c0.7-1.4,1.5-2.5,2.9-3.3
	c-0.5,0.6-1.1,1.1-1.6,1.7c-0.4,0.5-0.6,1.4-0.9,2c-0.6,1.1-1.3,2.2-1.8,3.3c-0.6,1.2-1.9,2.2-2.9,3.1c-0.6,0.5-1.2,1.1-1.8,1.7
	c-0.2,0.2-0.6,0.4-0.9,0.3C245.9,33.9,245.8,33.8,245.7,33.8z M241.4,31.9c0.3,0.1,0.5,0.2,0.8,0.4C242,32.3,241.7,32,241.4,31.9z
	M240.7,31.5c0.1,0.1,0.2,0.2,0.4,0.2C241.1,31.7,240.4,31.5,240.7,31.5z M244.1,33.4c0.1,0.1,0.1,0.1-0.2-0.1
	C243.9,33.3,244,33.3,244.1,33.4z M240.5,31.5c-0.5-0.4-0.2-0.1-0.3-0.1c-0.2-0.1-1.1-0.4-1-0.8C239.6,30.8,240.4,31,240.5,31.5z
	M247.9,22.7c-0.6,0.9-1,2.3-1.2,3.3c-0.2,0.9-1.1,1.9-1.7,2.6c-1.3,1.6-3.4,2.2-5.4,1.4c0,0-0.1,0.2-0.1,0.2
	c-0.4-0.2,2.4-3.5,2.7-3.7c1.5-1.2,3-2.3,4.6-3.2C247.1,23,247.5,22.9,247.9,22.7z M249,22.3c-0.5,1.2-0.5,2.4-0.8,3.6
	c-0.3,1.1-1.1,2.2-1.9,3c-0.9,0.8-2.1,1.4-3.2,1.9c-0.6,0.3-1.4,0.4-2,0.4c-0.4,0-0.7-0.4-1.1-0.6c1.1,0.1,2.2,0.4,3.3-0.1
	c0.7-0.3,1.1-0.9,1.7-1.4c0.8-0.6,1.9-2,2.1-3c0.2-0.9,1.1-2.2,1-3c0.2,0,0.4-0.5,0.6-0.7C248.8,22.3,248.9,22.3,249,22.3z
	M255.3,20.9c-1.4,0.9-2.4,1.7-3.1,3.2c-0.8,1.8-2.1,3.3-3.1,5c-0.5,0.9-1.1,1.5-1.9,2.2c-0.6,0.5-1.3,0.6-1.9,1
	c-0.6,0.5-1.4,0.7-2.1,0.2c-0.6-0.4-1-0.7-1.7-1.1c2-0.2,4.3-1.4,5.6-2.9c0.7-0.7,1.3-1.9,1.5-2.9c0.1-0.6,0.6-2.5,0.4-2.9
	c0.3,0,0.3-0.5,0.5-0.8c0.3-0.1,0.5-0.2,0.8-0.3c1.2-0.4,2.4-0.4,3.6-0.6C254.4,21.1,254.9,21,255.3,20.9z M256.8,20.8
	c-0.9,0.8-1.9,1.3-2.7,2.1c-0.6,0.6-0.9,1.2-1.2,1.9c-0.3,0.6-0.8,1.1-0.9,1.8c-0.1,0.3-0.3,0.7-0.5,1c-0.1,0.3-0.1,0.7-0.3,0.9
	c-0.6,0.6-1.1,1.4-1.8,1.9c-0.2,0.2-1.1,1.3-1.2,1.3c-0.2,0-0.4,0.4-0.5,0.6c-0.2,0.2-0.6,0.3-0.9,0.5c-0.4,0.3-0.8,0.7-1.2,0.8
	c-0.3,0.1-0.3,0-0.5,0.1c-0.3,0-0.3-0.2-0.5-0.4c1.2-0.4,2.6-1.1,3.5-1.9c1.2-1.1,2-2.4,2.9-3.8c0.6-0.9,1.2-1.8,1.7-2.8
	c0.3-0.6,0.5-1.4,1-1.8C254.4,22.1,255.5,20.9,256.8,20.8z M278.6,13.6c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0,0.1,0.1,0.2,0.1
	c-0.5,0.2-1.1,0.6-1.7,0.7c-0.2,0-0.7,0.5-1.1,0.5c-0.3,0.2-0.6,0.5-1.1,0.6c-0.1,0-0.5,0.3-0.4,0.3c-0.6,0.3-1.7,1.3-2.4,1
	c0,0.5-0.8,0.5-1.1,0.6c-0.5,0.3-1.1,0.6-1.6,0.8c-1.3,0.5-2.7,1-4.1,1.5c-0.8,0.3-2,0.6-2.8,0.3c-0.1,0,0,0.2-0.2,0.2
	c-0.5,0.1-0.9,0.2-1.4,0.3c-0.9,0.3-1.7,1.2-2.8,1.4c0.1,0,0.1,0,0.2,0.1c-1.6,1.7-2.2,4.2-2.7,6.3c-0.2,0.9-0.9,1.6-1.3,2.4
	c-0.4,0.9-1,1.6-1.4,2.4c-0.5,1-1.8,1.8-2.9,1.9c-1,0.1-1.7-0.3-2.6-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c0.6-0.2,0.9-0.9,1.4-1.3
	c0.5-0.5,1.2-1,1.7-1.5c0.5-0.4,2.4-1.9,2.1-2.5c0.5-0.1,1.1-1.7,1.3-2.2c0.6-1.1,1-2.5,1.8-3.4c0.9-1,1.8-2.1,3-2.8
	c1.5-0.1,2.9-0.3,4.4-0.6c3-0.5,6-2,8.7-3.3c1.3-0.6,2.6-1.2,3.8-1.9C276,14.3,277.5,14.1,278.6,13.6z M281.8,12.7
	c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.6,0-0.6,0c-0.2,0.1-0.1,0.2-0.4,0.3c-0.3,0.1-0.6,0.1-0.9,0.1
	c0.3-0.1,0.5-0.2,0.8-0.3c0.6-0.2,1.2-0.3,1.7-0.4C281.5,12.8,281.6,12.7,281.8,12.7z M283.9,13.2c0,0,0.1,0,0.1,0
	c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0,0c0.7,0.3,1.4,0.6,2.1,1c1,0.5,2,1,2.9,1.5c0.9,0.5,1.8,1.1,2.7,1.5c1.4,0.7,2.6,1.7,4.1,2.1
	c-0.3,0.7-0.7,0.7-1.4,0.3c-1.4-0.7-2.7-1.4-4-2.3c-0.9-0.7-1.9-1.3-2.9-1.8c-0.4-0.2-0.8-0.3-1.1-0.5c-0.5-0.2-0.9-0.4-1.4-0.6
	c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-0.7-0.2-1.1-0.3
	c-0.5-0.1-0.9-0.2-1.4-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4,0
	C283.3,13,283.6,13,283.9,13.2z M289.9,15.1c0.4,0.2,0.7,0.5,1.1,0.7c0.6,0.3,1.2,0.6,1.8,0.9c1.2,0.6,2.5,1.5,3.9,1.8
	c-0.2,0.6-0.3,0.7-0.9,0.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.3-1.3-0.6-1.8-1c-0.6-0.5-1.4-0.9-2.1-1.2c-1.4-0.7-2.8-1.4-4.2-2.1
	c-0.9-0.4-1.7-0.8-2.6-1.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.4-0.1
	C286,12.3,288.3,14.4,289.9,15.1z M289.1,13.5c0.8,0.7,1.9,1.1,2.9,1.6c0.9,0.4,1.8,0.9,2.7,1.3c0.7,0.3,1.5,1.1,2.3,0.9
	c0.1,0.1-0.1,0,0.2,0.1c-0.2,0.4-0.1,0.6-0.6,0.5c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.2-0.9-0.5-1.4-0.7c-1.1-0.6-2.2-1.1-3.4-1.7
	c-2-1.1-4-2.8-6.2-3.3c0.4,0,0.7-0.2,1.1-0.1c0.3,0.1,0.6,0.2,0.9,0.3C287.7,12.8,288.4,13,289.1,13.5z M297.5,16.5
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.3-1.3-0.4-1.9-0.8c-0.2-0.2-0.5-0.3-0.7-0.4c-0.8-0.4-1.5-0.8-2.3-1.2
	c-0.8-0.4-1.6-0.7-2.3-1.1c-1.1-0.7-2.1-1.5-3.4-1.8c0.8-0.2,1.6,0.5,2.3,0.9c0.6,0.3,1.2,0.6,1.8,0.9c0.8,0.4,1.6,0.7,2.4,1.1
	C294.2,15.3,296,16.6,297.5,16.5z M292.8,13.7c1.4,0.7,3.3,1.3,4.9,1.4c-0.1,0.4-0.1,1-0.4,1c-0.2,0-0.3-0.1-0.5-0.2
	c-0.5-0.1-1-0.2-1.5-0.5c-2.6-1.3-5.2-2.5-7.8-3.9c0.6-0.1,1.3,0.5,1.9,0.8c0.5,0.3,1,0.6,1.6,0.7
	C291.6,13.3,292.3,13.5,292.8,13.7z M289.8,12c0.4,0.1,0.9,0.2,1.3,0.3c0.9,0.2,1.7,0.5,2.6,0.7c1.4,0.4,2.8,1,4.3,1.1
	c-0.1,0.3,0,0.6-0.4,0.6c-1.5-0.1-3.2-0.6-4.6-1.3c-0.4-0.2-1.1-0.5-1.6-0.6c-1-0.3-2-0.8-2.9-1.3C288.8,11.4,289.5,11.9,289.8,12z
	M298.2,13.5c0,0.1-0.1,0.2-0.1,0.3c-1.5,0-2.9-0.7-4.3-1.1c-1.4-0.4-3.2-0.6-4.5-1.3c0.5,0,1-0.1,1.5-0.1c0,0-0.1,0.1-0.1,0.1
	c0.4,0.3,1.2,0.3,1.7,0.4c0.7,0.1,1.3,0.4,2,0.4c0.8,0,1.8,0.8,2.6,0.7C297.1,13.2,297.8,13.4,298.2,13.5z"
        />
      </g>
      <path
        fill={data.h4 !== "" ? data.h4 : "#86981f"}
        d="M105.6,1.7c-1,0.1-2.2-0.6-3.1-0.6c-4-0.1-8.2,2.1-12.3,2.6C81.1,5,72.8,4.2,65.6,1.7c0.2-0.1,0.4-0.2,0.7-0.3
c-0.8-0.1-1.6-0.2-2.4-0.6c-0.4-0.2-0.7-0.3-1.1-0.6c-0.2-0.1-0.3-0.2-0.5-0.3c-1.6,0.9-3.5,0.9-5.8,1.9c-0.8,0.3-1.4,1.2-2.1,1.7
c-5.4,3.5-13.2,8.1-21.4,9.2c-4.5,0.6-7.6-1-12.4-0.6c-0.3,0.4,0.2,1.3-0.8,1.2c-6.5,1.3-18.4-2.1-18.4-2.1L0,17.6
c0,0,8.3,0.6,12.3,1.2c2.2,0.3,4.1,0,6.4,0.1c4.8,0.2,9.4,2.2,15.5,0.2c1.9,1.3,6.3,1.1,7.4,4c1.6,4.1,1.8,5.4,1.7,4.9
c-0.2-1.5,0.1-2.9,0.6-4.4c1.9,1.1,3.2,2.9,4.9,4.3c1.2,0.1,2.3,0.7,3.4,1.8c-0.7-1.3-0.9-2.6-0.6-4c-0.2,0-0.5,0-0.7-0.1
c-3.2-2.4-6.6-4.5-9.3-7.6C49.4,14.5,55.1,9,64,6.6c4.9-1.3,8-0.4,13,0c3.8,0.3,7.7-0.5,11-0.3c1.2,0.1,2.3,0.8,3.4,0.8
c3.1,0.2,5.9-0.7,9.1-1.2c7-1,11.9,1.4,18.2,2.7c2.9,0.6,5.8,0.7,8.6,1.2c5.8,1.1,10.9,2.1,16.2,3.9c2.6,0.8,5.4,1.4,7.8,2.4
c1.1,0.4,2,1.4,3.1,1.9c3.7,1.6,7.5,2.1,11,3.5c6.4,4.3,13.5,7.5,20.3,11.1c1.8,1.9,4.2,3.7,6.3,6c2.1,2.3,5.3,4.9,6.1,7.8
c3.4,11.4,10.8,18.6,18.6,23.2c0.3,0.6,0.6,1.3,1.1,1.8c0.3-0.2,0.4-0.4,0.9-0.4c-0.3-3.2-3-4.1-4.9-5.3
c-5.8-3.6-9.9-10.3-13.4-17.4c-0.7-1.5-0.9-3.2-1.6-4.7c-1-2.1-3.7-4.3-5.2-6.5c-1.4-2.1-2-3.6-4.4-3.6c-7.7-6.6-18.9-9.4-26.1-15.7
c7.1-0.5,14-0.1,21.1-1.2c3.2-0.5,6.4-1.8,9.6-2.3c3.4-0.5,7.2-0.3,10.8-0.3c3.7,0,7.4-0.2,10.6,0.3c6.7,0.9,3.2-1.5,2.7-1.3
c-1.6,0.4-3.2-0.1-4.6-1.1c-0.9,0.2-1.9,0.3-3,0.1l-5.9-1c-5.5,1.2-11.9,0.8-17.3,1.5c-2.6,0.3-5.1,1.1-7.6,1.4
c-9.2,1-15.7,1.5-23.3,0.2c-4.5-0.8-8.7-2.2-13.2-3.5c-4.3-1.2-8.5-3.1-12.8-4.1c-6.5-1.5-13.2-2.3-19.6-3.7
C108.9,1.8,108.3,1.6,105.6,1.7z"
      />
      <g>
        <path
          fill={data.h4 !== "" ? data.h4 : "#86981f"}
          d="M239.5,107.1c0.2-0.3-0.1-0.3-0.1-0.2c-0.1,0.2-0.4,0.3-0.6,0.3c-0.4,0-0.3-0.4-0.3-0.7c0-0.4-0.1-0.8-0.2-1.1
	c-0.1-0.6-0.3-1.2-0.5-1.8c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.4,0.5,1,0.4,1.4c0.1,0,0.2,0,0.2,0c0.1-0.5-0.3-1.1-0.5-1.5
	c-0.2-0.6-0.4-1.1-0.7-1.6c-0.3-0.5-0.5-1.1-0.8-1.6c-0.1-0.1-0.6-1-0.7-1c-0.2,0-0.5-0.6-0.6-0.7c-0.2-0.2-0.3-0.4-0.5-0.6
	c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.6-1-1.1-1.6-1.6c-0.4-0.3-0.7-0.8-1.1-1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.4-0.2-0.6-0.3
	c-0.9-0.7-1.7-1.4-2.5-2.1c-0.9-0.8-1.7-1.5-2.3-2.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.1-0.2-0.1-0.2-0.1-0.5c-0.1-0.3-0.4-0.7-0.7-0.8
	c0.1,0,0.2,0,0.3,0c-0.9-0.6-1.4-1.6-2-2.5c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.2,0-0.1-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.4-0.3-0.6-0.5c-1.1-0.8-2.3-1.5-3.5-2c-0.4-0.2-1-0.3-1.5-0.3c-0.5,0-1-0.1-1.4,0
	c-0.2-0.9,0.4-2,0.9-2.7c0.5-0.8,0.3-0.9-0.2-0.1c-0.5,0.8-1.1,1.8-0.9,2.7c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.4,0
	c-0.3,0-0.5-0.2-0.8-0.2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.2c-0.8,0.3-1.7,0.5-2.5,1c-0.4,0.3-0.8,0.6-1.1,0.9
	c-0.3,0.2-0.5,0.6-0.8,0.6c0.2,0.6-0.9,0.7-1,1.1c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2,0.5-0.4,0.4c-0.1,0-0.2,0.1-0.2,0.2
	c0,0,0.1,0,0.1,0.1c-0.4,0.5-0.7,1.2-1.1,1.7c-0.4,0.5-0.6,1-1.2,1.3c0.3,0.4-1.2,1.6-1.5,1.8c-0.8,0.5-1.6,0.9-2.5,1.3
	c-0.9,0.4-1.8,0.7-2.7,1.1c-0.9,0.4-1.8,0.7-2.7,1.2c-0.2,0.1-0.4,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.8,0.5-1.2,0.8
	c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.5-0.9,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.7-0.7,1
	c-1,1.5-1.7,3.2-1.8,5c0,0.8,1.2,0.2,1.2,0.7c0.1,0,0.2,0,0.2-0.1c0.2,0.1,0.5,0.2,0.8,0.3c0.1,0,0.4-0.1,0.4,0.2
	c0.1,0,0.1,0.1,0.2,0c0-0.1,0-0.1,0-0.2c0.2,0,0.1,0.1,0.3,0.1c0.1,0,0-0.1,0.1-0.1c0.1-0.2,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0
	c0.2,0,0.7-0.2,0.9,0c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1-0.1,0.2-0.1h0c0.1,0,0.2,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0.2,0.2,0.3,0.3,0.3c0-0.3-0.1-0.4,0.2-0.5
	c0.2-0.1,0.8-0.4,0.9-0.3c0.1,0.1,0.3-0.5,0.4-0.2c0.1-0.1,0.3,0,0.2-0.2c0.2-0.1,0.9-0.5,1-0.4c0.1,0,0.2,0.2,0.2,0.3
	c0-0.1,0-0.3,0-0.5c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.5-0.5,0.7-0.3
	c-0.2-0.4,0.3-0.3,0.4-0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.2-0.3
	c0.3-0.2,0.7-0.6,1-0.5c0-0.1,0-0.1,0-0.2c0.1,0.1,0.2-0.2,0.2-0.2c0.4-0.1,0.5-0.8,0.9-0.6c0.1-0.1,0.1-0.2,0-0.2
	c0.2-0.2,0.5-0.6,0.8-0.4c-0.2-0.2,0-0.4,0.2-0.4c0.2-0.4,0.8-0.9,1.1-1.2c0.1,0.1,0.1,0,0.2-0.1c-0.1-0.4,0.4-0.1,0.4-0.4
	c0,0,0,0-0.1,0c-0.1-0.4,0.4-0.1,0.6-0.1c-0.1-0.2,0-0.3-0.2-0.3c0-0.1,0.2-0.3,0.2-0.3c0.2-0.2,0.1-0.1,0.3,0
	c0.1,0.1,0.2-0.2,0.1-0.3c0,0-0.1,0-0.1,0c-0.1-0.1,0.1-0.2,0.2-0.3c0,0.2,0.1,0.3,0.3,0.4c0.1-0.2,0-0.3,0-0.5
	c0-0.1,0-0.2,0.2-0.3c0,0.1,0,0.2,0.2,0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.3,0.5-0.5,0.7-0.7
	c0.2-0.2,0.5-0.7,0.9-0.7c0-0.4,0.6-0.6,0.9-0.7c0.5-0.3,1.1-0.6,1.7-0.8c0,0.1,0.1,0.1,0.2,0.1c0.1-0.2,0.2-0.5,0.5-0.3
	c0.1-0.5,1.7-0.7,2.2-0.9c0.7-0.3,1.2-0.9,1.4-1.7c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0,0,0.5,0,0.6c0.2,0.6,0.6,0.9,1.1,1.2
	c0.4,0.2,0.8,0.5,1.2,0.7c0.3,0.2,1.2,0.5,1.3,0.8c0.2,0,1,0.7,0.7,0.7c0,0.1,0,0.3,0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.3
	c0.2,0.2,0.3,0.3,0.3,0.5c0,0.1,0.1,0,0.1,0c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.2,0.6,0.3,0.8c0-0.1,0.2,0.2,0.2,0.1
	c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0-0.1,0.4-0.1,0.5c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0.3,0.2,0.3,0,0.6c0,0,0-0.2-0.1,0.1
	c0,0.1,0,0.3,0,0.5c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0.3,0.2,0.8,0.1,1.2c0,0,0.1-0.1,0.1-0.1c0.2,0.3,0.2,0.5,0.3,0.8
	c0,0,0.1-0.1,0.1-0.1c0.3,0.8,0.8,1.6,1.1,2.3c0.1,0.2,0.1,0.2,0.2,0.4c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.6,0.6,0.5,0.9
	c0.2-0.3,0.3,0.1,0.4,0.2c0.2,0.2,0.4,0.5,0.6,0.7c-0.2,0.2-0.1,0.4,0.1,0.2c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.1,0.3,0.2,0.7
	c0-0.1,0.1-0.1,0.2-0.2c0.2,0.2,0.1,0.4,0.1,0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.4,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.1
	c0,0.2-0.1,0.4-0.1,0.6c0.3-0.2,0.3-0.4,0.5-0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.1-0.2-0.1,0.2c0.1,0.1,0.2,0.1,0.2,0
	c0,0,0.5,0.5,0.5,0.5c0,0.1-0.1,0.3-0.1,0.3c0.4-0.3,0.5-0.1,0.9,0.2c0,0.1,0.8,0.5,0.8,0.7c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.7,0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.3,0,0.8,0,0.8,0.4c0.1-0.1,0.2-0.2,0.3-0.3
	c0.2,0,0.3,0,0.5,0c0.2,0,0.2,0.2,0.4,0.2c0,0,0-0.1,0-0.1c0.4,0,0.9-0.1,1.3,0c0.1-0.2,0.4-0.2,0.4,0c0,0,0.1,0.1,0.2,0.1
	c0-0.1,0-0.3,0-0.3c0.1,0,0.3-0.1,0.3,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.4-0.5,0.4-0.2c0,0.2-0.1,0.4,0.2,0.5c0.2,0,0.2-0.2,0.2-0.3
	c0-0.3,0.1-0.2,0.4-0.3c0.2,0,0.4-0.1,0.6-0.1c0.3-0.1,0.6-0.3,0.9,0c0-0.1,0-0.1,0-0.2c0.1,0,0,0.1,0.2,0.1c0.2,0,0-0.1,0.3-0.2
	C239.1,107.3,239.4,107.3,239.5,107.1z M214.4,88.8C214.3,88.9,214.4,88.8,214.4,88.8L214.4,88.8z M211.5,90.3
	c0.3-0.6,1-0.5,1.6-0.7c0.5-0.1,0.9-0.2,1.3-0.6c-0.5,0.6-1.1,0.8-1.8,0.9C212.2,90,211.8,90.1,211.5,90.3z M211,90.5
	c0.1-0.2,0.2-0.5,0.4-0.6c-0.1,0.1-0.1,0.5-0.2,0.5C211.1,90.4,211.1,90.5,211,90.5z M210.4,90.7c0-0.2,0.2-0.8,0.6-0.7
	c-0.1,0.2-0.2,0.4-0.2,0.6C210.6,90.6,210.5,90.7,210.4,90.7z M209.8,91c0-0.2,0.1-0.4,0.3-0.6c0.2-0.3,0.1-0.4,0.5-0.4
	c-0.2,0.2-0.2,0.8-0.5,0.9C209.9,90.9,209.8,91,209.8,91z M209.2,91.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.2-0.4,0.5-0.4
	c-0.2,0.4-0.5,0.7-0.5,1.2C209.4,91.2,209.3,91.3,209.2,91.3z M208.7,91.6c0.1-0.4,0.2-1.6,0.8-1.6c-0.1,0.3-0.3,0.5-0.4,0.9
	C209,91.3,209.1,91.4,208.7,91.6z M208.2,91.9c0-0.5,0.3-2,0.9-1.9c-0.3,0.5-0.6,1.1-0.6,1.7c0,0,0.1,0,0.1,0
	C208.4,91.7,208.3,91.8,208.2,91.9z M207.6,92.5c0-0.6,0.1-1.2,0.4-1.8c0.1-0.2,0.4-0.9,0.6-0.8c-0.4,0.6-0.7,1.5-0.7,2.2
	C207.8,92.2,207.7,92.4,207.6,92.5z M206.9,93.2c0-0.2,0-0.5,0-0.7c0.1-0.3,0.2-0.7,0.3-1c0.1-0.3,0.8-1.6,1-1.5
	c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.3,0.8c-0.1,0.4,0,1.2-0.3,1.5C207.1,93,207,93.1,206.9,93.2z M206.2,92.8
	c0-0.5,0.1-1.1,0.4-1.5c0.2-0.4,0.8-1.6,1.4-1.5c-0.3,0.5-0.8,1.2-1,1.7c-0.1,0.3-0.4,1-0.3,1.1c-0.1,0.1-0.1,0.6-0.1,0.8
	c-0.1,0.2-0.2,0.3-0.4,0.5C206.2,93.5,206.2,93.1,206.2,92.8z M206.2,90.6c0.2-0.3,0.8-1.3,1.2-1c-0.7,0.4-1.4,1.7-1.4,2.5
	c0,0.3-0.1,0.6-0.1,1c0,0.4,0.1,0.8-0.2,1.1c0-0.3-0.1-0.6-0.1-0.9C205.5,92.5,205.8,91.4,206.2,90.6z M205.3,91.7
	c0.1-0.5,0.2-0.9,0.5-1.4c0.2-0.3,0.6-0.9,1-0.7c-0.8,0.7-1.1,1.8-1.3,2.7c-0.1,0.4-0.1,1,0,1.3c0.1,0.5,0.2,0.8,0,1.1
	C205.2,93.7,205.2,92.8,205.3,91.7z M204.7,91.8c0.1-0.7,0.7-2.4,1.5-2.3c-0.9,0.6-1.1,1.7-1.2,2.7c0,0.5-0.1,1.2,0,1.7
	c0,0.3,0.1,0.5,0.2,0.8c0,0.3-0.1,0.4-0.3,0.6C204.6,94.2,204.6,93,204.7,91.8z M204.1,92.5c0-0.7,0.2-1.5,0.6-2.2
	c0.2-0.4,0.5-0.9,1-0.9c-0.9,0.8-1.3,1.9-1.3,3.1c0,0.6,0,1.3,0.1,1.9c0,0.4,0.4,1,0.1,1.4C204,94.7,204.1,93.5,204.1,92.5z
	M203.4,93.1c0-1,0.3-3.2,1.4-3.5c-0.4,0.6-0.8,1.4-0.9,2.1c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.3
	c0.1,0.4,0.1,0.8,0.2,1.1c0.1,0.3,0.3,0.5,0.1,0.8C203.8,95.2,203.5,94.1,203.4,93.1z M203.8,95.7c0.1,0.3,0.4,0.4,0.1,0.7
	C203.8,96.1,203.8,96,203.8,95.7z M203.5,94.9c0.1,0.5-0.1,1.2,0.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0,0-0.1-0.1
	C203.3,96,203.2,95.6,203.5,94.9z M203.4,94.4c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0.1,0.5,0.2,0.7
	c0.1,0.3-0.2,0.3-0.2,0.5C202.3,97.2,203.3,94.7,203.4,94.4z M202.2,97c-0.1-0.3,0-0.7,0.2-1c0.3-0.7,0.7-1.5,0.9-2.2
	c0.1,0.3-0.1,0.6-0.2,0.9c-0.2,0.4-0.3,0.9-0.4,1.3c-0.1,0.4-0.1,0.8,0,1.1c0.1,0.4,0.3,0.4,0,0.6c0,0,0-0.1-0.1-0.1
	C202.4,97.6,202.2,97.2,202.2,97z M202,95.5c0.4-1,0.8-2,1.2-3c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.3-0.1,0.4
	c-0.1,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.6,1.1-0.5,1.7c0,0.3,0.3,0.8,0.5,1c-0.2,0.2-0.4,0.3-0.5,0.5C201.3,97.5,201.7,96.3,202,95.5z
	M201.4,98.5c-0.4-0.6-0.2-1.4-0.2-2c0-0.8,0.4-1.6,0.7-2.3c0.3-0.9,0.7-1.6,1.2-2.4c0.3-0.5,0.5-1.3,1-1.7
	c-0.4,0.7-0.5,1.4-0.9,2.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.3-0.2,0.7-0.4,1c-0.4,0.7-0.8,1.7-0.8,2.5c0,0.2,0,0.5,0,0.7
	c0,0.3,0.4,0.9,0.2,1.1C201.6,98.4,201.4,98.6,201.4,98.5z M203.4,90.6c0-0.1,0-0.1,0.1-0.2C203.5,90.5,203.4,90.6,203.4,90.6z
	M200.5,98.4c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.7,0.3-1.4,0.5-2c0.6-1.5,1.2-3.2,2.3-4.4c-0.8,1.5-1.7,2.9-2,4.6
	c-0.2,0.7-0.7,3,0.2,3.3c-0.1,0.1-0.3,0.2-0.4,0.3c0,0,0,0-0.1-0.1C200.7,98.8,200.5,98.8,200.5,98.4z M200.1,98.7
	c-0.2-0.4-0.4-0.7-0.4-1.2c0.1-0.9,0.2-1.7,0.6-2.5c0.2-0.5,0.4-1.1,0.7-1.5c0.3-0.4,0.4-0.9,0.7-1.2c0.2-0.3,0.5-0.6,0.7-0.9
	c0.1-0.2,0.3-0.4,0.6-0.6c-0.5,0.6-1.1,1.5-1.4,2.2c-0.4,0.9-0.8,1.7-1.1,2.7c-0.2,0.6-0.4,1.3-0.4,1.9c0,0.3,0,1.1,0.3,1.3
	c0.1,0.1,0.3,0.3,0.4,0.4C200.6,99.4,200.2,98.8,200.1,98.7z M199.7,100c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.4,0.2
	C200,99.9,199.8,100,199.7,100z M199.2,99.2c0.1,0.2,0.2,0.1,0.2,0.3c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.2-0.1,0.3
	c-0.1,0.1-0.1,0.1-0.2,0C199.1,99.9,199.2,99.5,199.2,99.2z M199.2,96.8c0.1-0.8,0.4-1.5,0.7-2.2c0.5-1.2,1-2.2,2.1-3
	c-1,1.3-1.8,2.9-2.3,4.4c-0.2,0.6-0.3,1.4-0.2,1.9c0.1,0.7,0.6,1.2,1.1,1.5c-0.2,0.2-0.1,0.2-0.3,0c-0.3,0.4-0.6-0.5-0.8-0.3
	c-0.2-0.1-0.3-0.4-0.3-0.6C199,98,199.1,97.3,199.2,96.8z M199,98.6c0.1,0.4,0,0.7,0,1.1c0,0.2-0.1,0.6,0.1,0.8
	c-0.1,0-0.1,0.1-0.2,0.1C198.3,100.2,198.7,99.1,199,98.6z M198.3,99.4c0.2-0.5,0.4-1.1,0.7-1.5c0,0.5-0.4,0.8-0.4,1.3
	c0,0.4-0.3,1.2,0.2,1.5c-0.3,0.2-0.6,0.4-0.8,0.6c0,0-0.1-0.1-0.1-0.1C197.5,100.6,198,99.8,198.3,99.4z M197.3,101.4
	c-0.3-0.7,0.1-1.6,0.4-2.1c0.4-0.8,0.9-1.6,1.3-2.3c0,0.7-0.5,1.3-0.8,2c-0.3,0.8-1.1,1.7-0.5,2.5
	C197.5,101.5,197.4,101.6,197.3,101.4z M196.6,100.4c0.1-0.5,0.3-1.1,0.6-1.6c0.5-1,1.4-1.8,1.9-2.8c0,0.1-0.1,0.6-0.2,0.6
	c-0.1,0-0.2,0.3-0.3,0.4c-0.3,0.5-0.5,1.1-0.9,1.6c-0.5,0.7-1.3,2.2-0.6,3c-0.1,0-0.3,0.1-0.4,0.2c0-0.2-0.1-0.2-0.2-0.4
	C196.7,101.1,196.5,100.8,196.6,100.4z M196.2,101.2c0-1.4,0.4-2.5,1.1-3.7c0.4-0.7,1-1.3,1.5-1.9c0.4-0.5,0.7-1.1,1.1-1.6
	c-0.2,0.5-0.3,1-0.6,1.4c0,0.2-0.5,0.7-0.6,0.9c-0.6,0.9-1.3,1.6-1.8,2.6c-0.2,0.4-0.4,0.9-0.5,1.3c0,0.2,0,0.5,0,0.8
	c0,0,0.1,0.3,0.1,0.3c0,0.3,0.3,0.5,0,0.6C196.1,102.2,196.2,101.5,196.2,101.2z M195.7,100.2c0.3-1.1,0.6-2.2,1.3-3.1
	c0.7-0.9,1.5-1.7,2-2.7c0.2-0.4,0.5-0.9,0.9-1.2c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.3,0.4-0.3,0.7-0.5c-0.5,0.8-1.1,1.4-1.6,2.2
	c-0.6,0.9-1.3,1.7-1.9,2.6c-0.3,0.5-0.5,0.9-0.8,1.4c-0.2,0.4-0.5,0.8-0.5,1.2c0,0.5-0.2,1-0.2,1.5c0,0.3,0,0.6,0.1,0.8
	c0,0.2,0,0.2-0.2,0.2C195.5,101.6,195.5,100.9,195.7,100.2z M195.1,101.7c0-0.9,0.1-1.8,0.5-2.7c0.4-0.9,0.9-1.6,1.4-2.4
	c0.4-0.8,1-1.5,1.5-2.2c0.2-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.4-0.5,0.8-0.7c-0.3,0.4-0.7,0.7-0.9,1.1c-0.3,0.4-0.5,0.9-0.8,1.3
	c-0.6,0.7-1.2,1.4-1.7,2.2c-0.5,0.8-0.8,1.8-1,2.7c-0.2,0.2-0.2,0.9-0.2,1.2c0,0.4,0.1,0.8,0.3,1.1c-0.1,0.1-0.3,0.1-0.4,0.1
	c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0C195.2,102.2,195.1,102,195.1,101.7z M194.6,102.5c0-1.7,0.3-3.3,1.2-4.8c0.5-0.9,1.1-1.7,1.7-2.5
	c0.5-0.6,0.8-1.4,1.4-1.8c-0.1,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.6,0.8-0.9,1.2c-0.6,0.8-1.1,1.7-1.6,2.5c-0.5,0.8-0.8,1.6-1,2.6
	c-0.1,0.4-0.1,1.2,0,1.6c0.1,0.2,0,0.4,0.1,0.5c-0.1,0-0.3,0.1-0.4,0.1C194.6,102.7,194.6,102.6,194.6,102.5z M194.4,102
	c0,0.2,0.1,0.9-0.1,0.9c0-0.4,0-0.8,0-1.2c0-1.4,0.4-2.9,1-4.1c0.4-0.9,1.1-1.7,1.7-2.5c0.3-0.3,0.6-0.6,0.8-1
	c0.2-0.3,0.5-0.7,0.9-0.8c-0.7,0.5-1.1,1.5-1.6,2.2c-0.7,0.9-1.2,1.8-1.8,2.8c-0.4,0.7-0.8,1.8-0.8,2.6
	C194.4,101.2,194.3,101.6,194.4,102z M193.8,103c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c-0.3,0-0.1-0.7-0.1-1c0,0,0,0,0,0
	c0-0.3,0-0.6,0.1-0.8c0-0.8,0.5-1.5,0.7-2.3c0.4-1.1,0.9-2.1,1.7-3.1c0.3-0.4,0.7-0.8,0.9-1.2c0.1-0.2,0.2-0.3,0.3-0.5
	c0.1-0.2,0.3-0.2,0.5-0.3c-0.9,1.2-1.8,2.2-2.6,3.4c-0.4,0.6-0.6,1.4-0.9,2c-0.1,0.3-0.5,1.4-0.3,1.6c-0.2,0.1-0.1,1.1-0.1,1.3
	c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1C194.1,102.9,194.1,102.9,193.8,103C193.8,103,193.8,103,193.8,103z M193,103.2c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1c0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0-0.2,0-0.3
	c0-0.2,0.1-0.4,0.1-0.7l0,0c0-0.1,0.1-0.3,0.1-0.5c0.2-0.7,0.5-1.5,0.8-2.2c0.3-0.8,0.9-1.8,1.4-2.5c0.3-0.4,0.5-0.7,0.8-1.1
	c0.2-0.4,0.5-0.4,0.9-0.5c-0.6,1-1.5,1.8-2.1,2.8c-0.3,0.6-0.6,1.2-0.8,1.8c-0.2,0.5-0.5,1-0.6,1.6c-0.1,0.4-0.2,0.7-0.2,1.1
	c0,0.2-0.1,0.5-0.1,0.7l0,0c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c-0.1,0.4,0.1,0.5-0.1,0.6c-0.1,0-0.1,0-0.2,0.1
	C193,103.2,193,103.2,193,103.2z M192.4,103.3c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0-0.5,0.1-0.9,0.1-1.4c0-0.1,0-0.2,0.1-0.3
	c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.2-0.5,0.4-0.8c0.5-0.7,0.9-1.4,1.4-2.2c0.3-0.4,0.5-0.9,0.8-1.3
	c0.2-0.3,0.2-0.5,0.5-0.6c-0.5,0.8-1.1,1.5-1.5,2.3c-0.4,0.8-0.8,1.6-1.2,2.4c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.2-0.1,0.3-0.1,0.5
	c-0.1,0.3-0.1,0.5-0.2,0.8c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.3,0,0.7-0.1,1.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2
	c0,0.1,0,0.3,0,0.4C192.7,103.2,192.6,103.3,192.4,103.3C192.4,103.3,192.4,103.3,192.4,103.3z M191.7,103.4c0-0.2,0.2-0.5,0-0.5
	c0.1-0.5,0-0.9,0.1-1.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0.1-0.4,0.2-0.7,0.3-1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0.4-0.7,0.9-1.3,1.4-1.9c0.2-0.3,0.5-0.7,0.7-1.1
	c0.2-0.4,0.2-0.6,0.6-0.7c-0.7,1.5-1.9,2.8-2.7,4.3c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.3,0.8-0.4,1.2
	c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.4,0,0.7c0,0.1,0,0.2,0,0.3c0,0.6,0,1.2-0.2,1.2
	C192,103.4,191.8,103.4,191.7,103.4z M191.1,102.5c0-0.3,0-0.7,0-1c0-0.3,0.1-0.6,0.2-0.9c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.4,0.3-0.7,0.4-1.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.2-0.4,0.4-0.8,0.6-1.1c0.1-0.1,0.1-0.2,0.2-0.3
	c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.4,0.6-0.8,0.9-1.3c0.1-0.2,0.4-0.6,0.3-0.7c0,0,0.3-0.1,0.3-0.1c-0.5,1.2-1.4,2.2-2.1,3.3
	c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.3,0.7-0.4,1.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c-0.1,0.4-0.1,0.9-0.1,1.3c0,0.3-0.2,0.6-0.1,0.9c-0.1,0-0.3,0.1-0.4-0.1
	C191,103,191.1,102.7,191.1,102.5z M190.5,102.5c0-0.3,0-0.8,0.1-1.1c0.1-0.5,0.2-1.1,0.4-1.6c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.8,0.9-1.1c0.1-0.1,0.2-0.2,0.2-0.3
	c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.9-1.6,1.5-1.9c-0.5,0.9-1.1,1.7-1.7,2.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.5,0.8-0.7,1.2
	c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.3,0.6-0.4,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5-0.2,1-0.2,1.4
	c0,0.3,0,0.7,0,1c0,0.2-0.1,0.4,0,0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2C190.3,103.1,190.5,102.7,190.5,102.5z M190.1,102.7
	c0-0.4,0-0.8,0-1.2c0-0.7,0.3-1.5,0.6-2.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.3-0.8,0.6-1.1l0,0c0.1-0.1,0.1-0.1,0.2-0.2
	c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.4,0.5-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.5,0.7-1,0.5-1.1c0.2-0.1,0.4-0.1,0.6-0.2
	c-0.4,0.5-0.7,1.1-1.1,1.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.2,0.2
	c-0.2,0.3-0.4,0.7-0.5,1.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.8-0.5,1.6-0.5,2.4c0,0.2,0,0.4-0.1,0.6c0,0.2,0.1,0.8-0.2,0.8
	C190,103.2,190.1,102.9,190.1,102.7z M189.6,103.3c0-1.6,0.4-3.1,1-4.5c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.2-0.3,0.3-0.6,0.5-1
	c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.4,0.4-0.8,0.8-1.1
	c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.2c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.4-0.5,0.8-0.9,1.1
	c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3
	c-0.1,0.2-0.2,0.4-0.2,0.6c-0.5,1.3-0.4,2.6-0.5,3.9C189.8,103.4,189.7,103.4,189.6,103.3z M191,95.9c-0.3,0.3-0.6,0.7-0.8,1.1
	c-0.1,0.1-0.1,0.2-0.1,0.2c-0.4,0.6-0.6,1.3-1,1.9c-0.5,0.9-0.9,2-1,3.1c0,0,0.1,0,0.1,0c-0.1,0.1-0.2,0.6-0.2,0.8c0,0-0.4,0-0.4,0
	c0.1-0.1,0.1-0.7,0.1-0.9c0.1-0.3,0.1-0.6,0.2-1c0.1-0.7,0.3-1.3,0.6-1.9c0.3-0.6,0.6-1.2,1.1-1.7c0.1-0.2,0.3-0.3,0.4-0.5
	c0.1-0.1,0.1-0.1,0.1-0.2C190.4,96.4,190.6,96.1,191,95.9z M191.6,95.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
	c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.5-0.5,1-0.7,1.5
	c-0.1,0.3-0.4,0.9-0.4,1.3c-0.2,0.1-0.2,1.5-0.2,1.7c-0.1,0-0.2-0.1-0.4-0.1c0-0.5,0.1-1.3,0.2-1.7c0.3-0.8,0.6-1.5,0.9-2.2
	c0.2-0.5,0.5-1,0.8-1.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.1,0.2-0.2
	C191.4,95.8,191.5,95.7,191.6,95.6z M192.4,95.2c-0.4,0.4-0.7,0.9-1,1.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.4,0.8-0.7,1.2
	c0,0.1-0.1,0.2-0.1,0.3c-0.7,1.5-1,3.2-1.1,4.8c-0.3-0.1-0.5-0.1-0.5-0.4c0-1.3,0.4-2.6,1-3.8c0.2-0.3,0.4-0.6,0.5-1
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0.2-0.3,0.4-0.6,0.6-0.9c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3
	C191.6,95.8,192,95.3,192.4,95.2z M203.6,88.5c0.4-0.1,0.9-0.1,1.3-0.1c0.5,0,1.1,0,1.6,0c0.2,0,0.5,0.1,0.7,0.2
	c0.2,0.1,0.5,0,0.8,0.1c0.4,0.1,0.5,0.3,1,0.3c0.9,0,1.8,0.2,2.7,0.1c0.9-0.1,2.2-0.4,2.9-1c-0.1,0.5-0.7,0.9-1.1,1.1
	c-0.6,0.1-1.2,0.3-1.8,0.4c-0.9,0.1-1.8,0.2-2.6,0.1c0,0,0-0.1,0-0.1c-0.2,0.1-0.6,0.1-0.8-0.1c0,0-0.5,0.1-0.5,0
	c-0.2-0.2-0.4-0.1-0.7-0.1c0,0-0.4-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.2,0-0.2-0.1c-0.3,0.1-0.5,0-0.8,0
	c-0.1,0-0.6,0.2-0.7,0.3c0-0.2-0.6,0.3-0.6,0.3c-0.4,0.3-0.8,0.6-1.2,0.9c-1,0.7-1.9,1.4-3,1.9c-0.5,0.2-0.8,0.4-1.3,0.5
	c-0.3,0-0.6,0.2-0.9,0.3c0-0.1-0.7,0.2-0.9,0.2c0-0.2-0.2-0.1-0.2,0c0,0.2-1.7,0.7-2,0.8c-0.3,0.1-0.1,0-0.4,0.1
	c-0.1,0-0.1,0.1-0.2,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0.1c0.1,0,0.2-0.1,0.2-0.1
	c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.7c0.5-0.3,1.1-0.4,1.6-0.7c1-0.4,2-0.8,3-1.2c1.1-0.4,2.1-0.9,3.1-1.5
	c0.4-0.3,0.8-0.7,1.2-1.1C202.9,89.2,203.2,88.6,203.6,88.5z M205.9,86.3c0.5-0.1,1,0.1,1.6,0.1c0.9,0,1.8,0.5,2.7,0.4
	c1.7,0,3.4-0.4,4.4-1.9c0,0.3,0,0.7,0,1c0,0.3,0.2,0.7,0.1,1c-0.2,0.5-0.9,0.8-1.3,1c-1.2,0.6-2.5,0.6-3.8,0.6
	c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1-0.1-0.4-0.1-0.5-0.1c-0.3-0.1-0.7-0.2-1-0.3c-0.6-0.3-1.2-0.2-1.8-0.1
	c0.2-0.2,0.3-0.5,0.4-0.7C204.9,86.8,205.6,86.4,205.9,86.3z M206.5,84.8c0.2,0,0.4,0,0.6,0c0.4,0,0.9,0.2,1.3,0.4
	c0.5,0.3,1.4,0.5,2,0.3c0.3-0.1,0.7-0.1,1-0.2c0.2-0.1,0.5,0,0.7-0.2c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.8-0.7,1.2-1.2
	c0.3-0.4,0.4-0.8,0.8-1.1c0,1-0.8,2-1.5,2.6c-0.6,0.5-1.4,1-2.2,1c-0.9,0.1-2-0.1-2.8-0.5c-0.7-0.3-1.7-0.5-2.4-0.3
	C206,84.9,206,84.8,206.5,84.8z M214.2,81.8c-0.4,0.7-0.8,1.2-1.4,1.8c-0.4,0.4-1.2,1-1.8,1c-0.7,0.1-1.3,0.2-2-0.1
	c-0.6-0.2-1.3-0.6-2-0.7c0.5-0.5,1.1-1.1,1.8-1.4c0.6-0.3,1.3-0.4,1.9-0.7c1.1-0.5,2.2-0.1,3.2,0.1C214,81.8,214.1,81.8,214.2,81.8
	z M214.6,81.5C214.6,81.6,214.6,81.6,214.6,81.5L214.6,81.5z M214.1,82.7c-0.3,0.4-0.5,0.9-0.9,1.2c-0.6,0.7-1.5,1.4-2.4,1.3
	c-0.4,0-0.7,0.1-1.1,0.1c-0.4,0-0.7-0.2-1-0.2c-0.3,0-0.8-0.3-1.1-0.4c-0.4-0.2-0.9-0.2-1.4-0.1c0.3-0.4,0.5-0.6,1-0.5
	c0.4,0.1,0.9,0.3,1.3,0.4c0.9,0.4,1.7,0.5,2.7,0.3c0.8-0.2,1.6-0.9,2.2-1.5c0.4-0.4,0.6-0.8,0.9-1.2c0.1-0.2,0.3-0.2,0.3-0.5
	c0,0.1,0,0.1,0,0.2C214.9,82,214.2,82.5,214.1,82.7z M214.7,83.3c0,0.6-0.1,1.1-0.4,1.6c-0.3,0.4-0.7,0.8-1.1,1.1
	c-0.8,0.4-1.8,0.4-2.6,0.6c-0.3,0-0.6,0.1-0.8,0c-0.6-0.1-1.1-0.2-1.7-0.4c-0.5-0.1-1-0.2-1.5-0.2c-0.6-0.1-1.1,0.2-1.7,0.5
	c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.7,0c0.5,0,0.8,0.1,1.3,0.2c0.4,0.1,0.8,0.3,1.2,0.4
	c0.5,0.1,1,0.1,1.5,0.1C212.4,86.3,213.9,85,214.7,83.3z M214.7,87.4c-0.2,1.1-1.6,1.3-2.6,1.5c-1,0.3-2-0.1-3,0
	c-0.5,0-0.6-0.2-1-0.3c-0.2-0.1-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.9-0.1-2-0.1-2.9,0.1c0.1-0.2,0.3-0.4,0.4-0.6
	c0.5,0,0.9-0.1,1.4,0c0.5,0.1,1,0.3,1.4,0.4c0.1,0.2,0.3,0,0.5,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6,0.2,0.9,0.3
	c0.4,0.2,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8-0.1c0.9-0.1,2.2-0.7,2.7-1.4C214.7,87.3,214.7,87.3,214.7,87.4z M233.3,106
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.8,0.2-1.6,0.4-2.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.4,0.1-0.8,0.1-1.3
	c0-0.8-0.1-1.5-0.3-2.2c0.2,0.2,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1,0,0.1
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.5,0.1,0.6c0,0,0,0,0,0c-0.1,0.1-0.1,0.6-0.1,1.1c0,0.3,0,0.6,0,0.7
	c-0.1,0.6-0.2,1.2-0.2,1.8c0,0.6-0.2,1.5,0,2.1c0.1,0.3,0.2,0.4-0.2,0.4c-0.3,0-0.8,0.2-0.8-0.2C233.1,107.2,233.3,106.6,233.3,106
	z M232.3,107.8c0-0.3,0-0.6,0.1-0.9c0-0.1,0-0.2,0-0.4c0.1-1,0.4-2.1,0.6-3.1c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.7
	c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.2-0.9,0.2-1.3c0-0.9-0.1-1.7-0.2-2.7c0.5,0.5,0.5,1.8,0.5,2.5c0,0.3,0,0.6,0,0.9
	c0,0.4,0,0.7-0.1,1.1c0,0.2-0.1,0.4-0.1,0.6c-0.2,1-0.4,2.1-0.6,3.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,0.9
	c0,0.2,0,0.5,0,0.7C233,107.9,232.3,107.9,232.3,107.8z M231.5,107.9c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c0-0.2,0-0.1,0-0.2
	c0-0.6,0.1-1.2,0.3-1.8c0-0.2,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.1-0.5c0.1-0.6,0.3-1.2,0.5-1.8
	c0.5-1.7,0.9-3.5,0.4-5.3c0.7,0.6,0.6,1.8,0.6,2.7c0,1-0.2,2-0.4,3c0,0.1-0.1,0.3-0.1,0.5c0,0.2-0.1,0.4-0.1,0.6
	c-0.2,0.9-0.5,1.8-0.5,2.7c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.7C232.1,108,231.8,107.9,231.5,107.9z M230.6,107.6
	c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0c0.1-0.2,0.1-0.7,0.2-1c0.2-0.5,0.4-0.9,0.6-1.4c0.3-0.9,0.6-1.9,0.9-2.9
	c0.5-1.7,0.9-3.6,0.3-5.3c0.5,0.4,0.5,1.6,0.5,2.2c0,1.1-0.3,2.1-0.6,3.2c-0.2,0.8-0.4,1.7-0.6,2.5c0,0.2-0.1,0.3-0.1,0.5
	c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.4-0.2,0.9-0.1,1.3C230.9,107.7,230.7,107.7,230.6,107.6z
	M229.4,106.7c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.4,0.4-0.9,0.6-1.3c0.4-0.9,0.7-1.8,1-2.8c0.3-0.9,0.6-2,0.7-2.9
	c0-0.8,0.1-1.9-0.2-2.6c0.6,0.4,0.5,1.5,0.5,2.1c0,0.7,0,1.2-0.2,1.9c-0.6,2.3-1.4,4.5-2.1,6.8c-0.1,0-0.1-0.1-0.2-0.1
	C229.6,107.1,229.3,107,229.4,106.7z M228.7,106.2c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.5,0.5-1.1,0.7-1.6c0.4-1,0.8-2,1.1-3
	c0.3-0.9,0.5-1.9,0.6-2.8c0.1-0.7-0.1-1.4-0.2-2.1c0.8,0.6,0.6,1.7,0.6,2.6c0,0.9-0.2,1.8-0.5,2.7c-0.3,0.9-0.5,1.9-0.9,2.8
	c-0.4,0.9-0.8,1.7-1.2,2.6c0,0,0,0-0.1-0.1C229,106.6,228.6,106.5,228.7,106.2z M228.2,106c0.2-0.2,0.2-0.4,0.3-0.7
	c0.2-0.5,0.5-0.9,0.7-1.3c0.4-0.9,0.8-1.9,1.1-2.9c0.3-0.8,0.3-1.7,0.5-2.5c0.2-0.9,0.2-1.7,0-2.6c0.3,0.3,0.4,1.5,0.3,2
	c0,0.9-0.3,1.7-0.5,2.6c-0.2,1-0.7,2-1,3c-0.3,0.9-0.9,1.7-1.2,2.6c0,0-0.1-0.1-0.1-0.1C228.3,106.1,228.3,106.1,228.2,106z
	M228.5,104.7c0.2-0.4,0.4-0.9,0.6-1.4c0.2-0.8,0.4-1.6,0.7-2.4c0.4-1.7,1.2-3.6,0.5-5.3c0.6,0.3,0.6,1.5,0.6,2.1
	c0,0.8-0.3,1.7-0.4,2.4c-0.2,1.1-0.6,2.2-1.1,3.2c-0.2,0.4-0.4,0.9-0.6,1.3c-0.2,0.4-0.4,0.9-0.6,1.2
	C227.8,105.5,228.3,105,228.5,104.7z M227.7,104.8c0.1-0.3,0.2-0.6,0.4-0.8c0.2-0.5,0.4-1,0.5-1.5c0.2-0.5,0.3-1,0.4-1.5
	c0.1-0.4,0.3-0.9,0.3-1.4c0-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4,0.1-0.7,0-1.1c-0.1-0.3,0-0.7-0.1-1
	c1.3,1.4,0.4,3.9,0,5.5c-0.2,0.8-0.5,1.7-0.7,2.5c-0.2,0.9-0.7,1.6-1.1,2.5C227.5,105.2,227.5,105.2,227.7,104.8z M227.5,103.6
	c0.2-0.5,0.3-1,0.5-1.5c0.2-0.9,0.5-1.7,0.7-2.6c0.2-0.7,0.2-1.4,0.3-2.1c0.1-0.5,0.1-1.1,0.1-1.6c0-0.5-0.2-0.9-0.2-1.3
	c0.3,0.3,0.5,0.4,0.6,0.9c0,0.2,0.1,0.5,0.1,0.6c-0.1,0.1,0.1,0.6,0,0.8c0,0.3,0,0.6-0.1,0.9c-0.1,0.8-0.2,1.6-0.5,2.3
	c-0.5,1.7-0.9,3.6-1.8,5.2c-0.1-0.1-0.1-0.1-0.2-0.2C227,104.7,227.4,103.9,227.5,103.6z M227.2,103.3c0.2-0.5,0.3-1,0.5-1.5
	c0.3-1.1,0.5-2.1,0.7-3.2c0.1-0.3,0.1-0.7,0.2-1c0-0.4,0.2-0.9,0-1.2c-0.1-0.4,0.1-0.9-0.1-1.3c-0.1-0.3-0.1-0.7-0.2-1
	c0.7,0.6,0.5,2.1,0.5,3c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,1-0.5,2-0.8,2.9c-0.1,0.5-0.3,1-0.5,1.5c-0.1,0.3-0.2,0.6-0.3,0.8
	c-0.1,0.2,0-0.3-0.2,0.1C226.6,104.3,227.1,103.5,227.2,103.3z M226.2,103.7c0,0.1,0.1,0.1,0.2,0.1c0.3-0.5,0.5-1,0.7-1.5
	c0.2-0.5,0.4-0.9,0.5-1.5c0.1-0.6,0.3-1.1,0.4-1.6c0.1-0.3,0-0.7,0.1-1c0-0.4,0.1-0.7,0.1-1.1c0-0.4,0.1-0.8,0.1-1.3
	c0-0.9-0.2-1.7-0.6-2.5c0.3,0.3,0.5,0.4,0.6,0.8c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4,0.1,0.8,0.1,1.3c0,0.2,0.1,0.4,0.1,0.6
	c0,0.2-0.1,0.5-0.1,0.7c0,0.3-0.1,0.6-0.1,0.8c0.1,0.5-0.2,1.1-0.2,1.5c-0.1,0.6-0.3,1.1-0.4,1.7c-0.2,1-0.6,2-1,3
	c-0.1-0.1-0.2-0.2-0.2-0.3C226.3,103.8,226.2,103.7,226.2,103.7z M227.5,93.8C227.5,93.8,227.5,93.8,227.5,93.8
	c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.2,0-0.1C227.4,93.5,227.4,93.7,227.5,93.8z M227.7,95c0-0.3-0.2-0.7-0.1-1
	c0.3,0.7,0.3,1.3,0.3,2c0,0.3-0.1,0.6-0.1,0.9c0,0.3,0.1,0.7,0,1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.2,0c0-0.4,0-0.8,0.1-1.2
	c0-0.2,0-0.3-0.1-0.3c0-0.1,0-0.2,0-0.3C227.7,95.7,227.7,95.3,227.7,95z M225.8,102.9c0.2-0.2,0.3-0.4,0.4-0.6
	c0.3-0.4,0.4-0.8,0.6-1.3c0.3-0.9,0.5-2,0.8-2.9c-0.1,0.3-0.1,0.7-0.1,1c0,0.5-0.3,1-0.3,1.5c-0.2,1-0.5,2.2-1.1,3.1
	C226,103.4,225.7,103.2,225.8,102.9z M225.1,102.3c0,0,0.9-1.3,1-1.5c0.3-0.6,0.7-1.1,1-1.7c-0.2,1.3-0.8,2.7-1.6,3.8
	C225.4,102.7,225.3,102.5,225.1,102.3z M225,101.6c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.4,0.5-0.9,0.8-1.3c0.6-0.7,0.8-1.5,1.2-2.3
	c0,1.1-0.7,1.8-1.1,2.8c-0.2,0.5-0.5,0.9-0.8,1.3c-0.2,0.3-0.4,0.3-0.5,0.6C224.7,101.9,224.8,101.9,225,101.6z M224.7,101.1
	c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.4,0.6-0.9,0.9-1.3c0.6-0.9,1.3-2,1.6-3.1c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.7
	c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.5-0.6,0.9-0.9,1.4c-0.3,0.4-0.6,0.8-0.9,1.2C224.4,101.5,224.4,101.4,224.7,101.1z M224.2,100.9
	c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.3-0.5,0.7-1,1-1.6c0.7-1.2,1.4-2.2,1.6-3.6c0.1,1.4-0.7,2.8-1.4,4
	c-0.3,0.5-0.7,1.2-1.1,1.7c-0.2,0.3-0.5,0.6-0.7,0.9C224.1,101.1,224,101.1,224.2,100.9z M223.7,100.6c0,0,1.3-1.8,1.4-2
	c0.3-0.6,0.6-1.2,1-1.8c0.8-1.4,1-2.9,0.6-4.4c0.4,0.5,0.7,1.4,0.6,2.1c-0.1,1-0.6,1.8-1,2.6c-0.4,0.8-1,1.6-1.5,2.4
	C224.6,99.8,224,101.1,223.7,100.6z M224,99.6c0.2-0.3,0.5-0.7,0.7-1.1c0.4-0.7,0.7-1.5,1.1-2.3c0.6-1.4,1-2.9,0.6-4.4
	c0.4,0.5,0.5,1.6,0.5,2.2c0,0.8-0.4,1.8-0.7,2.4c-0.4,0.8-0.7,1.5-1.2,2.2c-0.2,0.4-0.5,0.7-0.7,1.1c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.1,0.1,0-0.2-0.2,0C223.5,100.1,223.8,99.8,224,99.6z M223.4,100c0-0.1,0.5-0.8,0.5-0.9c0.2-0.3,0.4-0.7,0.5-1.1
	c0.4-0.9,0.9-1.8,1.2-2.8c0.5-1.5,0.4-2.9-0.1-4.4c0.5,0.6,0.7,1.4,0.7,2.2c0,0.5,0,0.9-0.1,1.4c-0.1,0.4-0.3,0.8-0.4,1.2
	c-0.3,0.9-0.7,1.8-1.1,2.6c-0.2,0.5-0.5,0.9-0.7,1.3C223.8,99.5,223.4,100,223.4,100z M223,99.3c0.6-0.7,1-1.6,1.3-2.4
	c0.3-0.8,0.7-1.7,0.8-2.6c0.1-0.7,0.3-1.3,0.2-1.9c0.1-0.8-0.3-1.8-0.7-2.5c0.6,0.2,0.7,0.8,0.9,1.3c0.1,0.3,0.2,0.7,0.2,1
	c0,0.2,0,0.5,0.1,0.6c-0.2,0.2-0.1,1-0.1,1.2c-0.1,0.7-0.4,1.5-0.7,2.2c-0.3,0.7-0.6,1.5-1,2.1c-0.2,0.3-0.4,1.2-0.8,1.3
	C223.2,99.6,223.1,99.4,223,99.3z M222.9,98.2c0.1-0.1,0.2-0.2,0.3-0.4c0.3-0.4,0.4-1,0.6-1.4c0.3-0.9,0.6-1.8,0.8-2.7
	c0.1-0.4,0.1-0.7,0.2-1.1c0-0.3-0.1-0.5-0.1-0.8c0-0.4-0.1-0.9-0.3-1.2c-0.1-0.4-0.4-0.7-0.6-1c0.7,0,1,1.2,1.2,1.8
	c0.2,0.8,0.4,1.8,0.1,2.6c-0.3,0.9-0.5,1.8-0.8,2.7c-0.3,0.9-0.8,1.7-1.2,2.4c0-0.1-0.1-0.1-0.1-0.2
	C222.7,98.5,222.7,98.5,222.9,98.2z M223,97c0.2-0.4,0.3-0.8,0.4-1.2c0.2-0.5,0.4-1,0.5-1.5c0.1-0.3,0.1-0.7,0.1-1.1
	c0.1-0.5,0.1-1.4-0.1-1.9c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.3-0.1-0.6-0.3-0.8c0.8,0.3,0.9,1.4,1.1,2.1c0.1,0.2,0.1,0.6,0.1,0.8
	c0.1,0.2,0,0.7-0.1,0.9c-0.2,0.4-0.3,1-0.3,1.5c0,0.4-0.3,1-0.4,1.4c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.3-0.4,0.4-0.5,0.5
	c0,0,0,0,0-0.1C222.3,97.7,222.9,97.3,223,97z M222.2,97.1c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.4,0.3-0.8,0.5-1.2
	c0.3-0.8,0.6-1.7,0.6-2.6c0-0.3,0-0.5,0-0.8c0,0-0.1-0.5-0.1-0.5c-0.2-0.7-0.2-1.6-0.7-2.2c0.4,0,0.5,0.3,0.6,0.6
	c0.1,0.3,0.3,0.7,0.4,1c0.2,0.5,0.5,1.4,0.3,2c-0.1,0.3-0.1,0.7-0.1,1c-0.1,0.4-0.2,0.9-0.4,1.3c-0.2,0.5-0.3,1-0.5,1.4
	c-0.1,0.3-0.4,0.6-0.6,0.9C222.2,97.4,222,97.3,222.2,97.1z M222.1,95.6c0.2-0.3,0.3-0.5,0.4-0.8c0.2-0.6,0.3-1.3,0.5-1.8
	c0.2-0.6,0.1-1.3,0-1.9c-0.1-0.6-0.3-1.3-0.8-1.6c0.4-0.1,0.5,0.2,0.6,0.5c0.1,0.3,0.3,0.7,0.3,1.1c0,0.2,0.1,1.3,0.3,1.4
	c-0.1,0.1-0.1,0.5-0.1,0.7c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.5-0.3,1-0.5,1.5c-0.2,0.4-0.2,0.8-0.5,1.1c0-0.2-0.1-0.4-0.1-0.6
	C221.8,96.1,221.9,95.9,222.1,95.6z M221.8,95.1c0.2-0.3,0.3-0.6,0.4-0.9c0.2-0.7,0.4-1.3,0.4-2c0-0.8-0.2-2.4-1.1-2.7
	c1.1,0,1.3,1.8,1.4,2.6c0,0.6-0.2,1.2-0.4,1.9c-0.1,0.3-0.2,0.7-0.3,1c-0.1,0.3-0.3,0.6-0.4,0.9C221.5,95.5,221.6,95.4,221.8,95.1z
	M221.1,94.8c0.3,0.3,0.5-0.7,0.5-0.8c0.3-0.6,0.4-1.1,0.4-1.7c0-1-0.3-1.9-1-2.6c0.9-0.1,1.3,1.6,1.3,2.3c0,1.2-0.3,2.4-0.9,3.4
	C221.3,95.1,221.2,94.9,221.1,94.8z M221.6,92.3c0-0.6,0-1-0.3-1.6c-0.2-0.4-0.4-0.7-0.7-1.1c0.3-0.1,0.8,0.8,0.9,1
	c0.2,0.4,0.2,1,0.3,1.3c-0.2,0.3-0.1,0.9-0.2,1.3c-0.1,0.3-0.2,0.5-0.3,0.8C221.6,93.5,221.6,92.7,221.6,92.3z M221.1,94.5
	c0.1-0.1,0.1-0.2,0.2-0.3C221.3,94.3,221.2,94.4,221.1,94.5C221.2,94.5,221.1,94.5,221.1,94.5z M221.1,94.5c0,0.1,0,0.1,0,0.2
	C221,94.6,221,94.6,221.1,94.5z M220.7,93.9c0.1-0.1,0.1-0.1,0.2-0.2c0-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.1-0.8,0-1.2
	c-0.2-0.7-0.1-1.4-0.5-2c0.3,0.4,0.6,0.9,0.7,1.3c0.1,0.4,0,0.8,0,1.2c0,0.5-0.1,1.5-0.5,1.9C220.8,94.2,220.8,94.1,220.7,93.9z
	M220.6,92.4c0.1-0.3,0-0.6,0-0.8c0-0.6-0.1-1.1-0.3-1.6c0.5,0.5,0.5,1.4,0.6,2c0.1,0.7,0,1.2-0.2,1.8c-0.1-0.2-0.1-0.4-0.3-0.6
	C220.2,92.9,220.5,92.6,220.6,92.4z M220.4,89.7C220.4,89.7,220.4,89.7,220.4,89.7c0.1,0.1,0.1,0.1,0.2,0.2
	C220.5,89.8,220.5,89.8,220.4,89.7z M220.3,89.8c-0.1-0.1,0-0.1,0.1-0.1C220.3,89.8,220.3,89.8,220.3,89.8z M219.8,92.3
	c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.5-0.1-1-0.2-1.4c0.4,0,0.5,1.2,0.5,1.5c0,0.5,0,0.9-0.2,1.4
	C220,92.6,219.9,92.5,219.8,92.3z M219.4,91.7c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.7c0-0.3,0.1-0.6,0-0.9
	c0.3,0.1,0.3,1,0.3,1.2c0,0.4-0.1,0.8-0.3,1.2C219.5,92,219.2,91.9,219.4,91.7z M219.1,91.2c0.1-0.2,0.1-0.4,0.1-0.6
	c0-0.3,0.1-0.6,0-0.9c0.7,0.1,0,1.7,0,2C218.8,91.5,219,91.5,219.1,91.2z M218.4,91.2c0.3-0.3,0.4-1.1,0.4-1.5
	c0.3,0,0.1,0.6,0.2,0.7c-0.2,0.2-0.1,0.7-0.3,1C218.6,91.3,218.5,91.3,218.4,91.2z M217.5,90.7c0.2-0.3,0.3-0.7,0.3-1
	c0.3,0.1,0.6,0,0.8,0c0,0.2,0,0.4,0,0.6c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1-0.1-0.2-0.1-0.4-0.2c0.2-0.1,0.4-1,0.4-1.2
	c-0.5-0.1-0.3,0.8-0.5,1.1C217.7,90.8,217.6,90.7,217.5,90.7z M217,90.4c0.2-0.2,0.2-0.5,0.3-0.7c0-0.2,0.2-0.1,0.4-0.1
	c-0.1,0-0.2,0.8-0.4,1C217.2,90.5,217.1,90.4,217,90.4z M216.5,90.2c0.2-0.1,0.2-0.5,0.2-0.6c0.1,0,0.2,0,0.3,0
	c0,0.3-0.1,0.5-0.2,0.7C216.7,90.2,216.6,90.2,216.5,90.2z M215.3,88.6c0.3,0.5,0.9,0.7,1.4,0.9c-0.3-0.1-0.3,0.4-0.2,0.6
	c-0.1-0.1-0.2-0.1-0.3-0.1C215.7,89.7,215.4,89.2,215.3,88.6z M215,85.9c0.3,0.3,0.8,0.4,1.1,0.6c0.3,0.2,0.6,0.1,0.9,0.3
	c0.4,0.2,1,0.5,1.4,0.5c0.3,0,0.6,0.3,0.9,0.2c0.6-0.2,1.3-0.1,1.9-0.1c0.9,0,1.6,0.1,2.4,0.6c-0.4-0.1-0.8-0.3-1.3-0.4
	c-0.4-0.1-0.8,0-1.2,0c-0.7,0-1.4-0.1-2.1-0.1c-0.8,0-1.6-0.4-2.3-0.7c-0.4-0.2-0.9-0.3-1.3-0.5c-0.2-0.1-0.4-0.2-0.4-0.4
	C215,86,215,85.9,215,85.9z M214.9,83.2c0,0.2,0,0.3,0,0.5C214.9,83.6,214.9,83.4,214.9,83.2z M214.9,82.8c0,0.1,0,0.2,0,0.2
	C214.9,83,214.9,82.6,214.9,82.8z M214.8,84.9c0,0.1,0,0,0-0.1C214.8,84.8,214.9,84.9,214.8,84.9z M214.9,82.7c0.1-0.4,0-0.1,0-0.2
	c0-0.1-0.1-0.7,0.2-0.7C215.1,82.1,215.1,82.5,214.9,82.7z M221.2,84.2c-0.6,0-1.4,0.1-2,0.2c-0.5,0.1-1.2-0.1-1.7-0.2
	c-1.2-0.3-1.9-1.2-2.1-2.4c0,0-0.1,0-0.1,0c0-0.3,2.3,0.3,2.5,0.4c1,0.4,1.9,0.9,2.8,1.5C220.8,83.9,221,84,221.2,84.2z
	M221.7,84.6c-0.7,0-1.3,0.4-2,0.5c-0.6,0.1-1.4,0-2-0.2c-0.6-0.2-1.2-0.7-1.7-1.1c-0.3-0.2-0.5-0.6-0.7-0.9c-0.1-0.2,0-0.5,0-0.7
	c0.2,0.6,0.4,1.2,0.9,1.6c0.3,0.3,0.7,0.3,1.1,0.5c0.5,0.2,1.5,0.4,2,0.3c0.5-0.1,1.4,0,1.7-0.2c0.1,0.1,0.4,0.1,0.5,0.1
	C221.6,84.6,221.6,84.6,221.7,84.6z M223.9,87.5c-0.8-0.5-1.4-0.8-2.4-0.8c-1.1,0-2.2-0.2-3.3-0.3c-0.6-0.1-1-0.2-1.6-0.4
	c-0.4-0.2-0.6-0.5-1-0.7c-0.4-0.2-0.7-0.5-0.6-1c0-0.4,0.1-0.7,0.1-1.1c0.6,1,1.8,1.8,2.9,2.1c0.5,0.2,1.3,0.2,1.8,0
	c0.3-0.1,1.4-0.3,1.5-0.5c0.1,0.2,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.5,0.8,1.1,1.2,1.7
	C223.6,87.1,223.8,87.3,223.9,87.5z M224.3,88.2c-0.6-0.3-1.1-0.6-1.7-0.8c-0.4-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.7-0.1-1.1,0
	c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.1c-0.5-0.2-1-0.2-1.4-0.5c-0.1-0.1-0.9-0.2-0.9-0.3c0-0.1-0.3-0.1-0.4-0.1
	c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2,0-0.2,0.1-0.4c0.5,0.5,1.2,1,1.8,1.3
	c0.8,0.3,1.7,0.4,2.6,0.5c0.6,0.1,1.2,0.2,1.8,0.2c0.4,0,0.8-0.1,1.2,0.1C223.1,87.3,224,87.6,224.3,88.2z M233.4,97.4
	c-0.1,0-0.1,0-0.2-0.1c0,0,0,0.1,0,0.1c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.4-0.1-0.5-0.4
	c0,0-0.3-0.2-0.3-0.1c-0.3-0.2-1.1-0.5-1.1-0.9c-0.3,0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.6-0.4-0.8-0.6c-0.6-0.5-1.2-1.1-1.8-1.7
	c-0.3-0.3-0.8-0.9-0.8-1.3c0,0-0.1,0-0.2,0c-0.2-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-1-0.6-1.4-1.1c0,0,0,0.1,0,0.1
	c-1.3-0.4-2.7,0-3.9,0.2c-0.5,0.1-1-0.1-1.6-0.1c-0.5,0-1-0.1-1.6-0.1c-0.6,0-1.4-0.4-1.7-1c-0.3-0.5-0.3-0.9-0.3-1.5
	c0-0.1,0-0.2,0-0.3c0.3,0.2,0.7,0.2,1,0.4c0.4,0.2,0.8,0.4,1.2,0.5c0.3,0.1,1.5,0.8,1.8,0.5c0.2,0.2,1.1,0.1,1.4,0.1
	c0.7,0,1.5-0.1,2.2,0.1c0.7,0.2,1.5,0.4,2.1,0.8c0.4,0.7,0.9,1.4,1.4,2.1c1,1.4,2.5,2.5,3.8,3.6c0.6,0.5,1.2,1,1.9,1.4
	C232.4,96.3,232.9,96.9,233.4,97.4z M234.7,98.8c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c0,0-0.1-0.3-0.1-0.3
	c-0.1-0.1-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.5,0.5,0.7,0.8
	C234.6,98.6,234.6,98.7,234.7,98.8z M235,100C235,100,235,100,235,100c0,0.2,0,0.3,0,0.4c0,0,0,0,0,0c0.1,0.4,0,0.9,0,1.3
	c0,0.6,0,1.2,0,1.8c0,0.6-0.1,1.2-0.1,1.7c0,0.9-0.2,1.8,0,2.6c-0.4,0-0.5-0.2-0.5-0.6c0-0.9,0-1.7,0.2-2.6
	c0.1-0.6,0.2-1.3,0.2-1.9c0-0.2,0-0.5,0-0.7c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
	c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.1-0.5-0.2-0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2
	c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2C234.9,99.6,234.9,99.8,235,100z M235.5,103.5c0,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.8,0,1.1
	c0,0.8-0.1,1.7,0.1,2.4c-0.4,0.1-0.4,0-0.4-0.4c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.1-0.9,0.1-1.3c0-0.9,0-1.8,0-2.7
	c0-0.5,0-1-0.1-1.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.2
	C235.9,100.8,235.4,102.5,235.5,103.5z M236.1,102.6c-0.2,0.6-0.1,1.2-0.1,1.8c0,0.6,0,1.1,0,1.7c0,0.4-0.2,1,0.1,1.4
	c0,0.1,0,0,0,0.1c-0.2,0-0.3,0.1-0.4-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0-0.6,0-0.9c0-0.7,0-1.4,0-2.1c0.1-1.3,0.4-2.7,0.1-4
	c0.1,0.2,0.3,0.3,0.3,0.5c0,0.2,0.1,0.3,0.1,0.5C236.1,101.8,236.2,102.2,236.1,102.6z M236.6,107.6c-0.1,0-0.2,0-0.4,0
	c0-0.1,0,0,0-0.1c0-0.4-0.1-0.7-0.1-1.1c0-0.2,0-0.3,0-0.5c0-0.5,0-1,0-1.4c0-0.5,0-1,0-1.5c0.1-0.7,0.3-1.4,0.1-2.2
	c0.3,0.4,0.1,0.9,0.1,1.4c0,0.4,0,0.8,0,1.1c0,0.5,0.1,1,0.1,1.5C236.4,105.7,236.2,106.9,236.6,107.6z M236.9,104.6
	c0,0.9,0.2,2,0.6,2.8c-0.2,0.1-0.5,0.2-0.6,0c-0.1-0.1,0-0.2,0-0.3c-0.1-0.3-0.1-0.6-0.1-0.9c0-1.6-0.1-3.2,0-4.9
	c0.2,0.3,0.1,0.8,0.1,1.2c0,0.3,0,0.7,0,1C236.8,103.9,236.9,104.3,236.9,104.6z M237,102.6c0.1,0.3,0.1,0.5,0.2,0.8
	c0.1,0.5,0.2,1,0.3,1.5c0.2,0.8,0.2,1.7,0.5,2.4c-0.2,0-0.3,0.2-0.4,0c-0.3-0.8-0.5-1.8-0.5-2.6c0-0.3,0-0.7-0.1-1
	c-0.1-0.6-0.1-1.2-0.1-1.8C237.1,102,237,102.5,237,102.6z M238.4,107.2c-0.1,0-0.1,0-0.2,0c-0.4-0.7-0.4-1.7-0.5-2.4
	c-0.2-0.8-0.5-1.7-0.5-2.6c0.1,0.2,0.3,0.5,0.4,0.7c0,0-0.1,0-0.1,0c-0.1,0.3,0.2,0.7,0.2,1c0.1,0.4,0.1,0.8,0.3,1.1
	c0.2,0.4,0,1.1,0.3,1.5C238.2,106.6,238.3,107,238.4,107.2z"
        />
      </g>
      <path
        fill="none"
        stroke={data.h4 !== "" ? data.h4 : "#86981f"}
        strokeWidth="0.25"
        strokeMiterlimit="10"
        d="M212.9,13.1c2.4,0,4.6,0.6,6.9,0.9c2.2,0.2,4.6,0.9,6.6,2c2,1.2,2.5,2.1,4.9,4.1c2.1,1.8,2,3.6,3.1,5.3
c0.7,1,1.5,2.1,2.2,3c0.5,0.6,2.2,2,2.2,2.7"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        d="M27.7,115.7v-1.4h7.1V96.6h1.6v17.7h15.2v1.4H36.4v48.2h-1.6v-48.2H27.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        d="M90.6,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
V75.7h1.6v51.1C75,117,83.8,113,90.6,113z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        d="M130.8,139c0,14.2,10.3,24.5,24.8,24.5c9.2,0,15.6-3.6,19.9-9.5l1.3,1c-4.6,6.2-11.5,10-21.2,10
c-15.5,0-26.4-11.1-26.4-25.9c0-14.9,11.4-25.9,25.3-25.9c14.5,0,24.2,10,24.8,25.6h-48.5V139z M177.8,137.2
c-1.1-13.8-10-22.8-23.2-22.8c-12.3,0-22.9,9.5-23.6,22.8H177.8z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        d="M213.5,114.3h1.6v49.5h-1.6V114.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        d="M265.7,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6
h-1.6v-49.5h1.6v12.5C250.1,117,258.9,113,265.7,113z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeMiterlimit="10"
        d="M328,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
v-49.5h1.6v12.5C312.3,117,321.2,113,328,113z"
      />
    </svg>
  );
}
