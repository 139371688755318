import { lazy } from "react-imported-component";
const importFactory = (importFunction) => lazy(importFunction);
const requireViews = require.context(
  "views",
  true,
  /^(.*\.(js$))[^.]*$/im,
  "lazy"
);
const allpaths = requireViews.keys();
const viewsPaths = {};

allpaths.forEach((path) => {
  const str = path.replace(/\.\//, "");
  const componentName = str.replace(/\.js/, "");
  viewsPaths[componentName] = importFactory(() =>
    import(/* webpackChunkName: "[request]" */ `../views/${componentName}`)
  );
});

export default viewsPaths;