import styles from "../../styles/partials/_header.scss";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import useAppState from "../providers/AppProvider";


Header.propTypes = {};

function Header() {
  const { childrenById, byTemplate } = useAppState();
  const items = childrenById(1).filter((item) => item.showinnav === true);
  const home = byTemplate("home")[0];
  return (
    <header className={styles.header}>
      <DesktopNav items={items} page={home} />
      <MobileNav items={items} page={home} />
    </header>
  );
}

export default Header;
