import styles from "../../styles/partials/_page-title.scss";
import useAppState from "../providers/AppProvider";

const PageTitle = (props) => {
  const { summer } = useAppState();

  return (
    <h1
      className={`${props.className} ${styles.title} ${
        summer ? styles.title_summer : ""
      }`}
    >
      {props.title}
    </h1>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default PageTitle;
