import Link from "../widgets/Link";
import styles from "../../styles/partials/_mobile-nav.scss";
import {
  // logo,
  // logoSummer,
  logoAccessible,
  facebook,
  instagram,
} from "../widgets/SVG";
import { useState } from "react";
import useAppState from "../providers/AppProvider";
import AnimateHeight from "react-animate-height";
import Logo from "../widgets/Logo";
import ReservationDropdown from "./ReservationDropdown";

MobileNav.propTypes = {
  items: PropTypes.array,
  page: PropTypes.object,
};

function MobileNav({ items, page }) {
  const [open, toggleNav] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const { childrenById } = useAppState();

  const toggleSubnav = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  const renderNavItems = () => {
    if (items && items.length > 0) {
      return (
        <ul className={styles.mobileNav__navItems}>
          {items.map((item) => {
            const path = item.template === "redirect" ? item.h2 : item.path;
            const active =
              window.location.pathname === item.path ||
              window.location.pathname.includes(item.path);
            const children = childrenById(item.id);

            if (children && children.length > 0) {
              const open = activeId && activeId === item.id;

              return (
                <li
                  className={`${styles.mobileNav__item} ${
                    active ? styles.mobileNav__item_active : ""
                  }`}
                  key={item.id}
                >
                  <span onClick={() => toggleSubnav(item.id)}>
                    {item.linktitle}
                  </span>
                  <AnimateHeight
                    className={styles.mobileNav__subnav}
                    height={open ? "auto" : 0}
                  >
                    {children.map((child) => {
                      const subnavItemActive =
                        window.location.pathname === child.path;
                      return (
                        <Link
                          className={`${styles.mobileNav__subnavItem} ${
                            subnavItemActive
                              ? styles.mobileNav__subnavItem_active
                              : ""
                          }`}
                          key={child.id}
                          path={child.path}
                          onClick={() => toggleNav(false)}
                        >
                          {child.linktitle}
                        </Link>
                      );
                    })}
                  </AnimateHeight>
                </li>
              );
            } else {
              return (
                <li
                  className={`${styles.mobileNav__item} ${
                    active ? styles.mobileNav__item_active : ""
                  }`}
                  key={item.id}
                >
                  <Link
                    path={path}
                    onClick={() => {
                      toggleNav(false);
                      setActiveId(null);
                    }}
                  >
                    {item.linktitle}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      );
    }
  };

  const renderSocialIcons = () => {
    return (
      <div className={styles.mobileNav__socialIcons}>
        <Link className={styles.mobileNav__socialIcon} path={page.social_icon1}>
          {instagram}
        </Link>
        <Link className={styles.mobileNav__socialIcon} path={page.social_icon2}>
          {facebook}
        </Link>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.mobileNav__header}>
        <div
          className={styles.mobileNav__hamburger}
          onClick={() => toggleNav(!open)}
        >
          <span />
          <span />
          <span />
        </div>
        <Link
          className={`header__logo ${styles.mobileNav__logo}`}
          path="/"
          onClick={() => toggleNav(false)}
        >
          <Logo />
        </Link>
        <ReservationDropdown />
      </div>
    );
  };

  const renderDropdown = () => {
    return (
      <div className={styles.mobileNav__dropdown}>
        <div
          className={styles.mobileNav__blurb}
          dangerouslySetInnerHTML={{ __html: page.blurb1 }}
        />
        {renderNavItems()}
        {renderSocialIcons()}
      </div>
    );
  };

  return (
    <nav className={`${styles.mobileNav} ${open ? styles.mobileNav_open : ""}`}>
      {renderHeader()}
      {renderDropdown()}
    </nav>
  );
}

export default MobileNav;
