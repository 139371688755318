export const logo = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 348 241.2"
    enableBackground="new 0 0 348 241.2;"
	aria-label="the inn at Pound Ridge logo"
  >
    <g>
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M84.9,201.3h-1.6l-1.8-3.1h-8.6l-1.8,3.1h-1.6l7.1-12.5h1.4L84.9,201.3z M77.2,190.4l-3.4,6.1h6.7L77.2,190.4z
	"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M93.4,201.3h-1.6v-10.8h-5v-1.6h11.6v1.6h-5V201.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M113,201.3h-1.6v-12.5h7.6c2,0,4.4,0.7,4.4,3.9c0,3.2-2.5,3.8-4.4,3.8h-6V201.3z M119,194.9
	c2,0,2.8-0.7,2.8-2.2c0-1.6-0.8-2.3-2.8-2.3h-6v4.5H119z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M135.2,201.7c-4.3,0-7.5-2.7-7.5-6.6c0-4,3.2-6.6,7.5-6.6c4.2,0,7.5,2.6,7.5,6.6
	C142.7,199,139.4,201.7,135.2,201.7z M141,195c0-2.6-2.3-5-5.9-5c-3.6,0-5.9,2.3-5.9,5c0,2.6,2.3,5,5.9,5
	C138.7,200,141,197.7,141,195z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M160.3,196.5c0,4-2.9,5.2-6,5.2c-3.1,0-6-1.2-6-5.2v-7.7h1.6v7.7c0,2.1,1.1,3.5,4.4,3.5c3.3,0,4.4-1.4,4.4-3.5
	v-7.7h1.6V196.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M179.4,201.3h-1.6l-8.7-10.2v10.2h-1.6v-12.5h1.6l8.7,10.2v-10.2h1.6V201.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M192.6,188.8c4,0,6.1,2.8,6.1,6.2c0,3.4-2,6.2-6.1,6.2h-7v-12.5H192.6z M192.6,199.6c2.7,0,4.4-1.9,4.4-4.6
	c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2H192.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M225.7,201.3h-2l-3.5-5.1h-5.5v5.1H213v-12.5h7.7c2.3,0,4.7,0.9,4.7,3.7c0,2.3-2,3.4-3.4,3.6L225.7,201.3z
	M220.7,194.6c1.6,0,3.1-0.4,3.1-2.1c0-1.8-1.7-2.1-3.1-2.1h-6.1v4.1H220.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M233.3,201.3h-1.6v-12.5h1.6V201.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M246.5,188.8c4,0,6.1,2.8,6.1,6.2c0,3.4-2,6.2-6.1,6.2h-7v-12.5H246.5z M246.5,199.6c2.7,0,4.5-1.9,4.5-4.6
	c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2H246.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M271.1,199.3c-0.4,0.3-2.6,2.3-6.4,2.3c-4.5,0-7.4-3-7.4-6.6c0-3.9,3.4-6.6,7.6-6.6c3.2,0,5,1.4,5.8,2
	l-0.9,1.4c-1-0.7-2.4-1.7-4.9-1.7c-3.4,0-6,2-6,5c0,2.7,2.1,5,5.9,5c2.4,0,3.9-0.8,4.7-1.4v-2.5h-4.1v-1.6h5.7V199.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M286.9,201.3h-11.2v-12.5h10.9v1.6h-9.3v3.6h5.3v1.6h-5.3v3.9h9.6V201.3z"
      />
    </g>
    <g>
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M93.2,232.7l0.1,2.8h-1.1v-19.5h1.1V227c0.8-1.7,2.4-2.9,4.7-2.9c3.2,0,5.5,2.5,5.5,5.7s-2.3,5.8-5.5,5.8
	C95.8,235.6,94,234.4,93.2,232.7z M102.3,229.9c0-2.7-1.8-4.8-4.5-4.8c-2.8,0-4.6,2.1-4.6,4.8c0,2.7,1.8,4.8,4.6,4.8
	C100.6,234.7,102.3,232.6,102.3,229.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M110.4,234.6l-4.9-10.2h1.1l3.9,8.2c0.2,0.5,0.4,1.2,0.4,1.3c0-0.1,0.2-0.8,0.4-1.3l3.5-8.2h1.1l-7.2,16.8
	h-1.1L110.4,234.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M122,240.5l0.6-0.7c0.4,0.4,0.8,0.7,1.5,0.7c0.9,0,1.5-0.5,1.5-2.1v-14.1h1.1v14.1c0,2-1.1,3-2.5,3
	C123.1,241.4,122.4,241,122,240.5z M125.3,219.6c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9
	C125.7,220.5,125.3,220.1,125.3,219.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M136.2,234.7c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
	c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C131.6,232.8,133.3,234.7,136.2,234.7z M140.3,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
	c-2.5,0-4.2,1.8-4.4,4.2H140.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M152,228.5c0-2.5-1.1-3.4-3-3.4c-1.7,0-2.8,0.7-3.6,1.9l-0.8-0.5c0.9-1.4,2.5-2.3,4.4-2.3c2.3,0,4,1.4,4,4.2v7
	h-1l0-1.7c-0.7,1-1.9,1.9-3.8,1.9c-2.5,0-4.2-1.2-4.2-3.4c0-2.2,1.9-3.2,4-3.2h3.9V228.5z M148.3,229.9c-1.7,0-3.1,0.7-3.1,2.3
	c0,1.7,1.1,2.5,3.2,2.5c1.8,0,3.7-1.1,3.7-2.8v-2H148.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M157.5,224.4h1.1l0,2.5c0.7-1.7,2.3-2.7,4.2-2.7c2.5,0,4.1,1.6,4.1,4.4v6.9h-1.1v-6.7c0-2.3-1.1-3.6-3.2-3.6
	c-2.4,0-4.1,1.6-4.1,4.1v6.2h-1.1V224.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M170.4,228.8h4.7v1h-4.7V228.8z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M178.4,238.2l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7v-3.1c-0.8,1.6-2.6,2.9-4.7,2.9
	c-3.2,0-5.5-2.5-5.5-5.8s2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l0-2.7h1v11.6c0,3.8-2.1,5.5-5.4,5.5
	C181.2,241.5,179.3,240.1,178.4,238.2z M188.2,229.9c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8c0,2.7,1.8,4.8,4.5,4.8
	C186.4,234.7,188.2,232.6,188.2,229.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M198.7,234.7c1.8,0,3-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
	c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6H194C194.1,232.8,195.8,234.7,198.7,234.7z M202.8,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
	c-2.5,0-4.2,1.8-4.4,4.2H202.8z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M206.6,229.9c0-3.2,2.5-5.7,5.8-5.7c3.2,0,5.7,2.6,5.7,5.7c0,3.2-2.5,5.8-5.7,5.8
	C209.2,235.6,206.6,233,206.6,229.9z M217,229.9c0-2.7-1.9-4.8-4.6-4.8c-2.8,0-4.7,2.1-4.7,4.8c0,2.7,1.9,4.8,4.7,4.8
	C215.1,234.7,217,232.6,217,229.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M221.8,224.4h1.1l0,2.2c0.6-1.6,1.8-2.4,3.2-2.4c0.9,0,1.5,0.2,2,0.6l-0.5,0.9c-0.3-0.2-0.8-0.5-1.6-0.5
	c-1.6,0-3.1,1.3-3.1,4v6.3h-1.1V224.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M230.2,238.2l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7v-3.1c-0.8,1.6-2.6,2.9-4.7,2.9
	c-3.2,0-5.5-2.5-5.5-5.8s2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l-0.1-2.7h1.1v11.6c0,3.8-2.1,5.5-5.4,5.5
	C233,241.5,231.2,240.1,230.2,238.2z M240.1,229.9c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8c0,2.7,1.8,4.8,4.5,4.8
	C238.3,234.7,240.1,232.6,240.1,229.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M250.6,234.7c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
	c0-3.2,2.4-5.8,5.5-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C246,232.8,247.7,234.7,250.6,234.7z M254.7,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
	c-2.5,0-4.2,1.8-4.4,4.2H254.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M258.4,232.9l0.9-0.4c0.6,1.3,1.7,2.2,3.5,2.2c1.7,0,2.8-0.8,2.8-2.1c0-1.5-1.1-1.9-3.1-2.5
	c-1.9-0.5-3.4-1.2-3.4-3.2c0-1.6,1.5-2.8,3.3-2.8c1.7,0,2.8,0.8,3.5,2.1l-0.8,0.5c-0.5-0.9-1.4-1.7-2.7-1.7c-1.4,0-2.3,0.7-2.3,1.8
	c0,1.3,0.7,1.7,2.8,2.3c2,0.6,3.7,1.3,3.7,3.4c0,1.8-1.4,3.1-3.8,3.1C260.5,235.6,259,234.4,258.4,232.9z"
      />
    </g>
    <path
      fill="none"
      stroke="#A38169"
      strokeWidth="0.25"
      strokeMiterlimit="10"
      d="M217.3,69.6c0.4,0.5,0.5,0.4,0.7,1c0.3,0.5,0.3,1,0.4,1.6c0.1,1.1,0.3,2.3-0.2,3.3c-0.9,1.8-2.7,3.1-3.4,5
c-0.1,0.3-0.4,1.5,0.1,1.4"
    />
    <g>
      <path
        fill="#A38169"
        d="M299,11.5c-0.3-0.6-0.5-0.1-0.5,0c0.3,0.2,0.1,0.9,0,1.2c-0.3,0.7-0.9,0.1-1.4-0.1c-0.6-0.3-1.3-0.4-2-0.6
	c-1.1-0.3-2.2-0.5-3.3-0.7c1-0.1,2.1,0,3.3,0.1c0.8,0.1,1.9,0,2.6,0.4c0.1-0.1,0.2-0.2,0.2-0.3c-0.8-0.5-1.9-0.4-2.8-0.5
	c-1-0.1-2.1-0.2-3.1-0.2c-1,0-2.1-0.1-3.2,0.1c-0.3,0-2,0.2-2.1,0.3c-0.1,0.3-1.4,0.4-1.7,0.4c-0.5,0.1-0.9,0.2-1.4,0.3
	c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.2,0.1-0.3,0.1
	c-0.2,0-0.4,0.1-0.5,0.1c-1.3,0.3-2.5,0.7-3.8,1.2c-0.8,0.3-1.7,0.5-2.5,0.9c-0.3,0.2-0.6,0.2-0.9,0.4c-0.4,0.1-0.7,0.5-1,0.6
	c-1.8,0.9-3.5,1.6-5.3,2.3c-1.9,0.8-3.7,1.5-5.7,1.8c-0.5,0.1-1,0.2-1.4,0.2c-0.4,0.1-0.5-0.1-0.9-0.2c-0.5-0.2-1.4,0.1-1.8,0.4
	c0.1-0.2,0.2-0.3,0.3-0.5c-1.7,0.9-3.7,1-5.6,1.2c-0.6,0.1-1.2,0.2-1.7,0.4c-0.3,0.1-0.3-0.1-0.6,0c-0.2,0-0.4,0.2-0.5,0.4
	c-0.2,0.1-0.3,0.1-0.5,0.1c-0.4,0.1-0.9,0.4-1.3,0.6c-2.2,1-4.1,2.5-6,4c-0.7,0.6-1.3,1.3-1.7,2.1c-0.3,0.7-0.9,1.4-1.1,2.2
	c0.2,0.4-0.4,0.3-0.4,0.3c-0.2,0.2-0.2,0.3,0,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.4-0.8,0.6-1,1.1c-0.2,0.6-0.7,1-1,1.6
	c-0.3,0.7-0.7,1.4-0.8,2.2c-0.2,1.5-0.6,3.2-0.4,4.7c0.1,0.9,0.3,1.7,0.6,2.5c0.2,0.6,0.5,1.2,0.3,1.8c1.1,0.1,0.4,1.9,1,2.5
	c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.2,0.6,0.8,0.3,0.9c-0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.1-0.1,0.2-0.1c0.6,1,1.3,2,1.8,3.1
	c0.5,1,1.1,1.8,1.1,2.9c0.9-0.2,1.6,3.2,1.7,3.9c0.2,1.7,0.2,3.4,0.1,5.1c-0.1,1.7-0.3,3.4-0.4,5.1c-0.1,1.7-0.3,3.5-0.2,5.3
	c0,0.5,0.1,0.9,0.1,1.4c0,0.2,0,0.4,0,0.5c0.1,0.8,0.2,1.7,0.3,2.5c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3c0.1,0.7,0.4,1.4,0.6,2
	c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.7,0.7,1.3,1,2c1.6,2.7,3.8,5.3,6.6,6.9c1.3,0.7,1.2-1.8,2.1-1.4c0.1-0.1,0.2-0.3,0.1-0.4
	c0.3-0.3,0.7-0.6,1-1c0.2-0.2,0.2-0.7,0.6-0.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0.1-0.3,0.3-0.1,0.4-0.3
	c0.1-0.2-0.1-0.1-0.1-0.2c-0.2-0.2,0.2-0.6,0.3-0.7c0.2-0.4,0.4-0.9,0.7-1.4c0.2-0.4,0.3-1.3,0.7-1.4c-0.1-0.2-0.1-0.6,0.1-1
	c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.4c0,0,0,0,0,0
	c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0.3,0.1,0.6-0.1,0.8-0.2c-0.4-0.2-0.7-0.2-0.7-0.7c0.1-0.3,0-1.5,0.3-1.6
	c0.3-0.1-0.5-0.9,0.1-0.8c0-0.2,0.2-0.5-0.1-0.4c0-0.3-0.1-1.8,0.1-1.9c0.1-0.1,0.4-0.1,0.6-0.1c-0.2-0.1-0.5-0.2-0.7-0.3
	c0-0.2,0.1-0.6,0.1-0.7c0,0-0.2-0.1-0.2-0.2c0-0.3-0.1-0.5-0.1-0.8c0-0.4-0.4-1.2,0.1-1.3c-0.7,0-0.2-0.7,0.1-0.8
	c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.2-0.1-0.3,0-0.5c0,0,0.1-0.1,0.1-0.1c0-0.2-0.2-0.3-0.3-0.6c-0.1-0.5-0.5-1.6,0-1.9
	c-0.1,0-0.2-0.2-0.3-0.2c0.2-0.2-0.2-0.4-0.2-0.4c0.2-0.7-0.8-1.4-0.2-1.9c-0.1-0.2-0.2-0.3-0.4-0.2c-0.2-0.6-0.6-1.3,0-1.7
	c-0.4,0.2-0.7-0.3-0.5-0.6c-0.5-0.6-0.7-2-1-2.7c0.3,0,0.2-0.2,0.1-0.4c-0.6-0.2,0.2-0.8-0.4-0.9c0,0,0,0.1,0,0.1
	c-0.6-0.2,0.2-0.7,0.3-0.9c-0.3,0-0.5-0.2-0.6,0.1c-0.1,0-0.3-0.5-0.3-0.6c-0.2-0.5-0.1-0.2,0.2-0.5c0.2-0.1-0.2-0.4-0.4-0.4
	c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3-0.4-0.3-0.5c0.3,0.1,0.5,0,0.8-0.1c-0.2-0.3-0.5-0.3-0.8-0.3c-0.2-0.1-0.2-0.2-0.4-0.5
	c0.2,0.1,0.3,0.1,0.4-0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.6-0.4-1.2-0.6-1.7c-0.1-0.5-0.7-1.5-0.4-1.9
	c-0.6-0.3-0.5-1.4-0.5-2c0-1.1-0.1-2.2,0-3.3c0.1,0.1,0.3-0.1,0.4-0.2c-0.2-0.2-0.6-0.7-0.1-1c-0.7-0.6,0.3-3.3,0.4-4.1
	c0.1-1.3-0.5-2.7-1.5-3.6c0.2,0,0.4,0,0.6,0c-0.3,0.4,0.7,0.4,0.9,0.5c1.1,0.1,2-0.2,2.8-0.8c0.7-0.5,1.4-0.9,2-1.4
	c0.5-0.3,1.8-1.6,2.3-1.4c0.1-0.3,1.9-1.1,1.6-0.5c0.2,0.2,0.5,0.1,0.7,0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0.4-0.1,0.7-0.2,1.1-0.1
	c0.2,0,0-0.1,0.1-0.1c0.6-0.1,1.2-0.1,1.7,0.1c0.4,0.1,1,0.2,1.5,0.2c-0.1,0,0.4-0.2,0.4-0.2c0.3,0,0.6-0.1,1-0.1
	c-0.1,0.3,0.5,0.4,0.7,0.6c0-0.2-0.1-0.4-0.2-0.6c0.6,0,0.7-0.1,0.9,0.5c0.1,0.1-0.4-0.2,0,0.3c0.2,0.2,0.5,0.3,0.7,0.4
	c-0.2-0.3-0.3-0.7-0.5-1c0.6,0.1,1.5,0.3,2,0.7c0-0.1,0-0.2,0-0.3c0.6-0.1,0.9,0.1,1.5,0.2c0-0.1,0-0.1-0.1-0.2
	c1.5,0.1,3.1,0,4.5,0.1c0.4,0,0.3,0.1,0.7,0c0.4-0.1,0.8-0.2,1.2-0.3c0.6-0.1,1.4-0.5,1.8,0c-0.3-0.6,0.4-0.4,0.6-0.5
	c0.4-0.2,1.1-0.3,1.6-0.4c0.1,0.4,0.6,0.4,0.4-0.1c0.7-0.1,1.3-0.2,2-0.3c0.6-0.1,0.7,0,1.2,0.2c-0.1-0.1-0.1-0.3-0.1-0.5
	c0.4-0.1,0.7,0.1,1.1,0.3c-0.1-0.2-0.2-0.4-0.3-0.6c0.3-0.1,0.8-0.4,0.7,0.1c0.2,0,0.4,0.3,0.1,0.2c0.3,0.1,0.5,0.5,0.8,0.6
	c-0.1-0.6-0.3-0.8-0.7-1.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2,0.3-0.4-0.1,0.3,0.3c0.2,0,0.3-0.3,0.2-0.4c0.1,0,1.2-0.3,1.2-0.4
	c0.1,0,0.3,0.3,0.4,0.4c-0.1-0.9,0.2-0.8,1-1.2c0.2,0.2,1.5-0.9,1.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.3-0.3,0.5-0.5
	c0.3-0.3,0.5-0.6,0.8-0.9c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.3-0.5,0.6-1.2,1.3-1c0-0.3-0.1-0.5-0.3-0.7
	c0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.3,0.4-0.1,0.6-0.5c-0.1-0.1-0.1-0.1-0.2-0.1c0.4-0.7,0.5-1.6,1-2c-0.2-0.2-0.1-0.8,0.4-0.6
	c0.1,0,0.3,0,0.3-0.2c-0.1-0.1-0.5-0.3-0.5-0.3c0.1-0.2,0-0.6,0.3-0.5c0,0,0.2,0,0.3-0.1c0-0.1-0.5-0.9,0.1-0.8
	c0.3,0.1,0.5,0.4,0.9,0.1c0.2-0.3-0.2-0.4-0.4-0.6c-0.5-0.3-0.3-0.3-0.1-0.9c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.5,0-1.3,0.7-1.4
	c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.2,0.2,0.1,0.4-0.3c0.1-0.2-0.3,0,0-0.6C299,12.4,299.2,12,299,11.5z M249.9,37.1
	C250,37.3,249.9,37.1,249.9,37.1L249.9,37.1z M249.9,43c-0.8-0.9-0.1-2,0.1-3c0.2-0.8,0.4-1.7,0.1-2.5c0.5,1.2,0.3,2.4,0,3.6
	C250,41.6,249.9,42.3,249.9,43z M249.9,43.9c-0.3-0.3-0.7-0.7-0.6-1.1c0.1,0.2,0.6,0.6,0.6,0.8C249.9,43.7,249.9,43.8,249.9,43.9z
	M249.8,45c-0.3-0.3-1.2-1.1-0.7-1.5c0.2,0.3,0.5,0.6,0.8,0.9C249.8,44.6,249.8,44.8,249.8,45z M249.8,46.3
	c-0.3-0.2-0.6-0.6-0.8-0.9c-0.3-0.5-0.6-0.6-0.3-1.2c0.3,0.5,1.1,1,1.1,1.5C249.7,45.9,249.8,46.1,249.8,46.3z M249.8,47.4
	c-0.5-0.3-0.9-0.7-1.3-1.2c-0.4-0.5-0.5-0.5-0.2-1.2c0.5,0.6,0.7,1.4,1.5,1.8C249.8,47,249.8,47.2,249.8,47.4z M249.8,48.4
	c-0.6-0.4-2.4-1.5-1.9-2.5c0.3,0.4,0.6,0.9,1.1,1.3C249.5,47.7,249.8,47.7,249.8,48.4z M249.9,49.5c-0.7-0.4-2.9-2-2.4-2.9
	c0.5,0.9,1.2,1.8,2.2,2.2c0,0,0-0.1,0.1-0.2C249.8,49,249.9,49.3,249.9,49.5z M250.3,50.9c-1-0.5-1.8-1.2-2.5-2.1
	c-0.2-0.3-1.1-1.4-0.8-1.6c0.7,1.1,1.8,2.2,3,2.8C250.1,50.3,250.2,50.6,250.3,50.9z M250.9,52.6c-0.4-0.3-0.8-0.4-1.1-0.6
	c-0.5-0.4-0.9-0.9-1.4-1.3c-0.4-0.3-1.9-2.6-1.7-2.9c0.4,0.3,0.6,0.9,0.8,1.3c0.3,0.4,0.6,0.8,1,1.1c0.6,0.5,1.9,0.9,2.1,1.7
	C250.7,52.1,250.8,52.4,250.9,52.6z M249.7,53.3c-0.7-0.4-1.6-1.1-2.2-1.8c-0.4-0.5-1.9-2.6-1.3-3.4c0.5,0.9,1.2,2.2,2,2.9
	c0.4,0.3,1.2,1.4,1.6,1.3c0.1,0.2,0.9,0.6,1.1,0.8c0.2,0.2,0.3,0.6,0.4,1C250.9,53.9,250.3,53.6,249.7,53.3z M246.4,51.6
	c-0.4-0.5-1.5-2.3-0.6-2.7c0.1,1.4,1.6,3.6,2.9,4.2c0.5,0.3,0.9,0.6,1.5,0.9c0.7,0.3,1.4,0.5,1.7,1.1c-0.4-0.3-1-0.3-1.5-0.4
	C248.8,54.2,247.3,52.9,246.4,51.6z M247.4,53.9c-0.8-0.5-1.3-1-1.9-1.9c-0.3-0.5-0.9-1.7-0.4-2.2c0.4,1.8,2,3.1,3.3,4.2
	c0.5,0.4,1.5,0.8,2.1,1c0.8,0.2,1.4,0.2,1.7,1C250.5,55.7,248.9,54.9,247.4,53.9z M247,55c-1-0.6-3.3-3-2.4-4.3
	c0.3,1.9,1.8,3.1,3.3,4.1c0.8,0.5,1.8,1.1,2.8,1.4c0.5,0.1,1,0.2,1.4,0.3c0.5,0.1,0.5,0.4,0.7,0.9C250.7,57,248.8,56.1,247,55z
	M247.6,56.4c-1.2-0.6-2.2-1.6-3-2.7c-0.5-0.7-1.1-1.5-0.6-2.3c0.5,2.1,2,3.6,3.8,4.5c1,0.5,2,1,3.1,1.4c0.7,0.3,2,0.2,2.3,0.9
	C251.1,58.4,249.3,57.2,247.6,56.4z M248.1,58c-1.6-0.8-4.8-3.1-4.5-5.1c0.7,1.1,1.5,2.4,2.7,3.2c0.4,0.2,0.7,0.5,1.1,0.7
	c0.3,0.1,0.5,0.3,0.8,0.4c0.7,0.3,1.3,0.7,2,0.9c0.6,0.2,1.3,0.5,2,0.5c0.6,0,1.1-0.1,1.3,0.4C251.6,59.2,249.7,58.8,248.1,58z
	M252.5,59.5c0.6,0.1,1-0.3,1.2,0.3C253.2,59.9,253,59.7,252.5,59.5z M251,59.3c1,0.2,1.9,1.2,2.8,0.9c0.1,0.2,0.1,0.4,0.2,0.7
	c-0.1,0,0,0.1-0.2,0C252.6,60.5,251.9,60.3,251,59.3z M250.1,59.1c0.3,0.1,0.7,0.6,1,0.9c0.4,0.4,0.8,0.7,1.4,1
	c0.4,0.2,0.9,0.2,1.3,0.3c0.6,0.1,0.3,0.5,0.6,0.7C253.6,63,250.5,59.6,250.1,59.1z M253.2,63.1c-0.6-0.1-1.1-0.6-1.5-1.1
	c-0.8-1.1-1.8-2.3-2.8-3.2c0.6,0.1,0.9,0.6,1.2,1c0.5,0.6,1.1,1.2,1.7,1.6c0.5,0.4,1.2,0.7,1.8,0.9c0.6,0.2,0.8-0.2,1,0.5
	c-0.1,0-0.1,0-0.2,0C254.4,63.2,253.6,63.2,253.2,63.1z M250.8,62.2c-1.3-1.4-2.6-2.8-3.8-4.3c0.3,0.2,0.8,0.5,1,0.7
	c0.2,0.1,0.5,0.4,0.6,0.5c0.7,0.5,1.4,1.4,1.9,2.1c0.6,0.8,1.3,1.9,2.3,2.2c0.4,0.2,1.5,0.2,1.9-0.1c0.1,0.4,0.2,0.8,0.3,1.2
	C253.4,64.9,251.8,63.2,250.8,62.2z M255.1,65.6c-1.2,0.1-2.4-0.8-3.4-1.3c-1.2-0.6-2.2-2-3.2-3c-1.1-1.2-2-2.5-2.9-3.8
	c-0.7-0.9-1.6-1.9-1.9-3c0.7,1.2,1.8,1.9,2.6,3c0.5,0.6,1,1.3,1.5,1.9c0.4,0.5,1,0.9,1.3,1.4c0.8,1.2,2.1,2.5,3.3,3.3
	c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,1.8,0.1,1.9,0.5C255.1,65.1,255.2,65.6,255.1,65.6z M244.1,56.2c-0.2-0.2-0.1,0-0.2-0.3
	C244,56,244,56.1,244.1,56.2z M254.2,66.9c-0.7-0.2-1.3-0.4-1.9-0.7c-1-0.5-2-1.6-2.7-2.4c-1.9-2.2-4.1-4.4-5.2-7.2
	c1.7,2.4,3.4,5,5.7,6.9c0.9,0.8,4.2,3.5,5.4,2.4c0.1,0.3,0.1,0.6,0.2,0.9c0,0-0.1,0-0.1,0.1C255,67,254.8,67.1,254.2,66.9z
	M254.3,67.8c-0.9,0-1.4,0.2-2.2-0.3c-1.4-0.8-2.4-1.7-3.5-2.9c-0.7-0.7-1.4-1.4-1.9-2.3c-0.4-0.7-1.1-1.3-1.4-2.1
	c-0.2-0.6-0.5-1.3-0.8-1.8c-0.3-0.4-0.3-0.8-0.4-1.4c0.5,1.4,1.5,2.8,2.4,4c1.1,1.3,2.2,2.6,3.4,3.8c0.8,0.8,1.7,1.7,2.8,2.2
	c0.5,0.3,1.7,0.8,2.3,0.6c0.3-0.1,0.6-0.2,1-0.3C255.9,67.5,254.6,67.8,254.3,67.8z M256.1,69.5c-0.2-0.1-0.4-0.2-0.6-0.4
	c0.3-0.1,0.5-0.3,0.7-0.5C256.1,68.9,256.2,69.2,256.1,69.5z M254.5,69.6c0.4-0.1,0.4-0.3,0.7-0.1c0.2,0.1,0.4,0.2,0.6,0.3
	c0.3,0.2,0.4,0,0.5,0.4c0.1,0.2,0,0.3-0.2,0.2C255.5,70.4,255,69.9,254.5,69.6z M250.6,67.7c-1.2-0.7-2.1-1.8-3-2.9
	c-1.5-1.7-2.8-3.4-3.2-5.7c1.2,2.7,3.1,5.2,5.3,7.1c0.8,0.7,2,1.5,2.9,1.8c1.1,0.4,2.3,0,3.3-0.5c0.1,0.5,0.2,0.3-0.2,0.4
	c0.4,0.8-1.3,0.6-1.1,1.1c-0.4,0.2-0.9,0.1-1.3,0C252.4,68.9,251.4,68.2,250.6,67.7z M253.3,69.5c0.7,0.2,1.1,0.5,1.8,0.9
	c0.4,0.2,0.9,0.5,1.3,0.4c0,0.1,0,0.2,0,0.3C255.4,71.8,253.8,70.3,253.3,69.5z M254,71.2c-0.6-0.7-1.4-1.4-1.9-2.3
	c0.8,0.4,1,1.3,1.8,1.8c0.6,0.4,1.8,1.4,2.6,0.8c0.1,0.6,0.2,1.2,0.2,1.8c-0.1,0-0.2,0-0.3,0C255.3,73.4,254.6,72,254,71.2z
	M256.3,74.4c-1.2-0.1-2.4-1.4-3-2.3c-0.9-1.3-1.8-2.6-2.7-3.9c1.1,0.6,1.6,1.8,2.5,2.8c1,1,1.8,3,3.5,2.8
	C256.8,74.2,256.8,74.5,256.3,74.4z M254.2,74.7c-0.8-0.6-1.4-1.4-2-2.1c-1.2-1.6-1.8-3.6-2.9-5.2c0.2,0.1,0.8,0.5,0.8,0.7
	c-0.1,0.2,0.4,0.6,0.5,0.8c0.6,0.9,1.3,1.7,1.8,2.6c0.8,1.3,2.5,3.8,4.4,3.3c0,0.2,0,0.5,0,0.7c-0.3-0.1-0.4,0.1-0.7-0.1
	C255.5,75.1,254.8,75.1,254.2,74.7z M255.3,76.1c-2.2-1.1-3.7-2.7-4.9-4.7c-0.8-1.2-1.3-2.6-1.9-3.9c-0.5-1-1.1-2-1.7-3
	c0.6,0.7,1.4,1.4,1.8,2.1c0.3,0.1,0.8,1.5,0.9,1.7c1,1.6,1.6,3.3,2.7,4.9c0.5,0.6,1.1,1.3,1.8,1.7c0.4,0.3,0.8,0.5,1.2,0.6
	c0,0,0.6,0.2,0.6,0.1c0.4,0.1,1,0,0.9,0.5C256.7,77,255.7,76.3,255.3,76.1z M253.3,76c-1.5-1.2-3-2.8-3.9-4.5
	c-1-1.8-1.6-3.7-2.7-5.4c-0.5-0.7-1-1.5-1.3-2.4c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.4-0.2-0.9-0.3-1.4c0.9,1.4,1.4,2.8,2.3,4.3
	c1,1.6,1.7,3.4,2.6,5.1c0.5,0.9,1,1.6,1.7,2.4c0.4,0.6,0.9,1.4,1.5,1.7c0.8,0.4,1.5,1.1,2.3,1.5c0.4,0.2,0.9,0.4,1.4,0.5
	c0.4,0.1,0.3,0.1,0.2,0.5C255.4,77.5,254.2,76.8,253.3,76z M255.2,78.1c-1.5-0.8-2.9-1.5-3.9-2.9c-1-1.3-1.8-2.7-2.8-4.1
	c-0.9-1.3-1.6-2.8-2.3-4.2c-0.3-0.5-0.6-1.2-0.9-1.7c-0.5-0.7-0.4-1-0.4-1.8c0.4,0.8,0.6,1.6,1.1,2.4c0.5,0.7,1,1.4,1.4,2.2
	c0.7,1.5,1.3,3.1,2.2,4.4c0.9,1.4,2.2,2.7,3.5,3.8c0.1,0.5,1.3,1,1.7,1.2c0.6,0.3,1.4,0.4,2,0.4c0,0.2-0.1,0.5-0.1,0.7
	c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.1,0,0.1,0,0.1C256,78.4,255.6,78.3,255.2,78.1z M256,79.6c-2.7-1.3-5-3.1-6.7-5.7
	c-1-1.5-1.9-3-2.6-4.7c-0.6-1.3-1.6-2.4-1.8-3.8c0.4,0.5,0.6,1.1,0.9,1.7c0.4,0.8,0.8,1.6,1.2,2.4c0.8,1.6,1.8,3.1,2.8,4.5
	c0.9,1.4,2,2.6,3.4,3.6c0.6,0.4,1.7,1.1,2.5,1.2c0.3,0,0.6,0.3,0.9,0.2c0,0.3-0.1,0.5-0.1,0.8C256.3,79.7,256.2,79.7,256,79.6z
	M255,79.5c0.3,0.1,1.4,0.6,1.4,0.8c-0.6-0.3-1.2-0.6-1.9-0.9c-2.3-1.1-4.2-2.9-5.8-4.9c-1.1-1.4-1.9-3.1-2.7-4.7
	c-0.3-0.7-0.6-1.4-0.9-2.1c-0.3-0.6-0.8-1.3-0.6-2c0.3,1.5,1.4,3,2.1,4.3c0.9,1.7,1.9,3.4,3,5c0.8,1.2,2.3,2.7,3.6,3.3
	C253.9,78.8,254.4,79.3,255,79.5z M256.2,81.3c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0.5-1.3-0.4-1.7-0.6
	c0,0,0,0,0,0c-0.4-0.2-0.9-0.5-1.2-0.8c-1.3-0.6-2.1-1.9-3-3c-1.5-1.6-2.7-3.1-3.5-5.2c-0.3-0.8-0.7-1.7-1.2-2.4
	c-0.2-0.3-0.3-0.6-0.5-0.9c-0.2-0.4-0.1-0.6-0.1-1c1.2,2.3,2.1,4.6,3.4,6.8c0.7,1.2,1.7,2.1,2.6,3.1c0.3,0.4,1.9,1.8,2.2,1.7
	c0,0.4,1.7,1.1,2,1.3c0.4,0.2,0.8,0.4,1.2,0.6c0,0,0.1,0,0.1,0.1c0.5,0.2,0.5,0.2,0.5,0.7C256.2,81.2,256.2,81.2,256.2,81.3z
	M255.9,82.8c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2
	c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.2-0.8-1.8-1.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.6-0.5-0.9-0.7c0,0,0,0,0,0
	c-0.2-0.2-0.4-0.4-0.6-0.6c-1-0.9-1.9-2-2.8-3.1c-1.1-1.2-2.2-2.8-2.9-4.3c-0.4-0.7-0.7-1.4-1.2-2.1c-0.5-0.7-0.2-1.1-0.1-1.9
	c1,1.8,1.7,3.8,2.8,5.5c0.6,1,1.4,1.9,2.2,2.7c0.7,0.7,1.3,1.5,2,2.2c0.5,0.4,1,0.9,1.6,1.2c0.4,0.2,0.7,0.5,1.1,0.6l0,0
	c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.6,0.4,0.9,0.3,0.9,0.7c0,0.1,0,0.2-0.1,0.4
	C255.9,82.6,255.9,82.7,255.9,82.8z M255.6,83.6c-0.4-0.2-0.9-0.5-1.4-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.4-1.4-0.8-2.1-1.3
	c-0.2-0.1-0.3-0.3-0.5-0.4c-0.5-0.4-1.1-0.8-1.5-1.3c-0.2-0.2-0.4-0.4-0.5-0.6c-0.3-0.4-0.6-0.8-0.9-1.2c-0.8-1.3-1.5-2.6-2.4-3.9
	c-0.5-0.8-1-1.6-1.5-2.4c-0.3-0.5-0.7-0.7-0.6-1.3c0.9,1.4,1.5,2.9,2.5,4.2c1,1.3,1.9,2.6,3,3.8c0.4,0.4,0.8,0.8,1.2,1.2
	c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.7,0.6,1,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.4,1.1,0.6,1.6,1
	c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4C255.7,83.2,255.8,83.4,255.6,83.6
	C255.6,83.7,255.6,83.6,255.6,83.6z M255.2,84.8c-0.2-0.2-0.6-0.6-0.8-0.4c-0.7-0.5-1.5-0.7-2.1-1.2c-0.1,0-0.1-0.1-0.2-0.1
	c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.4-1-0.9-1.4-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.6-0.5-1.1-1.1-1.7-1.8
	c-0.2-0.2-0.3-0.4-0.4-0.6c0-0.1-0.1-0.1-0.2-0.2c-0.8-1.2-1.4-2.5-2-3.7c-0.3-0.7-0.7-1.3-1.2-1.9c-0.5-0.6-0.8-0.7-0.7-1.6
	c1.9,2.3,2.9,5.3,4.7,7.6c0,0.1,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.3,0.4,0.5c0.5,0.5,1,1.1,1.6,1.5c0.2,0.1,0.3,0.3,0.5,0.4
	c0.4,0.3,0.7,0.6,1.1,0.8c0.2,0.1,0.6,0.3,1.1,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.9,0.5,1.9,1,1.8,1.2
	C255.3,84.4,255.2,84.6,255.2,84.8z M253.3,85c-0.5-0.3-1.1-0.5-1.6-0.8c-0.4-0.3-0.9-0.6-1.2-0.9c-0.1-0.1-0.3-0.3-0.4-0.4
	c-0.5-0.5-0.9-1-1.4-1.6c-0.1-0.2-0.2-0.3-0.4-0.4c0,0-0.1-0.1-0.1-0.1c-0.5-0.6-0.9-1.2-1.3-1.9c-0.1-0.2-0.2-0.4-0.3-0.6
	c-0.3-0.6-0.7-1.3-1-1.9c-0.4-0.8-0.8-1.7-1.3-2.4c-0.2-0.3-0.6-1-0.9-1c0,0,0.1-0.5,0.1-0.5c1.5,1.8,2.3,4,3.6,6
	c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0.6,0.7,0.9,1c0.3,0.3,0.6,0.6,0.9,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.5,0.5,1,1,1.5,1.5
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.6,0.5,1.3,0.9,2,1.2c0.4,0.2,0.9,0.8,1.3,0.8c-0.1,0.2-0.2,0.6-0.4,0.5
	C254,85.7,253.7,85.3,253.3,85z M252.9,86.2c-0.5-0.2-1.2-0.6-1.7-1c-0.8-0.6-1.5-1.2-2.2-1.9c-0.2-0.2-0.3-0.3-0.4-0.4
	c-0.3-0.3-0.7-0.7-1-1.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.5c-0.4-0.7-0.8-1.5-1.2-2.3c-0.1-0.2-0.2-0.4-0.3-0.6
	c-0.1-0.2-0.2-0.4-0.3-0.5c-0.6-1.1-1.9-2.6-1.7-3.9c0.9,1.5,1.8,3.1,2.7,4.7c0.1,0.2,0.2,0.4,0.4,0.6c0.4,0.7,0.9,1.4,1.4,2.1
	c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.7,0.9,1.1,1.3c0.1,0.1,0.3,0.3,0.4,0.4c0.6,0.6,1.3,1.1,2.1,1.5
	c0.6,0.3,1.1,0.5,1.6,0.9c0.2,0.2,0.6,0.4,0.9,0.4c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1-0.1-0.2-0.2-0.4-0.2
	C253.7,86.9,253.2,86.3,252.9,86.2z M252.8,86.9c-0.7-0.3-1.4-0.6-2-1c-1.1-0.6-2.2-1.7-3.1-2.7c0,0,0,0,0-0.1
	c-0.1-0.1-0.2-0.3-0.4-0.4c-0.5-0.5-1-1.1-1.2-1.8v0c-0.1-0.1-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.8-0.6-1.1c-0.1-0.2-0.4-0.8-0.7-1.4
	c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-1-1-1.9-1.3-1.7c0.1-0.3,0.1-0.7,0.2-1c0.5,1,1.1,2,1.7,3c0.1,0.2,0.2,0.4,0.3,0.6
	c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.5,0.5,1.1,0.8,1.6c0.1,0.2,0.2,0.3,0.3,0.5c0.4,0.6,0.8,1.2,1.3,1.7c0,0,0,0,0.1,0.1
	c0.1,0.1,0.2,0.3,0.4,0.4c1,1,2.1,2.1,3.4,2.7c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.2,1.3,0.5,1.1,0.9C253.5,87.3,253.1,87,252.8,86.9z
	M253.4,88.2c-2.5-1.3-4.6-3-6.5-5.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.5-0.8-1-1.2-1.6
	c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.3-0.4-0.5c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-0.5-0.9-1.4-1.1-2.2
	c0-0.2-0.1-0.4-0.1-0.5c0-0.3,0-0.6,0-0.8c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0.2,0.3,0.5,0.4,0.7c0.5,0.7,0.9,1.5,1.1,2.3
	c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.4,0.8,0.6,1.1c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.2,0.3,0.3,0.4,0.5
	c0.2,0.3,0.5,0.6,0.7,0.8c1.6,1.8,3.7,2.8,5.8,3.8C253.6,87.9,253.5,88.1,253.4,88.2z M242.6,80.1c0.3,0.7,0.6,1.4,1.1,2.1
	c0.1,0.2,0.2,0.3,0.3,0.4c0.7,1.1,1.5,2,2.2,3.1c1.1,1.5,2.5,3.1,4.1,4c0,0,0-0.1,0.1-0.1c0.1,0.3,0.8,0.7,1.1,0.9
	c0,0.1-0.3,0.6-0.3,0.6c-0.1-0.2-1-0.6-1.3-0.8c-0.5-0.4-0.9-0.7-1.4-1.1c-0.9-0.7-1.8-1.5-2.6-2.5c-0.7-0.9-1.4-2-1.9-3
	c-0.1-0.4-0.3-0.7-0.5-1c-0.1-0.1-0.1-0.3-0.2-0.4C243,81.5,242.7,80.8,242.6,80.1z M242.7,78.9c0.1,0.2,0.2,0.5,0.3,0.7
	c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.4,0.7,0.9,0.9,1.4c0.2,0.3,0.3,0.5,0.5,0.8c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.5,0.7,0.8,1.1
	c0.6,0.8,1.2,1.6,1.9,2.2c0.5,0.4,1.1,1.4,1.8,1.6c0,0.4,2.2,1.6,2.5,1.7c-0.2,0.2-0.3,0.4-0.4,0.5c-0.8-0.3-1.9-1.1-2.6-1.7
	c-1-1-1.9-2.1-2.8-3.2c-0.6-0.8-1.2-1.6-1.8-2.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.5-0.7-1.1-0.9-1.7
	c-0.1-0.2-0.1-0.3-0.2-0.5C242.8,79.3,242.7,79.2,242.7,78.9z M242.7,77.3c0.4,0.9,0.9,1.9,1.4,2.7c0.1,0.2,0.2,0.3,0.3,0.5
	c0.4,0.7,0.9,1.4,1.4,2c0.1,0.2,0.3,0.3,0.4,0.5c1.9,2.3,4.2,4.2,6.9,5.5c-0.4,0.3-0.6,0.8-1.1,0.5c-2-1-3.9-2.8-5.2-4.5
	c-0.4-0.5-0.7-1.1-1.1-1.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c-0.4-0.5-0.6-1.1-1-1.6c-0.1-0.1-0.2-0.3-0.3-0.4
	c-0.1-0.2-0.2-0.4-0.4-0.6C243,79,242.6,78,242.7,77.3z M240.9,54.1c0.2-0.6,0.5-1.6,0.8-2.2c0.4-0.8,0.8-1.7,1.3-2.5
	c0.3-0.4,0.5-0.7,0.9-1c0.3-0.3,0.5-0.8,0.8-1.1c0.5-0.5,0.9-0.6,1.3-1.3c0.7-1.4,1.8-2.6,2.4-4.1c0.6-1.4,1.1-3.8,0.7-5.4
	c0.7,0.6,1,1.8,0.8,2.6c-0.3,1-0.5,2.2-0.9,3.2c-0.5,1.4-1.1,3-1.9,4.3c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.4-0.3,1.1-0.8,1.2
	c0,0.1-0.2,0.8-0.4,0.9c-0.5,0.2-0.4,0.7-0.8,1c0,0-0.5,0.5-0.5,0.4c-0.1,0.1,0,0.3-0.1,0.4c0.1,0-0.2,0.3-0.4,0.3
	c0,0.5-0.5,0.8-0.6,1.2c-0.1,0.1-0.1,1.1-0.1,1.4c-0.3-0.1,0,1.1,0,1.2c0.2,0.9,0.4,1.7,0.5,2.6c0.3,2.2,0.7,4.1,0.7,6.4
	c0,0.9,0,1.6-0.3,2.5c-0.1,0.5-0.2,1.1-0.3,1.6c-0.2-0.1-0.3,1.3-0.3,1.6c-0.3-0.2-0.4,0.2-0.1,0.4c0.3,0.2-0.2,3.2-0.3,3.9
	c-0.1,0.6-0.1,0.2-0.2,0.7c-0.1,0.2,0.1,0.2,0.1,0.5c-0.1,0.7-0.2,1.4-0.4,2c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.1,0.4
	c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4-0.1-0.5c-0.1-0.8-0.2-1.6-0.2-2.4c0-1,0.2-2,0.2-3c0-1.9,0.2-3.8,0.3-5.7c0.2-2,0.3-4.1,0-6.1
	c-0.1-1-0.4-1.8-0.8-2.7C241.4,55.8,240.7,54.8,240.9,54.1z M239.1,48.7c0.2-1,1-1.5,1.4-2.4c0.7-1.5,2.2-2.5,2.8-3.9
	c1.3-2.8,2.1-5.8,0.5-8.6c0.5,0.3,1.1,0.6,1.6,0.8c0.5,0.2,1.3,0.3,1.6,0.7c0.6,0.8,0.5,2,0.6,2.9c0.1,2.3-1,4.5-2.1,6.5
	c-0.3,0.7-0.8,1.2-1.3,1.7c-0.4,0.3-0.6,0.9-1,1.2c-0.2,0.1-0.4,0.6-0.6,0.7c-0.4,0.4-0.9,1-1.3,1.4c-0.9,0.7-1.2,1.7-1.6,2.7
	c-0.2-0.5-0.5-0.8-0.8-1.3C239.2,50.7,239,49.3,239.1,48.7z M237.2,46.6c0.2-0.3,0.3-0.7,0.5-1c0.4-0.6,1.1-1.3,1.7-1.7
	c0.8-0.7,1.9-1.8,2-2.9c0.1-0.6,0.4-1.2,0.6-1.8c0.1-0.4,0.3-0.8,0.3-1.2c-0.1-0.5-0.2-1.1-0.3-1.6c-0.3-1-0.5-1.9-1-2.8
	c-0.4-0.7-0.9-1.3-1.2-2.1c1.6,0.7,2.6,2.8,3,4.4c0.3,1.4,0.4,3-0.1,4.4c-0.6,1.6-1.6,3.1-3,4.1c-1.1,0.9-2.1,2.3-2.5,3.6
	C237,47.5,236.9,47.3,237.2,46.6z M238.6,31.9c0.7,1.2,1.4,2.3,1.7,3.6c0.3,1,0.6,2.7,0.2,3.7c-0.5,1.2-0.7,2.2-1.8,3.1
	c-0.9,0.8-2.1,1.6-2.7,2.6c-0.4-1.3-0.8-2.6-0.9-4c-0.1-1.2,0.3-2.4,0.4-3.7c0.1-2.1,1.5-3.6,2.7-5C238.4,32.2,238.5,32,238.6,31.9
	z M238.5,31.1C238.6,31.1,238.6,31.2,238.5,31.1L238.5,31.1z M239.9,32.8c0.5,0.8,1,1.5,1.3,2.4c0.5,1.5,1.1,3.4,0.2,4.9
	c-0.3,0.6-0.4,1.2-0.7,1.8c-0.3,0.6-0.9,0.9-1.1,1.4c-0.2,0.5-1.1,1-1.5,1.4c-0.6,0.6-1,1.3-1.3,2.1c-0.3-0.8-0.6-1.3,0-2
	c0.6-0.6,1.1-1.2,1.7-1.7c1.3-1.1,2.2-2.3,2.5-4c0.3-1.5-0.1-3.3-0.6-4.8c-0.3-0.9-0.7-1.7-1.3-2.4c-0.2-0.3-0.1-0.7-0.5-0.8
	c0.1,0,0.2,0,0.3,0.1C239.4,31,239.7,32.4,239.9,32.8z M241.4,32.3c1,0.5,1.8,1,2.3,1.9c0.4,0.8,0.8,1.7,0.8,2.7
	c0,1.6-0.7,3.2-1.2,4.7c-0.1,0.4-0.4,1-0.7,1.3c-0.7,0.8-1.3,1.6-1.9,2.4c-0.5,0.7-1.1,1.5-1.5,2.2c-0.6,1-0.6,2-0.6,3.1
	c-0.3-0.5-0.6-1.1-0.9-1.6c0-0.4-0.1-0.6,0.1-1c0.2-0.3,0.4-0.7,0.6-1c0.4-0.8,0.8-1.2,1.4-1.8c0.5-0.6,1.1-1,1.6-1.6
	c0.6-0.7,1-1.4,1.4-2.2C244.3,38.3,243.3,34.9,241.4,32.3z M247.9,35.6c1.5,1.2,0.7,3.6,0.4,5.3c-0.4,1.9-1.7,3.1-2.5,4.8
	c-0.3,0.7-0.7,0.9-1.3,1.4c-0.3,0.3-0.5,0.8-0.8,1.1c-0.4,0.3-0.7,0.7-1,1.1c-0.9,1.4-1.7,3.1-2.2,4.7c-0.2-0.4-0.4-0.7-0.6-1.1
	c0.4-0.7,0.6-1.6,1.1-2.3c0.5-0.7,1.2-1.3,1.7-1.9c0.4-0.1,0.3-0.5,0.6-0.7c0.4-0.2,0.6-0.5,0.8-0.9c0.3-0.4,0.7-0.8,1.1-1.2
	c0.6-0.5,0.9-1.4,1.2-2.1c0.5-1,0.9-2,1.2-3c0.5-1.6,0.6-3.9-0.1-5.4C247.7,35.3,247.8,35.4,247.9,35.6z M292.3,20.5
	c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-1.2-0.8-2.4-1.7-3.6-2.6c-0.6-0.5-1.2-0.9-1.8-1.3c-0.6-0.4-1.3-0.8-1.9-1.1
	c-1.2-0.6-2.5-1-3.7-1.2c0.5-0.2,1.2,0.2,1.7,0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.2,1.1,0.4c0,0,0.1,0.1,0.2,0.1
	c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.7,0.3c0.4,0.2,0.8,0.3,1,0.2c0,0,0.1,0,0.1,0c0,0.3,0.9,0.7,1.7,1.1
	c0.5,0.2,1,0.4,1.2,0.6c0.9,0.6,1.7,1.2,2.6,1.8c0.9,0.6,2.2,1.4,3.3,1.6c0.6,0.1,0.7,0,0.5,0.7c-0.1,0.4-0.4,1.3-0.9,1.2
	C294,21.9,293.2,21.1,292.3,20.5z M294.4,23.6c-0.5-0.2-0.9-0.5-1.4-0.8c-0.2-0.1-0.4-0.2-0.5-0.3c-1.6-1.1-3-2.3-4.5-3.5
	c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.6-0.5-1-0.7c-0.4-0.2-0.7-0.5-1-0.7c-0.6-0.5-1.2-1-1.9-1.4c-1.5-0.8-2.8-1.3-4.4-1.7
	c1.2-0.5,3.2,0.6,4.3,1.1c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,1.1,0.6,1.6,1c0.3,0.2,0.6,0.5,0.9,0.7c1.5,1.2,3,2.3,4.6,3.4
	c0,0,0.1,0,0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.3,0.9,0.5,1.3,0.8c0.4,0.2,0.7,0.4,1.1,0.5C295.1,22.6,294.5,23.7,294.4,23.6z
	M293.8,24.9c-0.1,0.1-0.2,0.3-0.3,0.3c0,0,0,0,0,0c-0.3-0.2-0.2-0.2-0.4-0.2c-1-0.4-1.9-1.2-2.7-1.9c-0.3-0.2-0.5-0.4-0.7-0.6
	c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6c-0.9-0.7-1.8-1.4-2.6-2.2c-2.3-2.1-4.9-4.2-8.1-4.8
	c1.5-0.7,3.4,0.5,4.8,1.2c1.6,0.8,3,1.9,4.4,3c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.2,0.5,0.4,0.8,0.6c1.3,1.1,2.5,2.2,3.9,3
	c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.3,0.2,0.7,0.4,1,0.6C294.5,24.1,294.1,24.5,293.8,24.9z M292.7,26.2
	c-0.1,0.1-0.3,0.3-0.4,0.4c0,0-0.1,0.1-0.1,0.1c-0.2-0.3-1-0.7-1.4-1.1c-0.6-0.7-1.2-1.4-1.8-2c-1.2-1.3-2.6-2.5-3.9-3.7
	c-2.3-2.1-5-4.3-8.1-4.7c1.1-0.5,2.9,0.4,3.9,1c1.7,0.9,3.2,2.2,4.7,3.5c1.2,1,2.3,2,3.5,3c0.2,0.2,0.5,0.4,0.7,0.6
	c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.6,1.1,0.9c0.6,0.5,1.2,0.9,2,1.3C293,25.8,292.9,26,292.7,26.2z
	M290.4,27.3c-0.2-0.3-0.4-0.6-0.6-0.8c-0.6-0.7-1.1-1.3-1.6-2c-1-1.4-2.4-2.5-3.6-3.7c-1.2-1.2-2.6-2.6-4.1-3.4
	c-1.3-0.7-2.9-1.7-4.4-1.7c1-0.6,2.9,0.4,3.8,0.8c1.1,0.5,2,1,2.9,1.8c3.2,2.7,6.1,5.8,9.1,8.7c-0.1,0.1-0.2,0.1-0.3,0.2
	C291.1,27.4,290.7,27.7,290.4,27.3z M289,28.1c-0.3-0.2-0.4-0.6-0.6-0.8c-0.7-0.8-1.3-1.7-2-2.5c-1.3-1.4-2.6-2.9-4-4.2
	c-1.2-1.1-2.6-2.2-4-3.1c-1.1-0.7-2.4-1-3.6-1.3c1.5-0.8,3.2,0.4,4.6,1c1.5,0.7,2.7,1.8,4,2.9c1.3,1.2,2.6,2.3,3.7,3.6
	c1.1,1.3,2,2.6,3.1,3.9c-0.1,0,0,0-0.1,0.1C289.7,28,289.3,28.4,289,28.1z M288.3,28.7c-0.2-0.5-0.5-0.7-0.9-1.1
	c-0.6-0.7-1-1.5-1.6-2.1c-1.2-1.4-2.4-2.8-3.7-4c-1.1-1.1-2.4-1.8-3.5-2.8c-1.3-1-2.6-1.7-4.2-2c0.7-0.3,2.7,0.6,3.4,1
	c1.4,0.7,2.6,1.8,3.8,2.8c1.5,1.2,2.7,2.7,4,4c1.2,1.2,2.1,2.7,3.3,4c-0.1,0.1-0.1,0.1-0.2,0.1C288.5,28.6,288.4,28.7,288.3,28.7z
	M286.4,27.2c-0.5-0.7-1.1-1.4-1.8-2c-1.1-0.9-2.2-2-3.3-3c-2.4-2-4.7-4.8-8.1-5c1-0.7,2.8,0.3,3.8,0.8c1.2,0.6,2.4,1.8,3.5,2.6
	c1.6,1.3,2.9,2.7,4.3,4.2c0.6,0.7,1.1,1.3,1.6,2c0.4,0.7,1.1,1.3,1.5,1.9C287.2,28.9,286.7,27.7,286.4,27.2z M285.9,28.6
	c-0.3-0.4-0.7-0.8-1.1-1.3c-0.6-0.7-1.3-1.4-2-2c-0.7-0.7-1.4-1.3-2.1-1.9c-0.6-0.5-1.2-1.3-1.9-1.6c-0.7-0.3-1.2-1-1.9-1.4
	c-0.5-0.4-1-0.8-1.6-1.1c-0.6-0.3-1-0.7-1.8-0.9c-0.6-0.1-1.1-0.5-1.7-0.6c3.2-1.1,6.6,2.4,8.8,4.3c1.2,1,2.2,2.1,3.4,3.1
	c1.3,1.1,2,2.4,3,3.7C286.3,29.2,286.5,29.2,285.9,28.6z M283.9,27.9c-0.6-0.7-1.4-1.3-2.1-2c-1.2-1.1-2.4-2.2-3.6-3.2
	c-1-0.8-2.1-1.4-3.1-2.1c-0.7-0.6-1.6-1-2.5-1.4c-0.7-0.4-1.5-0.4-2.2-0.7c0.7-0.3,1-0.4,1.8-0.2c0.3,0.1,0.8,0.3,1,0.3
	c0.1,0.2,0.9,0.4,1.3,0.6c0.4,0.3,1,0.5,1.3,0.8c1.1,0.8,2.4,1.6,3.4,2.6c2.4,2.2,5,4.2,6.9,6.9c-0.1,0.1-0.3,0.1-0.4,0.1
	C285.2,29.6,284.2,28.3,283.9,27.9z M283,28.2c-0.6-0.7-1.4-1.4-2.1-2c-1.5-1.3-3-2.4-4.6-3.7c-0.4-0.4-0.9-0.7-1.4-1
	c-0.6-0.4-1.3-1-1.9-1c-0.8-0.1-1.4-0.9-2.2-0.9c-0.5,0-1.1-0.4-1.7-0.5c1.6-0.7,3.8,0.8,5.1,1.6c1.1,0.6,2.1,1.4,3.2,2.1
	c1.5,1,2.8,2.3,4.1,3.5c0.7,0.6,1.4,1.2,2,1.9c0.4,0.4,0.7,0.8,1.1,1.1c0.2,0.3-0.6-0.2,0.1,0.4C284.3,29.9,283.4,28.5,283,28.2z
	M282.9,30.1c0.1,0,0.2-0.1,0.3-0.2c-0.5-0.9-1.1-1.6-1.9-2.3c-0.7-0.6-1.2-1.4-2-1.9c-0.8-0.5-1.5-1.3-2.3-1.9
	c-0.4-0.4-1-0.6-1.5-0.9c-0.6-0.3-1.1-0.7-1.7-1c-0.7-0.3-1.2-0.8-1.9-1.1c-1.5-0.6-2.9-1-4.5-1.1c0.7-0.2,1-0.4,1.7-0.3
	c0.3,0,0.8,0.1,1.1,0.3c0.7,0.3,1.4,0.5,2.1,0.9c0.3,0.2,0.6,0.2,1,0.3c0.4,0.1,0.7,0.5,1,0.7c0.4,0.2,0.8,0.6,1.3,0.8
	c0.8,0.3,1.6,1.1,2.2,1.6c0.9,0.6,1.6,1.4,2.4,2.1c1.5,1.2,2.7,2.6,4,3.9c-0.2,0-0.5,0.1-0.7,0.1C283.2,30,283,30.1,282.9,30.1z
	M268.3,20.2c0,0,0,0.1-0.1,0.1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.4,0-0.2,0C267.6,20.1,267.9,20.1,268.3,20.2z M270.2,20.8
	c-0.5-0.3-1.3-0.3-1.7-0.6c1.3,0.1,2.4,0.6,3.5,1.1c0.5,0.2,0.9,0.6,1.4,0.9c0.5,0.2,1.1,0.4,1.5,0.8c-0.1,0-0.1-0.1-0.2-0.1
	c0,0-0.1,0.2-0.1,0.3c-0.7-0.4-1.3-0.7-1.9-1.2c-0.2-0.2-0.4-0.2-0.6,0c-0.2-0.1-0.3-0.2-0.5-0.2C271.3,21.4,270.8,21.1,270.2,20.8
	z M281.5,30c-0.2-0.4-0.5-0.8-0.7-1.2c-0.4-0.8-1-1.4-1.6-2c-1.2-1.3-2.8-2.3-3.9-3.7c0.3,0.3,1.1,0.7,1.5,0.9
	c0.8,0.4,1.4,1.2,2.1,1.7c1.5,1.1,3.2,2.6,4,4.2C282.3,30.2,281.7,30.5,281.5,30z M280,30.7c0,0-1.4-2.6-1.6-2.8
	c-0.7-1-1.2-2.1-1.9-3c1.9,1.4,3.7,3.4,4.7,5.5C280.8,30.5,280.4,30.6,280,30.7z M278.6,30.4c-0.3-0.4-0.4-0.8-0.6-1.2
	c-0.4-0.8-1-1.5-1.4-2.4c-0.6-1.5-1.8-2.5-2.7-3.7c1.8,0.9,2.4,2.5,3.6,4c0.6,0.7,1,1.6,1.5,2.4c0.3,0.5,0.3,0.8,0.5,1.3
	C278.9,31,279,30.8,278.6,30.4z M277.6,30.4c-0.1-0.3-0.3-0.5-0.4-0.8c-0.3-0.9-1-1.6-1.4-2.4c-1-1.8-2.2-3.6-3.7-4.9
	c0.6,0.3,1,0.5,1.4,1c0.3,0.4,0.6,0.7,0.9,1.1c0.5,0.6,1,1.2,1.5,1.9c0.6,0.7,1,1.7,1.5,2.5c0.5,0.7,0.8,1.6,1.3,2.3
	C278,31.2,277.8,31,277.6,30.4z M276.8,31.1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3-0.5-0.6-0.9-0.9-1.4c-0.6-0.9-1.1-1.9-1.7-2.8
	c-1.4-2-2.5-3.9-4.4-5.4c2.3,1,3.9,3.3,5.2,5.4c0.6,1,1.4,2,1.9,3.1c0.3,0.6,0.5,1.3,0.9,1.8C277.1,31.3,277,31.6,276.8,31.1z
	M276.1,31.6c0,0-1.9-3.5-2.1-3.8c-0.7-1-1.4-2-2.1-3c-1.6-2.3-3.8-3.9-6.5-4.5c1.2-0.2,2.8,0.1,3.8,0.7c1.5,0.9,2.4,2.3,3.4,3.7
	c1,1.4,1.8,2.8,2.7,4.2C275.4,29.5,277.1,31.6,276.1,31.6z M274.7,30.5c-0.4-0.7-0.8-1.3-1.2-1.9c-0.9-1.2-1.9-2.4-2.9-3.5
	c-1.8-2.1-3.8-3.8-6.6-4.4c1.2-0.2,2.8,0.5,3.9,1c1.2,0.6,2.5,2,3.3,3.1c0.9,1.2,1.8,2.4,2.6,3.6c0.4,0.7,0.8,1.3,1.2,2
	c0.2,0.3,0.4,0.6,0.5,0.9c0,0.3-0.4-0.1-0.1,0.3C275.1,31.6,274.9,30.8,274.7,30.5z M274.8,31.6c-0.2,0-0.9-1.3-1.1-1.6
	c-0.4-0.6-0.9-1.1-1.3-1.7c-1.1-1.4-2.2-2.8-3.5-4c-2.1-2-4.3-2.9-7.1-3.4c1.3-0.3,2.8,0,4,0.7c0.7,0.4,1.5,0.7,2.1,1.2
	c0.6,0.4,1,1,1.5,1.5c1.2,1.2,2.3,2.6,3.3,3.9c0.5,0.7,1,1.5,1.5,2.2C274.4,30.7,274.8,31.6,274.8,31.6z M273.5,31.7
	c-0.6-1.5-1.8-2.8-2.9-4c-1.1-1.1-2.1-2.4-3.4-3.3c-1-0.7-1.8-1.5-2.9-1.8c-1.2-0.7-3.2-1-4.6-0.8c0.8-0.8,1.9-0.5,2.8-0.3
	c0.6,0.1,1.3,0.2,1.8,0.4c0.3,0.1,0.8,0.3,1,0.3c0.2,0.4,1.5,0.9,1.8,1.1c1.1,0.8,2.1,1.9,3,2.9c0.9,1,1.9,2.1,2.6,3.3
	c0.4,0.6,1.5,1.5,1.5,2.2C274,31.7,273.7,31.7,273.5,31.7z M271.6,31.1c-0.2-0.2-0.3-0.5-0.4-0.7c-0.4-0.8-1.2-1.5-1.8-2.1
	c-1.1-1.3-2.4-2.4-3.8-3.4c-0.5-0.4-1-0.8-1.6-1.1c-0.4-0.2-0.9-0.3-1.3-0.5c-0.6-0.3-1.5-0.4-2.1-0.6c-0.8-0.2-1.4,0.1-2.2,0.2
	c0.6-1.1,2.7-0.6,3.7-0.4c1.5,0.3,3.1,0.8,4.3,1.9c1.3,1.1,2.5,2.3,3.7,3.5c1.2,1.2,2.1,2.6,2.9,3.9c-0.1,0-0.3,0-0.4,0
	C272,31.5,271.9,31.6,271.6,31.1z M269.7,29.9c-0.5-0.6-1.1-1.1-1.6-1.7c-0.6-0.7-1.3-1.4-2-2c-0.5-0.4-1.1-0.7-1.6-1.1
	c-0.7-0.6-2.1-1.3-3-1.4c-0.7-0.1-1.4-0.2-2.1-0.1c-0.6,0-1-0.3-1.6-0.1c1.1-1.1,3-0.3,4.3-0.1c0.4,0.1,0.9,0.3,1.4,0.5
	c0.4,0.1,1.1,0.5,1.4,0.8c0.6,0.6,1.4,1.2,2.1,1.6c0.7,0.4,1.3,1.2,1.9,1.8c0.7,0.7,1.2,1.4,1.9,2.2c0.4,0.4,0.3,1,0.5,1.2
	c-0.1,0-0.1,0-0.2,0C270.4,31.5,270.1,30.3,269.7,29.9z M269.3,31.2c-0.1-0.3-0.4-0.5-0.6-0.7c-0.5-0.5-1-1.1-1.6-1.7
	c-1-1-2.3-2.3-3.6-3c-0.4-0.2-0.8-0.4-1.3-0.5c-0.1,0-1-0.3-0.9-0.3c-1.3-0.2-2.7-0.9-4.1-0.6c0.3-0.6,0.8-0.7,1.5-0.5
	c0.6,0.2,1.4,0.1,2,0.2c0.9,0.1,2.7,0.4,3.4,1.1c0.4,0.4,1,0.7,1.5,1c0.6,0.4,1.2,1,1.7,1.6c0.6,0.7,1.3,1.3,1.9,2
	c0.5,0.5,0.7,1.1,1,1.6C269.7,31.4,269.5,31.7,269.3,31.2z M266.8,30.3c-0.3-0.5-0.6-0.9-1-1.3c-0.8-0.8-1.8-1.4-2.5-2.3
	c-0.7-0.9-2-1.2-3.1-1.5c-1.1-0.3-2.3-0.4-3.3,0c0.2-0.7,0.8-0.6,1.4-0.6c0.6,0,1.4,0.2,1.9,0.3c0.3,0.1,2.2,0.8,2.4,0.6
	c0,0.3,0.8,0.5,1.1,0.8c0.6,0.5,1.2,0.8,1.7,1.3c0.7,0.7,1.4,1.3,2.1,2c0.4,0.5,1.1,1,1.4,1.6c-0.4-0.1-0.7-0.2-1.1-0.3
	C267.3,31,267.1,30.9,266.8,30.3z M265.7,30.2c-0.3-0.5-0.6-0.9-1-1.3c-0.9-0.8-1.9-1.6-3-2.2c-1.3-0.6-4-1.5-5.2-0.4
	c0.9-1.7,3.9-0.7,5.2-0.1c1,0.4,1.8,1.3,2.7,2.1c0.5,0.4,0.9,0.8,1.3,1.3c0.4,0.4,0.6,1,1.1,1.4C266.1,31,266.1,30.9,265.7,30.2z
	M264.7,31.1c0.7-0.2-0.7-1.3-0.9-1.5c-0.7-0.9-1.5-1.5-2.5-2c-1.5-0.8-3.3-1-4.9-0.5c0.6-1.4,3.6-0.8,4.7-0.3
	c1.9,1,3.6,2.3,4.6,4.2C265.4,31,265,31.1,264.7,31.1z M261.1,28.3c-1-0.5-1.7-0.8-2.8-0.8c-0.7,0-1.5,0.1-2.2,0.2
	c0-0.5,1.9-0.6,2.4-0.6c0.8,0,1.7,0.5,2.4,0.5c0.3,0.5,1.4,0.8,1.9,1.2c0.4,0.3,0.7,0.7,1,1.1C263,29.3,261.9,28.7,261.1,28.3z
	M264.2,30.9c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0.2,0.3,0.3,0.4,0.5C264.3,30.8,264.3,30.9,264.2,30.9z M264.3,31
	c0.1,0.1,0.2,0.1,0.3,0.1C264.4,31.2,264.3,31.2,264.3,31z M263.1,31.1c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.2-0.5-0.4-0.7-0.7
	c-0.5-0.6-1.1-0.8-1.9-1c-1.2-0.3-2.3-0.9-3.6-0.8c0.9-0.1,1.9-0.3,2.7-0.1c0.6,0.2,1.3,0.6,1.9,0.9c0.8,0.4,2.4,1.3,2.6,2.3
	C263.6,31.1,263.3,31.1,263.1,31.1z M260.4,30.1c-0.3-0.3-0.8-0.5-1.3-0.7c-1-0.4-1.8-0.7-2.8-0.8c1.1-0.4,2.6,0.3,3.6,0.6
	c1.1,0.4,1.9,0.9,2.7,1.8c-0.4-0.1-0.8-0.1-1.1,0C260.9,31,260.8,30.5,260.4,30.1z M256.1,28.2C256.1,28.2,256.1,28.2,256.1,28.2
	c0.1-0.1,0.3-0.1,0.4-0.1C256.3,28.1,256.2,28.2,256.1,28.2z M256.2,28.6c-0.2,0-0.3,0-0.1-0.3C256.1,28.4,256.1,28.5,256.2,28.6z
	M259.8,31.3c-0.1-0.2-0.3-0.4-0.4-0.5c-0.3-0.3-0.7-0.6-1.1-0.9c-0.7-0.5-1.6-0.7-2.5-0.7c0.3-0.6,2.3,0.2,2.8,0.5
	c0.9,0.4,1.5,0.7,2.1,1.4C260.4,31.1,260.1,31.2,259.8,31.3z M258.4,31.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.3-0.3-0.7-0.5-1-0.7
	c-0.5-0.3-1-0.6-1.5-0.7c0.3-0.4,1.8,0.2,2.2,0.4c0.6,0.3,1.3,0.8,1.7,1.3C258.9,31.6,258.7,31.9,258.4,31.5z M257.4,31.6
	c-0.2-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-1-0.6-1.5-0.7c0.7-1,2.8,1.4,3.2,1.6C257.7,32.2,257.8,32,257.4,31.6z M256.9,32.6
	c-0.2-0.8-1.5-1.5-2.2-1.8c0.2-0.4,1.1,0.3,1.3,0.3c0.2,0.4,1.1,0.7,1.4,1.2C257.3,32.4,257.1,32.5,256.9,32.6z M255.3,33.7
	c-0.3-0.6-0.9-1-1.4-1.3c0.3-0.4,0.5-0.8,0.7-1.3c0.3,0.1,0.6,0.3,0.9,0.5c0.4,0.3,0.8,0.9,1.2,1.1c-0.2,0.1-0.4,0.3-0.6,0.4
	c0-0.4-1.2-1.4-1.6-1.5c-0.5,0.7,1.1,1.1,1.3,1.7C255.6,33.5,255.5,33.6,255.3,33.7z M254.4,34.3c-0.1-0.4-0.5-0.7-0.9-0.9
	c-0.3-0.2,0-0.5,0.2-0.8c-0.2,0.2,1,1,1.2,1.3C254.8,34.1,254.6,34.2,254.4,34.3z M253.7,34.8c0-0.3-0.6-0.6-0.9-0.8
	c0.1-0.1,0.2-0.3,0.3-0.4c0.4,0.2,0.8,0.6,1,0.9C254,34.6,253.8,34.7,253.7,34.8z M250.3,35.7c1.1-0.1,1.9-0.8,2.6-1.6
	c-0.4,0.4,0.4,0.9,0.7,0.9c-0.2,0.1-0.3,0.2-0.5,0.3C252.3,35.8,251.2,35.9,250.3,35.7z M245.7,33.8c0.6-0.2,1.3-0.9,1.8-1.2
	c0.5-0.3,0.7-0.9,1.2-1.2c0.6-0.4,1.5-1.1,1.9-1.8c0.3-0.5,1-0.7,1.1-1.3c0.2-1.1,0.9-2.1,1.4-3c0.7-1.4,1.5-2.5,2.9-3.3
	c-0.5,0.6-1.1,1.1-1.6,1.7c-0.4,0.5-0.6,1.4-0.9,2c-0.6,1.1-1.3,2.2-1.8,3.3c-0.6,1.2-1.9,2.2-2.9,3.1c-0.6,0.5-1.2,1.1-1.8,1.7
	c-0.2,0.2-0.6,0.4-0.9,0.3C245.9,33.9,245.8,33.8,245.7,33.8z M241.4,31.9c0.3,0.1,0.5,0.2,0.8,0.4C242,32.3,241.7,32,241.4,31.9z
	M240.7,31.5c0.1,0.1,0.2,0.2,0.4,0.2C241.1,31.7,240.4,31.5,240.7,31.5z M244.1,33.4c0.1,0.1,0.1,0.1-0.2-0.1
	C243.9,33.3,244,33.3,244.1,33.4z M240.5,31.5c-0.5-0.4-0.2-0.1-0.3-0.1c-0.2-0.1-1.1-0.4-1-0.8C239.6,30.8,240.4,31,240.5,31.5z
	M247.9,22.7c-0.6,0.9-1,2.3-1.2,3.3c-0.2,0.9-1.1,1.9-1.7,2.6c-1.3,1.6-3.4,2.2-5.4,1.4c0,0-0.1,0.2-0.1,0.2
	c-0.4-0.2,2.4-3.5,2.7-3.7c1.5-1.2,3-2.3,4.6-3.2C247.1,23,247.5,22.9,247.9,22.7z M249,22.3c-0.5,1.2-0.5,2.4-0.8,3.6
	c-0.3,1.1-1.1,2.2-1.9,3c-0.9,0.8-2.1,1.4-3.2,1.9c-0.6,0.3-1.4,0.4-2,0.4c-0.4,0-0.7-0.4-1.1-0.6c1.1,0.1,2.2,0.4,3.3-0.1
	c0.7-0.3,1.1-0.9,1.7-1.4c0.8-0.6,1.9-2,2.1-3c0.2-0.9,1.1-2.2,1-3c0.2,0,0.4-0.5,0.6-0.7C248.8,22.3,248.9,22.3,249,22.3z
	M255.3,20.9c-1.4,0.9-2.4,1.7-3.1,3.2c-0.8,1.8-2.1,3.3-3.1,5c-0.5,0.9-1.1,1.5-1.9,2.2c-0.6,0.5-1.3,0.6-1.9,1
	c-0.6,0.5-1.4,0.7-2.1,0.2c-0.6-0.4-1-0.7-1.7-1.1c2-0.2,4.3-1.4,5.6-2.9c0.7-0.7,1.3-1.9,1.5-2.9c0.1-0.6,0.6-2.5,0.4-2.9
	c0.3,0,0.3-0.5,0.5-0.8c0.3-0.1,0.5-0.2,0.8-0.3c1.2-0.4,2.4-0.4,3.6-0.6C254.4,21.1,254.9,21,255.3,20.9z M256.8,20.8
	c-0.9,0.8-1.9,1.3-2.7,2.1c-0.6,0.6-0.9,1.2-1.2,1.9c-0.3,0.6-0.8,1.1-0.9,1.8c-0.1,0.3-0.3,0.7-0.5,1c-0.1,0.3-0.1,0.7-0.3,0.9
	c-0.6,0.6-1.1,1.4-1.8,1.9c-0.2,0.2-1.1,1.3-1.2,1.3c-0.2,0-0.4,0.4-0.5,0.6c-0.2,0.2-0.6,0.3-0.9,0.5c-0.4,0.3-0.8,0.7-1.2,0.8
	c-0.3,0.1-0.3,0-0.5,0.1c-0.3,0-0.3-0.2-0.5-0.4c1.2-0.4,2.6-1.1,3.5-1.9c1.2-1.1,2-2.4,2.9-3.8c0.6-0.9,1.2-1.8,1.7-2.8
	c0.3-0.6,0.5-1.4,1-1.8C254.4,22.1,255.5,20.9,256.8,20.8z M278.6,13.6c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0,0.1,0.1,0.2,0.1
	c-0.5,0.2-1.1,0.6-1.7,0.7c-0.2,0-0.7,0.5-1.1,0.5c-0.3,0.2-0.6,0.5-1.1,0.6c-0.1,0-0.5,0.3-0.4,0.3c-0.6,0.3-1.7,1.3-2.4,1
	c0,0.5-0.8,0.5-1.1,0.6c-0.5,0.3-1.1,0.6-1.6,0.8c-1.3,0.5-2.7,1-4.1,1.5c-0.8,0.3-2,0.6-2.8,0.3c-0.1,0,0,0.2-0.2,0.2
	c-0.5,0.1-0.9,0.2-1.4,0.3c-0.9,0.3-1.7,1.2-2.8,1.4c0.1,0,0.1,0,0.2,0.1c-1.6,1.7-2.2,4.2-2.7,6.3c-0.2,0.9-0.9,1.6-1.3,2.4
	c-0.4,0.9-1,1.6-1.4,2.4c-0.5,1-1.8,1.8-2.9,1.9c-1,0.1-1.7-0.3-2.6-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c0.6-0.2,0.9-0.9,1.4-1.3
	c0.5-0.5,1.2-1,1.7-1.5c0.5-0.4,2.4-1.9,2.1-2.5c0.5-0.1,1.1-1.7,1.3-2.2c0.6-1.1,1-2.5,1.8-3.4c0.9-1,1.8-2.1,3-2.8
	c1.5-0.1,2.9-0.3,4.4-0.6c3-0.5,6-2,8.7-3.3c1.3-0.6,2.6-1.2,3.8-1.9C276,14.3,277.5,14.1,278.6,13.6z M281.8,12.7
	c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.6,0-0.6,0c-0.2,0.1-0.1,0.2-0.4,0.3c-0.3,0.1-0.6,0.1-0.9,0.1
	c0.3-0.1,0.5-0.2,0.8-0.3c0.6-0.2,1.2-0.3,1.7-0.4C281.5,12.8,281.6,12.7,281.8,12.7z M283.9,13.2c0,0,0.1,0,0.1,0
	c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0,0c0.7,0.3,1.4,0.6,2.1,1c1,0.5,2,1,2.9,1.5c0.9,0.5,1.8,1.1,2.7,1.5c1.4,0.7,2.6,1.7,4.1,2.1
	c-0.3,0.7-0.7,0.7-1.4,0.3c-1.4-0.7-2.7-1.4-4-2.3c-0.9-0.7-1.9-1.3-2.9-1.8c-0.4-0.2-0.8-0.3-1.1-0.5c-0.5-0.2-0.9-0.4-1.4-0.6
	c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-0.7-0.2-1.1-0.3
	c-0.5-0.1-0.9-0.2-1.4-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4,0
	C283.3,13,283.6,13,283.9,13.2z M289.9,15.1c0.4,0.2,0.7,0.5,1.1,0.7c0.6,0.3,1.2,0.6,1.8,0.9c1.2,0.6,2.5,1.5,3.9,1.8
	c-0.2,0.6-0.3,0.7-0.9,0.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.3-1.3-0.6-1.8-1c-0.6-0.5-1.4-0.9-2.1-1.2c-1.4-0.7-2.8-1.4-4.2-2.1
	c-0.9-0.4-1.7-0.8-2.6-1.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.4-0.1
	C286,12.3,288.3,14.4,289.9,15.1z M289.1,13.5c0.8,0.7,1.9,1.1,2.9,1.6c0.9,0.4,1.8,0.9,2.7,1.3c0.7,0.3,1.5,1.1,2.3,0.9
	c0.1,0.1-0.1,0,0.2,0.1c-0.2,0.4-0.1,0.6-0.6,0.5c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.2-0.9-0.5-1.4-0.7c-1.1-0.6-2.2-1.1-3.4-1.7
	c-2-1.1-4-2.8-6.2-3.3c0.4,0,0.7-0.2,1.1-0.1c0.3,0.1,0.6,0.2,0.9,0.3C287.7,12.8,288.4,13,289.1,13.5z M297.5,16.5
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.3-1.3-0.4-1.9-0.8c-0.2-0.2-0.5-0.3-0.7-0.4c-0.8-0.4-1.5-0.8-2.3-1.2
	c-0.8-0.4-1.6-0.7-2.3-1.1c-1.1-0.7-2.1-1.5-3.4-1.8c0.8-0.2,1.6,0.5,2.3,0.9c0.6,0.3,1.2,0.6,1.8,0.9c0.8,0.4,1.6,0.7,2.4,1.1
	C294.2,15.3,296,16.6,297.5,16.5z M292.8,13.7c1.4,0.7,3.3,1.3,4.9,1.4c-0.1,0.4-0.1,1-0.4,1c-0.2,0-0.3-0.1-0.5-0.2
	c-0.5-0.1-1-0.2-1.5-0.5c-2.6-1.3-5.2-2.5-7.8-3.9c0.6-0.1,1.3,0.5,1.9,0.8c0.5,0.3,1,0.6,1.6,0.7
	C291.6,13.3,292.3,13.5,292.8,13.7z M289.8,12c0.4,0.1,0.9,0.2,1.3,0.3c0.9,0.2,1.7,0.5,2.6,0.7c1.4,0.4,2.8,1,4.3,1.1
	c-0.1,0.3,0,0.6-0.4,0.6c-1.5-0.1-3.2-0.6-4.6-1.3c-0.4-0.2-1.1-0.5-1.6-0.6c-1-0.3-2-0.8-2.9-1.3C288.8,11.4,289.5,11.9,289.8,12z
	M298.2,13.5c0,0.1-0.1,0.2-0.1,0.3c-1.5,0-2.9-0.7-4.3-1.1c-1.4-0.4-3.2-0.6-4.5-1.3c0.5,0,1-0.1,1.5-0.1c0,0-0.1,0.1-0.1,0.1
	c0.4,0.3,1.2,0.3,1.7,0.4c0.7,0.1,1.3,0.4,2,0.4c0.8,0,1.8,0.8,2.6,0.7C297.1,13.2,297.8,13.4,298.2,13.5z"
      />
    </g>
    <path
      fill="#A38169"
      d="M105.6,1.7c-1,0.1-2.2-0.6-3.1-0.6c-4-0.1-8.2,2.1-12.3,2.6C81.1,5,72.8,4.2,65.6,1.7c0.2-0.1,0.4-0.2,0.7-0.3
c-0.8-0.1-1.6-0.2-2.4-0.6c-0.4-0.2-0.7-0.3-1.1-0.6c-0.2-0.1-0.3-0.2-0.5-0.3c-1.6,0.9-3.5,0.9-5.8,1.9c-0.8,0.3-1.4,1.2-2.1,1.7
c-5.4,3.5-13.2,8.1-21.4,9.2c-4.5,0.6-7.6-1-12.4-0.6c-0.3,0.4,0.2,1.3-0.8,1.2c-6.5,1.3-18.4-2.1-18.4-2.1L0,17.6
c0,0,8.3,0.6,12.3,1.2c2.2,0.3,4.1,0,6.4,0.1c4.8,0.2,9.4,2.2,15.5,0.2c1.9,1.3,6.3,1.1,7.4,4c1.6,4.1,1.8,5.4,1.7,4.9
c-0.2-1.5,0.1-2.9,0.6-4.4c1.9,1.1,3.2,2.9,4.9,4.3c1.2,0.1,2.3,0.7,3.4,1.8c-0.7-1.3-0.9-2.6-0.6-4c-0.2,0-0.5,0-0.7-0.1
c-3.2-2.4-6.6-4.5-9.3-7.6C49.4,14.5,55.1,9,64,6.6c4.9-1.3,8-0.4,13,0c3.8,0.3,7.7-0.5,11-0.3c1.2,0.1,2.3,0.8,3.4,0.8
c3.1,0.2,5.9-0.7,9.1-1.2c7-1,11.9,1.4,18.2,2.7c2.9,0.6,5.8,0.7,8.6,1.2c5.8,1.1,10.9,2.1,16.2,3.9c2.6,0.8,5.4,1.4,7.8,2.4
c1.1,0.4,2,1.4,3.1,1.9c3.7,1.6,7.5,2.1,11,3.5c6.4,4.3,13.5,7.5,20.3,11.1c1.8,1.9,4.2,3.7,6.3,6c2.1,2.3,5.3,4.9,6.1,7.8
c3.4,11.4,10.8,18.6,18.6,23.2c0.3,0.6,0.6,1.3,1.1,1.8c0.3-0.2,0.4-0.4,0.9-0.4c-0.3-3.2-3-4.1-4.9-5.3
c-5.8-3.6-9.9-10.3-13.4-17.4c-0.7-1.5-0.9-3.2-1.6-4.7c-1-2.1-3.7-4.3-5.2-6.5c-1.4-2.1-2-3.6-4.4-3.6c-7.7-6.6-18.9-9.4-26.1-15.7
c7.1-0.5,14-0.1,21.1-1.2c3.2-0.5,6.4-1.8,9.6-2.3c3.4-0.5,7.2-0.3,10.8-0.3c3.7,0,7.4-0.2,10.6,0.3c6.7,0.9,3.2-1.5,2.7-1.3
c-1.6,0.4-3.2-0.1-4.6-1.1c-0.9,0.2-1.9,0.3-3,0.1l-5.9-1c-5.5,1.2-11.9,0.8-17.3,1.5c-2.6,0.3-5.1,1.1-7.6,1.4
c-9.2,1-15.7,1.5-23.3,0.2c-4.5-0.8-8.7-2.2-13.2-3.5c-4.3-1.2-8.5-3.1-12.8-4.1c-6.5-1.5-13.2-2.3-19.6-3.7
C108.9,1.8,108.3,1.6,105.6,1.7z"
    />
    <g>
      <path
        fill="#A38169"
        d="M239.5,107.1c0.2-0.3-0.1-0.3-0.1-0.2c-0.1,0.2-0.4,0.3-0.6,0.3c-0.4,0-0.3-0.4-0.3-0.7c0-0.4-0.1-0.8-0.2-1.1
	c-0.1-0.6-0.3-1.2-0.5-1.8c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.4,0.5,1,0.4,1.4c0.1,0,0.2,0,0.2,0c0.1-0.5-0.3-1.1-0.5-1.5
	c-0.2-0.6-0.4-1.1-0.7-1.6c-0.3-0.5-0.5-1.1-0.8-1.6c-0.1-0.1-0.6-1-0.7-1c-0.2,0-0.5-0.6-0.6-0.7c-0.2-0.2-0.3-0.4-0.5-0.6
	c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.6-1-1.1-1.6-1.6c-0.4-0.3-0.7-0.8-1.1-1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.4-0.2-0.6-0.3
	c-0.9-0.7-1.7-1.4-2.5-2.1c-0.9-0.8-1.7-1.5-2.3-2.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.1-0.2-0.1-0.2-0.1-0.5c-0.1-0.3-0.4-0.7-0.7-0.8
	c0.1,0,0.2,0,0.3,0c-0.9-0.6-1.4-1.6-2-2.5c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.2,0-0.1-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.4-0.3-0.6-0.5c-1.1-0.8-2.3-1.5-3.5-2c-0.4-0.2-1-0.3-1.5-0.3c-0.5,0-1-0.1-1.4,0
	c-0.2-0.9,0.4-2,0.9-2.7c0.5-0.8,0.3-0.9-0.2-0.1c-0.5,0.8-1.1,1.8-0.9,2.7c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.4,0
	c-0.3,0-0.5-0.2-0.8-0.2c-0.3,0-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.2c-0.8,0.3-1.7,0.5-2.5,1c-0.4,0.3-0.8,0.6-1.1,0.9
	c-0.3,0.2-0.5,0.6-0.8,0.6c0.2,0.6-0.9,0.7-1,1.1c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2,0.5-0.4,0.4c-0.1,0-0.2,0.1-0.2,0.2
	c0,0,0.1,0,0.1,0.1c-0.4,0.5-0.7,1.2-1.1,1.7c-0.4,0.5-0.6,1-1.2,1.3c0.3,0.4-1.2,1.6-1.5,1.8c-0.8,0.5-1.6,0.9-2.5,1.3
	c-0.9,0.4-1.8,0.7-2.7,1.1c-0.9,0.4-1.8,0.7-2.7,1.2c-0.2,0.1-0.4,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.8,0.5-1.2,0.8
	c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.5-0.9,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.7-0.7,1
	c-1,1.5-1.7,3.2-1.8,5c0,0.8,1.2,0.2,1.2,0.7c0.1,0,0.2,0,0.2-0.1c0.2,0.1,0.5,0.2,0.8,0.3c0.1,0,0.4-0.1,0.4,0.2
	c0.1,0,0.1,0.1,0.2,0c0-0.1,0-0.1,0-0.2c0.2,0,0.1,0.1,0.3,0.1c0.1,0,0-0.1,0.1-0.1c0.1-0.2,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0
	c0.2,0,0.7-0.2,0.9,0c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1-0.1,0.2-0.1h0c0.1,0,0.2,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0.2,0.2,0.3,0.3,0.3c0-0.3-0.1-0.4,0.2-0.5
	c0.2-0.1,0.8-0.4,0.9-0.3c0.1,0.1,0.3-0.5,0.4-0.2c0.1-0.1,0.3,0,0.2-0.2c0.2-0.1,0.9-0.5,1-0.4c0.1,0,0.2,0.2,0.2,0.3
	c0-0.1,0-0.3,0-0.5c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.5-0.5,0.7-0.3
	c-0.2-0.4,0.3-0.3,0.4-0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0.2-0.3
	c0.3-0.2,0.7-0.6,1-0.5c0-0.1,0-0.1,0-0.2c0.1,0.1,0.2-0.2,0.2-0.2c0.4-0.1,0.5-0.8,0.9-0.6c0.1-0.1,0.1-0.2,0-0.2
	c0.2-0.2,0.5-0.6,0.8-0.4c-0.2-0.2,0-0.4,0.2-0.4c0.2-0.4,0.8-0.9,1.1-1.2c0.1,0.1,0.1,0,0.2-0.1c-0.1-0.4,0.4-0.1,0.4-0.4
	c0,0,0,0-0.1,0c-0.1-0.4,0.4-0.1,0.6-0.1c-0.1-0.2,0-0.3-0.2-0.3c0-0.1,0.2-0.3,0.2-0.3c0.2-0.2,0.1-0.1,0.3,0
	c0.1,0.1,0.2-0.2,0.1-0.3c0,0-0.1,0-0.1,0c-0.1-0.1,0.1-0.2,0.2-0.3c0,0.2,0.1,0.3,0.3,0.4c0.1-0.2,0-0.3,0-0.5
	c0-0.1,0-0.2,0.2-0.3c0,0.1,0,0.2,0.2,0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.3,0.5-0.5,0.7-0.7
	c0.2-0.2,0.5-0.7,0.9-0.7c0-0.4,0.6-0.6,0.9-0.7c0.5-0.3,1.1-0.6,1.7-0.8c0,0.1,0.1,0.1,0.2,0.1c0.1-0.2,0.2-0.5,0.5-0.3
	c0.1-0.5,1.7-0.7,2.2-0.9c0.7-0.3,1.2-0.9,1.4-1.7c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0,0,0.5,0,0.6c0.2,0.6,0.6,0.9,1.1,1.2
	c0.4,0.2,0.8,0.5,1.2,0.7c0.3,0.2,1.2,0.5,1.3,0.8c0.2,0,1,0.7,0.7,0.7c0,0.1,0,0.3,0.1,0.3c0.1-0.1,0.1-0.2,0.2-0.3
	c0.2,0.2,0.3,0.3,0.3,0.5c0,0.1,0.1,0,0.1,0c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.2,0.6,0.3,0.8c0-0.1,0.2,0.2,0.2,0.1
	c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0-0.1,0.4-0.1,0.5c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0.3,0.2,0.3,0,0.6c0,0,0-0.2-0.1,0.1
	c0,0.1,0,0.3,0,0.5c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0.3,0.2,0.8,0.1,1.2c0,0,0.1-0.1,0.1-0.1c0.2,0.3,0.2,0.5,0.3,0.8
	c0,0,0.1-0.1,0.1-0.1c0.3,0.8,0.8,1.6,1.1,2.3c0.1,0.2,0.1,0.2,0.2,0.4c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.6,0.6,0.5,0.9
	c0.2-0.3,0.3,0.1,0.4,0.2c0.2,0.2,0.4,0.5,0.6,0.7c-0.2,0.2-0.1,0.4,0.1,0.2c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.1,0.3,0.2,0.7
	c0-0.1,0.1-0.1,0.2-0.2c0.2,0.2,0.1,0.4,0.1,0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.4,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.1
	c0,0.2-0.1,0.4-0.1,0.6c0.3-0.2,0.3-0.4,0.5-0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.1-0.2-0.1,0.2c0.1,0.1,0.2,0.1,0.2,0
	c0,0,0.5,0.5,0.5,0.5c0,0.1-0.1,0.3-0.1,0.3c0.4-0.3,0.5-0.1,0.9,0.2c0,0.1,0.8,0.5,0.8,0.7c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.7,0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.3,0,0.8,0,0.8,0.4c0.1-0.1,0.2-0.2,0.3-0.3
	c0.2,0,0.3,0,0.5,0c0.2,0,0.2,0.2,0.4,0.2c0,0,0-0.1,0-0.1c0.4,0,0.9-0.1,1.3,0c0.1-0.2,0.4-0.2,0.4,0c0,0,0.1,0.1,0.2,0.1
	c0-0.1,0-0.3,0-0.3c0.1,0,0.3-0.1,0.3,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.4-0.5,0.4-0.2c0,0.2-0.1,0.4,0.2,0.5c0.2,0,0.2-0.2,0.2-0.3
	c0-0.3,0.1-0.2,0.4-0.3c0.2,0,0.4-0.1,0.6-0.1c0.3-0.1,0.6-0.3,0.9,0c0-0.1,0-0.1,0-0.2c0.1,0,0,0.1,0.2,0.1c0.2,0,0-0.1,0.3-0.2
	C239.1,107.3,239.4,107.3,239.5,107.1z M214.4,88.8C214.3,88.9,214.4,88.8,214.4,88.8L214.4,88.8z M211.5,90.3
	c0.3-0.6,1-0.5,1.6-0.7c0.5-0.1,0.9-0.2,1.3-0.6c-0.5,0.6-1.1,0.8-1.8,0.9C212.2,90,211.8,90.1,211.5,90.3z M211,90.5
	c0.1-0.2,0.2-0.5,0.4-0.6c-0.1,0.1-0.1,0.5-0.2,0.5C211.1,90.4,211.1,90.5,211,90.5z M210.4,90.7c0-0.2,0.2-0.8,0.6-0.7
	c-0.1,0.2-0.2,0.4-0.2,0.6C210.6,90.6,210.5,90.7,210.4,90.7z M209.8,91c0-0.2,0.1-0.4,0.3-0.6c0.2-0.3,0.1-0.4,0.5-0.4
	c-0.2,0.2-0.2,0.8-0.5,0.9C209.9,90.9,209.8,91,209.8,91z M209.2,91.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.2-0.4,0.5-0.4
	c-0.2,0.4-0.5,0.7-0.5,1.2C209.4,91.2,209.3,91.3,209.2,91.3z M208.7,91.6c0.1-0.4,0.2-1.6,0.8-1.6c-0.1,0.3-0.3,0.5-0.4,0.9
	C209,91.3,209.1,91.4,208.7,91.6z M208.2,91.9c0-0.5,0.3-2,0.9-1.9c-0.3,0.5-0.6,1.1-0.6,1.7c0,0,0.1,0,0.1,0
	C208.4,91.7,208.3,91.8,208.2,91.9z M207.6,92.5c0-0.6,0.1-1.2,0.4-1.8c0.1-0.2,0.4-0.9,0.6-0.8c-0.4,0.6-0.7,1.5-0.7,2.2
	C207.8,92.2,207.7,92.4,207.6,92.5z M206.9,93.2c0-0.2,0-0.5,0-0.7c0.1-0.3,0.2-0.7,0.3-1c0.1-0.3,0.8-1.6,1-1.5
	c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.3,0.8c-0.1,0.4,0,1.2-0.3,1.5C207.1,93,207,93.1,206.9,93.2z M206.2,92.8
	c0-0.5,0.1-1.1,0.4-1.5c0.2-0.4,0.8-1.6,1.4-1.5c-0.3,0.5-0.8,1.2-1,1.7c-0.1,0.3-0.4,1-0.3,1.1c-0.1,0.1-0.1,0.6-0.1,0.8
	c-0.1,0.2-0.2,0.3-0.4,0.5C206.2,93.5,206.2,93.1,206.2,92.8z M206.2,90.6c0.2-0.3,0.8-1.3,1.2-1c-0.7,0.4-1.4,1.7-1.4,2.5
	c0,0.3-0.1,0.6-0.1,1c0,0.4,0.1,0.8-0.2,1.1c0-0.3-0.1-0.6-0.1-0.9C205.5,92.5,205.8,91.4,206.2,90.6z M205.3,91.7
	c0.1-0.5,0.2-0.9,0.5-1.4c0.2-0.3,0.6-0.9,1-0.7c-0.8,0.7-1.1,1.8-1.3,2.7c-0.1,0.4-0.1,1,0,1.3c0.1,0.5,0.2,0.8,0,1.1
	C205.2,93.7,205.2,92.8,205.3,91.7z M204.7,91.8c0.1-0.7,0.7-2.4,1.5-2.3c-0.9,0.6-1.1,1.7-1.2,2.7c0,0.5-0.1,1.2,0,1.7
	c0,0.3,0.1,0.5,0.2,0.8c0,0.3-0.1,0.4-0.3,0.6C204.6,94.2,204.6,93,204.7,91.8z M204.1,92.5c0-0.7,0.2-1.5,0.6-2.2
	c0.2-0.4,0.5-0.9,1-0.9c-0.9,0.8-1.3,1.9-1.3,3.1c0,0.6,0,1.3,0.1,1.9c0,0.4,0.4,1,0.1,1.4C204,94.7,204.1,93.5,204.1,92.5z
	M203.4,93.1c0-1,0.3-3.2,1.4-3.5c-0.4,0.6-0.8,1.4-0.9,2.1c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8,0,1.3
	c0.1,0.4,0.1,0.8,0.2,1.1c0.1,0.3,0.3,0.5,0.1,0.8C203.8,95.2,203.5,94.1,203.4,93.1z M203.8,95.7c0.1,0.3,0.4,0.4,0.1,0.7
	C203.8,96.1,203.8,96,203.8,95.7z M203.5,94.9c0.1,0.5-0.1,1.2,0.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0,0-0.1-0.1
	C203.3,96,203.2,95.6,203.5,94.9z M203.4,94.4c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0.1,0.5,0.2,0.7
	c0.1,0.3-0.2,0.3-0.2,0.5C202.3,97.2,203.3,94.7,203.4,94.4z M202.2,97c-0.1-0.3,0-0.7,0.2-1c0.3-0.7,0.7-1.5,0.9-2.2
	c0.1,0.3-0.1,0.6-0.2,0.9c-0.2,0.4-0.3,0.9-0.4,1.3c-0.1,0.4-0.1,0.8,0,1.1c0.1,0.4,0.3,0.4,0,0.6c0,0,0-0.1-0.1-0.1
	C202.4,97.6,202.2,97.2,202.2,97z M202,95.5c0.4-1,0.8-2,1.2-3c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.3-0.1,0.4
	c-0.1,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.6,1.1-0.5,1.7c0,0.3,0.3,0.8,0.5,1c-0.2,0.2-0.4,0.3-0.5,0.5C201.3,97.5,201.7,96.3,202,95.5z
	M201.4,98.5c-0.4-0.6-0.2-1.4-0.2-2c0-0.8,0.4-1.6,0.7-2.3c0.3-0.9,0.7-1.6,1.2-2.4c0.3-0.5,0.5-1.3,1-1.7
	c-0.4,0.7-0.5,1.4-0.9,2.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.3-0.2,0.7-0.4,1c-0.4,0.7-0.8,1.7-0.8,2.5c0,0.2,0,0.5,0,0.7
	c0,0.3,0.4,0.9,0.2,1.1C201.6,98.4,201.4,98.6,201.4,98.5z M203.4,90.6c0-0.1,0-0.1,0.1-0.2C203.5,90.5,203.4,90.6,203.4,90.6z
	M200.5,98.4c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.7,0.3-1.4,0.5-2c0.6-1.5,1.2-3.2,2.3-4.4c-0.8,1.5-1.7,2.9-2,4.6
	c-0.2,0.7-0.7,3,0.2,3.3c-0.1,0.1-0.3,0.2-0.4,0.3c0,0,0,0-0.1-0.1C200.7,98.8,200.5,98.8,200.5,98.4z M200.1,98.7
	c-0.2-0.4-0.4-0.7-0.4-1.2c0.1-0.9,0.2-1.7,0.6-2.5c0.2-0.5,0.4-1.1,0.7-1.5c0.3-0.4,0.4-0.9,0.7-1.2c0.2-0.3,0.5-0.6,0.7-0.9
	c0.1-0.2,0.3-0.4,0.6-0.6c-0.5,0.6-1.1,1.5-1.4,2.2c-0.4,0.9-0.8,1.7-1.1,2.7c-0.2,0.6-0.4,1.3-0.4,1.9c0,0.3,0,1.1,0.3,1.3
	c0.1,0.1,0.3,0.3,0.4,0.4C200.6,99.4,200.2,98.8,200.1,98.7z M199.7,100c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.4,0.2
	C200,99.9,199.8,100,199.7,100z M199.2,99.2c0.1,0.2,0.2,0.1,0.2,0.3c0,0.1,0,0.2,0,0.4c0,0.2,0.1,0.2-0.1,0.3
	c-0.1,0.1-0.1,0.1-0.2,0C199.1,99.9,199.2,99.5,199.2,99.2z M199.2,96.8c0.1-0.8,0.4-1.5,0.7-2.2c0.5-1.2,1-2.2,2.1-3
	c-1,1.3-1.8,2.9-2.3,4.4c-0.2,0.6-0.3,1.4-0.2,1.9c0.1,0.7,0.6,1.2,1.1,1.5c-0.2,0.2-0.1,0.2-0.3,0c-0.3,0.4-0.6-0.5-0.8-0.3
	c-0.2-0.1-0.3-0.4-0.3-0.6C199,98,199.1,97.3,199.2,96.8z M199,98.6c0.1,0.4,0,0.7,0,1.1c0,0.2-0.1,0.6,0.1,0.8
	c-0.1,0-0.1,0.1-0.2,0.1C198.3,100.2,198.7,99.1,199,98.6z M198.3,99.4c0.2-0.5,0.4-1.1,0.7-1.5c0,0.5-0.4,0.8-0.4,1.3
	c0,0.4-0.3,1.2,0.2,1.5c-0.3,0.2-0.6,0.4-0.8,0.6c0,0-0.1-0.1-0.1-0.1C197.5,100.6,198,99.8,198.3,99.4z M197.3,101.4
	c-0.3-0.7,0.1-1.6,0.4-2.1c0.4-0.8,0.9-1.6,1.3-2.3c0,0.7-0.5,1.3-0.8,2c-0.3,0.8-1.1,1.7-0.5,2.5
	C197.5,101.5,197.4,101.6,197.3,101.4z M196.6,100.4c0.1-0.5,0.3-1.1,0.6-1.6c0.5-1,1.4-1.8,1.9-2.8c0,0.1-0.1,0.6-0.2,0.6
	c-0.1,0-0.2,0.3-0.3,0.4c-0.3,0.5-0.5,1.1-0.9,1.6c-0.5,0.7-1.3,2.2-0.6,3c-0.1,0-0.3,0.1-0.4,0.2c0-0.2-0.1-0.2-0.2-0.4
	C196.7,101.1,196.5,100.8,196.6,100.4z M196.2,101.2c0-1.4,0.4-2.5,1.1-3.7c0.4-0.7,1-1.3,1.5-1.9c0.4-0.5,0.7-1.1,1.1-1.6
	c-0.2,0.5-0.3,1-0.6,1.4c0,0.2-0.5,0.7-0.6,0.9c-0.6,0.9-1.3,1.6-1.8,2.6c-0.2,0.4-0.4,0.9-0.5,1.3c0,0.2,0,0.5,0,0.8
	c0,0,0.1,0.3,0.1,0.3c0,0.3,0.3,0.5,0,0.6C196.1,102.2,196.2,101.5,196.2,101.2z M195.7,100.2c0.3-1.1,0.6-2.2,1.3-3.1
	c0.7-0.9,1.5-1.7,2-2.7c0.2-0.4,0.5-0.9,0.9-1.2c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.3,0.4-0.3,0.7-0.5c-0.5,0.8-1.1,1.4-1.6,2.2
	c-0.6,0.9-1.3,1.7-1.9,2.6c-0.3,0.5-0.5,0.9-0.8,1.4c-0.2,0.4-0.5,0.8-0.5,1.2c0,0.5-0.2,1-0.2,1.5c0,0.3,0,0.6,0.1,0.8
	c0,0.2,0,0.2-0.2,0.2C195.5,101.6,195.5,100.9,195.7,100.2z M195.1,101.7c0-0.9,0.1-1.8,0.5-2.7c0.4-0.9,0.9-1.6,1.4-2.4
	c0.4-0.8,1-1.5,1.5-2.2c0.2-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.4-0.5,0.8-0.7c-0.3,0.4-0.7,0.7-0.9,1.1c-0.3,0.4-0.5,0.9-0.8,1.3
	c-0.6,0.7-1.2,1.4-1.7,2.2c-0.5,0.8-0.8,1.8-1,2.7c-0.2,0.2-0.2,0.9-0.2,1.2c0,0.4,0.1,0.8,0.3,1.1c-0.1,0.1-0.3,0.1-0.4,0.1
	c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0C195.2,102.2,195.1,102,195.1,101.7z M194.6,102.5c0-1.7,0.3-3.3,1.2-4.8c0.5-0.9,1.1-1.7,1.7-2.5
	c0.5-0.6,0.8-1.4,1.4-1.8c-0.1,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.6,0.8-0.9,1.2c-0.6,0.8-1.1,1.7-1.6,2.5c-0.5,0.8-0.8,1.6-1,2.6
	c-0.1,0.4-0.1,1.2,0,1.6c0.1,0.2,0,0.4,0.1,0.5c-0.1,0-0.3,0.1-0.4,0.1C194.6,102.7,194.6,102.6,194.6,102.5z M194.4,102
	c0,0.2,0.1,0.9-0.1,0.9c0-0.4,0-0.8,0-1.2c0-1.4,0.4-2.9,1-4.1c0.4-0.9,1.1-1.7,1.7-2.5c0.3-0.3,0.6-0.6,0.8-1
	c0.2-0.3,0.5-0.7,0.9-0.8c-0.7,0.5-1.1,1.5-1.6,2.2c-0.7,0.9-1.2,1.8-1.8,2.8c-0.4,0.7-0.8,1.8-0.8,2.6
	C194.4,101.2,194.3,101.6,194.4,102z M193.8,103c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c-0.3,0-0.1-0.7-0.1-1c0,0,0,0,0,0
	c0-0.3,0-0.6,0.1-0.8c0-0.8,0.5-1.5,0.7-2.3c0.4-1.1,0.9-2.1,1.7-3.1c0.3-0.4,0.7-0.8,0.9-1.2c0.1-0.2,0.2-0.3,0.3-0.5
	c0.1-0.2,0.3-0.2,0.5-0.3c-0.9,1.2-1.8,2.2-2.6,3.4c-0.4,0.6-0.6,1.4-0.9,2c-0.1,0.3-0.5,1.4-0.3,1.6c-0.2,0.1-0.1,1.1-0.1,1.3
	c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1C194.1,102.9,194.1,102.9,193.8,103C193.8,103,193.8,103,193.8,103z M193,103.2c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1c0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0-0.2,0-0.3
	c0-0.2,0.1-0.4,0.1-0.7l0,0c0-0.1,0.1-0.3,0.1-0.5c0.2-0.7,0.5-1.5,0.8-2.2c0.3-0.8,0.9-1.8,1.4-2.5c0.3-0.4,0.5-0.7,0.8-1.1
	c0.2-0.4,0.5-0.4,0.9-0.5c-0.6,1-1.5,1.8-2.1,2.8c-0.3,0.6-0.6,1.2-0.8,1.8c-0.2,0.5-0.5,1-0.6,1.6c-0.1,0.4-0.2,0.7-0.2,1.1
	c0,0.2-0.1,0.5-0.1,0.7l0,0c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c-0.1,0.4,0.1,0.5-0.1,0.6c-0.1,0-0.1,0-0.2,0.1
	C193,103.2,193,103.2,193,103.2z M192.4,103.3c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0-0.5,0.1-0.9,0.1-1.4c0-0.1,0-0.2,0.1-0.3
	c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.2-0.5,0.4-0.8c0.5-0.7,0.9-1.4,1.4-2.2c0.3-0.4,0.5-0.9,0.8-1.3
	c0.2-0.3,0.2-0.5,0.5-0.6c-0.5,0.8-1.1,1.5-1.5,2.3c-0.4,0.8-0.8,1.6-1.2,2.4c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.2-0.1,0.3-0.1,0.5
	c-0.1,0.3-0.1,0.5-0.2,0.8c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.3,0,0.7-0.1,1.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2
	c0,0.1,0,0.3,0,0.4C192.7,103.2,192.6,103.3,192.4,103.3C192.4,103.3,192.4,103.3,192.4,103.3z M191.7,103.4c0-0.2,0.2-0.5,0-0.5
	c0.1-0.5,0-0.9,0.1-1.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0.1-0.4,0.2-0.7,0.3-1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0.4-0.7,0.9-1.3,1.4-1.9c0.2-0.3,0.5-0.7,0.7-1.1
	c0.2-0.4,0.2-0.6,0.6-0.7c-0.7,1.5-1.9,2.8-2.7,4.3c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.3,0.8-0.4,1.2
	c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.4,0,0.7c0,0.1,0,0.2,0,0.3c0,0.6,0,1.2-0.2,1.2
	C192,103.4,191.8,103.4,191.7,103.4z M191.1,102.5c0-0.3,0-0.7,0-1c0-0.3,0.1-0.6,0.2-0.9c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.4,0.3-0.7,0.4-1.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.2-0.4,0.4-0.8,0.6-1.1c0.1-0.1,0.1-0.2,0.2-0.3
	c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.4,0.6-0.8,0.9-1.3c0.1-0.2,0.4-0.6,0.3-0.7c0,0,0.3-0.1,0.3-0.1c-0.5,1.2-1.4,2.2-2.1,3.3
	c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.3,0.7-0.4,1.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c-0.1,0.4-0.1,0.9-0.1,1.3c0,0.3-0.2,0.6-0.1,0.9c-0.1,0-0.3,0.1-0.4-0.1
	C191,103,191.1,102.7,191.1,102.5z M190.5,102.5c0-0.3,0-0.8,0.1-1.1c0.1-0.5,0.2-1.1,0.4-1.6c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.8,0.9-1.1c0.1-0.1,0.2-0.2,0.2-0.3
	c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.9-1.6,1.5-1.9c-0.5,0.9-1.1,1.7-1.7,2.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.5,0.8-0.7,1.2
	c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.3,0.6-0.4,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5-0.2,1-0.2,1.4
	c0,0.3,0,0.7,0,1c0,0.2-0.1,0.4,0,0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2C190.3,103.1,190.5,102.7,190.5,102.5z M190.1,102.7
	c0-0.4,0-0.8,0-1.2c0-0.7,0.3-1.5,0.6-2.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.3-0.8,0.6-1.1l0,0c0.1-0.1,0.1-0.1,0.2-0.2
	c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.4,0.5-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.5,0.7-1,0.5-1.1c0.2-0.1,0.4-0.1,0.6-0.2
	c-0.4,0.5-0.7,1.1-1.1,1.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.2,0.2
	c-0.2,0.3-0.4,0.7-0.5,1.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.8-0.5,1.6-0.5,2.4c0,0.2,0,0.4-0.1,0.6c0,0.2,0.1,0.8-0.2,0.8
	C190,103.2,190.1,102.9,190.1,102.7z M189.6,103.3c0-1.6,0.4-3.1,1-4.5c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.2-0.3,0.3-0.6,0.5-1
	c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.4,0.4-0.8,0.8-1.1
	c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.2c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.4-0.5,0.8-0.9,1.1
	c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3
	c-0.1,0.2-0.2,0.4-0.2,0.6c-0.5,1.3-0.4,2.6-0.5,3.9C189.8,103.4,189.7,103.4,189.6,103.3z M191,95.9c-0.3,0.3-0.6,0.7-0.8,1.1
	c-0.1,0.1-0.1,0.2-0.1,0.2c-0.4,0.6-0.6,1.3-1,1.9c-0.5,0.9-0.9,2-1,3.1c0,0,0.1,0,0.1,0c-0.1,0.1-0.2,0.6-0.2,0.8c0,0-0.4,0-0.4,0
	c0.1-0.1,0.1-0.7,0.1-0.9c0.1-0.3,0.1-0.6,0.2-1c0.1-0.7,0.3-1.3,0.6-1.9c0.3-0.6,0.6-1.2,1.1-1.7c0.1-0.2,0.3-0.3,0.4-0.5
	c0.1-0.1,0.1-0.1,0.1-0.2C190.4,96.4,190.6,96.1,191,95.9z M191.6,95.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
	c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.5-0.5,1-0.7,1.5
	c-0.1,0.3-0.4,0.9-0.4,1.3c-0.2,0.1-0.2,1.5-0.2,1.7c-0.1,0-0.2-0.1-0.4-0.1c0-0.5,0.1-1.3,0.2-1.7c0.3-0.8,0.6-1.5,0.9-2.2
	c0.2-0.5,0.5-1,0.8-1.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.1,0.2-0.2
	C191.4,95.8,191.5,95.7,191.6,95.6z M192.4,95.2c-0.4,0.4-0.7,0.9-1,1.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.4,0.8-0.7,1.2
	c0,0.1-0.1,0.2-0.1,0.3c-0.7,1.5-1,3.2-1.1,4.8c-0.3-0.1-0.5-0.1-0.5-0.4c0-1.3,0.4-2.6,1-3.8c0.2-0.3,0.4-0.6,0.5-1
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0.2-0.3,0.4-0.6,0.6-0.9c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3
	C191.6,95.8,192,95.3,192.4,95.2z M203.6,88.5c0.4-0.1,0.9-0.1,1.3-0.1c0.5,0,1.1,0,1.6,0c0.2,0,0.5,0.1,0.7,0.2
	c0.2,0.1,0.5,0,0.8,0.1c0.4,0.1,0.5,0.3,1,0.3c0.9,0,1.8,0.2,2.7,0.1c0.9-0.1,2.2-0.4,2.9-1c-0.1,0.5-0.7,0.9-1.1,1.1
	c-0.6,0.1-1.2,0.3-1.8,0.4c-0.9,0.1-1.8,0.2-2.6,0.1c0,0,0-0.1,0-0.1c-0.2,0.1-0.6,0.1-0.8-0.1c0,0-0.5,0.1-0.5,0
	c-0.2-0.2-0.4-0.1-0.7-0.1c0,0-0.4-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.2,0-0.2-0.1c-0.3,0.1-0.5,0-0.8,0
	c-0.1,0-0.6,0.2-0.7,0.3c0-0.2-0.6,0.3-0.6,0.3c-0.4,0.3-0.8,0.6-1.2,0.9c-1,0.7-1.9,1.4-3,1.9c-0.5,0.2-0.8,0.4-1.3,0.5
	c-0.3,0-0.6,0.2-0.9,0.3c0-0.1-0.7,0.2-0.9,0.2c0-0.2-0.2-0.1-0.2,0c0,0.2-1.7,0.7-2,0.8c-0.3,0.1-0.1,0-0.4,0.1
	c-0.1,0-0.1,0.1-0.2,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0.1c0.1,0,0.2-0.1,0.2-0.1
	c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.7c0.5-0.3,1.1-0.4,1.6-0.7c1-0.4,2-0.8,3-1.2c1.1-0.4,2.1-0.9,3.1-1.5
	c0.4-0.3,0.8-0.7,1.2-1.1C202.9,89.2,203.2,88.6,203.6,88.5z M205.9,86.3c0.5-0.1,1,0.1,1.6,0.1c0.9,0,1.8,0.5,2.7,0.4
	c1.7,0,3.4-0.4,4.4-1.9c0,0.3,0,0.7,0,1c0,0.3,0.2,0.7,0.1,1c-0.2,0.5-0.9,0.8-1.3,1c-1.2,0.6-2.5,0.6-3.8,0.6
	c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1-0.1-0.4-0.1-0.5-0.1c-0.3-0.1-0.7-0.2-1-0.3c-0.6-0.3-1.2-0.2-1.8-0.1
	c0.2-0.2,0.3-0.5,0.4-0.7C204.9,86.8,205.6,86.4,205.9,86.3z M206.5,84.8c0.2,0,0.4,0,0.6,0c0.4,0,0.9,0.2,1.3,0.4
	c0.5,0.3,1.4,0.5,2,0.3c0.3-0.1,0.7-0.1,1-0.2c0.2-0.1,0.5,0,0.7-0.2c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.8-0.7,1.2-1.2
	c0.3-0.4,0.4-0.8,0.8-1.1c0,1-0.8,2-1.5,2.6c-0.6,0.5-1.4,1-2.2,1c-0.9,0.1-2-0.1-2.8-0.5c-0.7-0.3-1.7-0.5-2.4-0.3
	C206,84.9,206,84.8,206.5,84.8z M214.2,81.8c-0.4,0.7-0.8,1.2-1.4,1.8c-0.4,0.4-1.2,1-1.8,1c-0.7,0.1-1.3,0.2-2-0.1
	c-0.6-0.2-1.3-0.6-2-0.7c0.5-0.5,1.1-1.1,1.8-1.4c0.6-0.3,1.3-0.4,1.9-0.7c1.1-0.5,2.2-0.1,3.2,0.1C214,81.8,214.1,81.8,214.2,81.8
	z M214.6,81.5C214.6,81.6,214.6,81.6,214.6,81.5L214.6,81.5z M214.1,82.7c-0.3,0.4-0.5,0.9-0.9,1.2c-0.6,0.7-1.5,1.4-2.4,1.3
	c-0.4,0-0.7,0.1-1.1,0.1c-0.4,0-0.7-0.2-1-0.2c-0.3,0-0.8-0.3-1.1-0.4c-0.4-0.2-0.9-0.2-1.4-0.1c0.3-0.4,0.5-0.6,1-0.5
	c0.4,0.1,0.9,0.3,1.3,0.4c0.9,0.4,1.7,0.5,2.7,0.3c0.8-0.2,1.6-0.9,2.2-1.5c0.4-0.4,0.6-0.8,0.9-1.2c0.1-0.2,0.3-0.2,0.3-0.5
	c0,0.1,0,0.1,0,0.2C214.9,82,214.2,82.5,214.1,82.7z M214.7,83.3c0,0.6-0.1,1.1-0.4,1.6c-0.3,0.4-0.7,0.8-1.1,1.1
	c-0.8,0.4-1.8,0.4-2.6,0.6c-0.3,0-0.6,0.1-0.8,0c-0.6-0.1-1.1-0.2-1.7-0.4c-0.5-0.1-1-0.2-1.5-0.2c-0.6-0.1-1.1,0.2-1.7,0.5
	c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.7,0c0.5,0,0.8,0.1,1.3,0.2c0.4,0.1,0.8,0.3,1.2,0.4
	c0.5,0.1,1,0.1,1.5,0.1C212.4,86.3,213.9,85,214.7,83.3z M214.7,87.4c-0.2,1.1-1.6,1.3-2.6,1.5c-1,0.3-2-0.1-3,0
	c-0.5,0-0.6-0.2-1-0.3c-0.2-0.1-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.9-0.1-2-0.1-2.9,0.1c0.1-0.2,0.3-0.4,0.4-0.6
	c0.5,0,0.9-0.1,1.4,0c0.5,0.1,1,0.3,1.4,0.4c0.1,0.2,0.3,0,0.5,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6,0.2,0.9,0.3
	c0.4,0.2,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8-0.1c0.9-0.1,2.2-0.7,2.7-1.4C214.7,87.3,214.7,87.3,214.7,87.4z M233.3,106
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.8,0.2-1.6,0.4-2.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.4,0.1-0.8,0.1-1.3
	c0-0.8-0.1-1.5-0.3-2.2c0.2,0.2,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1,0,0.1
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.5,0.1,0.6c0,0,0,0,0,0c-0.1,0.1-0.1,0.6-0.1,1.1c0,0.3,0,0.6,0,0.7
	c-0.1,0.6-0.2,1.2-0.2,1.8c0,0.6-0.2,1.5,0,2.1c0.1,0.3,0.2,0.4-0.2,0.4c-0.3,0-0.8,0.2-0.8-0.2C233.1,107.2,233.3,106.6,233.3,106
	z M232.3,107.8c0-0.3,0-0.6,0.1-0.9c0-0.1,0-0.2,0-0.4c0.1-1,0.4-2.1,0.6-3.1c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.7
	c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.2-0.9,0.2-1.3c0-0.9-0.1-1.7-0.2-2.7c0.5,0.5,0.5,1.8,0.5,2.5c0,0.3,0,0.6,0,0.9
	c0,0.4,0,0.7-0.1,1.1c0,0.2-0.1,0.4-0.1,0.6c-0.2,1-0.4,2.1-0.6,3.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,0.9
	c0,0.2,0,0.5,0,0.7C233,107.9,232.3,107.9,232.3,107.8z M231.5,107.9c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c0-0.2,0-0.1,0-0.2
	c0-0.6,0.1-1.2,0.3-1.8c0-0.2,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.1-0.5c0.1-0.6,0.3-1.2,0.5-1.8
	c0.5-1.7,0.9-3.5,0.4-5.3c0.7,0.6,0.6,1.8,0.6,2.7c0,1-0.2,2-0.4,3c0,0.1-0.1,0.3-0.1,0.5c0,0.2-0.1,0.4-0.1,0.6
	c-0.2,0.9-0.5,1.8-0.5,2.7c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.7C232.1,108,231.8,107.9,231.5,107.9z M230.6,107.6
	c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0c0.1-0.2,0.1-0.7,0.2-1c0.2-0.5,0.4-0.9,0.6-1.4c0.3-0.9,0.6-1.9,0.9-2.9
	c0.5-1.7,0.9-3.6,0.3-5.3c0.5,0.4,0.5,1.6,0.5,2.2c0,1.1-0.3,2.1-0.6,3.2c-0.2,0.8-0.4,1.7-0.6,2.5c0,0.2-0.1,0.3-0.1,0.5
	c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.4-0.2,0.9-0.1,1.3C230.9,107.7,230.7,107.7,230.6,107.6z
	M229.4,106.7c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.4,0.4-0.9,0.6-1.3c0.4-0.9,0.7-1.8,1-2.8c0.3-0.9,0.6-2,0.7-2.9
	c0-0.8,0.1-1.9-0.2-2.6c0.6,0.4,0.5,1.5,0.5,2.1c0,0.7,0,1.2-0.2,1.9c-0.6,2.3-1.4,4.5-2.1,6.8c-0.1,0-0.1-0.1-0.2-0.1
	C229.6,107.1,229.3,107,229.4,106.7z M228.7,106.2c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.5,0.5-1.1,0.7-1.6c0.4-1,0.8-2,1.1-3
	c0.3-0.9,0.5-1.9,0.6-2.8c0.1-0.7-0.1-1.4-0.2-2.1c0.8,0.6,0.6,1.7,0.6,2.6c0,0.9-0.2,1.8-0.5,2.7c-0.3,0.9-0.5,1.9-0.9,2.8
	c-0.4,0.9-0.8,1.7-1.2,2.6c0,0,0,0-0.1-0.1C229,106.6,228.6,106.5,228.7,106.2z M228.2,106c0.2-0.2,0.2-0.4,0.3-0.7
	c0.2-0.5,0.5-0.9,0.7-1.3c0.4-0.9,0.8-1.9,1.1-2.9c0.3-0.8,0.3-1.7,0.5-2.5c0.2-0.9,0.2-1.7,0-2.6c0.3,0.3,0.4,1.5,0.3,2
	c0,0.9-0.3,1.7-0.5,2.6c-0.2,1-0.7,2-1,3c-0.3,0.9-0.9,1.7-1.2,2.6c0,0-0.1-0.1-0.1-0.1C228.3,106.1,228.3,106.1,228.2,106z
	M228.5,104.7c0.2-0.4,0.4-0.9,0.6-1.4c0.2-0.8,0.4-1.6,0.7-2.4c0.4-1.7,1.2-3.6,0.5-5.3c0.6,0.3,0.6,1.5,0.6,2.1
	c0,0.8-0.3,1.7-0.4,2.4c-0.2,1.1-0.6,2.2-1.1,3.2c-0.2,0.4-0.4,0.9-0.6,1.3c-0.2,0.4-0.4,0.9-0.6,1.2
	C227.8,105.5,228.3,105,228.5,104.7z M227.7,104.8c0.1-0.3,0.2-0.6,0.4-0.8c0.2-0.5,0.4-1,0.5-1.5c0.2-0.5,0.3-1,0.4-1.5
	c0.1-0.4,0.3-0.9,0.3-1.4c0-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4,0.1-0.7,0-1.1c-0.1-0.3,0-0.7-0.1-1
	c1.3,1.4,0.4,3.9,0,5.5c-0.2,0.8-0.5,1.7-0.7,2.5c-0.2,0.9-0.7,1.6-1.1,2.5C227.5,105.2,227.5,105.2,227.7,104.8z M227.5,103.6
	c0.2-0.5,0.3-1,0.5-1.5c0.2-0.9,0.5-1.7,0.7-2.6c0.2-0.7,0.2-1.4,0.3-2.1c0.1-0.5,0.1-1.1,0.1-1.6c0-0.5-0.2-0.9-0.2-1.3
	c0.3,0.3,0.5,0.4,0.6,0.9c0,0.2,0.1,0.5,0.1,0.6c-0.1,0.1,0.1,0.6,0,0.8c0,0.3,0,0.6-0.1,0.9c-0.1,0.8-0.2,1.6-0.5,2.3
	c-0.5,1.7-0.9,3.6-1.8,5.2c-0.1-0.1-0.1-0.1-0.2-0.2C227,104.7,227.4,103.9,227.5,103.6z M227.2,103.3c0.2-0.5,0.3-1,0.5-1.5
	c0.3-1.1,0.5-2.1,0.7-3.2c0.1-0.3,0.1-0.7,0.2-1c0-0.4,0.2-0.9,0-1.2c-0.1-0.4,0.1-0.9-0.1-1.3c-0.1-0.3-0.1-0.7-0.2-1
	c0.7,0.6,0.5,2.1,0.5,3c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,1-0.5,2-0.8,2.9c-0.1,0.5-0.3,1-0.5,1.5c-0.1,0.3-0.2,0.6-0.3,0.8
	c-0.1,0.2,0-0.3-0.2,0.1C226.6,104.3,227.1,103.5,227.2,103.3z M226.2,103.7c0,0.1,0.1,0.1,0.2,0.1c0.3-0.5,0.5-1,0.7-1.5
	c0.2-0.5,0.4-0.9,0.5-1.5c0.1-0.6,0.3-1.1,0.4-1.6c0.1-0.3,0-0.7,0.1-1c0-0.4,0.1-0.7,0.1-1.1c0-0.4,0.1-0.8,0.1-1.3
	c0-0.9-0.2-1.7-0.6-2.5c0.3,0.3,0.5,0.4,0.6,0.8c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4,0.1,0.8,0.1,1.3c0,0.2,0.1,0.4,0.1,0.6
	c0,0.2-0.1,0.5-0.1,0.7c0,0.3-0.1,0.6-0.1,0.8c0.1,0.5-0.2,1.1-0.2,1.5c-0.1,0.6-0.3,1.1-0.4,1.7c-0.2,1-0.6,2-1,3
	c-0.1-0.1-0.2-0.2-0.2-0.3C226.3,103.8,226.2,103.7,226.2,103.7z M227.5,93.8C227.5,93.8,227.5,93.8,227.5,93.8
	c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.2,0-0.1C227.4,93.5,227.4,93.7,227.5,93.8z M227.7,95c0-0.3-0.2-0.7-0.1-1
	c0.3,0.7,0.3,1.3,0.3,2c0,0.3-0.1,0.6-0.1,0.9c0,0.3,0.1,0.7,0,1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.2,0c0-0.4,0-0.8,0.1-1.2
	c0-0.2,0-0.3-0.1-0.3c0-0.1,0-0.2,0-0.3C227.7,95.7,227.7,95.3,227.7,95z M225.8,102.9c0.2-0.2,0.3-0.4,0.4-0.6
	c0.3-0.4,0.4-0.8,0.6-1.3c0.3-0.9,0.5-2,0.8-2.9c-0.1,0.3-0.1,0.7-0.1,1c0,0.5-0.3,1-0.3,1.5c-0.2,1-0.5,2.2-1.1,3.1
	C226,103.4,225.7,103.2,225.8,102.9z M225.1,102.3c0,0,0.9-1.3,1-1.5c0.3-0.6,0.7-1.1,1-1.7c-0.2,1.3-0.8,2.7-1.6,3.8
	C225.4,102.7,225.3,102.5,225.1,102.3z M225,101.6c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.4,0.5-0.9,0.8-1.3c0.6-0.7,0.8-1.5,1.2-2.3
	c0,1.1-0.7,1.8-1.1,2.8c-0.2,0.5-0.5,0.9-0.8,1.3c-0.2,0.3-0.4,0.3-0.5,0.6C224.7,101.9,224.8,101.9,225,101.6z M224.7,101.1
	c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.4,0.6-0.9,0.9-1.3c0.6-0.9,1.3-2,1.6-3.1c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.7
	c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.5-0.6,0.9-0.9,1.4c-0.3,0.4-0.6,0.8-0.9,1.2C224.4,101.5,224.4,101.4,224.7,101.1z M224.2,100.9
	c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.3-0.5,0.7-1,1-1.6c0.7-1.2,1.4-2.2,1.6-3.6c0.1,1.4-0.7,2.8-1.4,4
	c-0.3,0.5-0.7,1.2-1.1,1.7c-0.2,0.3-0.5,0.6-0.7,0.9C224.1,101.1,224,101.1,224.2,100.9z M223.7,100.6c0,0,1.3-1.8,1.4-2
	c0.3-0.6,0.6-1.2,1-1.8c0.8-1.4,1-2.9,0.6-4.4c0.4,0.5,0.7,1.4,0.6,2.1c-0.1,1-0.6,1.8-1,2.6c-0.4,0.8-1,1.6-1.5,2.4
	C224.6,99.8,224,101.1,223.7,100.6z M224,99.6c0.2-0.3,0.5-0.7,0.7-1.1c0.4-0.7,0.7-1.5,1.1-2.3c0.6-1.4,1-2.9,0.6-4.4
	c0.4,0.5,0.5,1.6,0.5,2.2c0,0.8-0.4,1.8-0.7,2.4c-0.4,0.8-0.7,1.5-1.2,2.2c-0.2,0.4-0.5,0.7-0.7,1.1c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.1,0.1,0-0.2-0.2,0C223.5,100.1,223.8,99.8,224,99.6z M223.4,100c0-0.1,0.5-0.8,0.5-0.9c0.2-0.3,0.4-0.7,0.5-1.1
	c0.4-0.9,0.9-1.8,1.2-2.8c0.5-1.5,0.4-2.9-0.1-4.4c0.5,0.6,0.7,1.4,0.7,2.2c0,0.5,0,0.9-0.1,1.4c-0.1,0.4-0.3,0.8-0.4,1.2
	c-0.3,0.9-0.7,1.8-1.1,2.6c-0.2,0.5-0.5,0.9-0.7,1.3C223.8,99.5,223.4,100,223.4,100z M223,99.3c0.6-0.7,1-1.6,1.3-2.4
	c0.3-0.8,0.7-1.7,0.8-2.6c0.1-0.7,0.3-1.3,0.2-1.9c0.1-0.8-0.3-1.8-0.7-2.5c0.6,0.2,0.7,0.8,0.9,1.3c0.1,0.3,0.2,0.7,0.2,1
	c0,0.2,0,0.5,0.1,0.6c-0.2,0.2-0.1,1-0.1,1.2c-0.1,0.7-0.4,1.5-0.7,2.2c-0.3,0.7-0.6,1.5-1,2.1c-0.2,0.3-0.4,1.2-0.8,1.3
	C223.2,99.6,223.1,99.4,223,99.3z M222.9,98.2c0.1-0.1,0.2-0.2,0.3-0.4c0.3-0.4,0.4-1,0.6-1.4c0.3-0.9,0.6-1.8,0.8-2.7
	c0.1-0.4,0.1-0.7,0.2-1.1c0-0.3-0.1-0.5-0.1-0.8c0-0.4-0.1-0.9-0.3-1.2c-0.1-0.4-0.4-0.7-0.6-1c0.7,0,1,1.2,1.2,1.8
	c0.2,0.8,0.4,1.8,0.1,2.6c-0.3,0.9-0.5,1.8-0.8,2.7c-0.3,0.9-0.8,1.7-1.2,2.4c0-0.1-0.1-0.1-0.1-0.2
	C222.7,98.5,222.7,98.5,222.9,98.2z M223,97c0.2-0.4,0.3-0.8,0.4-1.2c0.2-0.5,0.4-1,0.5-1.5c0.1-0.3,0.1-0.7,0.1-1.1
	c0.1-0.5,0.1-1.4-0.1-1.9c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.3-0.1-0.6-0.3-0.8c0.8,0.3,0.9,1.4,1.1,2.1c0.1,0.2,0.1,0.6,0.1,0.8
	c0.1,0.2,0,0.7-0.1,0.9c-0.2,0.4-0.3,1-0.3,1.5c0,0.4-0.3,1-0.4,1.4c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.3-0.4,0.4-0.5,0.5
	c0,0,0,0,0-0.1C222.3,97.7,222.9,97.3,223,97z M222.2,97.1c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.4,0.3-0.8,0.5-1.2
	c0.3-0.8,0.6-1.7,0.6-2.6c0-0.3,0-0.5,0-0.8c0,0-0.1-0.5-0.1-0.5c-0.2-0.7-0.2-1.6-0.7-2.2c0.4,0,0.5,0.3,0.6,0.6
	c0.1,0.3,0.3,0.7,0.4,1c0.2,0.5,0.5,1.4,0.3,2c-0.1,0.3-0.1,0.7-0.1,1c-0.1,0.4-0.2,0.9-0.4,1.3c-0.2,0.5-0.3,1-0.5,1.4
	c-0.1,0.3-0.4,0.6-0.6,0.9C222.2,97.4,222,97.3,222.2,97.1z M222.1,95.6c0.2-0.3,0.3-0.5,0.4-0.8c0.2-0.6,0.3-1.3,0.5-1.8
	c0.2-0.6,0.1-1.3,0-1.9c-0.1-0.6-0.3-1.3-0.8-1.6c0.4-0.1,0.5,0.2,0.6,0.5c0.1,0.3,0.3,0.7,0.3,1.1c0,0.2,0.1,1.3,0.3,1.4
	c-0.1,0.1-0.1,0.5-0.1,0.7c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.5-0.3,1-0.5,1.5c-0.2,0.4-0.2,0.8-0.5,1.1c0-0.2-0.1-0.4-0.1-0.6
	C221.8,96.1,221.9,95.9,222.1,95.6z M221.8,95.1c0.2-0.3,0.3-0.6,0.4-0.9c0.2-0.7,0.4-1.3,0.4-2c0-0.8-0.2-2.4-1.1-2.7
	c1.1,0,1.3,1.8,1.4,2.6c0,0.6-0.2,1.2-0.4,1.9c-0.1,0.3-0.2,0.7-0.3,1c-0.1,0.3-0.3,0.6-0.4,0.9C221.5,95.5,221.6,95.4,221.8,95.1z
	M221.1,94.8c0.3,0.3,0.5-0.7,0.5-0.8c0.3-0.6,0.4-1.1,0.4-1.7c0-1-0.3-1.9-1-2.6c0.9-0.1,1.3,1.6,1.3,2.3c0,1.2-0.3,2.4-0.9,3.4
	C221.3,95.1,221.2,94.9,221.1,94.8z M221.6,92.3c0-0.6,0-1-0.3-1.6c-0.2-0.4-0.4-0.7-0.7-1.1c0.3-0.1,0.8,0.8,0.9,1
	c0.2,0.4,0.2,1,0.3,1.3c-0.2,0.3-0.1,0.9-0.2,1.3c-0.1,0.3-0.2,0.5-0.3,0.8C221.6,93.5,221.6,92.7,221.6,92.3z M221.1,94.5
	c0.1-0.1,0.1-0.2,0.2-0.3C221.3,94.3,221.2,94.4,221.1,94.5C221.2,94.5,221.1,94.5,221.1,94.5z M221.1,94.5c0,0.1,0,0.1,0,0.2
	C221,94.6,221,94.6,221.1,94.5z M220.7,93.9c0.1-0.1,0.1-0.1,0.2-0.2c0-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.1-0.8,0-1.2
	c-0.2-0.7-0.1-1.4-0.5-2c0.3,0.4,0.6,0.9,0.7,1.3c0.1,0.4,0,0.8,0,1.2c0,0.5-0.1,1.5-0.5,1.9C220.8,94.2,220.8,94.1,220.7,93.9z
	M220.6,92.4c0.1-0.3,0-0.6,0-0.8c0-0.6-0.1-1.1-0.3-1.6c0.5,0.5,0.5,1.4,0.6,2c0.1,0.7,0,1.2-0.2,1.8c-0.1-0.2-0.1-0.4-0.3-0.6
	C220.2,92.9,220.5,92.6,220.6,92.4z M220.4,89.7C220.4,89.7,220.4,89.7,220.4,89.7c0.1,0.1,0.1,0.1,0.2,0.2
	C220.5,89.8,220.5,89.8,220.4,89.7z M220.3,89.8c-0.1-0.1,0-0.1,0.1-0.1C220.3,89.8,220.3,89.8,220.3,89.8z M219.8,92.3
	c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.5-0.1-1-0.2-1.4c0.4,0,0.5,1.2,0.5,1.5c0,0.5,0,0.9-0.2,1.4
	C220,92.6,219.9,92.5,219.8,92.3z M219.4,91.7c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.7c0-0.3,0.1-0.6,0-0.9
	c0.3,0.1,0.3,1,0.3,1.2c0,0.4-0.1,0.8-0.3,1.2C219.5,92,219.2,91.9,219.4,91.7z M219.1,91.2c0.1-0.2,0.1-0.4,0.1-0.6
	c0-0.3,0.1-0.6,0-0.9c0.7,0.1,0,1.7,0,2C218.8,91.5,219,91.5,219.1,91.2z M218.4,91.2c0.3-0.3,0.4-1.1,0.4-1.5
	c0.3,0,0.1,0.6,0.2,0.7c-0.2,0.2-0.1,0.7-0.3,1C218.6,91.3,218.5,91.3,218.4,91.2z M217.5,90.7c0.2-0.3,0.3-0.7,0.3-1
	c0.3,0.1,0.6,0,0.8,0c0,0.2,0,0.4,0,0.6c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1-0.1-0.2-0.1-0.4-0.2c0.2-0.1,0.4-1,0.4-1.2
	c-0.5-0.1-0.3,0.8-0.5,1.1C217.7,90.8,217.6,90.7,217.5,90.7z M217,90.4c0.2-0.2,0.2-0.5,0.3-0.7c0-0.2,0.2-0.1,0.4-0.1
	c-0.1,0-0.2,0.8-0.4,1C217.2,90.5,217.1,90.4,217,90.4z M216.5,90.2c0.2-0.1,0.2-0.5,0.2-0.6c0.1,0,0.2,0,0.3,0
	c0,0.3-0.1,0.5-0.2,0.7C216.7,90.2,216.6,90.2,216.5,90.2z M215.3,88.6c0.3,0.5,0.9,0.7,1.4,0.9c-0.3-0.1-0.3,0.4-0.2,0.6
	c-0.1-0.1-0.2-0.1-0.3-0.1C215.7,89.7,215.4,89.2,215.3,88.6z M215,85.9c0.3,0.3,0.8,0.4,1.1,0.6c0.3,0.2,0.6,0.1,0.9,0.3
	c0.4,0.2,1,0.5,1.4,0.5c0.3,0,0.6,0.3,0.9,0.2c0.6-0.2,1.3-0.1,1.9-0.1c0.9,0,1.6,0.1,2.4,0.6c-0.4-0.1-0.8-0.3-1.3-0.4
	c-0.4-0.1-0.8,0-1.2,0c-0.7,0-1.4-0.1-2.1-0.1c-0.8,0-1.6-0.4-2.3-0.7c-0.4-0.2-0.9-0.3-1.3-0.5c-0.2-0.1-0.4-0.2-0.4-0.4
	C215,86,215,85.9,215,85.9z M214.9,83.2c0,0.2,0,0.3,0,0.5C214.9,83.6,214.9,83.4,214.9,83.2z M214.9,82.8c0,0.1,0,0.2,0,0.2
	C214.9,83,214.9,82.6,214.9,82.8z M214.8,84.9c0,0.1,0,0,0-0.1C214.8,84.8,214.9,84.9,214.8,84.9z M214.9,82.7c0.1-0.4,0-0.1,0-0.2
	c0-0.1-0.1-0.7,0.2-0.7C215.1,82.1,215.1,82.5,214.9,82.7z M221.2,84.2c-0.6,0-1.4,0.1-2,0.2c-0.5,0.1-1.2-0.1-1.7-0.2
	c-1.2-0.3-1.9-1.2-2.1-2.4c0,0-0.1,0-0.1,0c0-0.3,2.3,0.3,2.5,0.4c1,0.4,1.9,0.9,2.8,1.5C220.8,83.9,221,84,221.2,84.2z
	M221.7,84.6c-0.7,0-1.3,0.4-2,0.5c-0.6,0.1-1.4,0-2-0.2c-0.6-0.2-1.2-0.7-1.7-1.1c-0.3-0.2-0.5-0.6-0.7-0.9c-0.1-0.2,0-0.5,0-0.7
	c0.2,0.6,0.4,1.2,0.9,1.6c0.3,0.3,0.7,0.3,1.1,0.5c0.5,0.2,1.5,0.4,2,0.3c0.5-0.1,1.4,0,1.7-0.2c0.1,0.1,0.4,0.1,0.5,0.1
	C221.6,84.6,221.6,84.6,221.7,84.6z M223.9,87.5c-0.8-0.5-1.4-0.8-2.4-0.8c-1.1,0-2.2-0.2-3.3-0.3c-0.6-0.1-1-0.2-1.6-0.4
	c-0.4-0.2-0.6-0.5-1-0.7c-0.4-0.2-0.7-0.5-0.6-1c0-0.4,0.1-0.7,0.1-1.1c0.6,1,1.8,1.8,2.9,2.1c0.5,0.2,1.3,0.2,1.8,0
	c0.3-0.1,1.4-0.3,1.5-0.5c0.1,0.2,0.3,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.5,0.8,1.1,1.2,1.7
	C223.6,87.1,223.8,87.3,223.9,87.5z M224.3,88.2c-0.6-0.3-1.1-0.6-1.7-0.8c-0.4-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.7-0.1-1.1,0
	c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.1c-0.5-0.2-1-0.2-1.4-0.5c-0.1-0.1-0.9-0.2-0.9-0.3c0-0.1-0.3-0.1-0.4-0.1
	c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2,0-0.2,0.1-0.4c0.5,0.5,1.2,1,1.8,1.3
	c0.8,0.3,1.7,0.4,2.6,0.5c0.6,0.1,1.2,0.2,1.8,0.2c0.4,0,0.8-0.1,1.2,0.1C223.1,87.3,224,87.6,224.3,88.2z M233.4,97.4
	c-0.1,0-0.1,0-0.2-0.1c0,0,0,0.1,0,0.1c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.4-0.1-0.5-0.4
	c0,0-0.3-0.2-0.3-0.1c-0.3-0.2-1.1-0.5-1.1-0.9c-0.3,0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.6-0.4-0.8-0.6c-0.6-0.5-1.2-1.1-1.8-1.7
	c-0.3-0.3-0.8-0.9-0.8-1.3c0,0-0.1,0-0.2,0c-0.2-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-1-0.6-1.4-1.1c0,0,0,0.1,0,0.1
	c-1.3-0.4-2.7,0-3.9,0.2c-0.5,0.1-1-0.1-1.6-0.1c-0.5,0-1-0.1-1.6-0.1c-0.6,0-1.4-0.4-1.7-1c-0.3-0.5-0.3-0.9-0.3-1.5
	c0-0.1,0-0.2,0-0.3c0.3,0.2,0.7,0.2,1,0.4c0.4,0.2,0.8,0.4,1.2,0.5c0.3,0.1,1.5,0.8,1.8,0.5c0.2,0.2,1.1,0.1,1.4,0.1
	c0.7,0,1.5-0.1,2.2,0.1c0.7,0.2,1.5,0.4,2.1,0.8c0.4,0.7,0.9,1.4,1.4,2.1c1,1.4,2.5,2.5,3.8,3.6c0.6,0.5,1.2,1,1.9,1.4
	C232.4,96.3,232.9,96.9,233.4,97.4z M234.7,98.8c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c0,0-0.1-0.3-0.1-0.3
	c-0.1-0.1-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.5,0.5,0.7,0.8
	C234.6,98.6,234.6,98.7,234.7,98.8z M235,100C235,100,235,100,235,100c0,0.2,0,0.3,0,0.4c0,0,0,0,0,0c0.1,0.4,0,0.9,0,1.3
	c0,0.6,0,1.2,0,1.8c0,0.6-0.1,1.2-0.1,1.7c0,0.9-0.2,1.8,0,2.6c-0.4,0-0.5-0.2-0.5-0.6c0-0.9,0-1.7,0.2-2.6
	c0.1-0.6,0.2-1.3,0.2-1.9c0-0.2,0-0.5,0-0.7c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
	c0-0.2,0-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.1-0.5-0.2-0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2
	c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2C234.9,99.6,234.9,99.8,235,100z M235.5,103.5c0,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.8,0,1.1
	c0,0.8-0.1,1.7,0.1,2.4c-0.4,0.1-0.4,0-0.4-0.4c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.1-0.9,0.1-1.3c0-0.9,0-1.8,0-2.7
	c0-0.5,0-1-0.1-1.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.2
	C235.9,100.8,235.4,102.5,235.5,103.5z M236.1,102.6c-0.2,0.6-0.1,1.2-0.1,1.8c0,0.6,0,1.1,0,1.7c0,0.4-0.2,1,0.1,1.4
	c0,0.1,0,0,0,0.1c-0.2,0-0.3,0.1-0.4-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0-0.6,0-0.9c0-0.7,0-1.4,0-2.1c0.1-1.3,0.4-2.7,0.1-4
	c0.1,0.2,0.3,0.3,0.3,0.5c0,0.2,0.1,0.3,0.1,0.5C236.1,101.8,236.2,102.2,236.1,102.6z M236.6,107.6c-0.1,0-0.2,0-0.4,0
	c0-0.1,0,0,0-0.1c0-0.4-0.1-0.7-0.1-1.1c0-0.2,0-0.3,0-0.5c0-0.5,0-1,0-1.4c0-0.5,0-1,0-1.5c0.1-0.7,0.3-1.4,0.1-2.2
	c0.3,0.4,0.1,0.9,0.1,1.4c0,0.4,0,0.8,0,1.1c0,0.5,0.1,1,0.1,1.5C236.4,105.7,236.2,106.9,236.6,107.6z M236.9,104.6
	c0,0.9,0.2,2,0.6,2.8c-0.2,0.1-0.5,0.2-0.6,0c-0.1-0.1,0-0.2,0-0.3c-0.1-0.3-0.1-0.6-0.1-0.9c0-1.6-0.1-3.2,0-4.9
	c0.2,0.3,0.1,0.8,0.1,1.2c0,0.3,0,0.7,0,1C236.8,103.9,236.9,104.3,236.9,104.6z M237,102.6c0.1,0.3,0.1,0.5,0.2,0.8
	c0.1,0.5,0.2,1,0.3,1.5c0.2,0.8,0.2,1.7,0.5,2.4c-0.2,0-0.3,0.2-0.4,0c-0.3-0.8-0.5-1.8-0.5-2.6c0-0.3,0-0.7-0.1-1
	c-0.1-0.6-0.1-1.2-0.1-1.8C237.1,102,237,102.5,237,102.6z M238.4,107.2c-0.1,0-0.1,0-0.2,0c-0.4-0.7-0.4-1.7-0.5-2.4
	c-0.2-0.8-0.5-1.7-0.5-2.6c0.1,0.2,0.3,0.5,0.4,0.7c0,0-0.1,0-0.1,0c-0.1,0.3,0.2,0.7,0.2,1c0.1,0.4,0.1,0.8,0.3,1.1
	c0.2,0.4,0,1.1,0.3,1.5C238.2,106.6,238.3,107,238.4,107.2z"
      />
    </g>
    <path
      fill="none"
      stroke="#A38169"
      strokeWidth="0.25"
      strokeMiterlimit="10"
      d="M212.9,13.1c2.4,0,4.6,0.6,6.9,0.9c2.2,0.2,4.6,0.9,6.6,2c2,1.2,2.5,2.1,4.9,4.1c2.1,1.8,2,3.6,3.1,5.3
c0.7,1,1.5,2.1,2.2,3c0.5,0.6,2.2,2,2.2,2.7"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M27.7,115.7v-1.4h7.1V96.6h1.6v17.7h15.2v1.4H36.4v48.2h-1.6v-48.2H27.7z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M90.6,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
V75.7h1.6v51.1C75,117,83.8,113,90.6,113z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M130.8,139c0,14.2,10.3,24.5,24.8,24.5c9.2,0,15.6-3.6,19.9-9.5l1.3,1c-4.6,6.2-11.5,10-21.2,10
c-15.5,0-26.4-11.1-26.4-25.9c0-14.9,11.4-25.9,25.3-25.9c14.5,0,24.2,10,24.8,25.6h-48.5V139z M177.8,137.2
c-1.1-13.8-10-22.8-23.2-22.8c-12.3,0-22.9,9.5-23.6,22.8H177.8z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M213.5,114.3h1.6v49.5h-1.6V114.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M265.7,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6
h-1.6v-49.5h1.6v12.5C250.1,117,258.9,113,265.7,113z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M328,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
v-49.5h1.6v12.5C312.3,117,321.2,113,328,113z"
    />
  </svg>
);

export const mobileLogo = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 321.4 166.1"
    enableBackground="new 0 0 321.4 166.1;"
  >
    <g>
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M57.7,125.5h-1.6l-1.8-3.1h-8.6l-1.8,3.1h-1.6l7.1-12.5h1.4L57.7,125.5z M50,114.7l-3.4,6.1h6.7L50,114.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M66.2,125.5h-1.6v-10.8h-5v-1.6h11.6v1.6h-5V125.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M85.7,125.5h-1.6v-12.5h7.6c2,0,4.4,0.7,4.4,3.9c0,3.2-2.5,3.8-4.4,3.8h-6V125.5z M91.7,119.2
		c2,0,2.8-0.7,2.8-2.2c0-1.6-0.8-2.3-2.8-2.3h-6v4.5H91.7z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M107.9,125.9c-4.3,0-7.5-2.7-7.5-6.6c0-4,3.2-6.6,7.5-6.6c4.2,0,7.5,2.6,7.5,6.6
		C115.4,123.3,112.2,125.9,107.9,125.9z M113.8,119.3c0-2.6-2.3-5-5.9-5c-3.6,0-5.9,2.3-5.9,5c0,2.6,2.3,5,5.9,5
		C111.5,124.3,113.8,122,113.8,119.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M133.1,120.8c0,4-2.9,5.2-6,5.2c-3.1,0-6-1.2-6-5.2v-7.7h1.6v7.7c0,2.1,1.1,3.5,4.4,3.5c3.3,0,4.4-1.4,4.4-3.5
		v-7.7h1.6V120.8z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M152.2,125.5h-1.6l-8.7-10.2v10.2h-1.6v-12.5h1.6l8.7,10.2v-10.2h1.6V125.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M165.4,113.1c4,0,6.1,2.8,6.1,6.2c0,3.4-2,6.2-6.1,6.2h-7v-12.5H165.4z M165.4,123.9c2.7,0,4.4-1.9,4.4-4.6
		c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2H165.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M198.5,125.5h-2l-3.5-5.1h-5.5v5.1h-1.6v-12.5h7.7c2.3,0,4.7,0.9,4.7,3.7c0,2.3-2,3.4-3.4,3.6L198.5,125.5z
		 M193.5,118.9c1.6,0,3.1-0.4,3.1-2.1c0-1.8-1.7-2.1-3.1-2.1h-6.1v4.1H193.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M206.1,125.5h-1.6v-12.5h1.6V125.5z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M219.3,113.1c4,0,6.1,2.8,6.1,6.2c0,3.4-2,6.2-6.1,6.2h-7v-12.5H219.3z M219.3,123.9c2.7,0,4.5-1.9,4.5-4.6
		c0-2.7-1.7-4.6-4.4-4.6H214v9.2H219.3z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M243.8,123.6c-0.4,0.3-2.6,2.3-6.4,2.3c-4.5,0-7.4-3-7.4-6.6c0-3.9,3.4-6.6,7.6-6.6c3.2,0,5,1.4,5.8,2
		l-0.9,1.4c-1-0.7-2.4-1.7-4.9-1.7c-3.4,0-6,2-6,5c0,2.7,2.1,5,5.9,5c2.4,0,3.9-0.8,4.7-1.4v-2.5h-4.1v-1.6h5.7V123.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M259.7,125.5h-11.2v-12.5h10.9v1.6h-9.3v3.6h5.3v1.6h-5.3v3.9h9.6V125.5z"
      />
    </g>
    <g>
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M66,156.9l0.1,2.8H65v-19.5h1.1v11.1c0.8-1.7,2.4-2.9,4.7-2.9c3.2,0,5.5,2.5,5.5,5.7c0,3.2-2.3,5.8-5.5,5.8
		C68.6,159.9,66.8,158.6,66,156.9z M75.1,154.1c0-2.7-1.8-4.8-4.5-4.8c-2.8,0-4.6,2.1-4.6,4.8c0,2.7,1.8,4.8,4.6,4.8
		C73.3,159,75.1,156.9,75.1,154.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M83.1,158.9l-4.9-10.2h1.1l3.9,8.2c0.2,0.5,0.4,1.2,0.4,1.3c0-0.1,0.2-0.8,0.4-1.3l3.5-8.2h1.1l-7.2,16.8h-1.1
		L83.1,158.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M94.8,164.7l0.6-0.7c0.4,0.4,0.8,0.7,1.5,0.7c0.9,0,1.5-0.5,1.5-2.1v-14.1h1.1v14.1c0,2-1.1,3-2.5,3
		C95.9,165.7,95.2,165.2,94.8,164.7z M98.1,143.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9
		C98.5,144.8,98.1,144.4,98.1,143.9z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M108.9,159c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
		c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C104.3,157.1,106.1,159,108.9,159z M113.1,153.5c-0.2-2.3-1.6-4.2-4.3-4.2
		c-2.5,0-4.2,1.8-4.4,4.2H113.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M124.8,152.8c0-2.5-1.1-3.4-3-3.4c-1.7,0-2.8,0.7-3.6,1.9l-0.8-0.5c0.9-1.4,2.5-2.3,4.4-2.3c2.3,0,4,1.4,4,4.2
		v7h-1l0-1.7c-0.7,1-1.9,1.9-3.8,1.9c-2.5,0-4.2-1.2-4.2-3.4c0-2.2,1.9-3.2,4-3.2h3.9V152.8z M121,154.2c-1.7,0-3.1,0.7-3.1,2.3
		c0,1.7,1.1,2.5,3.2,2.5c1.8,0,3.7-1.1,3.7-2.8v-2H121z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M130.3,148.6h1.1l0,2.5c0.7-1.7,2.3-2.7,4.2-2.7c2.5,0,4.1,1.6,4.1,4.4v6.9h-1.1V153c0-2.3-1.1-3.6-3.2-3.6
		c-2.4,0-4.1,1.6-4.1,4.1v6.2h-1.1V148.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M143.2,153.1h4.7v1h-4.7V153.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M151.1,162.5l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7V157c-0.8,1.6-2.6,2.9-4.7,2.9
		c-3.2,0-5.5-2.5-5.5-5.8c0-3.2,2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l0-2.7h1v11.6c0,3.8-2.1,5.5-5.4,5.5
		C153.9,165.7,152.1,164.4,151.1,162.5z M161,154.1c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8c0,2.7,1.8,4.8,4.5,4.8
		C159.1,159,161,156.9,161,154.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M171.4,159c1.8,0,3-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7c0-3.2,2.4-5.8,5.6-5.8
		c3.4,0,5.5,2.6,5.4,6h-9.9C166.8,157.1,168.6,159,171.4,159z M175.6,153.5c-0.2-2.3-1.6-4.2-4.3-4.2c-2.5,0-4.2,1.8-4.4,4.2H175.6z
		"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M179.4,154.1c0-3.2,2.5-5.7,5.8-5.7c3.2,0,5.7,2.6,5.7,5.7c0,3.2-2.5,5.8-5.7,5.8
		C181.9,159.9,179.4,157.3,179.4,154.1z M189.8,154.1c0-2.7-1.9-4.8-4.6-4.8s-4.7,2.1-4.7,4.8c0,2.7,1.9,4.8,4.7,4.8
		S189.8,156.9,189.8,154.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M194.5,148.6h1.1l0,2.2c0.6-1.6,1.8-2.4,3.2-2.4c0.9,0,1.5,0.2,2,0.6l-0.5,0.9c-0.3-0.2-0.8-0.5-1.6-0.5
		c-1.6,0-3.1,1.3-3.1,4v6.3h-1.1V148.6z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M203,162.5l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7V157c-0.8,1.6-2.6,2.9-4.7,2.9
		c-3.2,0-5.5-2.5-5.5-5.8c0-3.2,2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l-0.1-2.7h1.1v11.6c0,3.8-2.1,5.5-5.4,5.5
		C205.8,165.7,204,164.4,203,162.5z M212.9,154.1c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8c0,2.7,1.8,4.8,4.5,4.8
		C211,159,212.9,156.9,212.9,154.1z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M223.3,159c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
		c0-3.2,2.4-5.8,5.5-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C218.7,157.1,220.5,159,223.3,159z M227.4,153.5c-0.2-2.3-1.6-4.2-4.3-4.2
		c-2.5,0-4.2,1.8-4.4,4.2H227.4z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth=".6"
        strokeMiterlimit="6"
        d="M231.1,157.1l0.9-0.4c0.6,1.3,1.7,2.2,3.5,2.2c1.7,0,2.8-0.8,2.8-2.1c0-1.5-1.1-1.9-3.1-2.5
		c-1.9-0.5-3.4-1.2-3.4-3.2c0-1.6,1.5-2.8,3.3-2.8c1.7,0,2.8,0.8,3.5,2.1l-0.8,0.5c-0.5-0.9-1.4-1.7-2.7-1.7c-1.4,0-2.3,0.7-2.3,1.8
		c0,1.3,0.7,1.7,2.8,2.3c2,0.6,3.7,1.3,3.7,3.4c0,1.8-1.4,3.1-3.8,3.1C233.2,159.9,231.7,158.6,231.1,157.1z"
      />
    </g>
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M0.5,40v-1.4h7.1V20.9h1.6v17.7h15.2V40H9.2v48.2H7.6V40H0.5z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M63.4,37.3c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6V57.7c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
	V0h1.6v51.1C47.7,41.3,56.6,37.3,63.4,37.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M103.6,63.3c0,14.2,10.3,24.5,24.8,24.5c9.2,0,15.6-3.6,19.9-9.5l1.3,1c-4.6,6.2-11.5,10-21.2,10
	c-15.5,0-26.4-11.1-26.4-25.9c0-14.9,11.4-25.9,25.3-25.9c14.5,0,24.2,10,24.8,25.6h-48.5V63.3z M150.5,61.5
	c-1.1-13.8-10-22.8-23.2-22.8c-12.3,0-22.9,9.5-23.6,22.8H150.5z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M186.3,38.6h1.6v49.5h-1.6V38.6z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M238.5,37.3c11.9,0,20.1,6.5,20.1,20.4v30.4H257V57.7c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
	V38.6h1.6v12.5C222.9,41.3,231.7,37.3,238.5,37.3z"
    />
    <path
      fill="#FFFFFF"
      stroke="#FFFFFF"
      strokeMiterlimit="10"
      d="M300.7,37.3c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6V57.7c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6
	h-1.6V38.6h1.6v12.5C285.1,41.3,293.9,37.3,300.7,37.3z"
    />
  </svg>
);

export const logoSummer = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 98.3 68.2"
    enableBackground="new 0 0 98.3 68.2;"
  >
    <g>
      <path
        fill="#FFFFFF"
        d="M24,56.9h-0.5L23,56h-2.4l-0.5,0.9h-0.5l2-3.5H22L24,56.9z M21.8,53.8l-1,1.7h1.9L21.8,53.8z"
      />
      <path
        fill="#FFFFFF"
        d="M26.4,56.9h-0.5v-3.1h-1.4v-0.5h3.3v0.5h-1.4V56.9z"
      />
      <path
        fill="#FFFFFF"
        d="M31.9,56.9h-0.5v-3.5h2.2c0.6,0,1.2,0.2,1.2,1.1c0,0.9-0.7,1.1-1.2,1.1h-1.7V56.9z M33.6,55.1
		c0.6,0,0.8-0.2,0.8-0.6c0-0.4-0.2-0.6-0.8-0.6h-1.7v1.3H33.6z"
      />
      <path
        fill="#FFFFFF"
        d="M38.2,57c-1.2,0-2.1-0.8-2.1-1.9c0-1.1,0.9-1.9,2.1-1.9c1.2,0,2.1,0.7,2.1,1.9C40.3,56.2,39.4,57,38.2,57z
		 M39.8,55.1c0-0.7-0.6-1.4-1.7-1.4c-1,0-1.7,0.7-1.7,1.4c0,0.7,0.6,1.4,1.7,1.4C39.2,56.5,39.8,55.9,39.8,55.1z"
      />
      <path
        fill="#FFFFFF"
        d="M45.3,55.5c0,1.1-0.8,1.5-1.7,1.5c-0.9,0-1.7-0.3-1.7-1.5v-2.2h0.5v2.2c0,0.6,0.3,1,1.2,1c0.9,0,1.2-0.4,1.2-1
		v-2.2h0.5V55.5z"
      />
      <path
        fill="#FFFFFF"
        d="M50.7,56.9h-0.5L47.8,54v2.9h-0.5v-3.5h0.5l2.5,2.9v-2.9h0.5V56.9z"
      />
      <path
        fill="#FFFFFF"
        d="M54.4,53.3c1.1,0,1.7,0.8,1.7,1.8c0,1-0.6,1.8-1.7,1.8h-2v-3.5H54.4z M54.4,56.4c0.8,0,1.3-0.5,1.3-1.3
		c0-0.8-0.5-1.3-1.2-1.3h-1.5v2.6H54.4z"
      />
      <path
        fill="#FFFFFF"
        d="M63.8,56.9h-0.6l-1-1.4h-1.6v1.4h-0.5v-3.5h2.2c0.6,0,1.3,0.2,1.3,1c0,0.7-0.6,1-1,1L63.8,56.9z M62.4,55
		c0.4,0,0.9-0.1,0.9-0.6c0-0.5-0.5-0.6-0.9-0.6h-1.7V55H62.4z"
      />
      <path fill="#FFFFFF" d="M65.9,56.9h-0.5v-3.5h0.5V56.9z" />
      <path
        fill="#FFFFFF"
        d="M69.7,53.3c1.1,0,1.7,0.8,1.7,1.8c0,1-0.6,1.8-1.7,1.8h-2v-3.5H69.7z M69.7,56.4c0.8,0,1.3-0.5,1.3-1.3
		c0-0.8-0.5-1.3-1.2-1.3h-1.5v2.6H69.7z"
      />
      <path
        fill="#FFFFFF"
        d="M76.6,56.3c-0.1,0.1-0.7,0.7-1.8,0.7c-1.3,0-2.1-0.8-2.1-1.9c0-1.1,1-1.9,2.2-1.9c0.9,0,1.4,0.4,1.6,0.6
		l-0.2,0.4c-0.3-0.2-0.7-0.5-1.4-0.5c-1,0-1.7,0.6-1.7,1.4c0,0.8,0.6,1.4,1.7,1.4c0.7,0,1.1-0.2,1.3-0.4v-0.7H75v-0.5h1.6V56.3z"
      />
      <path
        fill="#FFFFFF"
        d="M81.1,56.9h-3.2v-3.5H81v0.5h-2.6v1h1.5v0.5h-1.5v1.1h2.7V56.9z"
      />
    </g>
    <g>
      <path
        fill="#FFFFFF"
        d="M26.3,65.7l0,0.8h-0.3V61h0.3v3.1c0.2-0.5,0.7-0.8,1.3-0.8c0.9,0,1.5,0.7,1.5,1.6c0,0.9-0.7,1.6-1.5,1.6
		C27.1,66.6,26.6,66.2,26.3,65.7z M28.9,64.9c0-0.8-0.5-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4c0,0.8,0.5,1.4,1.3,1.4
		C28.4,66.3,28.9,65.7,28.9,64.9z"
      />
      <path
        fill="#FFFFFF"
        d="M31.2,66.3l-1.4-2.9h0.3l1.1,2.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0,0-0.2,0.1-0.4l1-2.3h0.3l-2,4.7h-0.3L31.2,66.3z
		"
      />
      <path
        fill="#FFFFFF"
        d="M34.5,67.9l0.2-0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.4-0.2,0.4-0.6v-4h0.3v4c0,0.6-0.3,0.8-0.7,0.8
		C34.8,68.2,34.6,68.1,34.5,67.9z M35.4,62c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3
		C35.5,62.3,35.4,62.2,35.4,62z"
      />
      <path
        fill="#FFFFFF"
        d="M38.5,66.3c0.5,0,0.9-0.2,1.1-0.5l0.2,0.2c-0.3,0.3-0.7,0.6-1.3,0.6c-1,0-1.6-0.7-1.6-1.6
		c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.5,0.7,1.5,1.7h-2.8C37.2,65.8,37.7,66.3,38.5,66.3z M39.6,64.8c0-0.6-0.4-1.2-1.2-1.2
		c-0.7,0-1.2,0.5-1.3,1.2H39.6z"
      />
      <path
        fill="#FFFFFF"
        d="M43,64.6c0-0.7-0.3-1-0.8-1c-0.5,0-0.8,0.2-1,0.5L40.9,64c0.3-0.4,0.7-0.6,1.2-0.6c0.6,0,1.1,0.4,1.1,1.2v2H43
		l0-0.5c-0.2,0.3-0.5,0.5-1.1,0.5c-0.7,0-1.2-0.3-1.2-1c0-0.6,0.5-0.9,1.1-0.9H43V64.6z M41.9,65c-0.5,0-0.9,0.2-0.9,0.7
		c0,0.5,0.3,0.7,0.9,0.7c0.5,0,1-0.3,1-0.8V65H41.9z"
      />
      <path
        fill="#FFFFFF"
        d="M44.5,63.4h0.3l0,0.7c0.2-0.5,0.6-0.8,1.2-0.8c0.7,0,1.2,0.5,1.2,1.2v2h-0.3v-1.9c0-0.6-0.3-1-0.9-1
		c-0.7,0-1.1,0.4-1.1,1.2v1.7h-0.3V63.4z"
      />
      <path fill="#FFFFFF" d="M48.2,64.6h1.3v0.3h-1.3V64.6z" />
      <path
        fill="#FFFFFF"
        d="M50.4,67.3l0.2-0.2c0.3,0.5,0.7,0.8,1.3,0.8c0.8,0,1.2-0.4,1.2-1.3v-0.9c-0.2,0.5-0.7,0.8-1.3,0.8
		c-0.9,0-1.5-0.7-1.5-1.6c0-0.9,0.6-1.6,1.5-1.6c0.6,0,1.1,0.4,1.3,0.8l0-0.8h0.3v3.3c0,1.1-0.6,1.6-1.5,1.6
		C51.2,68.2,50.7,67.8,50.4,67.3z M53.2,64.9c0-0.8-0.5-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4c0,0.8,0.5,1.4,1.3,1.4
		C52.7,66.3,53.2,65.7,53.2,64.9z"
      />
      <path
        fill="#FFFFFF"
        d="M56.1,66.3c0.5,0,0.9-0.2,1.1-0.5l0.2,0.2c-0.3,0.3-0.7,0.6-1.3,0.6c-1,0-1.6-0.7-1.6-1.6
		c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.5,0.7,1.5,1.7h-2.8C54.8,65.8,55.3,66.3,56.1,66.3z M57.3,64.8c0-0.6-0.4-1.2-1.2-1.2
		c-0.7,0-1.2,0.5-1.3,1.2H57.3z"
      />
      <path
        fill="#FFFFFF"
        d="M58.4,64.9c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6C59.1,66.6,58.4,65.8,58.4,64.9
		z M61.3,64.9c0-0.8-0.5-1.4-1.3-1.4s-1.3,0.6-1.3,1.4c0,0.8,0.5,1.4,1.3,1.4S61.3,65.7,61.3,64.9z"
      />
      <path
        fill="#FFFFFF"
        d="M62.7,63.4H63l0,0.6c0.2-0.5,0.5-0.7,0.9-0.7c0.2,0,0.4,0,0.6,0.2l-0.1,0.2c-0.1-0.1-0.2-0.1-0.5-0.1
		c-0.4,0-0.9,0.4-0.9,1.1v1.8h-0.3V63.4z"
      />
      <path
        fill="#FFFFFF"
        d="M65.1,67.3l0.2-0.2c0.3,0.5,0.7,0.8,1.3,0.8c0.8,0,1.2-0.4,1.2-1.3v-0.9c-0.2,0.5-0.7,0.8-1.3,0.8
		c-0.9,0-1.5-0.7-1.5-1.6c0-0.9,0.7-1.6,1.5-1.6c0.6,0,1.1,0.4,1.3,0.8l0-0.8h0.3v3.3c0,1.1-0.6,1.6-1.5,1.6
		C65.8,68.2,65.3,67.8,65.1,67.3z M67.8,64.9c0-0.8-0.5-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4c0,0.8,0.5,1.4,1.3,1.4
		C67.3,66.3,67.8,65.7,67.8,64.9z"
      />
      <path
        fill="#FFFFFF"
        d="M70.8,66.3c0.5,0,0.9-0.2,1.1-0.5l0.2,0.2c-0.3,0.3-0.7,0.6-1.3,0.6c-1,0-1.6-0.7-1.6-1.6
		c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.5,0.7,1.5,1.7h-2.8C69.5,65.8,70,66.3,70.8,66.3z M72,64.8c0-0.6-0.4-1.2-1.2-1.2
		c-0.7,0-1.2,0.5-1.3,1.2H72z"
      />
      <path
        fill="#FFFFFF"
        d="M73,65.8l0.3-0.1c0.2,0.4,0.5,0.6,1,0.6c0.5,0,0.8-0.2,0.8-0.6c0-0.4-0.3-0.5-0.9-0.7c-0.5-0.2-1-0.3-1-0.9
		c0-0.5,0.4-0.8,0.9-0.8c0.5,0,0.8,0.2,1,0.6l-0.2,0.1c-0.2-0.3-0.4-0.5-0.8-0.5c-0.4,0-0.7,0.2-0.7,0.5c0,0.4,0.2,0.5,0.8,0.7
		c0.6,0.2,1,0.4,1,1c0,0.5-0.4,0.9-1.1,0.9C73.6,66.6,73.2,66.2,73,65.8z"
      />
    </g>
    <path
      fill="none"
      stroke="#B5CB34"
      strokeWidth=".25"
      strokeMiterlimit="10"
      d="M61.4,19.7c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3,0.1,0.6-0.1,0.9c-0.3,0.5-0.8,0.9-1,1.4
	c0,0.1-0.1,0.4,0,0.4"
    />
    <g>
      <path
        fill="#B5CB34"
        d="M84.5,3.3c-0.1-0.2-0.2,0-0.1,0c0.1,0.1,0,0.2,0,0.3c-0.1,0.2-0.2,0-0.4,0c-0.2-0.1-0.4-0.1-0.6-0.2
		c-0.3-0.1-0.6-0.1-0.9-0.2c0.3,0,0.6,0,0.9,0c0.2,0,0.5,0,0.7,0.1c0,0,0.1-0.1,0.1-0.1c-0.2-0.1-0.5-0.1-0.8-0.1
		c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.6,0-0.9,0c-0.1,0-0.6,0.1-0.6,0.1c0,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1c0,0-0.1,0-0.1,0
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.7,0.2-1.1,0.3
		C78.1,4,77.8,4,77.6,4.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.5,0.2-1,0.5-1.5,0.7c-0.5,0.2-1,0.4-1.6,0.5
		c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.4,0-0.5,0.1c0,0,0.1-0.1,0.1-0.1c-0.5,0.3-1,0.3-1.6,0.3
		c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.2
		c-0.6,0.3-1.2,0.7-1.7,1.1c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.3,0.4-0.3,0.6c0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1,0,0.1
		c0,0.1-0.1,0.1-0.1,0.2C67.2,9.2,67,9.2,67,9.4c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.4-0.2,0.9-0.1,1.3
		c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.3,0.1,0.5c0.3,0,0.1,0.5,0.3,0.7c0,0,0-0.1,0.1-0.1c0,0,0.2,0.2,0.1,0.3c0,0,0,0.1,0,0.1
		c0,0,0,0,0.1,0c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.3,0.3,0.5,0.3,0.8c0.2-0.1,0.5,0.9,0.5,1.1c0.1,0.5,0.1,1,0,1.4c0,0.5-0.1,1-0.1,1.5
		c0,0.5-0.1,1-0.1,1.5c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1c0,0.2,0,0.5,0.1,0.7c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
		c0,0.2,0.1,0.4,0.2,0.6c0,0,0,0.1,0,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.4,0.8,1.1,1.5,1.9,1.9c0.4,0.2,0.4-0.5,0.6-0.4
		c0,0,0.1-0.1,0-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.2,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0,0-0.1,0-0.1,0c0-0.1,0.1,0,0.1-0.1
		c0,0,0,0,0-0.1c-0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.4,0.2-0.4c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0.1-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.2-0.1
		c-0.1,0-0.2,0-0.2-0.2c0-0.1,0-0.4,0.1-0.5c0.1,0-0.1-0.3,0-0.2c0-0.1,0.1-0.1,0-0.1c0-0.1,0-0.5,0-0.5c0,0,0.1,0,0.2,0
		c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0-0.1,0c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3,0-0.4c-0.2,0-0.1-0.2,0-0.2
		c0,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.5,0-0.5c0,0,0,0-0.1-0.1c0.1-0.1-0.1-0.1,0-0.1
		c0-0.2-0.2-0.4-0.1-0.5c0-0.1-0.1-0.1-0.1-0.1c0-0.2-0.2-0.4,0-0.5c-0.1,0-0.2-0.1-0.1-0.2c-0.1-0.2-0.2-0.6-0.3-0.8
		c0.1,0,0-0.1,0-0.1c-0.2-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c-0.2,0,0.1-0.2,0.1-0.3c-0.1,0-0.1-0.1-0.2,0c0,0-0.1-0.1-0.1-0.2
		c-0.1-0.1,0-0.1,0-0.1c0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0,0.2,0c-0.1-0.1-0.1-0.1-0.2-0.1
		c-0.1,0-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.2,0c0-0.1-0.1-0.1-0.1-0.2C71,15,71,14.8,70.9,14.6
		c0-0.1-0.2-0.4-0.1-0.5c-0.2-0.1-0.1-0.4-0.1-0.6c0-0.3,0-0.6,0-0.9c0,0,0.1,0,0.1-0.1c-0.1-0.1-0.2-0.2,0-0.3
		c-0.2-0.2,0.1-0.9,0.1-1.2c0-0.4-0.1-0.8-0.4-1c0.1,0,0.1,0,0.2,0c-0.1,0.1,0.2,0.1,0.2,0.1c0.3,0,0.6-0.1,0.8-0.2
		c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.1,0.5-0.4,0.7-0.4c0-0.1,0.5-0.3,0.5-0.1c0.1,0,0.1,0,0.2,0c0,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0,0.3,0
		c0.1,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.1,0.2,0.2
		c0-0.1,0-0.1,0-0.2c0.2,0,0.2,0,0.3,0.1c0,0-0.1,0,0,0.1c0,0.1,0.1,0.1,0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0.2,0,0.4,0.1,0.6,0.2
		c0,0,0,0,0-0.1c0.2,0,0.3,0,0.4,0.1c0,0,0,0,0-0.1c0.4,0,0.9,0,1.3,0c0.1,0,0.1,0,0.2,0C78.1,9,78.3,9,78.4,9c0.2,0,0.4-0.1,0.5,0
		c-0.1-0.2,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0,0.1,0.2,0.1,0.1,0c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.2,0,0.3,0.1
		c0,0,0-0.1,0-0.1c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.2-0.1,0.2,0c0.1,0,0.1,0.1,0,0.1c0.1,0,0.2,0.1,0.2,0.2
		c0-0.2-0.1-0.2-0.2-0.4c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1-0.1,0,0.1,0.1c0.1,0,0.1-0.1,0-0.1c0,0,0.3-0.1,0.3-0.1c0,0,0.1,0.1,0.1,0.1
		c0-0.2,0.1-0.2,0.3-0.3c0,0.1,0.4-0.2,0.5-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0.1-0.1,0.2-0.3,0.4-0.3c0-0.1,0-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1,0,0.2-0.1c0,0,0,0-0.1,0
		c0.1-0.2,0.1-0.4,0.3-0.6c-0.1-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.2,0.1-0.1c0,0,0.1,0,0.1,0
		c0,0-0.1-0.3,0-0.2c0.1,0,0.2,0.1,0.2,0c0.1-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.4,0.2-0.4
		c0,0-0.1,0-0.1,0c0-0.1,0.1,0,0.1-0.1c0-0.1-0.1,0,0-0.2C84.5,3.5,84.5,3.4,84.5,3.3z M70.6,10.5C70.6,10.5,70.6,10.5,70.6,10.5
		L70.6,10.5z M70.6,12.1c-0.2-0.3,0-0.6,0-0.9c0-0.2,0.1-0.5,0-0.7c0.2,0.3,0.1,0.7,0,1C70.6,11.8,70.6,11.9,70.6,12.1z M70.6,12.4
		c-0.1-0.1-0.2-0.2-0.2-0.3C70.5,12.2,70.6,12.3,70.6,12.4C70.6,12.3,70.6,12.4,70.6,12.4z M70.6,12.7c-0.1-0.1-0.3-0.3-0.2-0.4
		c0.1,0.1,0.1,0.2,0.2,0.2C70.6,12.6,70.6,12.7,70.6,12.7z M70.6,13.1c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.2-0.2-0.2-0.1-0.3
		c0.1,0.1,0.3,0.3,0.3,0.4C70.6,13,70.6,13,70.6,13.1z M70.6,13.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.1-0.2-0.1-0.3
		c0.1,0.2,0.2,0.4,0.4,0.5C70.6,13.3,70.6,13.3,70.6,13.4z M70.6,13.7c-0.2-0.1-0.7-0.4-0.5-0.7c0.1,0.1,0.2,0.3,0.3,0.4
		C70.5,13.5,70.6,13.5,70.6,13.7z M70.6,14c-0.2-0.1-0.8-0.6-0.7-0.8c0.1,0.3,0.3,0.5,0.6,0.6c0,0,0,0,0,0
		C70.6,13.8,70.6,13.9,70.6,14z M70.7,14.4c-0.3-0.1-0.5-0.3-0.7-0.6c-0.1-0.1-0.3-0.4-0.2-0.5c0.2,0.3,0.5,0.6,0.8,0.8
		C70.7,14.2,70.7,14.3,70.7,14.4z M70.9,14.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.5-0.7-0.5-0.8
		c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.5,0.3,0.6,0.5C70.8,14.7,70.9,14.8,70.9,14.9z M70.6,15.1
		c-0.2-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.5-0.7-0.4-1c0.1,0.3,0.3,0.6,0.6,0.8c0.1,0.1,0.3,0.4,0.5,0.4c0,0.1,0.2,0.2,0.3,0.2
		c0.1,0.1,0.1,0.2,0.1,0.3C70.9,15.2,70.7,15.1,70.6,15.1z M69.6,14.6c-0.1-0.1-0.4-0.6-0.2-0.8c0,0.4,0.5,1,0.8,1.2
		c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.3c-0.1-0.1-0.3-0.1-0.4-0.1C70.3,15.3,69.9,14.9,69.6,14.6z M69.9,15.2
		c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.1-0.3-0.5-0.1-0.6c0.1,0.5,0.6,0.9,0.9,1.2c0.1,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.5,0.3
		C70.8,15.7,70.3,15.5,69.9,15.2z M69.8,15.5c-0.3-0.2-0.9-0.8-0.7-1.2c0.1,0.5,0.5,0.9,0.9,1.2c0.2,0.1,0.5,0.3,0.8,0.4
		c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0.1,0.2,0.3C70.8,16.1,70.3,15.9,69.8,15.5z M70,15.9c-0.3-0.2-0.6-0.4-0.8-0.8
		c-0.1-0.2-0.3-0.4-0.2-0.7c0.1,0.6,0.6,1,1.1,1.3c0.3,0.1,0.6,0.3,0.9,0.4c0.2,0.1,0.6,0.1,0.6,0.3C71,16.5,70.4,16.2,70,15.9z
		 M70.1,16.4c-0.4-0.2-1.4-0.9-1.3-1.4c0.2,0.3,0.4,0.7,0.8,0.9c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.3,0,0.4,0.1C71.1,16.7,70.5,16.6,70.1,16.4z M71.3,16.8
		c0.2,0,0.3-0.1,0.3,0.1C71.5,16.9,71.5,16.9,71.3,16.8z M70.9,16.8c0.3,0.1,0.5,0.3,0.8,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0
		C71.4,17.1,71.2,17,70.9,16.8z M70.7,16.7c0.1,0,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0,0.4,0.1
		c0.2,0,0.1,0.1,0.2,0.2C71.7,17.8,70.8,16.8,70.7,16.7z M71.5,17.8c-0.2,0-0.3-0.2-0.4-0.3c-0.2-0.3-0.5-0.7-0.8-0.9
		c0.2,0,0.2,0.2,0.4,0.3c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0,0.2-0.1,0.3,0.1c0,0,0,0-0.1,0
		C71.9,17.8,71.6,17.8,71.5,17.8z M70.9,17.6c-0.4-0.4-0.7-0.8-1.1-1.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.2,0.2
		c0.2,0.1,0.4,0.4,0.5,0.6c0.2,0.2,0.4,0.5,0.7,0.6c0.1,0,0.4,0.1,0.5,0c0,0.1,0.1,0.2,0.1,0.4C71.6,18.3,71.1,17.9,70.9,17.6z
		 M72.1,18.5c-0.3,0-0.7-0.2-1-0.4c-0.3-0.2-0.6-0.6-0.9-0.8c-0.3-0.3-0.6-0.7-0.8-1.1c-0.2-0.3-0.5-0.5-0.5-0.8
		c0.2,0.3,0.5,0.5,0.7,0.8c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.3,0.6,0.7,0.9,0.9c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1,0.1,0.5,0,0.5,0.1C72.1,18.4,72.1,18.5,72.1,18.5z M69,15.9C68.9,15.8,68.9,15.9,69,15.9C68.9,15.8,69,15.8,69,15.9z
		 M71.8,18.9c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.1-0.6-0.4-0.8-0.7c-0.6-0.6-1.1-1.3-1.5-2c0.5,0.7,1,1.4,1.6,1.9c0.3,0.2,1.2,1,1.5,0.7
		c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0C72,18.9,72,19,71.8,18.9z M71.9,19.1c-0.2,0-0.4,0-0.6-0.1c-0.4-0.2-0.7-0.5-1-0.8
		c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.4c0.1,0.4,0.4,0.8,0.7,1.1
		c0.3,0.4,0.6,0.7,1,1.1c0.2,0.2,0.5,0.5,0.8,0.6c0.1,0.1,0.5,0.2,0.6,0.2c0.1,0,0.2-0.1,0.3-0.1C72.3,19.1,71.9,19.1,71.9,19.1z
		 M72.3,19.6c-0.1,0-0.1-0.1-0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C72.4,19.5,72.4,19.6,72.3,19.6z M71.9,19.7c0.1,0,0.1-0.1,0.2,0
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1C72.2,19.9,72,19.7,71.9,19.7z M70.8,19.1
		c-0.3-0.2-0.6-0.5-0.8-0.8c-0.4-0.5-0.8-1-0.9-1.6c0.3,0.8,0.9,1.5,1.5,2c0.2,0.2,0.6,0.4,0.8,0.5c0.3,0.1,0.7,0,0.9-0.1
		c0,0.1,0,0.1-0.1,0.1c0.1,0.2-0.4,0.2-0.3,0.3c-0.1,0.1-0.3,0-0.4,0C71.3,19.5,71,19.3,70.8,19.1z M71.6,19.6
		c0.2,0,0.3,0.1,0.5,0.3c0.1,0.1,0.2,0.2,0.4,0.1c0,0,0,0.1,0,0.1C72.2,20.3,71.7,19.9,71.6,19.6z M71.8,20.1
		c-0.2-0.2-0.4-0.4-0.5-0.6c0.2,0.1,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.4,0.7,0.2c0,0.2,0,0.3,0.1,0.5c0,0-0.1,0-0.1,0
		C72.1,20.7,71.9,20.3,71.8,20.1z M72.4,21c-0.3,0-0.7-0.4-0.9-0.6c-0.2-0.4-0.5-0.7-0.8-1.1c0.3,0.2,0.5,0.5,0.7,0.8
		c0.3,0.3,0.5,0.8,1,0.8C72.5,21,72.6,21,72.4,21z M71.8,21.1c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.5-0.5-1-0.8-1.5c0,0,0.2,0.2,0.2,0.2
		c0,0,0.1,0.2,0.1,0.2c0.2,0.3,0.4,0.5,0.5,0.7c0.2,0.4,0.7,1.1,1.2,0.9c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0-0.2,0
		C72.2,21.2,72,21.2,71.8,21.1z M72.1,21.5c-0.6-0.3-1-0.8-1.4-1.3c-0.2-0.3-0.4-0.7-0.5-1.1c-0.1-0.3-0.3-0.6-0.5-0.8
		c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0,0.2,0.4,0.3,0.5c0.3,0.5,0.5,0.9,0.8,1.4c0.1,0.2,0.3,0.4,0.5,0.5c0.1,0.1,0.2,0.1,0.3,0.2
		c0,0,0.2,0,0.2,0c0.1,0,0.3,0,0.3,0.1C72.5,21.7,72.2,21.6,72.1,21.5z M71.6,21.5c-0.4-0.3-0.9-0.8-1.1-1.3c-0.3-0.5-0.5-1-0.8-1.5
		c-0.1-0.2-0.3-0.4-0.4-0.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0.2,0.4,0.4,0.8,0.6,1.2c0.3,0.5,0.5,1,0.7,1.4
		c0.1,0.2,0.3,0.4,0.5,0.7c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.3,0.7,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0.1
		C72.1,21.9,71.8,21.7,71.6,21.5z M72.1,22.1c-0.4-0.2-0.8-0.4-1.1-0.8c-0.3-0.4-0.5-0.8-0.8-1.2c-0.3-0.4-0.4-0.8-0.7-1.2
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.9,0.6,1.3
		c0.3,0.4,0.6,0.8,1,1.1c0,0.1,0.4,0.3,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0-0.1,0c0,0,0,0,0,0
		C72.3,22.1,72.2,22.1,72.1,22.1z M72.3,22.5c-0.8-0.4-1.4-0.9-1.9-1.6c-0.3-0.4-0.5-0.9-0.7-1.3c-0.2-0.4-0.4-0.7-0.5-1.1
		c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.4,0.3,0.7c0.2,0.4,0.5,0.9,0.8,1.3c0.3,0.4,0.6,0.7,0.9,1c0.2,0.1,0.5,0.3,0.7,0.3
		c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.2,0,0.2C72.4,22.5,72.4,22.5,72.3,22.5z M72,22.5c0.1,0,0.4,0.2,0.4,0.2
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.6-0.3-1.2-0.8-1.6-1.4c-0.3-0.4-0.5-0.9-0.8-1.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.6
		c0.1,0.4,0.4,0.8,0.6,1.2c0.3,0.5,0.5,1,0.8,1.4c0.2,0.3,0.6,0.8,1,0.9C71.7,22.3,71.9,22.4,72,22.5z M72.4,23
		C72.4,23,72.3,22.9,72.4,23c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0.1-0.4-0.1-0.5-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.4-0.2
		c-0.4-0.2-0.6-0.5-0.9-0.8c-0.4-0.4-0.8-0.9-1-1.5c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.1,0-0.2,0-0.3
		c0.3,0.6,0.6,1.3,1,1.9c0.2,0.3,0.5,0.6,0.7,0.9c0.1,0.1,0.5,0.5,0.6,0.5c0,0.1,0.5,0.3,0.6,0.4c0.1,0.1,0.2,0.1,0.3,0.2
		c0,0,0,0,0,0C72.4,22.8,72.4,22.8,72.4,23C72.4,22.9,72.4,22.9,72.4,23z M72.3,23.4C72.3,23.4,72.3,23.4,72.3,23.4
		c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-0.2-0.1-0.3-0.2-0.5-0.3c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2c-0.3-0.3-0.5-0.6-0.8-0.9c-0.3-0.3-0.6-0.8-0.8-1.2
		c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.3,0-0.5c0.3,0.5,0.5,1.1,0.8,1.6c0.2,0.3,0.4,0.5,0.6,0.8c0.2,0.2,0.4,0.4,0.6,0.6
		c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.2,0.1,0.3,0.2l0,0c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.3,0.1,0.3,0.2C72.3,23.2,72.3,23.3,72.3,23.4C72.3,23.3,72.3,23.4,72.3,23.4z M72.2,23.6c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.4-0.4-0.7-0.7-1.1c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1-0.1-0.2-0.2-0.2-0.4c0.3,0.4,0.4,0.8,0.7,1.2
		c0.3,0.4,0.5,0.7,0.8,1.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1C72.3,23.5,72.3,23.6,72.2,23.6
		C72.2,23.6,72.2,23.6,72.2,23.6z M72.1,24c-0.1-0.1-0.2-0.2-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0,0,0,0,0c0,0,0,0-0.1-0.1
		c-0.1-0.1-0.3-0.2-0.4-0.4c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.3-0.5-0.5c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1
		c-0.2-0.3-0.4-0.7-0.6-1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.2-0.2-0.2-0.4c0.5,0.7,0.8,1.5,1.3,2.1c0,0,0,0,0,0
		c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.3,0.4,0.4c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
		c0,0,0.1,0.1,0.2,0.1c0.3,0.1,0.5,0.3,0.5,0.3C72.1,23.9,72.1,23.9,72.1,24z M71.6,24c-0.2-0.1-0.3-0.1-0.4-0.2
		c-0.1-0.1-0.2-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.4c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
		c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.2-0.3
		c0,0,0-0.1,0-0.1c0.4,0.5,0.6,1.1,1,1.7c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2
		c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.4,0.4c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.6,0.3c0.1,0,0.3,0.2,0.4,0.2
		c0,0,0,0.2-0.1,0.1C71.8,24.2,71.7,24.1,71.6,24z M71.4,24.3c-0.1-0.1-0.4-0.2-0.5-0.3c-0.2-0.2-0.4-0.3-0.6-0.5
		c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6
		c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.2c-0.2-0.3-0.5-0.7-0.5-1.1c0.3,0.4,0.5,0.9,0.8,1.3c0,0.1,0.1,0.1,0.1,0.2
		c0.1,0.2,0.2,0.4,0.4,0.6c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.4c0,0,0.1,0.1,0.1,0.1
		c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.3,0.1,0.5,0.3c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1
		C71.7,24.5,71.5,24.4,71.4,24.3z M71.4,24.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.2-0.6-0.5-0.9-0.8c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
		c-0.1-0.2-0.3-0.3-0.3-0.5v0c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.2-0.4c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.3-0.3-0.5-0.4-0.5c0-0.1,0-0.2,0-0.3c0.2,0.3,0.3,0.6,0.5,0.8c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2
		c0.1,0.2,0.1,0.3,0.2,0.4c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.3,0.4,0.5c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.6,0.6,1,0.8
		C71.2,24.3,71.3,24.4,71.4,24.5c0.1,0,0.4,0.1,0.3,0.2C71.6,24.7,71.5,24.6,71.4,24.5z M71.6,24.9c-0.7-0.4-1.3-0.9-1.8-1.4
		c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0
		c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.4-0.3-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2
		c0.1,0.2,0.3,0.4,0.3,0.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1
		c0.1,0.1,0.1,0.2,0.2,0.2c0.5,0.5,1.1,0.8,1.7,1.1C71.7,24.8,71.6,24.9,71.6,24.9z M68.5,22.6c0.1,0.2,0.2,0.4,0.3,0.6
		c0,0,0.1,0.1,0.1,0.1c0.2,0.3,0.4,0.6,0.6,0.9c0.3,0.4,0.7,0.9,1.2,1.1c0,0,0,0,0,0c0,0.1,0.2,0.2,0.3,0.3c0,0-0.1,0.2-0.1,0.2
		c0-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.4-0.6-0.5-0.9c0-0.1-0.1-0.2-0.1-0.3
		c0,0,0-0.1-0.1-0.1C68.7,23,68.6,22.8,68.5,22.6z M68.6,22.3c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.4
		c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.3,0.4,0.5,0.5
		c0,0.1,0.6,0.4,0.7,0.5c0,0-0.1,0.1-0.1,0.2c-0.2-0.1-0.5-0.3-0.7-0.5c-0.3-0.3-0.5-0.6-0.8-0.9c-0.2-0.2-0.3-0.4-0.5-0.7
		c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.5c0,0,0-0.1,0-0.1C68.6,22.4,68.6,22.4,68.6,22.3z M68.6,21.8
		c0.1,0.3,0.2,0.5,0.4,0.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0.1,0.1,0.1,0.1c0.5,0.6,1.2,1.2,1.9,1.6
		c-0.1,0.1-0.2,0.2-0.3,0.1c-0.6-0.3-1.1-0.8-1.5-1.3c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1
		c-0.1-0.1-0.2-0.3-0.3-0.5c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2C68.7,22.3,68.5,22,68.6,21.8z M68.1,15.3
		c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.5,0.4-0.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.4
		c0.2-0.4,0.5-0.7,0.7-1.2c0.2-0.4,0.3-1.1,0.2-1.5c0.2,0.2,0.3,0.5,0.2,0.7c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.4-0.3,0.8-0.5,1.2
		c0,0,0,0-0.1,0c0,0.1-0.1,0.3-0.2,0.3c0,0-0.1,0.2-0.1,0.2c-0.1,0-0.1,0.2-0.2,0.3c0,0-0.2,0.1-0.1,0.1c0,0,0,0.1,0,0.1
		c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0.3,0,0.4c-0.1,0,0,0.3,0,0.3c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0.6,0.2,1.2,0.2,1.8
		c0,0.3,0,0.5-0.1,0.7c0,0.1,0,0.3-0.1,0.5c-0.1,0-0.1,0.4-0.1,0.5c-0.1,0-0.1,0.1,0,0.1c0.1,0.1-0.1,0.9-0.1,1.1
		c0,0.2,0,0.1-0.1,0.2c0,0.1,0,0,0,0.1c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c0-0.2,0-0.5-0.1-0.7c0-0.3,0-0.6,0.1-0.9c0-0.5,0.1-1.1,0.1-1.6c0-0.6,0.1-1.1,0-1.7c0-0.3-0.1-0.5-0.2-0.8
		C68.2,15.8,68,15.5,68.1,15.3z M67.6,13.8c0.1-0.3,0.3-0.4,0.4-0.7c0.2-0.4,0.6-0.7,0.8-1.1c0.4-0.8,0.6-1.6,0.1-2.4
		c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0.1,0.4,0.1,0.4,0.2c0.2,0.2,0.2,0.6,0.2,0.8c0,0.7-0.3,1.3-0.6,1.8c-0.1,0.2-0.2,0.3-0.4,0.5
		c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.2-0.3,0.5-0.5,0.8c-0.1-0.1-0.2-0.2-0.2-0.4
		C67.6,14.3,67.5,13.9,67.6,13.8z M67,13.2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.5-0.5,0.6-0.8
		c0-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.5-0.3-0.8c-0.1-0.2-0.3-0.4-0.3-0.6
		c0.4,0.2,0.7,0.8,0.9,1.3c0.1,0.4,0.1,0.9,0,1.2c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.2-0.6,0.6-0.7,1C67,13.4,66.9,13.4,67,13.2z
		 M67.4,9c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.8,0.1,1.1c-0.1,0.4-0.2,0.6-0.5,0.9c-0.2,0.2-0.6,0.5-0.8,0.7
		c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.4,0.1-0.7,0.1-1c0-0.6,0.4-1,0.8-1.4C67.4,9.1,67.4,9,67.4,9z M67.4,8.8C67.4,8.8,67.4,8.8,67.4,8.8
		L67.4,8.8z M67.8,9.3c0.1,0.2,0.3,0.4,0.4,0.7c0.1,0.4,0.3,1,0,1.4c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.2-0.3,0.4
		c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1-0.2-0.2-0.4,0-0.6c0.2-0.2,0.3-0.3,0.5-0.5c0.4-0.3,0.6-0.7,0.7-1.1
		c0.1-0.4,0-0.9-0.2-1.3c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1-0.1,0-0.2-0.2-0.2c0,0,0.1,0,0.1,0C67.7,8.8,67.7,9.2,67.8,9.3z M68.2,9.1
		c0.3,0.2,0.5,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8c0,0.4-0.2,0.9-0.3,1.3c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.5-0.5,0.7
		c-0.2,0.2-0.3,0.4-0.4,0.6c-0.2,0.3-0.2,0.6-0.2,0.9c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0-0.3c0.1-0.1,0.1-0.2,0.2-0.3
		c0.1-0.2,0.2-0.3,0.4-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.6C69,10.8,68.7,9.9,68.2,9.1z M70,10
		c0.4,0.3,0.2,1,0.1,1.5c-0.1,0.5-0.5,0.9-0.7,1.3c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.3,0.4-0.5,0.9-0.6,1.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.1-0.1,0.2-0.2
		c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.3-0.6,0.3-0.8C70.1,11.1,70.2,10.4,70,10
		C70,10,70,10,70,10z M82.6,5.8c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.3-0.2-0.7-0.5-1-0.7c-0.2-0.1-0.3-0.3-0.5-0.4
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.7-0.3-1.1-0.3c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
		c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.3,0.2
		c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.2,0.6,0.4,0.9,0.5c0.2,0,0.2,0,0.1,0.2c0,0.1-0.1,0.4-0.3,0.3C83.1,6.2,82.8,6,82.6,5.8z M83.2,6.7
		c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1-0.1-0.2-0.1c-0.4-0.3-0.9-0.6-1.3-1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.3-0.3-0.5-0.4c-0.4-0.2-0.8-0.4-1.2-0.5c0.3-0.1,0.9,0.2,1.2,0.3c0.1,0.1,0.3,0.1,0.4,0.2
		c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.9,0.7,1.3,1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.1,0.3,0.1C83.4,6.4,83.2,6.7,83.2,6.7z M83,7C83,7.1,83,7.1,83,7c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1-0.1-0.1,0-0.1,0
		c-0.3-0.1-0.5-0.3-0.8-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6
		c-0.6-0.6-1.4-1.2-2.3-1.4c0.4-0.2,1,0.1,1.4,0.3c0.4,0.2,0.8,0.5,1.2,0.9c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2
		c0.4,0.3,0.7,0.6,1.1,0.8c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2C83.2,6.8,83.1,6.9,83,7z M82.7,7.4
		c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.4-0.3C82,7,81.8,6.8,81.6,6.6c-0.3-0.4-0.7-0.7-1.1-1
		c-0.6-0.6-1.4-1.2-2.3-1.3c0.3-0.1,0.8,0.1,1.1,0.3c0.5,0.3,0.9,0.6,1.3,1c0.3,0.3,0.7,0.6,1,0.8c0.1,0.1,0.1,0.1,0.2,0.2
		c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0.3,0.6,0.4C82.8,7.3,82.7,7.3,82.7,7.4z M82,7.7
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.3-0.4-0.5-0.6c-0.3-0.4-0.7-0.7-1-1.1c-0.3-0.3-0.7-0.7-1.2-1c-0.4-0.2-0.8-0.5-1.2-0.5
		c0.3-0.2,0.8,0.1,1.1,0.2c0.3,0.2,0.6,0.3,0.8,0.5c0.9,0.8,1.7,1.6,2.6,2.5c0,0-0.1,0-0.1,0.1C82.2,7.7,82.1,7.8,82,7.7z M81.7,7.9
		c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.4-0.5-0.6-0.7c-0.4-0.4-0.7-0.8-1.1-1.2C79.4,5.5,79,5.2,78.6,5c-0.3-0.2-0.7-0.3-1-0.4
		c0.4-0.2,0.9,0.1,1.3,0.3c0.4,0.2,0.8,0.5,1.1,0.8c0.4,0.3,0.7,0.6,1.1,1c0.3,0.4,0.6,0.7,0.9,1.1c0,0,0,0,0,0
		C81.9,7.9,81.7,8,81.7,7.9z M81.4,8.1c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.4-0.4-0.6c-0.3-0.4-0.7-0.8-1-1.1
		c-0.3-0.3-0.7-0.5-1-0.8C78.4,5,78,4.8,77.5,4.7c0.2-0.1,0.8,0.2,1,0.3c0.4,0.2,0.7,0.5,1.1,0.8c0.4,0.3,0.8,0.8,1.1,1.1
		C81,7.3,81.3,7.7,81.6,8C81.6,8.1,81.6,8.1,81.4,8.1C81.5,8.1,81.5,8.1,81.4,8.1z M80.9,7.7c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.3-0.3-0.6-0.6-0.9-0.8c-0.7-0.6-1.3-1.3-2.3-1.4c0.3-0.2,0.8,0.1,1.1,0.2c0.4,0.2,0.7,0.5,1,0.7c0.4,0.4,0.8,0.8,1.2,1.2
		C80.6,7.2,80.8,7.4,80.9,7.7c0.1,0.1,0.3,0.3,0.4,0.5C81.1,8.2,81,7.8,80.9,7.7z M80.8,8.1c-0.1-0.1-0.2-0.2-0.3-0.4
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0-0.3-0.1-0.5-0.2c0.9-0.3,1.9,0.7,2.5,1.2c0.3,0.3,0.6,0.6,1,0.9c0.4,0.3,0.6,0.7,0.9,1
		C80.9,8.3,80.9,8.3,80.8,8.1z M80.2,7.9c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.3-0.7-0.6-1-0.9C78.3,6.2,78,6,77.7,5.8
		c-0.2-0.2-0.5-0.3-0.7-0.4c-0.2-0.1-0.4-0.1-0.6-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.3,0.1,0.4,0.2
		c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.2,0.7,0.5,0.9,0.7c0.7,0.6,1.4,1.2,1.9,2c0,0-0.1,0-0.1,0C80.6,8.4,80.3,8,80.2,7.9z M80,8
		c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.4-0.9-0.7-1.3-1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.3-0.6-0.3c-0.2,0-0.4-0.3-0.6-0.3
		c-0.2,0-0.3-0.1-0.5-0.1c0.4-0.2,1.1,0.2,1.4,0.4c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.3,0.8,0.7,1.2,1C79.8,7.6,80,7.8,80.1,8
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1-0.2-0.1,0,0.1C80.3,8.4,80.1,8.1,80,8z M79.9,8.5C79.9,8.5,80,8.5,79.9,8.5
		c0-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.2-0.4-0.4-0.7-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3
		C77.2,6.1,77,6,76.8,5.9c-0.4-0.2-0.8-0.3-1.3-0.3c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.4,0.7,0.6
		c0.4,0.3,0.8,0.7,1.1,1.1c-0.1,0-0.1,0-0.2,0C80,8.5,80,8.5,79.9,8.5z M75.8,5.7C75.8,5.7,75.8,5.7,75.8,5.7c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.1,0,0,0C75.6,5.7,75.7,5.7,75.8,5.7z M76.4,5.9c-0.1-0.1-0.4-0.1-0.5-0.2c0.4,0,0.7,0.2,1,0.3c0.2,0.1,0.3,0.2,0.4,0.3
		c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.1-0.1-0.2,0c0,0-0.1,0-0.1-0.1
		C76.7,6,76.5,6,76.4,5.9z M79.5,8.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-0.8-0.7-1.1-1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.4,0.3,0.6,0.5c0.4,0.3,0.9,0.7,1.1,1.2C79.8,8.5,79.6,8.6,79.5,8.5z M79.1,8.7
		c0,0-0.4-0.7-0.4-0.8c-0.2-0.3-0.3-0.6-0.5-0.8c0.5,0.4,1,0.9,1.3,1.6C79.3,8.6,79.2,8.7,79.1,8.7z M78.7,8.6
		c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.7c-0.2-0.4-0.5-0.7-0.8-1c0.5,0.3,0.7,0.7,1,1.1c0.2,0.2,0.3,0.4,0.4,0.7
		c0.1,0.1,0.1,0.2,0.1,0.4C78.8,8.7,78.8,8.7,78.7,8.6z M78.4,8.6c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.3-0.5-0.4-0.7
		c-0.3-0.5-0.6-1-1-1.4c0.2,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.3,0.5,0.4,0.7
		c0.1,0.2,0.2,0.4,0.4,0.7C78.5,8.8,78.5,8.8,78.4,8.6z M78.2,8.8c0-0.1,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.2-0.4
		c-0.2-0.3-0.3-0.5-0.5-0.8c-0.4-0.6-0.7-1.1-1.2-1.5c0.6,0.3,1.1,0.9,1.5,1.5c0.2,0.3,0.4,0.6,0.5,0.9c0.1,0.2,0.1,0.4,0.2,0.5
		C78.3,8.9,78.3,8.9,78.2,8.8z M78,8.9c0,0-0.5-1-0.6-1.1C77.2,7.6,77,7.3,76.8,7c-0.5-0.6-1.1-1.1-1.8-1.3c0.3,0,0.8,0,1.1,0.2
		c0.4,0.3,0.7,0.7,1,1.1c0.3,0.4,0.5,0.8,0.8,1.2C77.8,8.3,78.3,8.9,78,8.9z M77.6,8.6c-0.1-0.2-0.2-0.4-0.3-0.5
		c-0.2-0.3-0.5-0.7-0.8-1C76,6.5,75.4,6,74.6,5.8c0.3,0,0.8,0.1,1.1,0.3c0.3,0.2,0.7,0.6,0.9,0.9c0.3,0.3,0.5,0.7,0.7,1
		c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0,0,0.1C77.7,8.9,77.7,8.7,77.6,8.6z M77.6,8.9c0,0-0.3-0.4-0.3-0.4
		C77.2,8.3,77.1,8.2,77,8c-0.3-0.4-0.6-0.8-1-1.1c-0.6-0.6-1.2-0.8-2-1c0.4-0.1,0.8,0,1.1,0.2c0.2,0.1,0.4,0.2,0.6,0.3
		c0.2,0.1,0.3,0.3,0.4,0.4c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.2,0.3,0.4,0.4,0.6C77.5,8.7,77.6,8.9,77.6,8.9z M77.3,8.9
		c-0.2-0.4-0.5-0.8-0.8-1.1c-0.3-0.3-0.6-0.7-1-0.9c-0.3-0.2-0.5-0.4-0.8-0.5c-0.3-0.2-0.9-0.3-1.3-0.2c0.2-0.2,0.5-0.1,0.8-0.1
		c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.5,0.6,0.7,0.9
		c0.1,0.2,0.4,0.4,0.4,0.6C77.4,8.9,77.3,8.9,77.3,8.9z M76.7,8.8c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.3-0.4-0.7-0.7-1.1-1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2,0-0.4,0-0.6,0.1
		c0.2-0.3,0.8-0.2,1.1-0.1c0.4,0.1,0.9,0.2,1.2,0.5c0.4,0.3,0.7,0.6,1,1c0.3,0.3,0.6,0.7,0.8,1.1c0,0-0.1,0-0.1,0
		C76.8,8.9,76.8,8.9,76.7,8.8z M76.2,8.4c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.3-0.2-0.4-0.3
		c-0.2-0.2-0.6-0.4-0.9-0.4c-0.2,0-0.4-0.1-0.6,0c-0.2,0-0.3-0.1-0.4,0c0.3-0.3,0.9-0.1,1.2,0c0.1,0,0.3,0.1,0.4,0.1
		c0.1,0,0.3,0.1,0.4,0.2c0.2,0.2,0.4,0.3,0.6,0.5C75.6,7.6,75.8,7.8,76,8c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.1,0.3,0.1,0.3
		c0,0,0,0,0,0C76.4,8.9,76.3,8.6,76.2,8.4z M76.1,8.8c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.3-0.4-0.5c-0.3-0.3-0.6-0.6-1-0.8
		c-0.1-0.1-0.2-0.1-0.4-0.2c0,0-0.3-0.1-0.3-0.1c-0.4-0.1-0.8-0.3-1.1-0.2c0.1-0.2,0.2-0.2,0.4-0.1c0.2,0,0.4,0,0.6,0
		c0.3,0,0.8,0.1,1,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.2,0.3,0.3,0.5
		C76.2,8.9,76.2,9,76.1,8.8z M75.4,8.6c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.6-0.3-0.9-0.4
		c-0.3-0.1-0.6-0.1-0.9,0C72.6,7,72.8,7,73,7c0.2,0,0.4,0,0.5,0.1c0.1,0,0.6,0.2,0.7,0.2c0,0.1,0.2,0.2,0.3,0.2
		c0.2,0.1,0.3,0.2,0.5,0.4c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.3,0.3,0.4,0.5c-0.1,0-0.2-0.1-0.3-0.1C75.5,8.8,75.5,8.7,75.4,8.6z
		 M75.1,8.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.2-0.5-0.5-0.8-0.6c-0.4-0.2-1.1-0.4-1.5-0.1c0.2-0.5,1.1-0.2,1.5,0
		c0.3,0.1,0.5,0.4,0.8,0.6c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.4C75.2,8.8,75.2,8.7,75.1,8.5z M74.8,8.8
		c0.2,0-0.2-0.4-0.2-0.4c-0.2-0.2-0.4-0.4-0.7-0.6c-0.4-0.2-0.9-0.3-1.4-0.1c0.2-0.4,1-0.2,1.3-0.1c0.5,0.3,1,0.6,1.3,1.2
		C75,8.8,74.9,8.8,74.8,8.8z M73.8,8c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0-0.4,0-0.6,0.1c0-0.1,0.5-0.2,0.7-0.2c0.2,0,0.5,0.2,0.7,0.1
		c0.1,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.2,0.2,0.3,0.3C74.3,8.3,74,8.1,73.8,8z M74.7,8.7c0-0.1-0.1-0.1-0.1-0.2
		C74.6,8.6,74.6,8.7,74.7,8.7C74.7,8.7,74.7,8.7,74.7,8.7z M74.7,8.8C74.7,8.8,74.7,8.8,74.7,8.8C74.7,8.8,74.7,8.8,74.7,8.8z
		 M74.3,8.8c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.3-0.2-0.5-0.3c-0.3-0.1-0.6-0.3-1-0.2c0.2,0,0.5-0.1,0.8,0
		c0.2,0,0.4,0.2,0.5,0.2c0.2,0.1,0.7,0.4,0.7,0.6C74.5,8.8,74.4,8.8,74.3,8.8z M73.6,8.5c-0.1-0.1-0.2-0.1-0.4-0.2
		c-0.3-0.1-0.5-0.2-0.8-0.2c0.3-0.1,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5c-0.1,0-0.2,0-0.3,0C73.7,8.8,73.7,8.6,73.6,8.5z M72.3,8
		C72.3,8,72.3,8,72.3,8c0,0,0.1,0,0.1,0C72.4,8,72.4,8,72.3,8z M72.4,8.1C72.3,8.1,72.3,8.1,72.4,8.1C72.4,8,72.4,8.1,72.4,8.1z
		 M73.4,8.8c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.2-0.7-0.2c0.1-0.2,0.7,0.1,0.8,0.1
		c0.2,0.1,0.4,0.2,0.6,0.4C73.6,8.8,73.5,8.8,73.4,8.8z M73,8.9c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.2c0.1-0.1,0.5,0.1,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4C73.2,8.9,73.1,9,73,8.9z M72.7,8.9
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.2C72.3,8.3,72.8,8.9,73,9C72.8,9.1,72.8,9,72.7,8.9z M72.6,9.2
		C72.5,9,72.2,8.8,72,8.7c0.1-0.1,0.3,0.1,0.4,0.1c0,0.1,0.3,0.2,0.4,0.4C72.7,9.2,72.6,9.2,72.6,9.2z M72.1,9.5
		c-0.1-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.1-0.2,0.2-0.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.1,0.1-0.2,0.1
		C72.3,9.3,72,9,71.9,9c-0.1,0.2,0.3,0.3,0.4,0.5C72.2,9.5,72.2,9.5,72.1,9.5z M71.9,9.7c0-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.1,0-0.1,0.1-0.2c0,0.1,0.3,0.3,0.4,0.4C72,9.6,71.9,9.7,71.9,9.7z M71.7,9.8c0-0.1-0.2-0.2-0.2-0.2c0,0,0.1-0.1,0.1-0.1
		c0.1,0.1,0.2,0.2,0.3,0.3C71.8,9.8,71.7,9.8,71.7,9.8z M70.7,10.1c0.3,0,0.5-0.2,0.7-0.5c-0.1,0.1,0.1,0.2,0.2,0.2
		c0,0-0.1,0.1-0.1,0.1C71.3,10.1,71,10.1,70.7,10.1z M69.4,9.5c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.3
		c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.2,0.3-0.4c0.1-0.3,0.3-0.6,0.4-0.8c0.2-0.4,0.4-0.7,0.8-0.9c-0.1,0.2-0.3,0.3-0.5,0.5
		c-0.1,0.1-0.2,0.4-0.3,0.6c-0.2,0.3-0.4,0.6-0.5,0.9c-0.2,0.3-0.5,0.6-0.8,0.9c-0.2,0.2-0.3,0.3-0.5,0.5
		C69.7,9.6,69.6,9.6,69.4,9.5C69.5,9.6,69.4,9.6,69.4,9.5z M68.2,9c0.1,0,0.1,0.1,0.2,0.1C68.4,9.1,68.3,9,68.2,9z M68,8.9
		c0,0,0.1,0,0.1,0.1C68.1,9,67.9,8.9,68,8.9z M69,9.4C69,9.5,69,9.5,69,9.4C68.9,9.4,68.9,9.4,69,9.4z M68,8.9
		C67.8,8.8,67.9,8.9,68,8.9c-0.2-0.1-0.4-0.2-0.4-0.3C67.7,8.7,67.9,8.7,68,8.9z M70,6.4c-0.2,0.3-0.3,0.6-0.4,0.9
		c-0.1,0.3-0.3,0.5-0.5,0.7c-0.4,0.5-1,0.6-1.5,0.4c0,0,0,0.1,0,0.1c-0.1-0.1,0.7-1,0.7-1.1c0.4-0.3,0.8-0.7,1.3-0.9
		C69.8,6.5,69.9,6.5,70,6.4z M70.3,6.3c-0.2,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.6,0.4-0.9,0.5
		c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.2-0.1-0.3-0.2c0.3,0,0.6,0.1,0.9,0c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.5-0.6,0.6-0.8
		c0.1-0.2,0.3-0.6,0.3-0.8C70.1,6.5,70.2,6.4,70.3,6.3C70.3,6.3,70.3,6.3,70.3,6.3z M72.1,5.9c-0.4,0.3-0.7,0.5-0.9,0.9
		c-0.2,0.5-0.6,0.9-0.9,1.4c-0.1,0.3-0.3,0.4-0.5,0.6C69.7,9,69.5,9,69.3,9.1c-0.2,0.1-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.2-0.5-0.3
		c0.6-0.1,1.2-0.4,1.6-0.8c0.2-0.2,0.4-0.5,0.4-0.8c0-0.2,0.2-0.7,0.1-0.8c0.1,0,0.1-0.1,0.1-0.2c0.1,0,0.2-0.1,0.2-0.1
		C71.1,6,71.4,6,71.8,6C71.9,6,72,5.9,72.1,5.9z M72.5,5.9c-0.3,0.2-0.5,0.4-0.8,0.6c-0.2,0.2-0.2,0.3-0.4,0.5
		c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1-0.1,0.2-0.1,0.3C71,7.8,71,8,71,8c-0.2,0.2-0.3,0.4-0.5,0.5c-0.1,0-0.3,0.4-0.4,0.4
		C70,8.9,70,9,69.9,9.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1-0.1
		c0.3-0.1,0.7-0.3,1-0.5c0.3-0.3,0.6-0.7,0.8-1.1C71,7.5,71.2,7.3,71.3,7c0.1-0.2,0.1-0.4,0.3-0.5C71.9,6.2,72.2,5.9,72.5,5.9z
		 M78.7,3.8C78.7,3.9,78.7,3.9,78.7,3.8C78.7,3.9,78.7,3.9,78.7,3.8c-0.2,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.2,0.2-0.3,0.1
		c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.5,0.4-0.7,0.3c0,0.2-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.2-0.5,0.2
		c-0.4,0.1-0.8,0.3-1.1,0.4c-0.2,0.1-0.6,0.2-0.8,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1-0.5,0.3-0.8,0.4c0,0,0,0,0,0
		c-0.4,0.5-0.6,1.2-0.8,1.8c-0.1,0.3-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.5,0.5-0.8,0.5c-0.3,0-0.5-0.1-0.7-0.2
		c0,0-0.1,0-0.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.7-0.5,0.6-0.7c0.1,0,0.3-0.5,0.4-0.6
		c0.2-0.3,0.3-0.7,0.5-1c0.3-0.3,0.5-0.6,0.8-0.8c0.4,0,0.8-0.1,1.2-0.2c0.8-0.2,1.7-0.6,2.5-0.9c0.4-0.2,0.7-0.3,1.1-0.5
		C78,4.1,78.4,4,78.7,3.8z M79.6,3.6c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.2,0-0.2,0c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0
		c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.3-0.1,0.5-0.1C79.5,3.6,79.6,3.6,79.6,3.6z M80.2,3.7C80.2,3.7,80.2,3.7,80.2,3.7
		c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0C80.6,3.9,80.8,4,81,4.1c0.3,0.1,0.6,0.3,0.8,0.4c0.3,0.1,0.5,0.3,0.8,0.4
		c0.4,0.2,0.7,0.5,1.2,0.6c-0.1,0.2-0.2,0.2-0.4,0.1c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.2-0.5-0.4-0.8-0.5c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0C80,3.7,80.1,3.7,80.2,3.7z
		 M81.9,4.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.2,0.7,0.4,1.1,0.5c0,0.2-0.1,0.2-0.3,0.1
		c-0.1,0-0.2-0.1-0.3-0.1C83.1,5.1,83,5,82.8,4.9c-0.2-0.1-0.4-0.2-0.6-0.3C81.8,4.4,81.4,4.2,81,4c-0.2-0.1-0.5-0.2-0.7-0.3
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0C80.8,3.5,81.4,4.1,81.9,4.3z M81.7,3.8c0.2,0.2,0.5,0.3,0.8,0.4
		c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.1,0.4,0.3,0.6,0.3c0,0,0,0,0,0c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.3-1.1-0.8-1.8-0.9c0.1,0,0.2-0.1,0.3,0c0.1,0,0.2,0.1,0.3,0.1C81.3,3.6,81.5,3.7,81.7,3.8z
		 M84,4.7c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
		c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.2-0.6-0.4-1-0.5c0.2,0,0.5,0.1,0.7,0.3c0.2,0.1,0.3,0.2,0.5,0.3C82.3,4,82.6,4,82.8,4.2
		C83.1,4.3,83.6,4.7,84,4.7z M82.7,3.9c0.4,0.2,0.9,0.4,1.4,0.4c0,0.1,0,0.3-0.1,0.3c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.1
		C82.7,4,82,3.7,81.3,3.3c0.2,0,0.4,0.2,0.5,0.2c0.1,0.1,0.3,0.2,0.5,0.2C82.4,3.7,82.6,3.8,82.7,3.9z M81.9,3.4
		c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2C83.4,3.8,83.8,4,84.2,4c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.9-0.2-1.3-0.4
		c-0.1-0.1-0.3-0.1-0.5-0.2c-0.3-0.1-0.6-0.2-0.8-0.4C81.6,3.2,81.8,3.4,81.9,3.4z M84.3,3.8C84.2,3.8,84.2,3.9,84.3,3.8
		c-0.4,0.1-0.9-0.1-1.2-0.2c-0.4-0.1-0.9-0.2-1.3-0.4c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1
		c0.2,0,0.5,0.2,0.7,0.2C84,3.7,84.1,3.8,84.3,3.8z"
      />
    </g>
    <path
      fill="#B5CB34"
      d="M29.8,0.5c-0.3,0-0.6-0.2-0.9-0.2c-1.1,0-2.3,0.6-3.5,0.7c-2.6,0.3-4.9,0.1-7-0.6c0.1,0,0.1-0.1,0.2-0.1
	c-0.2,0-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.2c0,0-0.1-0.1-0.1-0.1c-0.4,0.2-1,0.3-1.6,0.5c-0.2,0.1-0.4,0.4-0.6,0.5
	c-1.5,1-3.7,2.3-6,2.6C8,3.8,7.2,3.3,5.8,3.4C5.7,3.5,5.9,3.8,5.6,3.8C3.8,4.1,0.4,3.2,0.4,3.2L0,5c0,0,2.4,0.2,3.5,0.3
	c0.6,0.1,1.2,0,1.8,0C6.7,5.4,7.9,6,9.7,5.4c0.5,0.4,1.8,0.3,2.1,1.1c0.4,1.2,0.5,1.5,0.5,1.4c-0.1-0.4,0-0.8,0.2-1.2
	c0.5,0.3,0.9,0.8,1.4,1.2c0.3,0,0.7,0.2,1,0.5c-0.2-0.4-0.2-0.7-0.2-1.1c-0.1,0-0.1,0-0.2,0c-0.9-0.7-1.9-1.3-2.6-2.2
	c2.2-1,3.8-2.6,6.3-3.2c1.4-0.4,2.3-0.1,3.7,0c1.1,0.1,2.2-0.1,3.1-0.1c0.3,0,0.6,0.2,0.9,0.2c0.9,0.1,1.7-0.2,2.6-0.3
	c2-0.3,3.4,0.4,5.1,0.8c0.8,0.2,1.6,0.2,2.4,0.3c1.6,0.3,3.1,0.6,4.6,1.1c0.7,0.2,1.5,0.4,2.2,0.7c0.3,0.1,0.6,0.4,0.9,0.5
	c1,0.4,2.1,0.6,3.1,1c1.8,1.2,3.8,2.1,5.7,3.1c0.5,0.6,1.2,1.1,1.8,1.7c0.6,0.6,1.5,1.4,1.7,2.2c1,3.2,3.1,5.3,5.3,6.5
	c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0,0.1-0.1,0.3-0.1c-0.1-0.9-0.9-1.2-1.4-1.5c-1.6-1-2.8-2.9-3.8-4.9c-0.2-0.4-0.3-0.9-0.5-1.3
	c-0.3-0.6-1.1-1.2-1.5-1.8c-0.4-0.6-0.6-1-1.2-1c-2.2-1.9-5.3-2.7-7.4-4.4c2-0.1,4,0,6-0.3C53,4.5,53.9,4.1,54.8,4
	c1-0.1,2-0.1,3-0.1c1,0,2.1,0,3,0.1c1.9,0.2,0.9-0.4,0.8-0.4c-0.4,0.1-0.9,0-1.3-0.3c-0.3,0.1-0.5,0.1-0.8,0L57.8,3
	c-1.6,0.3-3.4,0.2-4.9,0.4c-0.7,0.1-1.4,0.3-2.1,0.4c-2.6,0.3-4.4,0.4-6.6,0.1c-1.3-0.2-2.5-0.6-3.7-1C39.2,2.5,38,2,36.8,1.7
	c-1.8-0.4-3.7-0.6-5.5-1C30.8,0.5,30.6,0.5,29.8,0.5z"
    />
    <g>
      <path
        fill="#B5CB34"
        d="M67.7,30.3C67.7,30.2,67.6,30.2,67.7,30.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.3
		c0-0.2-0.1-0.3-0.1-0.5c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0,0.1,0c0-0.1-0.1-0.3-0.1-0.4
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c0,0-0.2-0.3-0.2-0.3c0,0-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1
		c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
		c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.5-0.4-0.7-0.7c0-0.1-0.1-0.1-0.1-0.2
		c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c0,0,0.1,0,0.1,0c-0.3-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0-0.1
		c0,0-0.1,0-0.1,0c0,0,0,0-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c-0.3-0.2-0.6-0.4-1-0.6c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0
		c-0.1-0.3,0.1-0.6,0.3-0.8c0.1-0.2,0.1-0.3-0.1,0c-0.1,0.2-0.3,0.5-0.3,0.8c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.2,0.1-0.5,0.1-0.7,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
		c0.1,0.2-0.2,0.2-0.3,0.3c0,0,0,0,0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0.2-0.2,0.3-0.3,0.5
		c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0.1-0.3,0.5-0.4,0.5c-0.2,0.2-0.5,0.3-0.7,0.4c-0.3,0.1-0.5,0.2-0.8,0.3c-0.2,0.1-0.5,0.2-0.8,0.3
		c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.2,0.2
		c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3C53.2,28,53,28.5,52.9,29c0,0.2,0.3,0,0.3,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1
		c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2,0
		c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0h0c0,0,0,0,0.1,0c0,0,0,0,0,0
		c0,0,0,0.1,0.1,0.1c0-0.1,0-0.1,0-0.1c0,0,0.2-0.1,0.2-0.1c0,0,0.1-0.1,0.1,0c0,0,0.1,0,0,0c0,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0.1,0.1
		c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1,0c0,0,0-0.1,0-0.1
		c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0.1,0,0.2-0.2,0.3-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0,0c0.1,0,0.1-0.2,0.3-0.2
		c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.2,0.2-0.1c0-0.1,0-0.1,0-0.1c0-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0.1,0c0-0.1,0.1,0,0.1-0.1c0,0,0,0,0,0
		c0-0.1,0.1,0,0.2,0c0,0,0-0.1-0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0,0,0.1,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1
		c0,0,0,0.1,0.1,0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
		c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.2c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0-0.1,0.5-0.2,0.6-0.2
		c0.2-0.1,0.4-0.3,0.4-0.5c0,0,0,0.1,0,0.1c-0.1,0,0,0.1,0,0.2c0.1,0.2,0.2,0.3,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1,0,0.3,0.1,0.4,0.2c0,0,0.3,0.2,0.2,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0.1-0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
		c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0.1-0.1
		c0,0.1,0.1,0.1,0,0.2c0,0,0-0.1,0,0c0,0,0,0.1,0,0.1c0,0,0.1-0.1,0.1-0.1c0,0.1,0.1,0.2,0,0.3c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.2
		c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.3,0.7c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.1,0.3c0.1-0.1,0.1,0,0.1,0.1
		c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0.1,0,0c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0,0.1,0,0.2c0,0,0,0,0.1-0.1c0,0.1,0,0.1,0,0.2
		c0,0,0-0.1,0.1-0.1c0,0,0.1,0.1,0,0.1c0,0,0,0.1,0,0c0,0.1,0,0.1,0,0.2c0.1,0,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0.1c0,0.1,0-0.1,0,0.1
		c0,0,0.1,0,0.1,0c0,0,0.1,0.2,0.1,0.1c0,0,0,0.1,0,0.1c0.1-0.1,0.1,0,0.2,0.1c0,0,0.2,0.1,0.2,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
		c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0,0,0.1,0c0,0,0,0,0,0
		c0.1,0,0.3,0,0.4,0c0-0.1,0.1-0.1,0.1,0c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1,0
		c0,0,0,0.1,0,0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.2,0c0,0,0,0,0-0.1c0,0,0,0,0.1,0
		c0,0,0,0,0.1-0.1C67.5,30.3,67.6,30.3,67.7,30.3z M60.6,25.1C60.6,25.1,60.6,25.1,60.6,25.1L60.6,25.1z M59.8,25.5
		c0.1-0.2,0.3-0.2,0.4-0.2c0.1,0,0.3-0.1,0.4-0.2c-0.1,0.2-0.3,0.2-0.5,0.3C59.9,25.4,59.8,25.5,59.8,25.5z M59.6,25.6
		c0-0.1,0-0.1,0.1-0.2C59.7,25.4,59.7,25.5,59.6,25.6C59.6,25.6,59.6,25.6,59.6,25.6z M59.5,25.6c0-0.1,0.1-0.2,0.2-0.2
		C59.6,25.5,59.6,25.5,59.5,25.6C59.5,25.6,59.5,25.6,59.5,25.6z M59.3,25.7c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0-0.1,0.1-0.1
		C59.4,25.5,59.4,25.6,59.3,25.7C59.3,25.7,59.3,25.7,59.3,25.7z M59.1,25.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0-0.1,0.2-0.1
		C59.3,25.5,59.2,25.6,59.1,25.8C59.2,25.8,59.1,25.8,59.1,25.8z M59,25.9c0-0.1,0-0.4,0.2-0.4c0,0.1-0.1,0.2-0.1,0.2
		C59,25.8,59.1,25.8,59,25.9z M58.8,26c0-0.1,0.1-0.6,0.2-0.5C59,25.6,58.9,25.7,58.8,26c0.1-0.1,0.1-0.1,0.1-0.1
		C58.9,25.9,58.9,25.9,58.8,26z M58.6,26.1c0-0.2,0-0.3,0.1-0.5c0,0,0.1-0.2,0.2-0.2c-0.1,0.2-0.2,0.4-0.2,0.6
		C58.7,26.1,58.7,26.1,58.6,26.1z M58.5,26.3c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.2-0.5,0.3-0.4c0,0.1-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.1-0.1,0.2C58.6,26,58.6,26.2,58.5,26.3C58.5,26.3,58.5,26.3,58.5,26.3z M58.3,26.2c0-0.1,0-0.3,0.1-0.4
		c0-0.1,0.2-0.5,0.4-0.4c-0.1,0.1-0.2,0.3-0.3,0.5c0,0.1-0.1,0.3-0.1,0.3c0,0,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1
		C58.3,26.4,58.3,26.3,58.3,26.2z M58.3,25.6c0-0.1,0.2-0.4,0.3-0.3c-0.2,0.1-0.4,0.5-0.4,0.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
		c0-0.1,0-0.2,0-0.2C58.1,26.1,58.2,25.8,58.3,25.6z M58,25.9c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0.2-0.2,0.3-0.2
		c-0.2,0.2-0.3,0.5-0.4,0.8c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0,0.3C58,26.5,58,26.2,58,25.9z M57.8,25.9c0-0.2,0.2-0.7,0.4-0.6
		c-0.3,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.3,0,0.5c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.1-0.1,0.2C57.8,26.6,57.8,26.3,57.8,25.9z M57.7,26.1
		c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.1-0.3,0.3-0.3C57.9,25.5,57.8,25.8,57.7,26.1c0.1,0.2,0.1,0.4,0.1,0.5c0,0.1,0.1,0.3,0,0.4
		C57.6,26.8,57.7,26.4,57.7,26.1z M57.5,26.3c0-0.3,0.1-0.9,0.4-1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
		c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0,0.2C57.6,26.9,57.5,26.6,57.5,26.3z M57.6,27c0,0.1,0.1,0.1,0,0.2
		C57.6,27.2,57.6,27.1,57.6,27z M57.5,26.8c0,0.2,0,0.3,0.1,0.5c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0C57.4,27.1,57.4,27,57.5,26.8z
		 M57.5,26.7c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1C57.2,27.5,57.4,26.8,57.5,26.7z
		 M57.1,27.4c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.3
		c0,0.1,0.1,0.1,0,0.2c0,0,0,0,0,0C57.2,27.6,57.1,27.5,57.1,27.4z M57.1,27c0.1-0.3,0.2-0.6,0.3-0.8c0,0.1,0,0.2,0,0.2
		c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.1,0.1-0.2,0.1
		C56.9,27.6,57,27.2,57.1,27z M56.9,27.8c-0.1-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.5,0.3-0.7
		c0.1-0.2,0.2-0.4,0.3-0.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.5-0.2,0.7
		c0,0.1,0,0.1,0,0.2C56.9,27.6,57,27.8,56.9,27.8C57,27.8,56.9,27.9,56.9,27.8z M57.5,25.6C57.5,25.6,57.5,25.6,57.5,25.6
		C57.5,25.6,57.5,25.6,57.5,25.6z M56.6,27.8c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.3-0.9,0.7-1.2
		c-0.2,0.4-0.5,0.8-0.6,1.3c0,0.2-0.2,0.8,0,0.9c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0C56.7,27.9,56.7,27.9,56.6,27.8z M56.5,27.9
		c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.2-0.2
		c0-0.1,0.1-0.1,0.2-0.2c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.5-0.3,0.8c-0.1,0.2-0.1,0.4-0.1,0.5
		C56.5,27.7,56.5,27.9,56.5,27.9c0.1,0.1,0.2,0.1,0.2,0.2C56.7,28.1,56.6,27.9,56.5,27.9z M56.4,28.2c0,0,0-0.1,0-0.1
		c0,0,0.1,0,0.1,0.1C56.5,28.2,56.5,28.3,56.4,28.2z M56.3,28C56.3,28.1,56.4,28.1,56.3,28c0.1,0.1,0.1,0.2,0.1,0.2
		c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0C56.2,28.2,56.3,28.1,56.3,28z M56.3,27.3c0-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.3-0.6,0.6-0.9
		c-0.3,0.4-0.5,0.8-0.6,1.3c0,0.2-0.1,0.4-0.1,0.5c0,0.2,0.2,0.3,0.3,0.4c-0.1,0,0,0-0.1,0c-0.1,0.1-0.2-0.1-0.2-0.1
		c-0.1,0-0.1-0.1-0.1-0.2C56.2,27.7,56.3,27.5,56.3,27.3z M56.2,27.9c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0
		C56,28.3,56.1,28,56.2,27.9z M56,28.1c0.1-0.1,0.1-0.3,0.2-0.4C56.2,27.8,56.1,27.9,56,28.1c0.1,0.1,0,0.3,0.1,0.4
		c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0C55.8,28.4,56,28.2,56,28.1z M55.7,28.6c-0.1-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.4,0.4-0.7
		c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.5-0.1,0.7C55.8,28.7,55.8,28.7,55.7,28.6z M55.5,28.4c0-0.2,0.1-0.3,0.2-0.4
		c0.1-0.3,0.4-0.5,0.5-0.8c0,0,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.4,0.6-0.2,0.9c0,0-0.1,0-0.1,0
		c0,0,0-0.1,0-0.1C55.6,28.6,55.5,28.5,55.5,28.4z M55.4,28.6c0-0.4,0.1-0.7,0.3-1c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.2-0.3,0.3-0.4
		c-0.1,0.1-0.1,0.3-0.2,0.4c0,0-0.2,0.2-0.2,0.3c-0.2,0.3-0.4,0.5-0.5,0.7c-0.1,0.1-0.1,0.2-0.1,0.4C55.5,28.4,55.5,28.5,55.4,28.6
		C55.5,28.6,55.5,28.7,55.4,28.6c0.1,0.1,0.2,0.2,0.1,0.2C55.4,28.9,55.4,28.7,55.4,28.6z M55.3,28.3c0.1-0.3,0.2-0.6,0.4-0.9
		c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.1,0.1-0.3,0.2-0.3c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.2-0.1c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.2,0.3-0.4,0.5-0.5,0.7c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.2c0,0.1,0,0-0.1,0.1
		C55.2,28.7,55.2,28.5,55.3,28.3z M55.1,28.7c0-0.3,0-0.5,0.1-0.8c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.3-0.4,0.4-0.6
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.2-0.3,0.4-0.5,0.6
		c-0.1,0.2-0.2,0.5-0.3,0.8c-0.1,0-0.1,0.3,0,0.3c0,0.1,0,0.2,0.1,0.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
		C55.1,28.9,55.1,28.8,55.1,28.7z M55,29c0-0.5,0.1-0.9,0.3-1.4c0.1-0.2,0.3-0.5,0.5-0.7c0.1-0.2,0.2-0.4,0.4-0.5
		c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c0,0.1,0,0.3,0,0.4
		c0,0,0,0.1,0,0.1C55.1,29,55,29,55,29C55,29,55,29,55,29z M54.9,28.8c0,0.1,0,0.2,0,0.3C54.9,29,54.9,28.8,54.9,28.8
		c0-0.5,0.1-0.9,0.3-1.2c0.1-0.3,0.3-0.5,0.5-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.2c-0.2,0.1-0.3,0.4-0.5,0.6
		c-0.2,0.3-0.4,0.5-0.5,0.8c-0.1,0.2-0.2,0.5-0.2,0.7C54.9,28.6,54.9,28.7,54.9,28.8z M54.7,29.1C54.7,29.1,54.8,29.1,54.7,29.1
		c0-0.1,0-0.1,0-0.1c-0.1,0,0-0.2,0-0.3c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.3-0.6,0.5-0.9
		c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c-0.2,0.3-0.5,0.6-0.7,1c-0.1,0.2-0.2,0.4-0.3,0.6
		c0,0.1-0.1,0.4-0.1,0.4c-0.1,0,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0C54.8,29.1,54.8,29.1,54.7,29.1
		C54.8,29.1,54.8,29.1,54.7,29.1z M54.5,29.2C54.5,29.2,54.5,29.1,54.5,29.2c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2l0,0c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.4,0.2-0.6
		c0.1-0.2,0.2-0.5,0.4-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.3-0.1c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.2-0.2,0.3-0.2,0.5
		c0,0.1-0.1,0.3-0.2,0.4c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2l0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
		C54.6,29.1,54.7,29.1,54.5,29.2C54.6,29.1,54.6,29.2,54.5,29.2C54.5,29.2,54.5,29.2,54.5,29.2z M54.4,29.2c0-0.1,0-0.2,0-0.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2
		c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.7
		c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0
		c0,0,0,0,0,0.1C54.4,29,54.4,29.1,54.4,29.2C54.4,29.2,54.4,29.2,54.4,29.2C54.4,29.2,54.4,29.2,54.4,29.2z M54.2,29.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0-0.1,0.1-0.2,0.1-0.4c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.2
		c-0.2,0.4-0.5,0.8-0.8,1.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2
		c0,0,0,0.1,0,0.1C54.3,29,54.3,29.2,54.2,29.2C54.2,29.2,54.2,29.2,54.2,29.2z M54,29c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2
		c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0.1-0.2,0.1-0.2c0,0,0.1,0,0.1,0c-0.1,0.3-0.4,0.6-0.6,0.9
		c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0
		c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0C54,29.1,54,29,54,29z M53.8,29c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.3,0.1-0.4
		c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0-0.1,0.1-0.1
		c0,0,0-0.1,0.1-0.1c0.1-0.2,0.2-0.5,0.4-0.5c-0.2,0.2-0.3,0.5-0.5,0.7c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0.1
		c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.2c0,0-0.1,0-0.1,0
		c0,0,0,0,0-0.1C53.8,29.1,53.8,29,53.8,29z M53.7,29c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.4,0.2-0.6c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0-0.1,0.1-0.2,0.2-0.3l0,0c0,0,0,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.2-0.3
		c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0.1
		C54,27.8,54,27.9,53.9,28c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.4-0.1,0.7C53.8,28.9,53.8,28.9,53.7,29c0,0,0.1,0.2,0,0.2
		C53.7,29.1,53.7,29.1,53.7,29z M53.6,29.2c0-0.4,0.1-0.9,0.3-1.3c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0.1-0.1,0.1-0.1,0.2
		c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1
		c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2C53.6,28.5,53.6,28.8,53.6,29.2C53.6,29.2,53.6,29.2,53.6,29.2z M54,27.1
		c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.3-0.3,0.6-0.3,0.9c0,0,0,0,0,0c0,0-0.1,0.2,0,0.2
		c0,0-0.1,0-0.1,0c0,0,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0.1-0.1,0.1-0.1
		c0,0,0,0,0-0.1C53.8,27.2,53.9,27.2,54,27.1z M54.1,27C54.1,27,54.1,27.1,54.1,27C54,27.1,54,27.2,54,27.2c0,0.1-0.1,0.2-0.1,0.2
		c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.1,0.4-0.1,0.5
		c0,0-0.1,0-0.1,0c0-0.1,0-0.4,0.1-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1
		c0.1-0.1,0.1-0.2,0.2-0.2C54,27.1,54,27.1,54.1,27C54.1,27.1,54.1,27,54.1,27z M54.4,26.9c-0.1,0.1-0.2,0.3-0.3,0.4
		c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1,0,0.1c-0.2,0.4-0.3,0.9-0.3,1.4c-0.1,0-0.1,0-0.1-0.1c0-0.4,0.1-0.7,0.3-1.1
		c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1
		C54.1,27.1,54.2,26.9,54.4,26.9z M57.5,25c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.5,0.1,0.8,0c0.2,0,0.6-0.1,0.8-0.3c0,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.3,0.1-0.5,0.1
		c-0.2,0-0.5,0.1-0.7,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.2,0c-0.1-0.1-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.2,0.1-0.2,0.1c0-0.1-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.3,0.2-0.5,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.2,0-0.3,0.1c0-0.1-0.1,0-0.1,0
		c0,0.1-0.5,0.2-0.6,0.2c-0.1,0,0,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0.1,0
		c0,0,0,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.6-0.2,0.8-0.4c0.3-0.1,0.6-0.2,0.9-0.4
		c0.1-0.1,0.2-0.2,0.3-0.3C57.3,25.2,57.4,25,57.5,25z M58.2,24.4c0.2,0,0.3,0,0.4,0c0.3,0,0.5,0.1,0.8,0.1c0.5,0,1-0.1,1.3-0.5
		c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.2-0.4,0.3C59.9,25,59.6,25,59.2,25c-0.1,0-0.2,0-0.3-0.1
		c-0.1,0-0.2,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3,0-0.5,0c0-0.1,0.1-0.1,0.1-0.2
		C57.9,24.5,58.1,24.4,58.2,24.4z M58.3,24c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.3,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0,0.3-0.2,0.6-0.4,0.7
		c-0.2,0.1-0.4,0.3-0.6,0.3c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.5-0.1-0.7-0.1C58.2,24,58.2,24,58.3,24z M60.5,23.1
		c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.4-0.2-0.6-0.2c0.2-0.2,0.3-0.3,0.5-0.4
		c0.2-0.1,0.4-0.1,0.5-0.2C59.8,22.9,60.2,23,60.5,23.1C60.5,23.1,60.5,23.1,60.5,23.1z M60.6,23C60.6,23.1,60.6,23.1,60.6,23
		L60.6,23z M60.5,23.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.4-0.7,0.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4,0c0.1-0.1,0.1-0.2,0.3-0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0.1,0.5,0.1,0.8,0.1
		c0.2-0.1,0.5-0.3,0.6-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0,0.1C60.7,23.2,60.5,23.3,60.5,23.4z M60.7,23.5
		c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3-0.1-0.5-0.1
		c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.3,0.1-0.5,0.1c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4,0C60,24.4,60.4,24,60.7,23.5z M60.7,24.7c-0.1,0.3-0.5,0.4-0.7,0.4
		c-0.3,0.1-0.6,0-0.9,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.3,0-0.6,0-0.8,0c0-0.1,0.1-0.1,0.1-0.2
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.3,0,0.4,0
		c0.2,0,0.3,0,0.5,0C60.2,25,60.5,24.9,60.7,24.7C60.7,24.7,60.7,24.7,60.7,24.7z M65.9,30C65.9,29.9,65.9,29.9,65.9,30
		c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.2,0.1-0.4c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4-0.1-0.6c0.1,0.1,0.1,0.2,0.1,0.3
		c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0.2,0,0.3
		c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0C65.9,30.3,65.9,30.1,65.9,30z
		 M65.6,30.5c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.3,0.1-0.6,0.2-0.9c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
		c0-0.1,0.1-0.2,0.1-0.4c0-0.3,0-0.5-0.1-0.8c0.2,0.1,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2
		c-0.1,0.3-0.1,0.6-0.2,0.9c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2C65.8,30.5,65.6,30.5,65.6,30.5z
		 M65.4,30.5C65.4,30.5,65.4,30.5,65.4,30.5C65.3,30.5,65.3,30.5,65.4,30.5C65.4,30.4,65.4,30.4,65.4,30.5c-0.1-0.3,0-0.4,0-0.6
		c0,0,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.2,0.1-0.4,0.1-0.5c0.1-0.5,0.3-1,0.1-1.5c0.2,0.2,0.2,0.5,0.2,0.8
		c0,0.3-0.1,0.6-0.1,0.8c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.1,0.5-0.1,0.8c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0,0.1,0,0.1,0,0.2C65.6,30.5,65.5,30.5,65.4,30.5z M65.2,30.4C65.1,30.4,65.1,30.4,65.2,30.4c-0.1,0-0.1,0-0.1,0
		c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.5,0.3-1,0.1-1.5c0.1,0.1,0.1,0.4,0.1,0.6
		c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.1,0.5-0.2,0.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4
		C65.2,30.4,65.2,30.4,65.2,30.4z M64.8,30.2c0,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.5,0.3-0.8
		c0.1-0.3,0.2-0.6,0.2-0.8c0-0.2,0-0.5-0.1-0.7c0.2,0.1,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.5c-0.2,0.7-0.4,1.3-0.6,1.9c0,0,0,0-0.1,0
		C64.9,30.3,64.8,30.2,64.8,30.2z M64.6,30c0-0.1,0-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.3,0.2-0.6,0.3-0.9
		c0.1-0.3,0.1-0.5,0.2-0.8c0-0.2,0-0.4-0.1-0.6c0.2,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.2,0.8
		c-0.1,0.2-0.2,0.5-0.3,0.7c0,0,0,0,0,0C64.7,30.1,64.6,30.1,64.6,30z M64.5,30c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.4
		c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.1-0.5,0.1-0.7c0.1-0.3,0.1-0.5,0-0.7c0.1,0.1,0.1,0.4,0.1,0.6c0,0.2-0.1,0.5-0.1,0.7
		c-0.1,0.3-0.2,0.6-0.3,0.9C64.8,29.5,64.6,29.8,64.5,30C64.5,30,64.5,30,64.5,30C64.5,30,64.5,30,64.5,30z M64.6,29.6
		c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.5,0.3-1,0.1-1.5c0.2,0.1,0.2,0.4,0.2,0.6c0,0.2-0.1,0.5-0.1,0.7
		c-0.1,0.3-0.2,0.6-0.3,0.9C64.7,29.3,64.7,29.4,64.6,29.6c0,0.1-0.1,0.2-0.1,0.3C64.4,29.8,64.5,29.7,64.6,29.6z M64.3,29.6
		c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0.1-0.4
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0.4,0.4,0.1,1.1,0,1.6c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.2,0.5-0.3,0.7
		C64.3,29.7,64.3,29.7,64.3,29.6z M64.3,29.3c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.5,0.2-0.7c0-0.2,0-0.4,0.1-0.6
		c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2-0.1-0.4c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0,0.2c0,0,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
		c0,0.2-0.1,0.4-0.1,0.7c-0.2,0.5-0.2,1-0.5,1.5c0,0,0,0,0,0C64.1,29.6,64.3,29.4,64.3,29.3z M64.2,29.2c0.1-0.1,0.1-0.3,0.1-0.4
		c0.1-0.3,0.1-0.6,0.2-0.9c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0.2,0.2,0.2,0.6,0.1,0.8
		c0,0.2-0.1,0.4-0.1,0.6c0,0.3-0.1,0.6-0.2,0.8C64.3,29,64.3,29.1,64.2,29.2c0,0.2,0,0.2,0,0.3c0,0,0-0.1-0.1,0
		C64,29.5,64.2,29.2,64.2,29.2z M63.9,29.3C63.9,29.3,63.9,29.3,63.9,29.3c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0.1-0.3,0.1-0.4
		c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.4c0-0.3-0.1-0.5-0.2-0.7c0.1,0.1,0.1,0.1,0.2,0.2
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.3-0.1,0.4
		c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.3-0.2,0.6-0.3,0.8C64,29.4,64,29.4,63.9,29.3C63.9,29.3,63.9,29.3,63.9,29.3z M64.3,26.5
		C64.3,26.5,64.3,26.5,64.3,26.5c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0,0C64.2,26.4,64.3,26.5,64.3,26.5z M64.3,26.8c0-0.1,0-0.2,0-0.3
		c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1C64.3,27,64.3,26.9,64.3,26.8z M63.8,29.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0.1-0.6,0.2-0.8
		c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.9C63.8,29.2,63.8,29.2,63.8,29.1z M63.6,28.9
		c0,0,0.3-0.4,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0.4-0.2,0.8-0.5,1.1C63.7,29,63.7,29,63.6,28.9z M63.6,28.7
		c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.2,0.2-0.4,0.3-0.7c0,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.2,0.3-0.2,0.4
		c0,0.1-0.1,0.1-0.1,0.2C63.5,28.8,63.5,28.8,63.6,28.7z M63.5,28.6c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.2-0.4
		c0.2-0.3,0.4-0.6,0.4-0.9c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
		c-0.1,0.1-0.2,0.2-0.2,0.3C63.4,28.7,63.4,28.6,63.5,28.6z M63.3,28.5C63.4,28.5,63.4,28.5,63.3,28.5c0.1-0.1,0.1-0.2,0.2-0.3
		c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.6,0.5-1c0,0.4-0.2,0.8-0.4,1.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.2
		C63.3,28.6,63.3,28.6,63.3,28.5z M63.2,28.4c0,0,0.4-0.5,0.4-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.4,0.3-0.8,0.2-1.2
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.5-0.4,0.7C63.5,28.2,63.3,28.6,63.2,28.4z M63.3,28.2
		c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.4,0.3-0.8,0.2-1.2c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.5-0.2,0.7
		c-0.1,0.2-0.2,0.4-0.3,0.6C63.5,28,63.4,28.1,63.3,28.2c0,0.1,0,0.1-0.1,0.2c0,0,0-0.1-0.1,0C63.1,28.3,63.2,28.2,63.3,28.2z
		 M63.1,28.2c0,0,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.4,0.1-0.8,0-1.2c0.1,0.2,0.2,0.4,0.2,0.6
		c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.4C63.2,28.1,63.1,28.2,63.1,28.2z
		 M63,28.1c0.2-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.2-0.5,0.2-0.7c0-0.2,0.1-0.4,0-0.5c0-0.2-0.1-0.5-0.2-0.7c0.2,0.1,0.2,0.2,0.2,0.4
		c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0,0.3c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.1-0.1,0.3-0.2,0.4C63.1,28.1,63,28.1,63,28.1z M63,27.7c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0.2-0.4
		c0.1-0.2,0.2-0.5,0.2-0.8c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c0.2,0,0.3,0.3,0.3,0.5
		c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.7c0,0,0,0,0-0.1C62.9,27.8,62.9,27.8,63,27.7z M63,27.4
		c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.4,0-0.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.2
		c0.2,0.1,0.3,0.4,0.3,0.6c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4
		c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0C62.8,27.6,63,27.5,63,27.4z M62.8,27.4c0,0,0-0.1,0.1-0.1
		c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.5,0.2-0.7c0-0.1,0-0.1,0-0.2c0,0,0-0.2,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
		c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.4,0.1,0.6c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.4
		c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3C62.8,27.5,62.7,27.5,62.8,27.4z M62.7,27c0-0.1,0.1-0.1,0.1-0.2
		c0.1-0.2,0.1-0.4,0.1-0.5c0.1-0.2,0-0.4,0-0.5c0-0.2-0.1-0.4-0.2-0.5c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3
		c0,0,0,0.4,0.1,0.4c0,0,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.1,0-0.2
		C62.7,27.1,62.7,27.1,62.7,27z M62.7,26.9c0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.7-0.3-0.8
		c0.3,0,0.4,0.5,0.4,0.7c0,0.2-0.1,0.4-0.1,0.5C62.8,26.6,62.8,26.7,62.7,26.9c0.1,0,0,0.1,0,0.2C62.6,27,62.6,27,62.7,26.9z
		 M62.5,26.8c0.1,0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.5-0.3-0.7c0.2,0,0.4,0.5,0.4,0.7c0,0.3-0.1,0.7-0.3,1
		C62.5,26.9,62.5,26.8,62.5,26.8z M62.6,26.1c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0.2,0.3,0.3
		C62.6,25.7,62.6,25.9,62.6,26.1c0,0,0.1,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.2C62.6,26.4,62.6,26.2,62.6,26.1z M62.5,26.7
		C62.5,26.7,62.5,26.6,62.5,26.7C62.5,26.6,62.5,26.7,62.5,26.7C62.5,26.7,62.5,26.7,62.5,26.7z M62.5,26.7
		C62.5,26.7,62.5,26.7,62.5,26.7C62.4,26.7,62.4,26.7,62.5,26.7z M62.4,26.5C62.4,26.5,62.4,26.5,62.4,26.5c0.1-0.1,0.1-0.2,0.1-0.2
		c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.6c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.4-0.1,0.5
		C62.4,26.6,62.4,26.6,62.4,26.5z M62.3,26.1c0-0.1,0-0.2,0-0.2c0-0.2,0-0.3-0.1-0.5c0.1,0.1,0.1,0.4,0.2,0.6c0,0.2,0,0.3-0.1,0.5
		c0-0.1,0-0.1-0.1-0.2C62.2,26.2,62.3,26.2,62.3,26.1z M62.3,25.3C62.3,25.3,62.3,25.3,62.3,25.3C62.3,25.4,62.3,25.4,62.3,25.3
		C62.3,25.4,62.3,25.4,62.3,25.3z M62.2,25.4C62.2,25.4,62.2,25.3,62.2,25.4C62.3,25.4,62.2,25.4,62.2,25.4z M62.1,26.1
		C62.1,26.1,62.1,26,62.1,26.1c0.1-0.2,0.1-0.2,0.1-0.3c0-0.1,0-0.3-0.1-0.4c0.1,0,0.1,0.3,0.1,0.4c0,0.2,0,0.3-0.1,0.4
		C62.2,26.2,62.1,26.1,62.1,26.1z M62,25.9C62,25.9,62,25.9,62,25.9c0.1-0.2,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3
		c0.1,0,0.1,0.3,0.1,0.3c0,0.1,0,0.2-0.1,0.3C62,26,61.9,26,62,25.9z M61.9,25.8c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
		c0.2,0,0,0.5,0,0.6C61.8,25.8,61.9,25.9,61.9,25.8z M61.7,25.8c0.1-0.1,0.1-0.3,0.1-0.4c0.1,0,0,0.2,0.1,0.2
		C61.8,25.6,61.9,25.7,61.7,25.8C61.8,25.8,61.7,25.8,61.7,25.8z M61.4,25.6c0.1-0.1,0.1-0.2,0.1-0.3c0.1,0,0.2,0,0.2,0
		c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0-0.1-0.1c0.1,0,0.1-0.3,0.1-0.3C61.5,25.3,61.6,25.6,61.4,25.6
		C61.5,25.7,61.5,25.6,61.4,25.6z M61.3,25.5c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0.1,0,0.1,0C61.5,25.3,61.4,25.5,61.3,25.5
		C61.4,25.6,61.3,25.6,61.3,25.5z M61.2,25.5c0,0,0-0.1,0-0.2c0,0,0.1,0,0.1,0C61.3,25.4,61.3,25.5,61.2,25.5
		C61.2,25.5,61.2,25.5,61.2,25.5z M60.8,25c0.1,0.1,0.3,0.2,0.4,0.2c-0.1,0-0.1,0.1-0.1,0.2c0,0-0.1,0-0.1,0
		C60.9,25.3,60.9,25.2,60.8,25z M60.8,24.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.2-0.1,0.4,0,0.5,0c0.2,0,0.5,0,0.7,0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0
		c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.1-0.4-0.1C60.8,24.4,60.8,24.4,60.8,24.3C60.8,24.3,60.8,24.3,60.8,24.3z M60.7,23.5
		c0,0,0,0.1,0,0.1C60.7,23.6,60.7,23.6,60.7,23.5z M60.7,23.4C60.7,23.4,60.7,23.4,60.7,23.4C60.7,23.5,60.7,23.3,60.7,23.4z
		 M60.7,24C60.7,24,60.7,24,60.7,24C60.7,24,60.7,24,60.7,24z M60.7,23.4C60.7,23.3,60.7,23.3,60.7,23.4c0-0.1,0-0.2,0-0.3
		C60.8,23.2,60.8,23.3,60.7,23.4z M62.5,23.8c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.3,0-0.5-0.1c-0.3-0.1-0.5-0.3-0.6-0.7c0,0,0,0,0,0
		c0-0.1,0.7,0.1,0.7,0.1c0.3,0.1,0.5,0.3,0.8,0.4C62.4,23.7,62.4,23.7,62.5,23.8z M62.6,23.9c-0.2,0-0.4,0.1-0.6,0.1
		c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.1,0-0.2c0.1,0.2,0.1,0.3,0.2,0.5
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.4,0.1,0.6,0.1c0.1,0,0.4,0,0.5-0.1C62.5,23.9,62.6,23.9,62.6,23.9
		C62.6,23.9,62.6,23.9,62.6,23.9z M63.3,24.7c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6-0.1-0.9-0.1c-0.2,0-0.3-0.1-0.4-0.1
		c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0-0.3c0.2,0.3,0.5,0.5,0.8,0.6c0.2,0,0.4,0.1,0.5,0
		c0.1,0,0.4-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.5C63.2,24.6,63.2,24.7,63.3,24.7z M63.4,24.9
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4-0.1
		c0,0-0.3-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0.1,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0C63,24.7,63.3,24.7,63.4,24.9z M66,27.5
		C65.9,27.5,65.9,27.5,66,27.5C65.9,27.5,65.9,27.5,66,27.5c-0.1,0-0.2-0.1-0.3-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2-0.1
		c0,0-0.1-0.1-0.1,0C65.2,27,65,26.9,65,26.8c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.3-0.3-0.5-0.5
		c-0.1-0.1-0.2-0.2-0.2-0.4c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c0,0,0,0,0,0c-0.4-0.1-0.8,0-1.1,0.1
		c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.1c0.1,0.1,0.2,0.1,0.3,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.4,0.2,0.5,0.1c0.1,0.1,0.3,0,0.4,0c0.2,0,0.4,0,0.6,0c0.2,0.1,0.4,0.1,0.6,0.2
		c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0.7,0.7,1.1,1c0.2,0.1,0.4,0.3,0.5,0.4C65.7,27.2,65.8,27.4,66,27.5z M66.3,27.9
		C66.3,27.9,66.3,27.9,66.3,27.9c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1
		C66.1,27.7,66.2,27.8,66.3,27.9C66.3,27.9,66.3,27.9,66.3,27.9z M66.4,28.2C66.4,28.2,66.4,28.2,66.4,28.2c0,0,0,0.1,0,0.1
		c0,0,0,0,0,0c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.5,0,0.7c-0.1,0-0.1,0-0.1-0.2c0-0.3,0-0.5,0-0.7
		c0-0.2,0.1-0.4,0-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1C66.4,28.1,66.4,28.2,66.4,28.2
		z M66.5,29.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5,0,0.7c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5,0-0.8c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
		C66.7,28.5,66.5,28.9,66.5,29.2z M66.7,29c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c-0.1,0-0.1,0-0.1,0
		c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0-0.2,0-0.4,0-0.6c0-0.4,0.1-0.8,0-1.1c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1
		C66.7,28.8,66.7,28.9,66.7,29z M66.9,30.4c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4
		c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.4,0-0.6c0.1,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4C66.8,29.9,66.7,30.2,66.9,30.4z
		 M66.9,29.6c0,0.3,0,0.6,0.2,0.8c-0.1,0-0.1,0.1-0.2,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0-0.5,0-0.9,0-1.4c0.1,0.1,0,0.2,0,0.3
		c0,0.1,0,0.2,0,0.3C66.9,29.3,66.9,29.5,66.9,29.6z M67,29c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.5,0.1,0.7
		c-0.1,0-0.1,0-0.1,0C67,30.1,67,29.8,67,29.6c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5C67,28.8,67,28.9,67,29z M67.3,30.3
		C67.3,30.3,67.3,30.3,67.3,30.3c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.2-0.1-0.5-0.1-0.7c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
		c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0,0.3,0.1,0.4C67.3,30.1,67.3,30.2,67.3,30.3z"
      />
    </g>
    <path
      fill="none"
      stroke="#B5CB34"
      strokeWidth=".25"
      strokeMiterlimit="10"
      d="M60.1,3.7c0.7,0,1.3,0.2,2,0.2C62.7,4,63.4,4.2,64,4.5c0.6,0.3,1,0.6,1.4,1.2c0.3,0.5,0.6,1,0.9,1.5
	c0.2,0.3,0.4,0.6,0.6,0.8c0.1,0.2,0.6,0.6,0.6,0.8"
    />
    <path
      fill="#FFFFFF"
      d="M7.8,32.7v-0.4h2v-5h0.4v5h4.3v0.4h-4.3v13.6H9.8V32.7H7.8z"
    />
    <path
      fill="#FFFFFF"
      d="M25.6,31.9c3.4,0,5.7,1.8,5.7,5.8v8.6h-0.4v-8.6c0-3.8-2.1-5.4-5.2-5.4c-2.1,0-5.2,1.7-5.2,5.6v8.4h-0.4V21.4
	h0.4v14.4C21.2,33.1,23.7,31.9,25.6,31.9z"
    />
    <path
      fill="#FFFFFF"
      d="M37,39.3c0,4,2.9,6.9,7,6.9c2.6,0,4.4-1,5.6-2.7l0.4,0.3c-1.3,1.7-3.3,2.8-6,2.8c-4.4,0-7.5-3.1-7.5-7.3
	c0-4.2,3.2-7.3,7.1-7.3c4.1,0,6.8,2.8,7,7.2L37,39.3L37,39.3z M50.2,38.8c-0.3-3.9-2.8-6.4-6.6-6.4c-3.5,0-6.5,2.7-6.7,6.4H50.2z"
    />
    <path fill="#FFFFFF" d="M60.3,32.3h0.4v14h-0.4V32.3z" />
    <path
      fill="#FFFFFF"
      d="M75.1,31.9c3.4,0,5.7,1.8,5.7,5.8v8.6h-0.4v-8.6c0-3.8-2.1-5.4-5.2-5.4c-2.1,0-5.2,1.7-5.2,5.6v8.4h-0.4v-14
	h0.4v3.5C70.7,33.1,73.2,31.9,75.1,31.9z"
    />
    <path
      fill="#FFFFFF"
      d="M92.7,31.9c3.4,0,5.7,1.8,5.7,5.8v8.6h-0.4v-8.6c0-3.8-2.1-5.4-5.2-5.4c-2.1,0-5.2,1.7-5.2,5.6v8.4H87v-14h0.4
	v3.5C88.2,33.1,90.7,31.9,92.7,31.9z"
    />
  </svg>
);

export const calendarArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
  </svg>
);

export const instagram = (
  <svg
    version="1.1"
    fill="#FFFFFF"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 30.1 30.1"
    enableBackground="new 0 0 30.1 30.1;"
  >
    <g>
      <path
        d="M15.1,7.3c-4.3,0-7.7,3.5-7.7,7.7c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7C22.8,10.8,19.3,7.3,15.1,7.3z
		 M15.1,20.4c-2.9,0-5.3-2.4-5.3-5.3c0-1.1,0.4-2.2,1-3.1c1-1.3,2.5-2.2,4.3-2.2c1.8,0,3.3,0.9,4.3,2.2c0.6,0.9,1,1.9,1,3.1
		C20.4,18,18,20.4,15.1,20.4z"
      />
      <path d="M23.1,5.2c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C24.9,6,24.1,5.2,23.1,5.2z" />
      <path
        d="M30.1,8.9c-0.1-1.6-0.3-2.7-0.7-3.7c-0.4-1-0.9-1.8-1.7-2.7c-0.8-0.8-1.7-1.4-2.7-1.7c-1-0.4-2.1-0.6-3.7-0.7
		C19.7,0,19.2,0,15.1,0C11,0,10.5,0,8.9,0.1C7.3,0.2,6.2,0.4,5.2,0.8c-1,0.4-1.8,0.9-2.7,1.7C1.7,3.4,1.2,4.2,0.8,5.2
		c-0.4,1-0.6,2.1-0.7,3.7C0,10.5,0,11,0,15.1c0,4.1,0,4.6,0.1,6.2c0.1,1.6,0.3,2.7,0.7,3.7c0.4,1,0.9,1.8,1.7,2.7
		c0.8,0.8,1.7,1.4,2.7,1.7c1,0.4,2.1,0.6,3.7,0.7c1.6,0.1,2.1,0.1,6.2,0.1c4.1,0,4.6,0,6.2-0.1c1.6-0.1,2.7-0.3,3.7-0.7
		c1-0.4,1.8-0.9,2.7-1.7c0.8-0.8,1.4-1.7,1.7-2.7c0.4-1,0.6-2.1,0.7-3.7c0.1-1.6,0.1-2.1,0.1-6.2C30.1,11,30.1,10.5,30.1,8.9z
		 M27.3,21.2c-0.1,1.5-0.3,2.3-0.5,2.8c-0.3,0.7-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.7,1.1c-0.5,0.2-1.3,0.5-2.8,0.5
		c-1.6,0.1-2.1,0.1-6.1,0.1s-4.5,0-6.1-0.1c-1.5-0.1-2.3-0.3-2.8-0.5c-0.7-0.3-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1-1.1-1.7
		c-0.2-0.5-0.5-1.3-0.5-2.8c-0.1-1.6-0.1-2.1-0.1-6.1s0-4.5,0.1-6.1c0.1-1.5,0.3-2.3,0.5-2.8C3.6,5.5,3.9,5,4.4,4.4
		c0.5-0.5,1-0.9,1.7-1.1C6.7,3.1,7.5,2.9,9,2.8c1.6-0.1,2.1-0.1,6.1-0.1c4,0,4.5,0,6.1,0.1c1.5,0.1,2.3,0.3,2.8,0.5
		c0.7,0.3,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7C27,6.7,27.3,7.5,27.3,9c0.1,1.6,0.1,2.1,0.1,6.1S27.4,19.6,27.3,21.2z"
      />
    </g>
  </svg>
);

export const facebook = (
  <svg
    version="1.1"
    id="Icons"
    fill="#ffffff"
    x="0px"
    y="0px"
    viewBox="0 0 42 90"
    enableBackground="new 0 0 42 90;"
  >
    <path
      d="M9.1,17.4c0,2.3,0,12.4,0,12.4H0V45h9.1v45h18.6V45h12.5c0,0,1.2-7.3,1.7-15.2
	c-1.6,0-14.2,0-14.2,0s0-8.8,0-10.4c0-1.5,2-3.6,4-3.6c2,0,6.2,0,10.2,0c0-2.1,0-9.2,0-15.8c-5.2,0-11.2,0-13.8,0
	C8.6,0,9.1,15.2,9.1,17.4z"
    />
  </svg>
);

export const logoAccessible = (
  <svg
    className="accessble"
    fill="#000"
    stroke="#000"
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    x="50px"
    y="50px"
    viewBox="0 0 348 241.2"
  >
    <g>
      <path
        class="st0"
        d="M84.9,201.3h-1.6l-1.8-3.1h-8.6l-1.8,3.1h-1.6l7.1-12.5H78L84.9,201.3z M77.2,190.4l-3.4,6.1h6.7L77.2,190.4z"
      />
      <path class="st0" d="M93.4,201.3h-1.6v-10.8h-5v-1.6h11.6v1.6h-5V201.3z" />
      <path
        class="st0"
        d="M113,201.3h-1.6v-12.5h7.6c2,0,4.4,0.7,4.4,3.9s-2.5,3.8-4.4,3.8h-6V201.3z M119,194.9c2,0,2.8-0.7,2.8-2.2
		c0-1.6-0.8-2.3-2.8-2.3h-6v4.5H119z"
      />
      <path
        class="st0"
        d="M135.2,201.7c-4.3,0-7.5-2.7-7.5-6.6c0-4,3.2-6.6,7.5-6.6c4.2,0,7.5,2.6,7.5,6.6
		C142.7,199,139.4,201.7,135.2,201.7z M141,195c0-2.6-2.3-5-5.9-5c-3.6,0-5.9,2.3-5.9,5c0,2.6,2.3,5,5.9,5S141,197.7,141,195z"
      />
      <path
        class="st0"
        d="M160.3,196.5c0,4-2.9,5.2-6,5.2s-6-1.2-6-5.2v-7.7h1.6v7.7c0,2.1,1.1,3.5,4.4,3.5c3.3,0,4.4-1.4,4.4-3.5v-7.7
		h1.6V196.5z"
      />
      <path
        class="st0"
        d="M179.4,201.3h-1.6l-8.7-10.2v10.2h-1.6v-12.5h1.6l8.7,10.2v-10.2h1.6V201.3z"
      />
      <path
        class="st0"
        d="M192.6,188.8c4,0,6.1,2.8,6.1,6.2s-2,6.2-6.1,6.2h-7v-12.5h7V188.8z M192.6,199.6c2.7,0,4.4-1.9,4.4-4.6
		c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2L192.6,199.6L192.6,199.6z"
      />
      <path
        class="st0"
        d="M225.7,201.3h-2l-3.5-5.1h-5.5v5.1H213v-12.5h7.7c2.3,0,4.7,0.9,4.7,3.7c0,2.3-2,3.4-3.4,3.6L225.7,201.3z
		 M220.7,194.6c1.6,0,3.1-0.4,3.1-2.1c0-1.8-1.7-2.1-3.1-2.1h-6.1v4.1h6.1V194.6z"
      />
      <path class="st0" d="M233.3,201.3h-1.6v-12.5h1.6V201.3z" />
      <path
        class="st0"
        d="M246.5,188.8c4,0,6.1,2.8,6.1,6.2s-2,6.2-6.1,6.2h-7v-12.5h7V188.8z M246.5,199.6c2.7,0,4.5-1.9,4.5-4.6
		c0-2.7-1.7-4.6-4.4-4.6h-5.4v9.2L246.5,199.6L246.5,199.6z"
      />
      <path
        class="st0"
        d="M271.1,199.3c-0.4,0.3-2.6,2.3-6.4,2.3c-4.5,0-7.4-3-7.4-6.6c0-3.9,3.4-6.6,7.6-6.6c3.2,0,5,1.4,5.8,2
		l-0.9,1.4c-1-0.7-2.4-1.7-4.9-1.7c-3.4,0-6,2-6,5c0,2.7,2.1,5,5.9,5c2.4,0,3.9-0.8,4.7-1.4v-2.5h-4.1v-1.6h5.7L271.1,199.3
		L271.1,199.3z"
      />
      <path
        class="st0"
        d="M286.9,201.3h-11.2v-12.5h10.9v1.6h-9.3v3.6h5.3v1.6h-5.3v3.9h9.6V201.3z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M93.2,232.7l0.1,2.8h-1.1V216h1.1v11c0.8-1.7,2.4-2.9,4.7-2.9c3.2,0,5.5,2.5,5.5,5.7s-2.3,5.8-5.5,5.8
		C95.8,235.6,94,234.4,93.2,232.7z M102.3,229.9c0-2.7-1.8-4.8-4.5-4.8c-2.8,0-4.6,2.1-4.6,4.8s1.8,4.8,4.6,4.8
		S102.3,232.6,102.3,229.9z"
      />
      <path
        class="st0"
        d="M110.4,234.6l-4.9-10.2h1.1l3.9,8.2c0.2,0.5,0.4,1.2,0.4,1.3c0-0.1,0.2-0.8,0.4-1.3l3.5-8.2h1.1l-7.2,16.8
		h-1.1L110.4,234.6z"
      />
      <path
        class="st0"
        d="M122,240.5l0.6-0.7c0.4,0.4,0.8,0.7,1.5,0.7c0.9,0,1.5-0.5,1.5-2.1v-14.1h1.1v14.1c0,2-1.1,3-2.5,3
		C123.1,241.4,122.4,241,122,240.5z M125.3,219.6c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9
		C125.7,220.5,125.3,220.1,125.3,219.6z"
      />
      <path
        class="st0"
        d="M136.2,234.7c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
		c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C131.6,232.8,133.3,234.7,136.2,234.7z M140.3,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
		c-2.5,0-4.2,1.8-4.4,4.2H140.3z"
      />
      <path
        class="st0"
        d="M152,228.5c0-2.5-1.1-3.4-3-3.4c-1.7,0-2.8,0.7-3.6,1.9l-0.8-0.5c0.9-1.4,2.5-2.3,4.4-2.3c2.3,0,4,1.4,4,4.2v7
		h-1v-1.7c-0.7,1-1.9,1.9-3.8,1.9c-2.5,0-4.2-1.2-4.2-3.4s1.9-3.2,4-3.2h3.9v-0.5H152z M148.3,229.9c-1.7,0-3.1,0.7-3.1,2.3
		c0,1.7,1.1,2.5,3.2,2.5c1.8,0,3.7-1.1,3.7-2.8v-2H148.3z"
      />
      <path
        class="st0"
        d="M157.5,224.4h1.1v2.5c0.7-1.7,2.3-2.7,4.2-2.7c2.5,0,4.1,1.6,4.1,4.4v6.9h-1.1v-6.7c0-2.3-1.1-3.6-3.2-3.6
		c-2.4,0-4.1,1.6-4.1,4.1v6.2h-1.1v-11.1H157.5z"
      />
      <path class="st0" d="M170.4,228.8h4.7v1h-4.7V228.8z" />
      <path
        class="st0"
        d="M178.4,238.2l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7v-3.1c-0.8,1.6-2.6,2.9-4.7,2.9
		c-3.2,0-5.5-2.5-5.5-5.8s2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3v-2.7h1V236c0,3.8-2.1,5.5-5.4,5.5
		C181.2,241.5,179.3,240.1,178.4,238.2z M188.2,229.9c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8s1.8,4.8,4.5,4.8
		C186.4,234.7,188.2,232.6,188.2,229.9z"
      />
      <path
        class="st0"
        d="M198.7,234.7c1.8,0,3-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
		c0-3.2,2.4-5.8,5.6-5.8c3.4,0,5.5,2.6,5.4,6H194C194.1,232.8,195.8,234.7,198.7,234.7z M202.8,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
		c-2.5,0-4.2,1.8-4.4,4.2H202.8z"
      />
      <path
        class="st0"
        d="M206.6,229.9c0-3.2,2.5-5.7,5.8-5.7c3.2,0,5.7,2.6,5.7,5.7c0,3.2-2.5,5.8-5.7,5.8
		C209.2,235.6,206.6,233,206.6,229.9z M217,229.9c0-2.7-1.9-4.8-4.6-4.8c-2.8,0-4.7,2.1-4.7,4.8s1.9,4.8,4.7,4.8
		C215.1,234.7,217,232.6,217,229.9z"
      />
      <path
        class="st0"
        d="M221.8,224.4h1.1v2.2c0.6-1.6,1.8-2.4,3.2-2.4c0.9,0,1.5,0.2,2,0.6l-0.5,0.9c-0.3-0.2-0.8-0.5-1.6-0.5
		c-1.6,0-3.1,1.3-3.1,4v6.3h-1.1L221.8,224.4L221.8,224.4z"
      />
      <path
        class="st0"
        d="M230.2,238.2l0.9-0.5c1,1.8,2.5,2.8,4.6,2.8c2.8,0,4.3-1.4,4.3-4.7v-3.1c-0.8,1.6-2.6,2.9-4.7,2.9
		c-3.2,0-5.5-2.5-5.5-5.8s2.3-5.7,5.5-5.7c2.2,0,3.9,1.2,4.7,3l-0.1-2.7h1.1V236c0,3.8-2.1,5.5-5.4,5.5
		C233,241.5,231.2,240.1,230.2,238.2z M240.1,229.9c0-2.7-1.8-4.8-4.6-4.8c-2.7,0-4.5,2.1-4.5,4.8s1.8,4.8,4.5,4.8
		C238.3,234.7,240.1,232.6,240.1,229.9z"
      />
      <path
        class="st0"
        d="M250.6,234.7c1.8,0,3.1-0.7,3.9-1.7l0.7,0.6c-1,1.2-2.5,2.1-4.6,2.1c-3.5,0-5.8-2.6-5.8-5.7
		c0-3.2,2.4-5.8,5.5-5.8c3.4,0,5.5,2.6,5.4,6h-9.9C246,232.8,247.7,234.7,250.6,234.7z M254.7,229.2c-0.2-2.3-1.6-4.2-4.3-4.2
		c-2.5,0-4.2,1.8-4.4,4.2H254.7z"
      />
      <path
        class="st0"
        d="M258.4,232.9l0.9-0.4c0.6,1.3,1.7,2.2,3.5,2.2c1.7,0,2.8-0.8,2.8-2.1c0-1.5-1.1-1.9-3.1-2.5
		c-1.9-0.5-3.4-1.2-3.4-3.2c0-1.6,1.5-2.8,3.3-2.8c1.7,0,2.8,0.8,3.5,2.1l-0.8,0.5c-0.5-0.9-1.4-1.7-2.7-1.7c-1.4,0-2.3,0.7-2.3,1.8
		c0,1.3,0.7,1.7,2.8,2.3c2,0.6,3.7,1.3,3.7,3.4c0,1.8-1.4,3.1-3.8,3.1C260.5,235.6,259,234.4,258.4,232.9z"
      />
    </g>
    <path
      class="st0"
      d="M217.3,69.6c0.4,0.5,0.5,0.4,0.7,1c0.3,0.5,0.3,1,0.4,1.6c0.1,1.1,0.3,2.3-0.2,3.3c-0.9,1.8-2.7,3.1-3.4,5
	c-0.1,0.3-0.4,1.5,0.1,1.4"
    />
    <g>
      <path
        class="st1"
        d="M299,11.5c-0.3-0.6-0.5-0.1-0.5,0c0.3,0.2,0.1,0.9,0,1.2c-0.3,0.7-0.9,0.1-1.4-0.1c-0.6-0.3-1.3-0.4-2-0.6
		c-1.1-0.3-2.2-0.5-3.3-0.7c1-0.1,2.1,0,3.3,0.1c0.8,0.1,1.9,0,2.6,0.4c0.1-0.1,0.2-0.2,0.2-0.3c-0.8-0.5-1.9-0.4-2.8-0.5
		c-1-0.1-2.1-0.2-3.1-0.2s-2.1-0.1-3.2,0.1c-0.3,0-2,0.2-2.1,0.3c-0.1,0.3-1.4,0.4-1.7,0.4c-0.5,0.1-0.9,0.2-1.4,0.3
		c-0.1,0-0.3,0.1-0.4,0.1s-0.2,0-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4,0.1-0.5,0.1
		c-1.3,0.3-2.5,0.7-3.8,1.2c-0.8,0.3-1.7,0.5-2.5,0.9c-0.3,0.2-0.6,0.2-0.9,0.4c-0.4,0.1-0.7,0.5-1,0.6c-1.8,0.9-3.5,1.6-5.3,2.3
		c-1.9,0.8-3.7,1.5-5.7,1.8c-0.5,0.1-1,0.2-1.4,0.2c-0.4,0.1-0.5-0.1-0.9-0.2c-0.5-0.2-1.4,0.1-1.8,0.4c0.1-0.2,0.2-0.3,0.3-0.5
		c-1.7,0.9-3.7,1-5.6,1.2c-0.6,0.1-1.2,0.2-1.7,0.4c-0.3,0.1-0.3-0.1-0.6,0c-0.2,0-0.4,0.2-0.5,0.4c-0.2,0.1-0.3,0.1-0.5,0.1
		c-0.4,0.1-0.9,0.4-1.3,0.6c-2.2,1-4.1,2.5-6,4c-0.7,0.6-1.3,1.3-1.7,2.1c-0.3,0.7-0.9,1.4-1.1,2.2c0.2,0.4-0.4,0.3-0.4,0.3
		c-0.2,0.2-0.2,0.3,0,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.4-0.8,0.6-1,1.1c-0.2,0.6-0.7,1-1,1.6c-0.3,0.7-0.7,1.4-0.8,2.2
		c-0.2,1.5-0.6,3.2-0.4,4.7c0.1,0.9,0.3,1.7,0.6,2.5c0.2,0.6,0.5,1.2,0.3,1.8c1.1,0.1,0.4,1.9,1,2.5c0.1-0.1,0.2-0.2,0.2-0.3
		c0.1,0.2,0.6,0.8,0.3,0.9c-0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.1-0.1,0.2-0.1c0.6,1,1.3,2,1.8,3.1c0.5,1,1.1,1.8,1.1,2.9
		c0.9-0.2,1.6,3.2,1.7,3.9c0.2,1.7,0.2,3.4,0.1,5.1c-0.1,1.7-0.3,3.4-0.4,5.1c-0.1,1.7-0.3,3.5-0.2,5.3c0,0.5,0.1,0.9,0.1,1.4
		c0,0.2,0,0.4,0,0.5c0.1,0.8,0.2,1.7,0.3,2.5c0,0.1,0.1,0.3,0.1,0.4s0,0.2,0,0.3c0.1,0.7,0.4,1.4,0.6,2c0.1,0.1,0.1,0.3,0.2,0.4
		c0.3,0.7,0.7,1.3,1,2c1.6,2.7,3.8,5.3,6.6,6.9c1.3,0.7,1.2-1.8,2.1-1.4c0.1-0.1,0.2-0.3,0.1-0.4c0.3-0.3,0.7-0.6,1-1
		c0.2-0.2,0.2-0.7,0.6-0.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0.1-0.3,0.3-0.1,0.4-0.3c0.1-0.2-0.1-0.1-0.1-0.2
		c-0.2-0.2,0.2-0.6,0.3-0.7c0.2-0.4,0.4-0.9,0.7-1.4c0.2-0.4,0.3-1.3,0.7-1.4c-0.1-0.2-0.1-0.6,0.1-1c0.1-0.2,0.1-0.3,0.2-0.5
		c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.4l0,0c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2
		c0.3,0.1,0.6-0.1,0.8-0.2c-0.4-0.2-0.7-0.2-0.7-0.7c0.1-0.3,0-1.5,0.3-1.6s-0.5-0.9,0.1-0.8c0-0.2,0.2-0.5-0.1-0.4
		c0-0.3-0.1-1.8,0.1-1.9c0.1-0.1,0.4-0.1,0.6-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c0-0.2,0.1-0.6,0.1-0.7c0,0-0.2-0.1-0.2-0.2
		c0-0.3-0.1-0.5-0.1-0.8c0-0.4-0.4-1.2,0.1-1.3c-0.7,0-0.2-0.7,0.1-0.8c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.2-0.1-0.3,0-0.5l0.1-0.1
		c0-0.2-0.2-0.3-0.3-0.6c-0.1-0.5-0.5-1.6,0-1.9c-0.1,0-0.2-0.2-0.3-0.2c0.2-0.2-0.2-0.4-0.2-0.4c0.2-0.7-0.8-1.4-0.2-1.9
		c-0.1-0.2-0.2-0.3-0.4-0.2c-0.2-0.6-0.6-1.3,0-1.7c-0.4,0.2-0.7-0.3-0.5-0.6c-0.5-0.6-0.7-2-1-2.7c0.3,0,0.2-0.2,0.1-0.4
		c-0.6-0.2,0.2-0.8-0.4-0.9v0.1c-0.6-0.2,0.2-0.7,0.3-0.9c-0.3,0-0.5-0.2-0.6,0.1c-0.1,0-0.3-0.5-0.3-0.6c-0.2-0.5-0.1-0.2,0.2-0.5
		c0.2-0.1-0.2-0.4-0.4-0.4c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3-0.4-0.3-0.5c0.3,0.1,0.5,0,0.8-0.1c-0.2-0.3-0.5-0.3-0.8-0.3
		c-0.2-0.1-0.2-0.2-0.4-0.5c0.2,0.1,0.3,0.1,0.4-0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.6-0.4-1.2-0.6-1.7
		c-0.1-0.5-0.7-1.5-0.4-1.9c-0.6-0.3-0.5-1.4-0.5-2c0-1.1-0.1-2.2,0-3.3c0.1,0.1,0.3-0.1,0.4-0.2c-0.2-0.2-0.6-0.7-0.1-1
		c-0.7-0.6,0.3-3.3,0.4-4.1c0.1-1.3-0.5-2.7-1.5-3.6c0.2,0,0.4,0,0.6,0c-0.3,0.4,0.7,0.4,0.9,0.5c1.1,0.1,2-0.2,2.8-0.8
		c0.7-0.5,1.4-0.9,2-1.4c0.5-0.3,1.8-1.6,2.3-1.4c0.1-0.3,1.9-1.1,1.6-0.5c0.2,0.2,0.5,0.1,0.7,0.1c-0.1-0.2-0.2-0.3-0.3-0.5
		c0.4-0.1,0.7-0.2,1.1-0.1c0.2,0,0-0.1,0.1-0.1c0.6-0.1,1.2-0.1,1.7,0.1c0.4,0.1,1,0.2,1.5,0.2c-0.1,0,0.4-0.2,0.4-0.2
		c0.3,0,0.6-0.1,1-0.1c-0.1,0.3,0.5,0.4,0.7,0.6c0-0.2-0.1-0.4-0.2-0.6c0.6,0,0.7-0.1,0.9,0.5c0.1,0.1-0.4-0.2,0,0.3
		c0.2,0.2,0.5,0.3,0.7,0.4c-0.2-0.3-0.3-0.7-0.5-1c0.6,0.1,1.5,0.3,2,0.7c0-0.1,0-0.2,0-0.3c0.6-0.1,0.9,0.1,1.5,0.2
		c0-0.1,0-0.1-0.1-0.2c1.5,0.1,3.1,0,4.5,0.1c0.4,0,0.3,0.1,0.7,0c0.4-0.1,0.8-0.2,1.2-0.3c0.6-0.1,1.4-0.5,1.8,0
		c-0.3-0.6,0.4-0.4,0.6-0.5c0.4-0.2,1.1-0.3,1.6-0.4c0.1,0.4,0.6,0.4,0.4-0.1c0.7-0.1,1.3-0.2,2-0.3c0.6-0.1,0.7,0,1.2,0.2
		c-0.1-0.1-0.1-0.3-0.1-0.5c0.4-0.1,0.7,0.1,1.1,0.3c-0.1-0.2-0.2-0.4-0.3-0.6c0.3-0.1,0.8-0.4,0.7,0.1c0.2,0,0.4,0.3,0.1,0.2
		c0.3,0.1,0.5,0.5,0.8,0.6c-0.1-0.6-0.3-0.8-0.7-1.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2,0.3-0.4-0.1,0.3,0.3c0.2,0,0.3-0.3,0.2-0.4
		c0.1,0,1.2-0.3,1.2-0.4c0.1,0,0.3,0.3,0.4,0.4c-0.1-0.9,0.2-0.8,1-1.2c0.2,0.2,1.5-0.9,1.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.1,0.3-0.3,0.5-0.5c0.3-0.3,0.5-0.6,0.8-0.9c0-0.1,0.1-0.1,0.1-0.2s0.1-0.1,0.1-0.2c0.3-0.5,0.6-1.2,1.3-1
		c0-0.3-0.1-0.5-0.3-0.7c0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.3,0.4-0.1,0.6-0.5c-0.1-0.1-0.1-0.1-0.2-0.1c0.4-0.7,0.5-1.6,1-2
		c-0.2-0.2-0.1-0.8,0.4-0.6c0.1,0,0.3,0,0.3-0.2c-0.1-0.1-0.5-0.3-0.5-0.3c0.1-0.2,0-0.6,0.3-0.5c0,0,0.2,0,0.3-0.1
		c0-0.1-0.5-0.9,0.1-0.8c0.3,0.1,0.5,0.4,0.9,0.1c0.2-0.3-0.2-0.4-0.4-0.6c-0.5-0.3-0.3-0.3-0.1-0.9c0.1-0.4,0.2-0.7,0.3-1.1
		c0.1-0.5,0-1.3,0.7-1.4c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.2,0.2,0.1,0.4-0.3c0.1-0.2-0.3,0,0-0.6C299,12.4,299.2,12,299,11.5z
		 M249.9,37.1C250,37.3,249.9,37.1,249.9,37.1L249.9,37.1z M249.9,43c-0.8-0.9-0.1-2,0.1-3c0.2-0.8,0.4-1.7,0.1-2.5
		c0.5,1.2,0.3,2.4,0,3.6C250,41.6,249.9,42.3,249.9,43z M249.9,43.9c-0.3-0.3-0.7-0.7-0.6-1.1c0.1,0.2,0.6,0.6,0.6,0.8
		C249.9,43.7,249.9,43.8,249.9,43.9z M249.8,45c-0.3-0.3-1.2-1.1-0.7-1.5c0.2,0.3,0.5,0.6,0.8,0.9C249.8,44.6,249.8,44.8,249.8,45z
		 M249.8,46.3c-0.3-0.2-0.6-0.6-0.8-0.9c-0.3-0.5-0.6-0.6-0.3-1.2c0.3,0.5,1.1,1,1.1,1.5C249.7,45.9,249.8,46.1,249.8,46.3z
		 M249.8,47.4c-0.5-0.3-0.9-0.7-1.3-1.2c-0.4-0.5-0.5-0.5-0.2-1.2c0.5,0.6,0.7,1.4,1.5,1.8C249.8,47,249.8,47.2,249.8,47.4z
		 M249.8,48.4c-0.6-0.4-2.4-1.5-1.9-2.5c0.3,0.4,0.6,0.9,1.1,1.3C249.5,47.7,249.8,47.7,249.8,48.4z M249.9,49.5
		c-0.7-0.4-2.9-2-2.4-2.9c0.5,0.9,1.2,1.8,2.2,2.2c0,0,0-0.1,0.1-0.2C249.8,49,249.9,49.3,249.9,49.5z M250.3,50.9
		c-1-0.5-1.8-1.2-2.5-2.1c-0.2-0.3-1.1-1.4-0.8-1.6c0.7,1.1,1.8,2.2,3,2.8C250.1,50.3,250.2,50.6,250.3,50.9z M250.9,52.6
		c-0.4-0.3-0.8-0.4-1.1-0.6c-0.5-0.4-0.9-0.9-1.4-1.3c-0.4-0.3-1.9-2.6-1.7-2.9c0.4,0.3,0.6,0.9,0.8,1.3c0.3,0.4,0.6,0.8,1,1.1
		c0.6,0.5,1.9,0.9,2.1,1.7C250.7,52.1,250.8,52.4,250.9,52.6z M249.7,53.3c-0.7-0.4-1.6-1.1-2.2-1.8c-0.4-0.5-1.9-2.6-1.3-3.4
		c0.5,0.9,1.2,2.2,2,2.9c0.4,0.3,1.2,1.4,1.6,1.3c0.1,0.2,0.9,0.6,1.1,0.8c0.2,0.2,0.3,0.6,0.4,1C250.9,53.9,250.3,53.6,249.7,53.3z
		 M246.4,51.6c-0.4-0.5-1.5-2.3-0.6-2.7c0.1,1.4,1.6,3.6,2.9,4.2c0.5,0.3,0.9,0.6,1.5,0.9c0.7,0.3,1.4,0.5,1.7,1.1
		c-0.4-0.3-1-0.3-1.5-0.4C248.8,54.2,247.3,52.9,246.4,51.6z M247.4,53.9c-0.8-0.5-1.3-1-1.9-1.9c-0.3-0.5-0.9-1.7-0.4-2.2
		c0.4,1.8,2,3.1,3.3,4.2c0.5,0.4,1.5,0.8,2.1,1c0.8,0.2,1.4,0.2,1.7,1C250.5,55.7,248.9,54.9,247.4,53.9z M247,55
		c-1-0.6-3.3-3-2.4-4.3c0.3,1.9,1.8,3.1,3.3,4.1c0.8,0.5,1.8,1.1,2.8,1.4c0.5,0.1,1,0.2,1.4,0.3c0.5,0.1,0.5,0.4,0.7,0.9
		C250.7,57,248.8,56.1,247,55z M247.6,56.4c-1.2-0.6-2.2-1.6-3-2.7c-0.5-0.7-1.1-1.5-0.6-2.3c0.5,2.1,2,3.6,3.8,4.5
		c1,0.5,2,1,3.1,1.4c0.7,0.3,2,0.2,2.3,0.9C251.1,58.4,249.3,57.2,247.6,56.4z M248.1,58c-1.6-0.8-4.8-3.1-4.5-5.1
		c0.7,1.1,1.5,2.4,2.7,3.2c0.4,0.2,0.7,0.5,1.1,0.7c0.3,0.1,0.5,0.3,0.8,0.4c0.7,0.3,1.3,0.7,2,0.9c0.6,0.2,1.3,0.5,2,0.5
		c0.6,0,1.1-0.1,1.3,0.4C251.6,59.2,249.7,58.8,248.1,58z M252.5,59.5c0.6,0.1,1-0.3,1.2,0.3C253.2,59.9,253,59.7,252.5,59.5z
		 M251,59.3c1,0.2,1.9,1.2,2.8,0.9c0.1,0.2,0.1,0.4,0.2,0.7c-0.1,0,0,0.1-0.2,0C252.6,60.5,251.9,60.3,251,59.3z M250.1,59.1
		c0.3,0.1,0.7,0.6,1,0.9c0.4,0.4,0.8,0.7,1.4,1c0.4,0.2,0.9,0.2,1.3,0.3c0.6,0.1,0.3,0.5,0.6,0.7C253.6,63,250.5,59.6,250.1,59.1z
		 M253.2,63.1c-0.6-0.1-1.1-0.6-1.5-1.1c-0.8-1.1-1.8-2.3-2.8-3.2c0.6,0.1,0.9,0.6,1.2,1c0.5,0.6,1.1,1.2,1.7,1.6
		c0.5,0.4,1.2,0.7,1.8,0.9s0.8-0.2,1,0.5c-0.1,0-0.1,0-0.2,0C254.4,63.2,253.6,63.2,253.2,63.1z M250.8,62.2
		c-1.3-1.4-2.6-2.8-3.8-4.3c0.3,0.2,0.8,0.5,1,0.7c0.2,0.1,0.5,0.4,0.6,0.5c0.7,0.5,1.4,1.4,1.9,2.1c0.6,0.8,1.3,1.9,2.3,2.2
		c0.4,0.2,1.5,0.2,1.9-0.1c0.1,0.4,0.2,0.8,0.3,1.2C253.4,64.9,251.8,63.2,250.8,62.2z M255.1,65.6c-1.2,0.1-2.4-0.8-3.4-1.3
		c-1.2-0.6-2.2-2-3.2-3c-1.1-1.2-2-2.5-2.9-3.8c-0.7-0.9-1.6-1.9-1.9-3c0.7,1.2,1.8,1.9,2.6,3c0.5,0.6,1,1.3,1.5,1.9
		c0.4,0.5,1,0.9,1.3,1.4c0.8,1.2,2.1,2.5,3.3,3.3c0.3,0.2,0.7,0.4,1.1,0.6c0.5,0.2,1.8,0.1,1.9,0.5
		C255.1,65.1,255.2,65.6,255.1,65.6z M244.1,56.2c-0.2-0.2-0.1,0-0.2-0.3C244,56,244,56.1,244.1,56.2z M254.2,66.9
		c-0.7-0.2-1.3-0.4-1.9-0.7c-1-0.5-2-1.6-2.7-2.4c-1.9-2.2-4.1-4.4-5.2-7.2c1.7,2.4,3.4,5,5.7,6.9c0.9,0.8,4.2,3.5,5.4,2.4
		c0.1,0.3,0.1,0.6,0.2,0.9c0,0-0.1,0-0.1,0.1C255,67,254.8,67.1,254.2,66.9z M254.3,67.8c-0.9,0-1.4,0.2-2.2-0.3
		c-1.4-0.8-2.4-1.7-3.5-2.9c-0.7-0.7-1.4-1.4-1.9-2.3c-0.4-0.7-1.1-1.3-1.4-2.1c-0.2-0.6-0.5-1.3-0.8-1.8c-0.3-0.4-0.3-0.8-0.4-1.4
		c0.5,1.4,1.5,2.8,2.4,4c1.1,1.3,2.2,2.6,3.4,3.8c0.8,0.8,1.7,1.7,2.8,2.2c0.5,0.3,1.7,0.8,2.3,0.6c0.3-0.1,0.6-0.2,1-0.3
		C255.9,67.5,254.6,67.8,254.3,67.8z M256.1,69.5c-0.2-0.1-0.4-0.2-0.6-0.4c0.3-0.1,0.5-0.3,0.7-0.5
		C256.1,68.9,256.2,69.2,256.1,69.5z M254.5,69.6c0.4-0.1,0.4-0.3,0.7-0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.4,0,0.5,0.4
		c0.1,0.2,0,0.3-0.2,0.2C255.5,70.4,255,69.9,254.5,69.6z M250.6,67.7c-1.2-0.7-2.1-1.8-3-2.9c-1.5-1.7-2.8-3.4-3.2-5.7
		c1.2,2.7,3.1,5.2,5.3,7.1c0.8,0.7,2,1.5,2.9,1.8c1.1,0.4,2.3,0,3.3-0.5c0.1,0.5,0.2,0.3-0.2,0.4c0.4,0.8-1.3,0.6-1.1,1.1
		c-0.4,0.2-0.9,0.1-1.3,0C252.4,68.9,251.4,68.2,250.6,67.7z M253.3,69.5c0.7,0.2,1.1,0.5,1.8,0.9c0.4,0.2,0.9,0.5,1.3,0.4
		c0,0.1,0,0.2,0,0.3C255.4,71.8,253.8,70.3,253.3,69.5z M254,71.2c-0.6-0.7-1.4-1.4-1.9-2.3c0.8,0.4,1,1.3,1.8,1.8
		c0.6,0.4,1.8,1.4,2.6,0.8c0.1,0.6,0.2,1.2,0.2,1.8c-0.1,0-0.2,0-0.3,0C255.3,73.4,254.6,72,254,71.2z M256.3,74.4
		c-1.2-0.1-2.4-1.4-3-2.3c-0.9-1.3-1.8-2.6-2.7-3.9c1.1,0.6,1.6,1.8,2.5,2.8c1,1,1.8,3,3.5,2.8C256.8,74.2,256.8,74.5,256.3,74.4z
		 M254.2,74.7c-0.8-0.6-1.4-1.4-2-2.1c-1.2-1.6-1.8-3.6-2.9-5.2c0.2,0.1,0.8,0.5,0.8,0.7c-0.1,0.2,0.4,0.6,0.5,0.8
		c0.6,0.9,1.3,1.7,1.8,2.6c0.8,1.3,2.5,3.8,4.4,3.3c0,0.2,0,0.5,0,0.7c-0.3-0.1-0.4,0.1-0.7-0.1C255.5,75.1,254.8,75.1,254.2,74.7z
		 M255.3,76.1c-2.2-1.1-3.7-2.7-4.9-4.7c-0.8-1.2-1.3-2.6-1.9-3.9c-0.5-1-1.1-2-1.7-3c0.6,0.7,1.4,1.4,1.8,2.1
		c0.3,0.1,0.8,1.5,0.9,1.7c1,1.6,1.6,3.3,2.7,4.9c0.5,0.6,1.1,1.3,1.8,1.7c0.4,0.3,0.8,0.5,1.2,0.6c0,0,0.6,0.2,0.6,0.1
		c0.4,0.1,1,0,0.9,0.5C256.7,77,255.7,76.3,255.3,76.1z M253.3,76c-1.5-1.2-3-2.8-3.9-4.5c-1-1.8-1.6-3.7-2.7-5.4
		c-0.5-0.7-1-1.5-1.3-2.4c-0.1-0.3-0.2-0.6-0.4-0.9c-0.3-0.4-0.2-0.9-0.3-1.4c0.9,1.4,1.4,2.8,2.3,4.3c1,1.6,1.7,3.4,2.6,5.1
		c0.5,0.9,1,1.6,1.7,2.4c0.4,0.6,0.9,1.4,1.5,1.7c0.8,0.4,1.5,1.1,2.3,1.5c0.4,0.2,0.9,0.4,1.4,0.5c0.4,0.1,0.3,0.1,0.2,0.5
		C255.4,77.5,254.2,76.8,253.3,76z M255.2,78.1c-1.5-0.8-2.9-1.5-3.9-2.9c-1-1.3-1.8-2.7-2.8-4.1c-0.9-1.3-1.6-2.8-2.3-4.2
		c-0.3-0.5-0.6-1.2-0.9-1.7c-0.5-0.7-0.4-1-0.4-1.8c0.4,0.8,0.6,1.6,1.1,2.4c0.5,0.7,1,1.4,1.4,2.2c0.7,1.5,1.3,3.1,2.2,4.4
		c0.9,1.4,2.2,2.7,3.5,3.8c0.1,0.5,1.3,1,1.7,1.2c0.6,0.3,1.4,0.4,2,0.4c0,0.2-0.1,0.5-0.1,0.7c-0.1-0.1-0.1-0.1-0.2-0.1
		c0,0.1,0,0.1,0,0.1C256,78.4,255.6,78.3,255.2,78.1z M256,79.6c-2.7-1.3-5-3.1-6.7-5.7c-1-1.5-1.9-3-2.6-4.7
		c-0.6-1.3-1.6-2.4-1.8-3.8c0.4,0.5,0.6,1.1,0.9,1.7c0.4,0.8,0.8,1.6,1.2,2.4c0.8,1.6,1.8,3.1,2.8,4.5c0.9,1.4,2,2.6,3.4,3.6
		c0.6,0.4,1.7,1.1,2.5,1.2c0.3,0,0.6,0.3,0.9,0.2c0,0.3-0.1,0.5-0.1,0.8C256.3,79.7,256.2,79.7,256,79.6z M255,79.5
		c0.3,0.1,1.4,0.6,1.4,0.8c-0.6-0.3-1.2-0.6-1.9-0.9c-2.3-1.1-4.2-2.9-5.8-4.9c-1.1-1.4-1.9-3.1-2.7-4.7c-0.3-0.7-0.6-1.4-0.9-2.1
		c-0.3-0.6-0.8-1.3-0.6-2c0.3,1.5,1.4,3,2.1,4.3c0.9,1.7,1.9,3.4,3,5c0.8,1.2,2.3,2.7,3.6,3.3C253.9,78.8,254.4,79.3,255,79.5z
		 M256.2,81.3c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0.5-1.3-0.4-1.7-0.6l0,0c-0.4-0.2-0.9-0.5-1.2-0.8
		c-1.3-0.6-2.1-1.9-3-3c-1.5-1.6-2.7-3.1-3.5-5.2c-0.3-0.8-0.7-1.7-1.2-2.4c-0.2-0.3-0.3-0.6-0.5-0.9c-0.2-0.4-0.1-0.6-0.1-1
		c1.2,2.3,2.1,4.6,3.4,6.8c0.7,1.2,1.7,2.1,2.6,3.1c0.3,0.4,1.9,1.8,2.2,1.7c0,0.4,1.7,1.1,2,1.3c0.4,0.2,0.8,0.4,1.2,0.6
		c0,0,0.1,0,0.1,0.1c0.5,0.2,0.5,0.2,0.5,0.7C256.2,81.2,256.2,81.2,256.2,81.3z M255.9,82.8c-0.1-0.1-0.1-0.1-0.2-0.1
		c-0.1-0.1-0.3-0.2-0.4-0.3l0,0l0,0c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.4-1.2-0.8-1.8-1.2
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.6-0.5-0.9-0.7l0,0c-0.2-0.2-0.4-0.4-0.6-0.6c-1-0.9-1.9-2-2.8-3.1c-1.1-1.2-2.2-2.8-2.9-4.3
		c-0.4-0.7-0.7-1.4-1.2-2.1s-0.2-1.1-0.1-1.9c1,1.8,1.7,3.8,2.8,5.5c0.6,1,1.4,1.9,2.2,2.7c0.7,0.7,1.3,1.5,2,2.2
		c0.5,0.4,1,0.9,1.6,1.2c0.4,0.2,0.7,0.5,1.1,0.6l0,0c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4
		c0.6,0.4,0.9,0.3,0.9,0.7c0,0.1,0,0.2-0.1,0.4C255.9,82.6,255.9,82.7,255.9,82.8z M255.6,83.6c-0.4-0.2-0.9-0.5-1.4-0.7
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.4-1.4-0.8-2.1-1.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.5-0.4-1.1-0.8-1.5-1.3c-0.2-0.2-0.4-0.4-0.5-0.6
		c-0.3-0.4-0.6-0.8-0.9-1.2c-0.8-1.3-1.5-2.6-2.4-3.9c-0.5-0.8-1-1.6-1.5-2.4c-0.3-0.5-0.7-0.7-0.6-1.3c0.9,1.4,1.5,2.9,2.5,4.2
		c1,1.3,1.9,2.6,3,3.8c0.4,0.4,0.8,0.8,1.2,1.2c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.7,0.6,1,0.9c0.1,0.1,0.3,0.3,0.4,0.4
		c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.4,1.1,0.6,1.6,1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4
		C255.7,83.2,255.8,83.4,255.6,83.6C255.6,83.7,255.6,83.6,255.6,83.6z M255.2,84.8c-0.2-0.2-0.6-0.6-0.8-0.4
		c-0.7-0.5-1.5-0.7-2.1-1.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.4-1-0.9-1.4-1.3c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.1-0.1-0.3-0.3-0.4-0.4c-0.6-0.5-1.1-1.1-1.7-1.8c-0.2-0.2-0.3-0.4-0.4-0.6c0-0.1-0.1-0.1-0.2-0.2c-0.8-1.2-1.4-2.5-2-3.7
		c-0.3-0.7-0.7-1.3-1.2-1.9s-0.8-0.7-0.7-1.6c1.9,2.3,2.9,5.3,4.7,7.6c0,0.1,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.3,0.4,0.5
		c0.5,0.5,1,1.1,1.6,1.5c0.2,0.1,0.3,0.3,0.5,0.4c0.4,0.3,0.7,0.6,1.1,0.8c0.2,0.1,0.6,0.3,1.1,0.5c0.2,0.1,0.4,0.2,0.6,0.3
		c0.9,0.5,1.9,1,1.8,1.2C255.3,84.4,255.2,84.6,255.2,84.8z M253.3,85c-0.5-0.3-1.1-0.5-1.6-0.8c-0.4-0.3-0.9-0.6-1.2-0.9
		c-0.1-0.1-0.3-0.3-0.4-0.4c-0.5-0.5-0.9-1-1.4-1.6c-0.1-0.2-0.2-0.3-0.4-0.4l-0.1-0.1c-0.5-0.6-0.9-1.2-1.3-1.9
		c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.6-0.7-1.3-1-1.9c-0.4-0.8-0.8-1.7-1.3-2.4c-0.2-0.3-0.6-1-0.9-1l0.1-0.5c1.5,1.8,2.3,4,3.6,6
		c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0.6,0.7,0.9,1c0.3,0.3,0.6,0.6,0.9,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.5,0.5,1,1,1.5,1.5
		c0.1,0.1,0.2,0.2,0.3,0.2l0.1,0.1c0.6,0.5,1.3,0.9,2,1.2c0.4,0.2,0.9,0.8,1.3,0.8c-0.1,0.2-0.2,0.6-0.4,0.5
		C254,85.7,253.7,85.3,253.3,85z M252.9,86.2c-0.5-0.2-1.2-0.6-1.7-1c-0.8-0.6-1.5-1.2-2.2-1.9c-0.2-0.2-0.3-0.3-0.4-0.4
		c-0.3-0.3-0.7-0.7-1-1.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.5c-0.4-0.7-0.8-1.5-1.2-2.3c-0.1-0.2-0.2-0.4-0.3-0.6
		s-0.2-0.4-0.3-0.5c-0.6-1.1-1.9-2.6-1.7-3.9c0.9,1.5,1.8,3.1,2.7,4.7c0.1,0.2,0.2,0.4,0.4,0.6c0.4,0.7,0.9,1.4,1.4,2.1
		c0.1,0.1,0.2,0.3,0.3,0.4s0.1,0.2,0.2,0.3c0.3,0.4,0.7,0.9,1.1,1.3c0.1,0.1,0.3,0.3,0.4,0.4c0.6,0.6,1.3,1.1,2.1,1.5
		c0.6,0.3,1.1,0.5,1.6,0.9c0.2,0.2,0.6,0.4,0.9,0.4c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		C253.7,86.9,253.2,86.3,252.9,86.2z M252.8,86.9c-0.7-0.3-1.4-0.6-2-1c-1.1-0.6-2.2-1.7-3.1-2.7c0,0,0,0,0-0.1
		c-0.1-0.1-0.2-0.3-0.4-0.4c-0.5-0.5-1-1.1-1.2-1.8l0,0c-0.1-0.1-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.8-0.6-1.1
		c-0.1-0.2-0.4-0.8-0.7-1.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.5-1-1-1.9-1.3-1.7c0.1-0.3,0.1-0.7,0.2-1c0.5,1,1.1,2,1.7,3
		c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.5,0.5,1.1,0.8,1.6c0.1,0.2,0.2,0.3,0.3,0.5c0.4,0.6,0.8,1.2,1.3,1.7
		c0,0,0,0,0.1,0.1s0.2,0.3,0.4,0.4c1,1,2.1,2.1,3.4,2.7c0.3,0.2,0.6,0.4,0.9,0.6c0.4,0.2,1.3,0.5,1.1,0.9
		C253.5,87.3,253.1,87,252.8,86.9z M253.4,88.2c-2.5-1.3-4.6-3-6.5-5.1l-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.5-0.8-1-1.2-1.6
		c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.3-0.4-0.5c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-0.5-0.9-1.4-1.1-2.2
		c0-0.2-0.1-0.4-0.1-0.5c0-0.3,0-0.6,0-0.8c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0.2,0.3,0.5,0.4,0.7c0.5,0.7,0.9,1.5,1.1,2.3
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.4,0.8,0.6,1.1c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.2,0.3,0.3,0.4,0.5
		c0.2,0.3,0.5,0.6,0.7,0.8c1.6,1.8,3.7,2.8,5.8,3.8C253.6,87.9,253.5,88.1,253.4,88.2z M242.6,80.1c0.3,0.7,0.6,1.4,1.1,2.1
		c0.1,0.2,0.2,0.3,0.3,0.4c0.7,1.1,1.5,2,2.2,3.1c1.1,1.5,2.5,3.1,4.1,4c0,0,0-0.1,0.1-0.1c0.1,0.3,0.8,0.7,1.1,0.9
		c0,0.1-0.3,0.6-0.3,0.6c-0.1-0.2-1-0.6-1.3-0.8c-0.5-0.4-0.9-0.7-1.4-1.1c-0.9-0.7-1.8-1.5-2.6-2.5c-0.7-0.9-1.4-2-1.9-3
		c-0.1-0.4-0.3-0.7-0.5-1c-0.1-0.1-0.1-0.3-0.2-0.4C243,81.5,242.7,80.8,242.6,80.1z M242.7,78.9c0.1,0.2,0.2,0.5,0.3,0.7
		s0.2,0.4,0.4,0.6c0.3,0.4,0.7,0.9,0.9,1.4c0.2,0.3,0.3,0.5,0.5,0.8c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.5,0.7,0.8,1.1
		c0.6,0.8,1.2,1.6,1.9,2.2c0.5,0.4,1.1,1.4,1.8,1.6c0,0.4,2.2,1.6,2.5,1.7c-0.2,0.2-0.3,0.4-0.4,0.5c-0.8-0.3-1.9-1.1-2.6-1.7
		c-1-1-1.9-2.1-2.8-3.2c-0.6-0.8-1.2-1.6-1.8-2.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.5-0.7-1.1-0.9-1.7
		c-0.1-0.2-0.1-0.3-0.2-0.5C242.8,79.3,242.7,79.2,242.7,78.9z M242.7,77.3c0.4,0.9,0.9,1.9,1.4,2.7c0.1,0.2,0.2,0.3,0.3,0.5
		c0.4,0.7,0.9,1.4,1.4,2c0.1,0.2,0.3,0.3,0.4,0.5c1.9,2.3,4.2,4.2,6.9,5.5c-0.4,0.3-0.6,0.8-1.1,0.5c-2-1-3.9-2.8-5.2-4.5
		c-0.4-0.5-0.7-1.1-1.1-1.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c-0.4-0.5-0.6-1.1-1-1.6c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.1-0.2-0.2-0.4-0.4-0.6C243,79,242.6,78,242.7,77.3z M240.9,54.1c0.2-0.6,0.5-1.6,0.8-2.2c0.4-0.8,0.8-1.7,1.3-2.5
		c0.3-0.4,0.5-0.7,0.9-1c0.3-0.3,0.5-0.8,0.8-1.1c0.5-0.5,0.9-0.6,1.3-1.3c0.7-1.4,1.8-2.6,2.4-4.1c0.6-1.4,1.1-3.8,0.7-5.4
		c0.7,0.6,1,1.8,0.8,2.6c-0.3,1-0.5,2.2-0.9,3.2c-0.5,1.4-1.1,3-1.9,4.3c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.4-0.3,1.1-0.8,1.2
		c0,0.1-0.2,0.8-0.4,0.9c-0.5,0.2-0.4,0.7-0.8,1c0,0-0.5,0.5-0.5,0.4c-0.1,0.1,0,0.3-0.1,0.4c0.1,0-0.2,0.3-0.4,0.3
		c0,0.5-0.5,0.8-0.6,1.2c-0.1,0.1-0.1,1.1-0.1,1.4c-0.3-0.1,0,1.1,0,1.2c0.2,0.9,0.4,1.7,0.5,2.6c0.3,2.2,0.7,4.1,0.7,6.4
		c0,0.9,0,1.6-0.3,2.5c-0.1,0.5-0.2,1.1-0.3,1.6c-0.2-0.1-0.3,1.3-0.3,1.6c-0.3-0.2-0.4,0.2-0.1,0.4c0.3,0.2-0.2,3.2-0.3,3.9
		c-0.1,0.6-0.1,0.2-0.2,0.7c-0.1,0.2,0.1,0.2,0.1,0.5c-0.1,0.7-0.2,1.4-0.4,2c0,0.2-0.1,0.3-0.1,0.5c0,0.1-0.1,0.3-0.1,0.4
		c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4-0.1-0.5c-0.1-0.8-0.2-1.6-0.2-2.4c0-1,0.2-2,0.2-3c0-1.9,0.2-3.8,0.3-5.7c0.2-2,0.3-4.1,0-6.1
		c-0.1-1-0.4-1.8-0.8-2.7C241.4,55.8,240.7,54.8,240.9,54.1z M239.1,48.7c0.2-1,1-1.5,1.4-2.4c0.7-1.5,2.2-2.5,2.8-3.9
		c1.3-2.8,2.1-5.8,0.5-8.6c0.5,0.3,1.1,0.6,1.6,0.8s1.3,0.3,1.6,0.7c0.6,0.8,0.5,2,0.6,2.9c0.1,2.3-1,4.5-2.1,6.5
		c-0.3,0.7-0.8,1.2-1.3,1.7c-0.4,0.3-0.6,0.9-1,1.2c-0.2,0.1-0.4,0.6-0.6,0.7c-0.4,0.4-0.9,1-1.3,1.4c-0.9,0.7-1.2,1.7-1.6,2.7
		c-0.2-0.5-0.5-0.8-0.8-1.3C239.2,50.7,239,49.3,239.1,48.7z M237.2,46.6c0.2-0.3,0.3-0.7,0.5-1c0.4-0.6,1.1-1.3,1.7-1.7
		c0.8-0.7,1.9-1.8,2-2.9c0.1-0.6,0.4-1.2,0.6-1.8c0.1-0.4,0.3-0.8,0.3-1.2c-0.1-0.5-0.2-1.1-0.3-1.6c-0.3-1-0.5-1.9-1-2.8
		c-0.4-0.7-0.9-1.3-1.2-2.1c1.6,0.7,2.6,2.8,3,4.4c0.3,1.4,0.4,3-0.1,4.4c-0.6,1.6-1.6,3.1-3,4.1c-1.1,0.9-2.1,2.3-2.5,3.6
		C237,47.5,236.9,47.3,237.2,46.6z M238.6,31.9c0.7,1.2,1.4,2.3,1.7,3.6c0.3,1,0.6,2.7,0.2,3.7c-0.5,1.2-0.7,2.2-1.8,3.1
		c-0.9,0.8-2.1,1.6-2.7,2.6c-0.4-1.3-0.8-2.6-0.9-4c-0.1-1.2,0.3-2.4,0.4-3.7c0.1-2.1,1.5-3.6,2.7-5C238.4,32.2,238.5,32,238.6,31.9
		z M238.5,31.1C238.6,31.1,238.6,31.2,238.5,31.1L238.5,31.1z M239.9,32.8c0.5,0.8,1,1.5,1.3,2.4c0.5,1.5,1.1,3.4,0.2,4.9
		c-0.3,0.6-0.4,1.2-0.7,1.8s-0.9,0.9-1.1,1.4c-0.2,0.5-1.1,1-1.5,1.4c-0.6,0.6-1,1.3-1.3,2.1c-0.3-0.8-0.6-1.3,0-2
		c0.6-0.6,1.1-1.2,1.7-1.7c1.3-1.1,2.2-2.3,2.5-4c0.3-1.5-0.1-3.3-0.6-4.8c-0.3-0.9-0.7-1.7-1.3-2.4c-0.2-0.3-0.1-0.7-0.5-0.8
		c0.1,0,0.2,0,0.3,0.1C239.4,31,239.7,32.4,239.9,32.8z M241.4,32.3c1,0.5,1.8,1,2.3,1.9c0.4,0.8,0.8,1.7,0.8,2.7
		c0,1.6-0.7,3.2-1.2,4.7c-0.1,0.4-0.4,1-0.7,1.3c-0.7,0.8-1.3,1.6-1.9,2.4c-0.5,0.7-1.1,1.5-1.5,2.2c-0.6,1-0.6,2-0.6,3.1
		c-0.3-0.5-0.6-1.1-0.9-1.6c0-0.4-0.1-0.6,0.1-1c0.2-0.3,0.4-0.7,0.6-1c0.4-0.8,0.8-1.2,1.4-1.8c0.5-0.6,1.1-1,1.6-1.6
		c0.6-0.7,1-1.4,1.4-2.2C244.3,38.3,243.3,34.9,241.4,32.3z M247.9,35.6c1.5,1.2,0.7,3.6,0.4,5.3c-0.4,1.9-1.7,3.1-2.5,4.8
		c-0.3,0.7-0.7,0.9-1.3,1.4c-0.3,0.3-0.5,0.8-0.8,1.1c-0.4,0.3-0.7,0.7-1,1.1c-0.9,1.4-1.7,3.1-2.2,4.7c-0.2-0.4-0.4-0.7-0.6-1.1
		c0.4-0.7,0.6-1.6,1.1-2.3s1.2-1.3,1.7-1.9c0.4-0.1,0.3-0.5,0.6-0.7c0.4-0.2,0.6-0.5,0.8-0.9c0.3-0.4,0.7-0.8,1.1-1.2
		c0.6-0.5,0.9-1.4,1.2-2.1c0.5-1,0.9-2,1.2-3c0.5-1.6,0.6-3.9-0.1-5.4C247.7,35.3,247.8,35.4,247.9,35.6z M292.3,20.5
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-1.2-0.8-2.4-1.7-3.6-2.6c-0.6-0.5-1.2-0.9-1.8-1.3c-0.6-0.4-1.3-0.8-1.9-1.1
		c-1.2-0.6-2.5-1-3.7-1.2c0.5-0.2,1.2,0.2,1.7,0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.2,1.1,0.4c0,0,0.1,0.1,0.2,0.1
		c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.7,0.3c0.4,0.2,0.8,0.3,1,0.2h0.1c0,0.3,0.9,0.7,1.7,1.1c0.5,0.2,1,0.4,1.2,0.6
		c0.9,0.6,1.7,1.2,2.6,1.8c0.9,0.6,2.2,1.4,3.3,1.6c0.6,0.1,0.7,0,0.5,0.7c-0.1,0.4-0.4,1.3-0.9,1.2C294,21.9,293.2,21.1,292.3,20.5
		z M294.4,23.6c-0.5-0.2-0.9-0.5-1.4-0.8c-0.2-0.1-0.4-0.2-0.5-0.3c-1.6-1.1-3-2.3-4.5-3.5c-0.1-0.1-0.3-0.2-0.4-0.3
		c-0.3-0.2-0.6-0.5-1-0.7s-0.7-0.5-1-0.7c-0.6-0.5-1.2-1-1.9-1.4c-1.5-0.8-2.8-1.3-4.4-1.7c1.2-0.5,3.2,0.6,4.3,1.1
		c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,1.1,0.6,1.6,1c0.3,0.2,0.6,0.5,0.9,0.7c1.5,1.2,3,2.3,4.6,3.4c0,0,0.1,0,0.1,0.1
		c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.3,0.9,0.5,1.3,0.8c0.4,0.2,0.7,0.4,1.1,0.5C295.1,22.6,294.5,23.7,294.4,23.6z M293.8,24.9
		c-0.1,0.1-0.2,0.3-0.3,0.3l0,0c-0.3-0.2-0.2-0.2-0.4-0.2c-1-0.4-1.9-1.2-2.7-1.9c-0.3-0.2-0.5-0.4-0.7-0.6c-0.1,0-0.1-0.1-0.2-0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6c-0.9-0.7-1.8-1.4-2.6-2.2c-2.3-2.1-4.9-4.2-8.1-4.8c1.5-0.7,3.4,0.5,4.8,1.2
		c1.6,0.8,3,1.9,4.4,3c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.2,0.5,0.4,0.8,0.6c1.3,1.1,2.5,2.2,3.9,3c0,0,0.1,0,0.1,0.1
		c0.2,0.1,0.4,0.2,0.6,0.4c0.3,0.2,0.7,0.4,1,0.6C294.5,24.1,294.1,24.5,293.8,24.9z M292.7,26.2c-0.1,0.1-0.3,0.3-0.4,0.4l-0.1,0.1
		c-0.2-0.3-1-0.7-1.4-1.1c-0.6-0.7-1.2-1.4-1.8-2c-1.2-1.3-2.6-2.5-3.9-3.7c-2.3-2.1-5-4.3-8.1-4.7c1.1-0.5,2.9,0.4,3.9,1
		c1.7,0.9,3.2,2.2,4.7,3.5c1.2,1,2.3,2,3.5,3c0.2,0.2,0.5,0.4,0.7,0.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2
		c0.4,0.3,0.7,0.6,1.1,0.9c0.6,0.5,1.2,0.9,2,1.3C293,25.8,292.9,26,292.7,26.2z M290.4,27.3c-0.2-0.3-0.4-0.6-0.6-0.8
		c-0.6-0.7-1.1-1.3-1.6-2c-1-1.4-2.4-2.5-3.6-3.7s-2.6-2.6-4.1-3.4c-1.3-0.7-2.9-1.7-4.4-1.7c1-0.6,2.9,0.4,3.8,0.8
		c1.1,0.5,2,1,2.9,1.8c3.2,2.7,6.1,5.8,9.1,8.7c-0.1,0.1-0.2,0.1-0.3,0.2C291.1,27.4,290.7,27.7,290.4,27.3z M289,28.1
		c-0.3-0.2-0.4-0.6-0.6-0.8c-0.7-0.8-1.3-1.7-2-2.5c-1.3-1.4-2.6-2.9-4-4.2c-1.2-1.1-2.6-2.2-4-3.1c-1.1-0.7-2.4-1-3.6-1.3
		c1.5-0.8,3.2,0.4,4.6,1c1.5,0.7,2.7,1.8,4,2.9c1.3,1.2,2.6,2.3,3.7,3.6c1.1,1.3,2,2.6,3.1,3.9c-0.1,0,0,0-0.1,0.1
		C289.7,28,289.3,28.4,289,28.1z M288.3,28.7c-0.2-0.5-0.5-0.7-0.9-1.1c-0.6-0.7-1-1.5-1.6-2.1c-1.2-1.4-2.4-2.8-3.7-4
		c-1.1-1.1-2.4-1.8-3.5-2.8c-1.3-1-2.6-1.7-4.2-2c0.7-0.3,2.7,0.6,3.4,1c1.4,0.7,2.6,1.8,3.8,2.8c1.5,1.2,2.7,2.7,4,4
		c1.2,1.2,2.1,2.7,3.3,4c-0.1,0.1-0.1,0.1-0.2,0.1C288.5,28.6,288.4,28.7,288.3,28.7z M286.4,27.2c-0.5-0.7-1.1-1.4-1.8-2
		c-1.1-0.9-2.2-2-3.3-3c-2.4-2-4.7-4.8-8.1-5c1-0.7,2.8,0.3,3.8,0.8c1.2,0.6,2.4,1.8,3.5,2.6c1.6,1.3,2.9,2.7,4.3,4.2
		c0.6,0.7,1.1,1.3,1.6,2c0.4,0.7,1.1,1.3,1.5,1.9C287.2,28.9,286.7,27.7,286.4,27.2z M285.9,28.6c-0.3-0.4-0.7-0.8-1.1-1.3
		c-0.6-0.7-1.3-1.4-2-2c-0.7-0.7-1.4-1.3-2.1-1.9c-0.6-0.5-1.2-1.3-1.9-1.6c-0.7-0.3-1.2-1-1.9-1.4c-0.5-0.4-1-0.8-1.6-1.1
		c-0.6-0.3-1-0.7-1.8-0.9c-0.6-0.1-1.1-0.5-1.7-0.6c3.2-1.1,6.6,2.4,8.8,4.3c1.2,1,2.2,2.1,3.4,3.1c1.3,1.1,2,2.4,3,3.7
		C286.3,29.2,286.5,29.2,285.9,28.6z M283.9,27.9c-0.6-0.7-1.4-1.3-2.1-2c-1.2-1.1-2.4-2.2-3.6-3.2c-1-0.8-2.1-1.4-3.1-2.1
		c-0.7-0.6-1.6-1-2.5-1.4c-0.7-0.4-1.5-0.4-2.2-0.7c0.7-0.3,1-0.4,1.8-0.2c0.3,0.1,0.8,0.3,1,0.3c0.1,0.2,0.9,0.4,1.3,0.6
		c0.4,0.3,1,0.5,1.3,0.8c1.1,0.8,2.4,1.6,3.4,2.6c2.4,2.2,5,4.2,6.9,6.9c-0.1,0.1-0.3,0.1-0.4,0.1C285.2,29.6,284.2,28.3,283.9,27.9
		z M283,28.2c-0.6-0.7-1.4-1.4-2.1-2c-1.5-1.3-3-2.4-4.6-3.7c-0.4-0.4-0.9-0.7-1.4-1c-0.6-0.4-1.3-1-1.9-1c-0.8-0.1-1.4-0.9-2.2-0.9
		c-0.5,0-1.1-0.4-1.7-0.5c1.6-0.7,3.8,0.8,5.1,1.6c1.1,0.6,2.1,1.4,3.2,2.1c1.5,1,2.8,2.3,4.1,3.5c0.7,0.6,1.4,1.2,2,1.9
		c0.4,0.4,0.7,0.8,1.1,1.1c0.2,0.3-0.6-0.2,0.1,0.4C284.3,29.9,283.4,28.5,283,28.2z M282.9,30.1c0.1,0,0.2-0.1,0.3-0.2
		c-0.5-0.9-1.1-1.6-1.9-2.3c-0.7-0.6-1.2-1.4-2-1.9s-1.5-1.3-2.3-1.9c-0.4-0.4-1-0.6-1.5-0.9c-0.6-0.3-1.1-0.7-1.7-1
		c-0.7-0.3-1.2-0.8-1.9-1.1c-1.5-0.6-2.9-1-4.5-1.1c0.7-0.2,1-0.4,1.7-0.3c0.3,0,0.8,0.1,1.1,0.3c0.7,0.3,1.4,0.5,2.1,0.9
		c0.3,0.2,0.6,0.2,1,0.3c0.4,0.1,0.7,0.5,1,0.7c0.4,0.2,0.8,0.6,1.3,0.8c0.8,0.3,1.6,1.1,2.2,1.6c0.9,0.6,1.6,1.4,2.4,2.1
		c1.5,1.2,2.7,2.6,4,3.9c-0.2,0-0.5,0.1-0.7,0.1C283.2,30,283,30.1,282.9,30.1z M268.3,20.2c0,0,0,0.1-0.1,0.1
		c-0.3-0.1-0.6-0.1-0.9-0.1s-0.4,0-0.2,0C267.6,20.1,267.9,20.1,268.3,20.2z M270.2,20.8c-0.5-0.3-1.3-0.3-1.7-0.6
		c1.3,0.1,2.4,0.6,3.5,1.1c0.5,0.2,0.9,0.6,1.4,0.9c0.5,0.2,1.1,0.4,1.5,0.8c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0.2-0.1,0.3
		c-0.7-0.4-1.3-0.7-1.9-1.2c-0.2-0.2-0.4-0.2-0.6,0c-0.2-0.1-0.3-0.2-0.5-0.2C271.3,21.4,270.8,21.1,270.2,20.8z M281.5,30
		c-0.2-0.4-0.5-0.8-0.7-1.2c-0.4-0.8-1-1.4-1.6-2c-1.2-1.3-2.8-2.3-3.9-3.7c0.3,0.3,1.1,0.7,1.5,0.9c0.8,0.4,1.4,1.2,2.1,1.7
		c1.5,1.1,3.2,2.6,4,4.2C282.3,30.2,281.7,30.5,281.5,30z M280,30.7c0,0-1.4-2.6-1.6-2.8c-0.7-1-1.2-2.1-1.9-3
		c1.9,1.4,3.7,3.4,4.7,5.5C280.8,30.5,280.4,30.6,280,30.7z M278.6,30.4c-0.3-0.4-0.4-0.8-0.6-1.2c-0.4-0.8-1-1.5-1.4-2.4
		c-0.6-1.5-1.8-2.5-2.7-3.7c1.8,0.9,2.4,2.5,3.6,4c0.6,0.7,1,1.6,1.5,2.4c0.3,0.5,0.3,0.8,0.5,1.3C278.9,31,279,30.8,278.6,30.4z
		 M277.6,30.4c-0.1-0.3-0.3-0.5-0.4-0.8c-0.3-0.9-1-1.6-1.4-2.4c-1-1.8-2.2-3.6-3.7-4.9c0.6,0.3,1,0.5,1.4,1
		c0.3,0.4,0.6,0.7,0.9,1.1c0.5,0.6,1,1.2,1.5,1.9c0.6,0.7,1,1.7,1.5,2.5c0.5,0.7,0.8,1.6,1.3,2.3C278,31.2,277.8,31,277.6,30.4z
		 M276.8,31.1c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3-0.5-0.6-0.9-0.9-1.4c-0.6-0.9-1.1-1.9-1.7-2.8c-1.4-2-2.5-3.9-4.4-5.4
		c2.3,1,3.9,3.3,5.2,5.4c0.6,1,1.4,2,1.9,3.1c0.3,0.6,0.5,1.3,0.9,1.8C277.1,31.3,277,31.6,276.8,31.1z M276.1,31.6
		c0,0-1.9-3.5-2.1-3.8c-0.7-1-1.4-2-2.1-3c-1.6-2.3-3.8-3.9-6.5-4.5c1.2-0.2,2.8,0.1,3.8,0.7c1.5,0.9,2.4,2.3,3.4,3.7
		c1,1.4,1.8,2.8,2.7,4.2C275.4,29.5,277.1,31.6,276.1,31.6z M274.7,30.5c-0.4-0.7-0.8-1.3-1.2-1.9c-0.9-1.2-1.9-2.4-2.9-3.5
		c-1.8-2.1-3.8-3.8-6.6-4.4c1.2-0.2,2.8,0.5,3.9,1c1.2,0.6,2.5,2,3.3,3.1c0.9,1.2,1.8,2.4,2.6,3.6c0.4,0.7,0.8,1.3,1.2,2
		c0.2,0.3,0.4,0.6,0.5,0.9c0,0.3-0.4-0.1-0.1,0.3C275.1,31.6,274.9,30.8,274.7,30.5z M274.8,31.6c-0.2,0-0.9-1.3-1.1-1.6
		c-0.4-0.6-0.9-1.1-1.3-1.7c-1.1-1.4-2.2-2.8-3.5-4c-2.1-2-4.3-2.9-7.1-3.4c1.3-0.3,2.8,0,4,0.7c0.7,0.4,1.5,0.7,2.1,1.2
		c0.6,0.4,1,1,1.5,1.5c1.2,1.2,2.3,2.6,3.3,3.9c0.5,0.7,1,1.5,1.5,2.2C274.4,30.7,274.8,31.6,274.8,31.6z M273.5,31.7
		c-0.6-1.5-1.8-2.8-2.9-4c-1.1-1.1-2.1-2.4-3.4-3.3c-1-0.7-1.8-1.5-2.9-1.8c-1.2-0.7-3.2-1-4.6-0.8c0.8-0.8,1.9-0.5,2.8-0.3
		c0.6,0.1,1.3,0.2,1.8,0.4c0.3,0.1,0.8,0.3,1,0.3c0.2,0.4,1.5,0.9,1.8,1.1c1.1,0.8,2.1,1.9,3,2.9s1.9,2.1,2.6,3.3
		c0.4,0.6,1.5,1.5,1.5,2.2C274,31.7,273.7,31.7,273.5,31.7z M271.6,31.1c-0.2-0.2-0.3-0.5-0.4-0.7c-0.4-0.8-1.2-1.5-1.8-2.1
		c-1.1-1.3-2.4-2.4-3.8-3.4c-0.5-0.4-1-0.8-1.6-1.1c-0.4-0.2-0.9-0.3-1.3-0.5c-0.6-0.3-1.5-0.4-2.1-0.6c-0.8-0.2-1.4,0.1-2.2,0.2
		c0.6-1.1,2.7-0.6,3.7-0.4c1.5,0.3,3.1,0.8,4.3,1.9c1.3,1.1,2.5,2.3,3.7,3.5s2.1,2.6,2.9,3.9c-0.1,0-0.3,0-0.4,0
		C272,31.5,271.9,31.6,271.6,31.1z M269.7,29.9c-0.5-0.6-1.1-1.1-1.6-1.7c-0.6-0.7-1.3-1.4-2-2c-0.5-0.4-1.1-0.7-1.6-1.1
		c-0.7-0.6-2.1-1.3-3-1.4c-0.7-0.1-1.4-0.2-2.1-0.1c-0.6,0-1-0.3-1.6-0.1c1.1-1.1,3-0.3,4.3-0.1c0.4,0.1,0.9,0.3,1.4,0.5
		c0.4,0.1,1.1,0.5,1.4,0.8c0.6,0.6,1.4,1.2,2.1,1.6s1.3,1.2,1.9,1.8c0.7,0.7,1.2,1.4,1.9,2.2c0.4,0.4,0.3,1,0.5,1.2
		c-0.1,0-0.1,0-0.2,0C270.4,31.5,270.1,30.3,269.7,29.9z M269.3,31.2c-0.1-0.3-0.4-0.5-0.6-0.7c-0.5-0.5-1-1.1-1.6-1.7
		c-1-1-2.3-2.3-3.6-3c-0.4-0.2-0.8-0.4-1.3-0.5c-0.1,0-1-0.3-0.9-0.3c-1.3-0.2-2.7-0.9-4.1-0.6c0.3-0.6,0.8-0.7,1.5-0.5
		c0.6,0.2,1.4,0.1,2,0.2c0.9,0.1,2.7,0.4,3.4,1.1c0.4,0.4,1,0.7,1.5,1c0.6,0.4,1.2,1,1.7,1.6c0.6,0.7,1.3,1.3,1.9,2
		c0.5,0.5,0.7,1.1,1,1.6C269.7,31.4,269.5,31.7,269.3,31.2z M266.8,30.3c-0.3-0.5-0.6-0.9-1-1.3c-0.8-0.8-1.8-1.4-2.5-2.3
		s-2-1.2-3.1-1.5s-2.3-0.4-3.3,0c0.2-0.7,0.8-0.6,1.4-0.6s1.4,0.2,1.9,0.3c0.3,0.1,2.2,0.8,2.4,0.6c0,0.3,0.8,0.5,1.1,0.8
		c0.6,0.5,1.2,0.8,1.7,1.3c0.7,0.7,1.4,1.3,2.1,2c0.4,0.5,1.1,1,1.4,1.6c-0.4-0.1-0.7-0.2-1.1-0.3C267.3,31,267.1,30.9,266.8,30.3z
		 M265.7,30.2c-0.3-0.5-0.6-0.9-1-1.3c-0.9-0.8-1.9-1.6-3-2.2c-1.3-0.6-4-1.5-5.2-0.4c0.9-1.7,3.9-0.7,5.2-0.1
		c1,0.4,1.8,1.3,2.7,2.1c0.5,0.4,0.9,0.8,1.3,1.3c0.4,0.4,0.6,1,1.1,1.4C266.1,31,266.1,30.9,265.7,30.2z M264.7,31.1
		c0.7-0.2-0.7-1.3-0.9-1.5c-0.7-0.9-1.5-1.5-2.5-2c-1.5-0.8-3.3-1-4.9-0.5c0.6-1.4,3.6-0.8,4.7-0.3c1.9,1,3.6,2.3,4.6,4.2
		C265.4,31,265,31.1,264.7,31.1z M261.1,28.3c-1-0.5-1.7-0.8-2.8-0.8c-0.7,0-1.5,0.1-2.2,0.2c0-0.5,1.9-0.6,2.4-0.6
		c0.8,0,1.7,0.5,2.4,0.5c0.3,0.5,1.4,0.8,1.9,1.2c0.4,0.3,0.7,0.7,1,1.1C263,29.3,261.9,28.7,261.1,28.3z M264.2,30.9
		c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0.2,0.3,0.3,0.4,0.5C264.3,30.8,264.3,30.9,264.2,30.9z M264.3,31c0.1,0.1,0.2,0.1,0.3,0.1
		C264.4,31.2,264.3,31.2,264.3,31z M263.1,31.1c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.2-0.5-0.4-0.7-0.7c-0.5-0.6-1.1-0.8-1.9-1
		c-1.2-0.3-2.3-0.9-3.6-0.8c0.9-0.1,1.9-0.3,2.7-0.1c0.6,0.2,1.3,0.6,1.9,0.9c0.8,0.4,2.4,1.3,2.6,2.3
		C263.6,31.1,263.3,31.1,263.1,31.1z M260.4,30.1c-0.3-0.3-0.8-0.5-1.3-0.7c-1-0.4-1.8-0.7-2.8-0.8c1.1-0.4,2.6,0.3,3.6,0.6
		c1.1,0.4,1.9,0.9,2.7,1.8c-0.4-0.1-0.8-0.1-1.1,0C260.9,31,260.8,30.5,260.4,30.1z M256.1,28.2L256.1,28.2c0.1-0.1,0.3-0.1,0.4-0.1
		C256.3,28.1,256.2,28.2,256.1,28.2z M256.2,28.6c-0.2,0-0.3,0-0.1-0.3C256.1,28.4,256.1,28.5,256.2,28.6z M259.8,31.3
		c-0.1-0.2-0.3-0.4-0.4-0.5c-0.3-0.3-0.7-0.6-1.1-0.9c-0.7-0.5-1.6-0.7-2.5-0.7c0.3-0.6,2.3,0.2,2.8,0.5c0.9,0.4,1.5,0.7,2.1,1.4
		C260.4,31.1,260.1,31.2,259.8,31.3z M258.4,31.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.3-0.3-0.7-0.5-1-0.7c-0.5-0.3-1-0.6-1.5-0.7
		c0.3-0.4,1.8,0.2,2.2,0.4c0.6,0.3,1.3,0.8,1.7,1.3C258.9,31.6,258.7,31.9,258.4,31.5z M257.4,31.6c-0.2-0.2-0.5-0.4-0.8-0.6
		c-0.4-0.3-1-0.6-1.5-0.7c0.7-1,2.8,1.4,3.2,1.6C257.7,32.2,257.8,32,257.4,31.6z M256.9,32.6c-0.2-0.8-1.5-1.5-2.2-1.8
		c0.2-0.4,1.1,0.3,1.3,0.3c0.2,0.4,1.1,0.7,1.4,1.2C257.3,32.4,257.1,32.5,256.9,32.6z M255.3,33.7c-0.3-0.6-0.9-1-1.4-1.3
		c0.3-0.4,0.5-0.8,0.7-1.3c0.3,0.1,0.6,0.3,0.9,0.5c0.4,0.3,0.8,0.9,1.2,1.1c-0.2,0.1-0.4,0.3-0.6,0.4c0-0.4-1.2-1.4-1.6-1.5
		c-0.5,0.7,1.1,1.1,1.3,1.7C255.6,33.5,255.5,33.6,255.3,33.7z M254.4,34.3c-0.1-0.4-0.5-0.7-0.9-0.9c-0.3-0.2,0-0.5,0.2-0.8
		c-0.2,0.2,1,1,1.2,1.3C254.8,34.1,254.6,34.2,254.4,34.3z M253.7,34.8c0-0.3-0.6-0.6-0.9-0.8c0.1-0.1,0.2-0.3,0.3-0.4
		c0.4,0.2,0.8,0.6,1,0.9C254,34.6,253.8,34.7,253.7,34.8z M250.3,35.7c1.1-0.1,1.9-0.8,2.6-1.6c-0.4,0.4,0.4,0.9,0.7,0.9
		c-0.2,0.1-0.3,0.2-0.5,0.3C252.3,35.8,251.2,35.9,250.3,35.7z M245.7,33.8c0.6-0.2,1.3-0.9,1.8-1.2s0.7-0.9,1.2-1.2
		c0.6-0.4,1.5-1.1,1.9-1.8c0.3-0.5,1-0.7,1.1-1.3c0.2-1.1,0.9-2.1,1.4-3c0.7-1.4,1.5-2.5,2.9-3.3c-0.5,0.6-1.1,1.1-1.6,1.7
		c-0.4,0.5-0.6,1.4-0.9,2c-0.6,1.1-1.3,2.2-1.8,3.3c-0.6,1.2-1.9,2.2-2.9,3.1c-0.6,0.5-1.2,1.1-1.8,1.7c-0.2,0.2-0.6,0.4-0.9,0.3
		C245.9,33.9,245.8,33.8,245.7,33.8z M241.4,31.9c0.3,0.1,0.5,0.2,0.8,0.4C242,32.3,241.7,32,241.4,31.9z M240.7,31.5
		c0.1,0.1,0.2,0.2,0.4,0.2C241.1,31.7,240.4,31.5,240.7,31.5z M244.1,33.4c0.1,0.1,0.1,0.1-0.2-0.1C243.9,33.3,244,33.3,244.1,33.4z
		 M240.5,31.5c-0.5-0.4-0.2-0.1-0.3-0.1c-0.2-0.1-1.1-0.4-1-0.8C239.6,30.8,240.4,31,240.5,31.5z M247.9,22.7
		c-0.6,0.9-1,2.3-1.2,3.3c-0.2,0.9-1.1,1.9-1.7,2.6c-1.3,1.6-3.4,2.2-5.4,1.4l-0.1,0.2c-0.4-0.2,2.4-3.5,2.7-3.7
		c1.5-1.2,3-2.3,4.6-3.2C247.1,23,247.5,22.9,247.9,22.7z M249,22.3c-0.5,1.2-0.5,2.4-0.8,3.6c-0.3,1.1-1.1,2.2-1.9,3
		c-0.9,0.8-2.1,1.4-3.2,1.9c-0.6,0.3-1.4,0.4-2,0.4c-0.4,0-0.7-0.4-1.1-0.6c1.1,0.1,2.2,0.4,3.3-0.1c0.7-0.3,1.1-0.9,1.7-1.4
		c0.8-0.6,1.9-2,2.1-3c0.2-0.9,1.1-2.2,1-3c0.2,0,0.4-0.5,0.6-0.7C248.8,22.3,248.9,22.3,249,22.3z M255.3,20.9
		c-1.4,0.9-2.4,1.7-3.1,3.2c-0.8,1.8-2.1,3.3-3.1,5c-0.5,0.9-1.1,1.5-1.9,2.2c-0.6,0.5-1.3,0.6-1.9,1c-0.6,0.5-1.4,0.7-2.1,0.2
		c-0.6-0.4-1-0.7-1.7-1.1c2-0.2,4.3-1.4,5.6-2.9c0.7-0.7,1.3-1.9,1.5-2.9c0.1-0.6,0.6-2.5,0.4-2.9c0.3,0,0.3-0.5,0.5-0.8
		c0.3-0.1,0.5-0.2,0.8-0.3c1.2-0.4,2.4-0.4,3.6-0.6C254.4,21.1,254.9,21,255.3,20.9z M256.8,20.8c-0.9,0.8-1.9,1.3-2.7,2.1
		c-0.6,0.6-0.9,1.2-1.2,1.9c-0.3,0.6-0.8,1.1-0.9,1.8c-0.1,0.3-0.3,0.7-0.5,1c-0.1,0.3-0.1,0.7-0.3,0.9c-0.6,0.6-1.1,1.4-1.8,1.9
		c-0.2,0.2-1.1,1.3-1.2,1.3c-0.2,0-0.4,0.4-0.5,0.6c-0.2,0.2-0.6,0.3-0.9,0.5c-0.4,0.3-0.8,0.7-1.2,0.8c-0.3,0.1-0.3,0-0.5,0.1
		c-0.3,0-0.3-0.2-0.5-0.4c1.2-0.4,2.6-1.1,3.5-1.9c1.2-1.1,2-2.4,2.9-3.8c0.6-0.9,1.2-1.8,1.7-2.8c0.3-0.6,0.5-1.4,1-1.8
		C254.4,22.1,255.5,20.9,256.8,20.8z M278.6,13.6c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c-0.5,0.2-1.1,0.6-1.7,0.7
		c-0.2,0-0.7,0.5-1.1,0.5c-0.3,0.2-0.6,0.5-1.1,0.6c-0.1,0-0.5,0.3-0.4,0.3c-0.6,0.3-1.7,1.3-2.4,1c0,0.5-0.8,0.5-1.1,0.6
		c-0.5,0.3-1.1,0.6-1.6,0.8c-1.3,0.5-2.7,1-4.1,1.5c-0.8,0.3-2,0.6-2.8,0.3c-0.1,0,0,0.2-0.2,0.2c-0.5,0.1-0.9,0.2-1.4,0.3
		c-0.9,0.3-1.7,1.2-2.8,1.4c0.1,0,0.1,0,0.2,0.1c-1.6,1.7-2.2,4.2-2.7,6.3c-0.2,0.9-0.9,1.6-1.3,2.4c-0.4,0.9-1,1.6-1.4,2.4
		c-0.5,1-1.8,1.8-2.9,1.9c-1,0.1-1.7-0.3-2.6-0.7c-0.2-0.1-0.3-0.2-0.5-0.3c0.6-0.2,0.9-0.9,1.4-1.3c0.5-0.5,1.2-1,1.7-1.5
		c0.5-0.4,2.4-1.9,2.1-2.5c0.5-0.1,1.1-1.7,1.3-2.2c0.6-1.1,1-2.5,1.8-3.4c0.9-1,1.8-2.1,3-2.8c1.5-0.1,2.9-0.3,4.4-0.6
		c3-0.5,6-2,8.7-3.3c1.3-0.6,2.6-1.2,3.8-1.9C276,14.3,277.5,14.1,278.6,13.6z M281.8,12.7c-0.1,0.1-0.2,0.1-0.4,0.1
		c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.6,0-0.6,0c-0.2,0.1-0.1,0.2-0.4,0.3s-0.6,0.1-0.9,0.1c0.3-0.1,0.5-0.2,0.8-0.3
		c0.6-0.2,1.2-0.3,1.7-0.4C281.5,12.8,281.6,12.7,281.8,12.7z M283.9,13.2h0.1c0.2,0.1,0.4,0.1,0.6,0.2l0,0c0.7,0.3,1.4,0.6,2.1,1
		c1,0.5,2,1,2.9,1.5s1.8,1.1,2.7,1.5c1.4,0.7,2.6,1.7,4.1,2.1c-0.3,0.7-0.7,0.7-1.4,0.3c-1.4-0.7-2.7-1.4-4-2.3
		c-0.9-0.7-1.9-1.3-2.9-1.8c-0.4-0.2-0.8-0.3-1.1-0.5c-0.5-0.2-0.9-0.4-1.4-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.1,0-0.2,0-0.2-0.1c-0.3-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-0.7-0.2-1.1-0.3c-0.5-0.1-0.9-0.2-1.4-0.3c0.2-0.1,0.4-0.2,0.6-0.2
		c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0,0.4,0C283.3,13,283.6,13,283.9,13.2z M289.9,15.1c0.4,0.2,0.7,0.5,1.1,0.7
		c0.6,0.3,1.2,0.6,1.8,0.9c1.2,0.6,2.5,1.5,3.9,1.8c-0.2,0.6-0.3,0.7-0.9,0.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.3-1.3-0.6-1.8-1
		c-0.6-0.5-1.4-0.9-2.1-1.2c-1.4-0.7-2.8-1.4-4.2-2.1c-0.9-0.4-1.7-0.8-2.6-1.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.4-0.1C286,12.3,288.3,14.4,289.9,15.1z M289.1,13.5c0.8,0.7,1.9,1.1,2.9,1.6
		c0.9,0.4,1.8,0.9,2.7,1.3c0.7,0.3,1.5,1.1,2.3,0.9c0.1,0.1-0.1,0,0.2,0.1c-0.2,0.4-0.1,0.6-0.6,0.5c-0.2,0-0.4-0.1-0.6-0.2
		c-0.5-0.2-0.9-0.5-1.4-0.7c-1.1-0.6-2.2-1.1-3.4-1.7c-2-1.1-4-2.8-6.2-3.3c0.4,0,0.7-0.2,1.1-0.1c0.3,0.1,0.6,0.2,0.9,0.3
		C287.7,12.8,288.4,13,289.1,13.5z M297.5,16.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0-0.1,0-0.2-0.1c-0.7-0.3-1.3-0.4-1.9-0.8
		c-0.2-0.2-0.5-0.3-0.7-0.4c-0.8-0.4-1.5-0.8-2.3-1.2s-1.6-0.7-2.3-1.1c-1.1-0.7-2.1-1.5-3.4-1.8c0.8-0.2,1.6,0.5,2.3,0.9
		c0.6,0.3,1.2,0.6,1.8,0.9c0.8,0.4,1.6,0.7,2.4,1.1C294.2,15.3,296,16.6,297.5,16.5z M292.8,13.7c1.4,0.7,3.3,1.3,4.9,1.4
		c-0.1,0.4-0.1,1-0.4,1c-0.2,0-0.3-0.1-0.5-0.2c-0.5-0.1-1-0.2-1.5-0.5c-2.6-1.3-5.2-2.5-7.8-3.9c0.6-0.1,1.3,0.5,1.9,0.8
		c0.5,0.3,1,0.6,1.6,0.7C291.6,13.3,292.3,13.5,292.8,13.7z M289.8,12c0.4,0.1,0.9,0.2,1.3,0.3c0.9,0.2,1.7,0.5,2.6,0.7
		c1.4,0.4,2.8,1,4.3,1.1c-0.1,0.3,0,0.6-0.4,0.6c-1.5-0.1-3.2-0.6-4.6-1.3c-0.4-0.2-1.1-0.5-1.6-0.6c-1-0.3-2-0.8-2.9-1.3
		C288.8,11.4,289.5,11.9,289.8,12z M298.2,13.5c0,0.1-0.1,0.2-0.1,0.3c-1.5,0-2.9-0.7-4.3-1.1c-1.4-0.4-3.2-0.6-4.5-1.3
		c0.5,0,1-0.1,1.5-0.1l-0.1,0.1c0.4,0.3,1.2,0.3,1.7,0.4c0.7,0.1,1.3,0.4,2,0.4c0.8,0,1.8,0.8,2.6,0.7
		C297.1,13.2,297.8,13.4,298.2,13.5z"
      />
    </g>
    <path
      class="st1"
      d="M105.6,1.7c-1,0.1-2.2-0.6-3.1-0.6c-4-0.1-8.2,2.1-12.3,2.6c-9.1,1.3-17.4,0.5-24.6-2c0.2-0.1,0.4-0.2,0.7-0.3
	c-0.8-0.1-1.6-0.2-2.4-0.6c-0.4-0.2-0.7-0.3-1.1-0.6c-0.2-0.1-0.3-0.2-0.5-0.3c-1.6,0.9-3.5,0.9-5.8,1.9c-0.8,0.3-1.4,1.2-2.1,1.7
	C49,7,41.2,11.6,33,12.7c-4.5,0.6-7.6-1-12.4-0.6c-0.3,0.4,0.2,1.3-0.8,1.2c-6.5,1.3-18.4-2.1-18.4-2.1L0,17.6c0,0,8.3,0.6,12.3,1.2
	c2.2,0.3,4.1,0,6.4,0.1c4.8,0.2,9.4,2.2,15.5,0.2c1.9,1.3,6.3,1.1,7.4,4c1.6,4.1,1.8,5.4,1.7,4.9c-0.2-1.5,0.1-2.9,0.6-4.4
	c1.9,1.1,3.2,2.9,4.9,4.3c1.2,0.1,2.3,0.7,3.4,1.8c-0.7-1.3-0.9-2.6-0.6-4c-0.2,0-0.5,0-0.7-0.1c-3.2-2.4-6.6-4.5-9.3-7.6
	C49.4,14.5,55.1,9,64,6.6c4.9-1.3,8-0.4,13,0c3.8,0.3,7.7-0.5,11-0.3c1.2,0.1,2.3,0.8,3.4,0.8c3.1,0.2,5.9-0.7,9.1-1.2
	c7-1,11.9,1.4,18.2,2.7c2.9,0.6,5.8,0.7,8.6,1.2c5.8,1.1,10.9,2.1,16.2,3.9c2.6,0.8,5.4,1.4,7.8,2.4c1.1,0.4,2,1.4,3.1,1.9
	c3.7,1.6,7.5,2.1,11,3.5c6.4,4.3,13.5,7.5,20.3,11.1c1.8,1.9,4.2,3.7,6.3,6s5.3,4.9,6.1,7.8c3.4,11.4,10.8,18.6,18.6,23.2
	c0.3,0.6,0.6,1.3,1.1,1.8c0.3-0.2,0.4-0.4,0.9-0.4c-0.3-3.2-3-4.1-4.9-5.3c-5.8-3.6-9.9-10.3-13.4-17.4c-0.7-1.5-0.9-3.2-1.6-4.7
	c-1-2.1-3.7-4.3-5.2-6.5c-1.4-2.1-2-3.6-4.4-3.6c-7.7-6.6-18.9-9.4-26.1-15.7c7.1-0.5,14-0.1,21.1-1.2c3.2-0.5,6.4-1.8,9.6-2.3
	c3.4-0.5,7.2-0.3,10.8-0.3c3.7,0,7.4-0.2,10.6,0.3c6.7,0.9,3.2-1.5,2.7-1.3c-1.6,0.4-3.2-0.1-4.6-1.1c-0.9,0.2-1.9,0.3-3,0.1l-5.9-1
	c-5.5,1.2-11.9,0.8-17.3,1.5c-2.6,0.3-5.1,1.1-7.6,1.4c-9.2,1-15.7,1.5-23.3,0.2c-4.5-0.8-8.7-2.2-13.2-3.5
	c-4.3-1.2-8.5-3.1-12.8-4.1C123.7,5,117,4.2,110.6,2.8C108.9,1.8,108.3,1.6,105.6,1.7z"
    />
    <g>
      <path
        class="st1"
        d="M239.5,107.1c0.2-0.3-0.1-0.3-0.1-0.2c-0.1,0.2-0.4,0.3-0.6,0.3c-0.4,0-0.3-0.4-0.3-0.7c0-0.4-0.1-0.8-0.2-1.1
		c-0.1-0.6-0.3-1.2-0.5-1.8c0.3,0.5,0.5,1.1,0.8,1.7c0.2,0.4,0.5,1,0.4,1.4c0.1,0,0.2,0,0.2,0c0.1-0.5-0.3-1.1-0.5-1.5
		c-0.2-0.6-0.4-1.1-0.7-1.6s-0.5-1.1-0.8-1.6c-0.1-0.1-0.6-1-0.7-1c-0.2,0-0.5-0.6-0.6-0.7c-0.2-0.2-0.3-0.4-0.5-0.6
		c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.6-1-1.1-1.6-1.6c-0.4-0.3-0.7-0.8-1.1-1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.4-0.2-0.6-0.3
		c-0.9-0.7-1.7-1.4-2.5-2.1c-0.9-0.8-1.7-1.5-2.3-2.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.1-0.2-0.1-0.2-0.1-0.5c-0.1-0.3-0.4-0.7-0.7-0.8
		c0.1,0,0.2,0,0.3,0c-0.9-0.6-1.4-1.6-2-2.5c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.2,0-0.1-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.4-0.3-0.6-0.5c-1.1-0.8-2.3-1.5-3.5-2c-0.4-0.2-1-0.3-1.5-0.3s-1-0.1-1.4,0
		c-0.2-0.9,0.4-2,0.9-2.7c0.5-0.8,0.3-0.9-0.2-0.1s-1.1,1.8-0.9,2.7c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.4,0
		c-0.3,0-0.5-0.2-0.8-0.2s-0.7-0.1-1-0.1c-0.4,0-0.9,0-1.3,0.2c-0.8,0.3-1.7,0.5-2.5,1c-0.4,0.3-0.8,0.6-1.1,0.9
		c-0.3,0.2-0.5,0.6-0.8,0.6c0.2,0.6-0.9,0.7-1,1.1c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2,0.5-0.4,0.4c-0.1,0-0.2,0.1-0.2,0.2
		c0,0,0.1,0,0.1,0.1c-0.4,0.5-0.7,1.2-1.1,1.7c-0.4,0.5-0.6,1-1.2,1.3c0.3,0.4-1.2,1.6-1.5,1.8c-0.8,0.5-1.6,0.9-2.5,1.3
		s-1.8,0.7-2.7,1.1s-1.8,0.7-2.7,1.2c-0.2,0.1-0.4,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.8,0.5-1.2,0.8
		c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.6,0.5-0.9,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.5,0.7-0.7,1
		c-1,1.5-1.7,3.2-1.8,5c0,0.8,1.2,0.2,1.2,0.7c0.1,0,0.2,0,0.2-0.1c0.2,0.1,0.5,0.2,0.8,0.3c0.1,0,0.4-0.1,0.4,0.2
		c0.1,0,0.1,0.1,0.2,0c0-0.1,0-0.1,0-0.2c0.2,0,0.1,0.1,0.3,0.1c0.1,0,0-0.1,0.1-0.1c0.1-0.2,0.3,0,0.4,0c0.3,0,0.6,0,0.9,0
		c0.2,0,0.7-0.2,0.9,0c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.1-0.1,0.2-0.1l0,0c0.1,0,0.2,0,0.2-0.1h0.1c0,0.2,0.2,0.3,0.3,0.3c0-0.3-0.1-0.4,0.2-0.5c0.2-0.1,0.8-0.4,0.9-0.3
		c0.1,0.1,0.3-0.5,0.4-0.2c0.1-0.1,0.3,0,0.2-0.2c0.2-0.1,0.9-0.5,1-0.4c0.1,0,0.2,0.2,0.2,0.3c0-0.1,0-0.3,0-0.5
		c0.1-0.1,0.3-0.1,0.4-0.1v-0.1c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.5-0.5,0.7-0.3c-0.2-0.4,0.3-0.3,0.4-0.1c0-0.1,0-0.2-0.1-0.3
		c0.1-0.1,0.1-0.1,0.2-0.1h0.1c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.2,0.7-0.6,1-0.5c0-0.1,0-0.1,0-0.2c0.1,0.1,0.2-0.2,0.2-0.2
		c0.4-0.1,0.5-0.8,0.9-0.6c0.1-0.1,0.1-0.2,0-0.2c0.2-0.2,0.5-0.6,0.8-0.4c-0.2-0.2,0-0.4,0.2-0.4c0.2-0.4,0.8-0.9,1.1-1.2
		c0.1,0.1,0.1,0,0.2-0.1c-0.1-0.4,0.4-0.1,0.4-0.4c0,0,0,0-0.1,0c-0.1-0.4,0.4-0.1,0.6-0.1c-0.1-0.2,0-0.3-0.2-0.3
		c0-0.1,0.2-0.3,0.2-0.3c0.2-0.2,0.1-0.1,0.3,0c0.1,0.1,0.2-0.2,0.1-0.3h-0.1c-0.1-0.1,0.1-0.2,0.2-0.3c0,0.2,0.1,0.3,0.3,0.4
		c0.1-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0.2-0.3c0,0.1,0,0.2,0.2,0.1c0-0.1,0-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.3
		c0.2-0.3,0.5-0.5,0.7-0.7s0.5-0.7,0.9-0.7c0-0.4,0.6-0.6,0.9-0.7c0.5-0.3,1.1-0.6,1.7-0.8c0,0.1,0.1,0.1,0.2,0.1
		c0.1-0.2,0.2-0.5,0.5-0.3c0.1-0.5,1.7-0.7,2.2-0.9c0.7-0.3,1.2-0.9,1.4-1.7c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0,0,0.5,0,0.6
		c0.2,0.6,0.6,0.9,1.1,1.2c0.4,0.2,0.8,0.5,1.2,0.7c0.3,0.2,1.2,0.5,1.3,0.8c0.2,0,1,0.7,0.7,0.7c0,0.1,0,0.3,0.1,0.3
		c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.2,0.3,0.3,0.3,0.5c0,0.1,0.1,0,0.1,0c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.2,0.6,0.3,0.8
		c0-0.1,0.2,0.2,0.2,0.1c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0-0.1,0.4-0.1,0.5c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0.3,0.2,0.3,0,0.6
		c0,0,0-0.2-0.1,0.1c0,0.1,0,0.3,0,0.5c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0.3,0.2,0.8,0.1,1.2l0.1-0.1c0.2,0.3,0.2,0.5,0.3,0.8l0.1-0.1
		c0.3,0.8,0.8,1.6,1.1,2.3c0.1,0.2,0.1,0.2,0.2,0.4c0.1,0.2,0.3,0.3,0.5,0.5s0.6,0.6,0.5,0.9c0.2-0.3,0.3,0.1,0.4,0.2
		c0.2,0.2,0.4,0.5,0.6,0.7c-0.2,0.2-0.1,0.4,0.1,0.2c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.1,0.3,0.2,0.7c0-0.1,0.1-0.1,0.2-0.2
		c0.2,0.2,0.1,0.4,0.1,0.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.4,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.1c0,0.2-0.1,0.4-0.1,0.6
		c0.3-0.2,0.3-0.4,0.5-0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.1-0.2-0.1,0.2c0.1,0.1,0.2,0.1,0.2,0l0.5,0.5c0,0.1-0.1,0.3-0.1,0.3
		c0.4-0.3,0.5-0.1,0.9,0.2c0,0.1,0.8,0.5,0.8,0.7c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.7,0.2h0.1h0.1
		c0.3,0,0.8,0,0.8,0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0,0.3,0,0.5,0s0.2,0.2,0.4,0.2v-0.1c0.4,0,0.9-0.1,1.3,0c0.1-0.2,0.4-0.2,0.4,0
		c0,0,0.1,0.1,0.2,0.1c0-0.1,0-0.3,0-0.3c0.1,0,0.3-0.1,0.3,0l0.1,0.1c0.1,0,0.4-0.5,0.4-0.2c0,0.2-0.1,0.4,0.2,0.5
		c0.2,0,0.2-0.2,0.2-0.3c0-0.3,0.1-0.2,0.4-0.3c0.2,0,0.4-0.1,0.6-0.1c0.3-0.1,0.6-0.3,0.9,0c0-0.1,0-0.1,0-0.2c0.1,0,0,0.1,0.2,0.1
		s0-0.1,0.3-0.2C239.1,107.3,239.4,107.3,239.5,107.1z M214.4,88.8C214.3,88.9,214.4,88.8,214.4,88.8L214.4,88.8z M211.5,90.3
		c0.3-0.6,1-0.5,1.6-0.7c0.5-0.1,0.9-0.2,1.3-0.6c-0.5,0.6-1.1,0.8-1.8,0.9C212.2,90,211.8,90.1,211.5,90.3z M211,90.5
		c0.1-0.2,0.2-0.5,0.4-0.6c-0.1,0.1-0.1,0.5-0.2,0.5S211.1,90.5,211,90.5z M210.4,90.7c0-0.2,0.2-0.8,0.6-0.7
		c-0.1,0.2-0.2,0.4-0.2,0.6C210.6,90.6,210.5,90.7,210.4,90.7z M209.8,91c0-0.2,0.1-0.4,0.3-0.6c0.2-0.3,0.1-0.4,0.5-0.4
		c-0.2,0.2-0.2,0.8-0.5,0.9C209.9,90.9,209.8,91,209.8,91z M209.2,91.3c0-0.3,0.1-0.6,0.3-0.9s0.2-0.4,0.5-0.4
		c-0.2,0.4-0.5,0.7-0.5,1.2C209.4,91.2,209.3,91.3,209.2,91.3z M208.7,91.6c0.1-0.4,0.2-1.6,0.8-1.6c-0.1,0.3-0.3,0.5-0.4,0.9
		C209,91.3,209.1,91.4,208.7,91.6z M208.2,91.9c0-0.5,0.3-2,0.9-1.9c-0.3,0.5-0.6,1.1-0.6,1.7h0.1C208.4,91.7,208.3,91.8,208.2,91.9
		z M207.6,92.5c0-0.6,0.1-1.2,0.4-1.8c0.1-0.2,0.4-0.9,0.6-0.8c-0.4,0.6-0.7,1.5-0.7,2.2C207.8,92.2,207.7,92.4,207.6,92.5z
		 M206.9,93.2c0-0.2,0-0.5,0-0.7c0.1-0.3,0.2-0.7,0.3-1s0.8-1.6,1-1.5c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.3,0.8
		c-0.1,0.4,0,1.2-0.3,1.5C207.1,93,207,93.1,206.9,93.2z M206.2,92.8c0-0.5,0.1-1.1,0.4-1.5c0.2-0.4,0.8-1.6,1.4-1.5
		c-0.3,0.5-0.8,1.2-1,1.7c-0.1,0.3-0.4,1-0.3,1.1c-0.1,0.1-0.1,0.6-0.1,0.8c-0.1,0.2-0.2,0.3-0.4,0.5
		C206.2,93.5,206.2,93.1,206.2,92.8z M206.2,90.6c0.2-0.3,0.8-1.3,1.2-1c-0.7,0.4-1.4,1.7-1.4,2.5c0,0.3-0.1,0.6-0.1,1
		s0.1,0.8-0.2,1.1c0-0.3-0.1-0.6-0.1-0.9C205.5,92.5,205.8,91.4,206.2,90.6z M205.3,91.7c0.1-0.5,0.2-0.9,0.5-1.4
		c0.2-0.3,0.6-0.9,1-0.7c-0.8,0.7-1.1,1.8-1.3,2.7c-0.1,0.4-0.1,1,0,1.3c0.1,0.5,0.2,0.8,0,1.1C205.2,93.7,205.2,92.8,205.3,91.7z
		 M204.7,91.8c0.1-0.7,0.7-2.4,1.5-2.3c-0.9,0.6-1.1,1.7-1.2,2.7c0,0.5-0.1,1.2,0,1.7c0,0.3,0.1,0.5,0.2,0.8c0,0.3-0.1,0.4-0.3,0.6
		C204.6,94.2,204.6,93,204.7,91.8z M204.1,92.5c0-0.7,0.2-1.5,0.6-2.2c0.2-0.4,0.5-0.9,1-0.9c-0.9,0.8-1.3,1.9-1.3,3.1
		c0,0.6,0,1.3,0.1,1.9c0,0.4,0.4,1,0.1,1.4C204,94.7,204.1,93.5,204.1,92.5z M203.4,93.1c0-1,0.3-3.2,1.4-3.5
		c-0.4,0.6-0.8,1.4-0.9,2.1c0,0.2-0.1,0.5-0.1,0.7s0,0.3,0,0.5c0,0.4,0,0.8,0,1.3c0.1,0.4,0.1,0.8,0.2,1.1c0.1,0.3,0.3,0.5,0.1,0.8
		C203.8,95.2,203.5,94.1,203.4,93.1z M203.8,95.7c0.1,0.3,0.4,0.4,0.1,0.7C203.8,96.1,203.8,96,203.8,95.7z M203.5,94.9
		c0.1,0.5-0.1,1.2,0.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1,0,0-0.1-0.1C203.3,96,203.2,95.6,203.5,94.9z M203.4,94.4
		c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.1,0.6-0.1,0.9s0.1,0.5,0.2,0.7c0.1,0.3-0.2,0.3-0.2,0.5C202.3,97.2,203.3,94.7,203.4,94.4z
		 M202.2,97c-0.1-0.3,0-0.7,0.2-1c0.3-0.7,0.7-1.5,0.9-2.2c0.1,0.3-0.1,0.6-0.2,0.9c-0.2,0.4-0.3,0.9-0.4,1.3
		c-0.1,0.4-0.1,0.8,0,1.1c0.1,0.4,0.3,0.4,0,0.6c0,0,0-0.1-0.1-0.1C202.4,97.6,202.2,97.2,202.2,97z M202,95.5c0.4-1,0.8-2,1.2-3
		c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.6,1.1-0.5,1.7c0,0.3,0.3,0.8,0.5,1
		c-0.2,0.2-0.4,0.3-0.5,0.5C201.3,97.5,201.7,96.3,202,95.5z M201.4,98.5c-0.4-0.6-0.2-1.4-0.2-2c0-0.8,0.4-1.6,0.7-2.3
		c0.3-0.9,0.7-1.6,1.2-2.4c0.3-0.5,0.5-1.3,1-1.7c-0.4,0.7-0.5,1.4-0.9,2.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.3-0.2,0.7-0.4,1
		c-0.4,0.7-0.8,1.7-0.8,2.5c0,0.2,0,0.5,0,0.7c0,0.3,0.4,0.9,0.2,1.1C201.6,98.4,201.4,98.6,201.4,98.5z M203.4,90.6
		c0-0.1,0-0.1,0.1-0.2C203.5,90.5,203.4,90.6,203.4,90.6z M200.5,98.4c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.7,0.3-1.4,0.5-2
		c0.6-1.5,1.2-3.2,2.3-4.4c-0.8,1.5-1.7,2.9-2,4.6c-0.2,0.7-0.7,3,0.2,3.3c-0.1,0.1-0.3,0.2-0.4,0.3c0,0,0,0-0.1-0.1
		C200.7,98.8,200.5,98.8,200.5,98.4z M200.1,98.7c-0.2-0.4-0.4-0.7-0.4-1.2c0.1-0.9,0.2-1.7,0.6-2.5c0.2-0.5,0.4-1.1,0.7-1.5
		s0.4-0.9,0.7-1.2c0.2-0.3,0.5-0.6,0.7-0.9c0.1-0.2,0.3-0.4,0.6-0.6c-0.5,0.6-1.1,1.5-1.4,2.2c-0.4,0.9-0.8,1.7-1.1,2.7
		c-0.2,0.6-0.4,1.3-0.4,1.9c0,0.3,0,1.1,0.3,1.3c0.1,0.1,0.3,0.3,0.4,0.4C200.6,99.4,200.2,98.8,200.1,98.7z M199.7,100
		c0-0.1,0-0.3,0-0.4c0.1,0.1,0.2,0.2,0.4,0.2C200,99.9,199.8,100,199.7,100z M199.2,99.2c0.1,0.2,0.2,0.1,0.2,0.3c0,0.1,0,0.2,0,0.4
		c0,0.2,0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0C199.1,99.9,199.2,99.5,199.2,99.2z M199.2,96.8c0.1-0.8,0.4-1.5,0.7-2.2
		c0.5-1.2,1-2.2,2.1-3c-1,1.3-1.8,2.9-2.3,4.4c-0.2,0.6-0.3,1.4-0.2,1.9c0.1,0.7,0.6,1.2,1.1,1.5c-0.2,0.2-0.1,0.2-0.3,0
		c-0.3,0.4-0.6-0.5-0.8-0.3c-0.2-0.1-0.3-0.4-0.3-0.6C199,98,199.1,97.3,199.2,96.8z M199,98.6c0.1,0.4,0,0.7,0,1.1
		c0,0.2-0.1,0.6,0.1,0.8c-0.1,0-0.1,0.1-0.2,0.1C198.3,100.2,198.7,99.1,199,98.6z M198.3,99.4c0.2-0.5,0.4-1.1,0.7-1.5
		c0,0.5-0.4,0.8-0.4,1.3c0,0.4-0.3,1.2,0.2,1.5c-0.3,0.2-0.6,0.4-0.8,0.6l-0.1-0.1C197.5,100.6,198,99.8,198.3,99.4z M197.3,101.4
		c-0.3-0.7,0.1-1.6,0.4-2.1c0.4-0.8,0.9-1.6,1.3-2.3c0,0.7-0.5,1.3-0.8,2c-0.3,0.8-1.1,1.7-0.5,2.5
		C197.5,101.5,197.4,101.6,197.3,101.4z M196.6,100.4c0.1-0.5,0.3-1.1,0.6-1.6c0.5-1,1.4-1.8,1.9-2.8c0,0.1-0.1,0.6-0.2,0.6
		c-0.1,0-0.2,0.3-0.3,0.4c-0.3,0.5-0.5,1.1-0.9,1.6c-0.5,0.7-1.3,2.2-0.6,3c-0.1,0-0.3,0.1-0.4,0.2c0-0.2-0.1-0.2-0.2-0.4
		C196.7,101.1,196.5,100.8,196.6,100.4z M196.2,101.2c0-1.4,0.4-2.5,1.1-3.7c0.4-0.7,1-1.3,1.5-1.9c0.4-0.5,0.7-1.1,1.1-1.6
		c-0.2,0.5-0.3,1-0.6,1.4c0,0.2-0.5,0.7-0.6,0.9c-0.6,0.9-1.3,1.6-1.8,2.6c-0.2,0.4-0.4,0.9-0.5,1.3c0,0.2,0,0.5,0,0.8l0.1,0.3
		c0,0.3,0.3,0.5,0,0.6C196.1,102.2,196.2,101.5,196.2,101.2z M195.7,100.2c0.3-1.1,0.6-2.2,1.3-3.1s1.5-1.7,2-2.7
		c0.2-0.4,0.5-0.9,0.9-1.2c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.3,0.4-0.3,0.7-0.5c-0.5,0.8-1.1,1.4-1.6,2.2c-0.6,0.9-1.3,1.7-1.9,2.6
		c-0.3,0.5-0.5,0.9-0.8,1.4c-0.2,0.4-0.5,0.8-0.5,1.2c0,0.5-0.2,1-0.2,1.5c0,0.3,0,0.6,0.1,0.8c0,0.2,0,0.2-0.2,0.2
		C195.5,101.6,195.5,100.9,195.7,100.2z M195.1,101.7c0-0.9,0.1-1.8,0.5-2.7c0.4-0.9,0.9-1.6,1.4-2.4c0.4-0.8,1-1.5,1.5-2.2
		c0.2-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.4-0.5,0.8-0.7c-0.3,0.4-0.7,0.7-0.9,1.1c-0.3,0.4-0.5,0.9-0.8,1.3c-0.6,0.7-1.2,1.4-1.7,2.2
		c-0.5,0.8-0.8,1.8-1,2.7c-0.2,0.2-0.2,0.9-0.2,1.2c0,0.4,0.1,0.8,0.3,1.1c-0.1,0.1-0.3,0.1-0.4,0.1v-0.1c0,0,0,0-0.1,0
		C195.2,102.2,195.1,102,195.1,101.7z M194.6,102.5c0-1.7,0.3-3.3,1.2-4.8c0.5-0.9,1.1-1.7,1.7-2.5c0.5-0.6,0.8-1.4,1.4-1.8
		c-0.1,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.6,0.8-0.9,1.2c-0.6,0.8-1.1,1.7-1.6,2.5s-0.8,1.6-1,2.6c-0.1,0.4-0.1,1.2,0,1.6
		c0.1,0.2,0,0.4,0.1,0.5c-0.1,0-0.3,0.1-0.4,0.1C194.6,102.7,194.6,102.6,194.6,102.5z M194.4,102c0,0.2,0.1,0.9-0.1,0.9
		c0-0.4,0-0.8,0-1.2c0-1.4,0.4-2.9,1-4.1c0.4-0.9,1.1-1.7,1.7-2.5c0.3-0.3,0.6-0.6,0.8-1c0.2-0.3,0.5-0.7,0.9-0.8
		c-0.7,0.5-1.1,1.5-1.6,2.2c-0.7,0.9-1.2,1.8-1.8,2.8c-0.4,0.7-0.8,1.8-0.8,2.6C194.4,101.2,194.3,101.6,194.4,102z M193.8,103
		c0-0.1,0-0.1,0-0.2s0-0.2,0-0.3c-0.3,0-0.1-0.7-0.1-1l0,0c0-0.3,0-0.6,0.1-0.8c0-0.8,0.5-1.5,0.7-2.3c0.4-1.1,0.9-2.1,1.7-3.1
		c0.3-0.4,0.7-0.8,0.9-1.2c0.1-0.2,0.2-0.3,0.3-0.5s0.3-0.2,0.5-0.3c-0.9,1.2-1.8,2.2-2.6,3.4c-0.4,0.6-0.6,1.4-0.9,2
		c-0.1,0.3-0.5,1.4-0.3,1.6c-0.2,0.1-0.1,1.1-0.1,1.3c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1C194.1,102.9,194.1,102.9,193.8,103L193.8,103
		z M193,103.2L193,103.2c0-0.2,0-0.3,0-0.4l0,0l0,0c-0.1,0-0.1,0-0.2,0v-0.1c0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0-0.2,0-0.3
		c0-0.2,0.1-0.4,0.1-0.7l0,0c0-0.1,0.1-0.3,0.1-0.5c0.2-0.7,0.5-1.5,0.8-2.2c0.3-0.8,0.9-1.8,1.4-2.5c0.3-0.4,0.5-0.7,0.8-1.1
		c0.2-0.4,0.5-0.4,0.9-0.5c-0.6,1-1.5,1.8-2.1,2.8c-0.3,0.6-0.6,1.2-0.8,1.8c-0.2,0.5-0.5,1-0.6,1.6c-0.1,0.4-0.2,0.7-0.2,1.1
		c0,0.2-0.1,0.5-0.1,0.7l0,0c0,0,0,0,0,0.1s0,0.3,0,0.4s0,0.3,0,0.4c-0.1,0.4,0.1,0.5-0.1,0.6c-0.1,0-0.1,0-0.2,0.1
		C193,103.2,193,103.2,193,103.2z M192.4,103.3c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0-0.5,0.1-0.9,0.1-1.4c0-0.1,0-0.2,0.1-0.3
		c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.2-0.5,0.4-0.8c0.5-0.7,0.9-1.4,1.4-2.2c0.3-0.4,0.5-0.9,0.8-1.3
		c0.2-0.3,0.2-0.5,0.5-0.6c-0.5,0.8-1.1,1.5-1.5,2.3s-0.8,1.6-1.2,2.4c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.2-0.1,0.3-0.1,0.5
		c-0.1,0.3-0.1,0.5-0.2,0.8c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.3,0,0.7-0.1,1.1c0,0,0,0,0,0.1s0,0.2,0,0.2
		c0,0.1,0,0.3,0,0.4C192.7,103.2,192.6,103.3,192.4,103.3L192.4,103.3z M191.7,103.4c0-0.2,0.2-0.5,0-0.5c0.1-0.5,0-0.9,0.1-1.4
		v-0.1c0-0.1,0-0.1,0-0.2c0.1-0.4,0.2-0.7,0.3-1v-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.9,0.5-1.3c0-0.1,0.1-0.3,0.2-0.4
		c0,0,0-0.1,0.1-0.1c0.4-0.7,0.9-1.3,1.4-1.9c0.2-0.3,0.5-0.7,0.7-1.1s0.2-0.6,0.6-0.7c-0.7,1.5-1.9,2.8-2.7,4.3v0.1
		c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.4,0,0.7
		c0,0.1,0,0.2,0,0.3c0,0.6,0,1.2-0.2,1.2C192,103.4,191.8,103.4,191.7,103.4z M191.1,102.5c0-0.3,0-0.7,0-1s0.1-0.6,0.2-0.9
		c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.7,0.4-1.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.1c0.2-0.4,0.4-0.8,0.6-1.1c0.1-0.1,0.1-0.2,0.2-0.3
		c0.2-0.3,0.5-0.6,0.7-0.9c0.3-0.4,0.6-0.8,0.9-1.3c0.1-0.2,0.4-0.6,0.3-0.7l0.3-0.1c-0.5,1.2-1.4,2.2-2.1,3.3
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.5-0.3,0.7s-0.1,0.4-0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.3,0.7-0.4,1.1
		c0,0.1,0,0.1,0,0.2v0.1c-0.1,0.4-0.1,0.9-0.1,1.3c0,0.3-0.2,0.6-0.1,0.9c-0.1,0-0.3,0.1-0.4-0.1C191,103,191.1,102.7,191.1,102.5z
		 M190.5,102.5c0-0.3,0-0.8,0.1-1.1c0.1-0.5,0.2-1.1,0.4-1.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.5,0.3-0.7
		c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.6-0.8,0.9-1.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
		c0.4-0.6,0.9-1.6,1.5-1.9c-0.5,0.9-1.1,1.7-1.7,2.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.5,0.8-0.7,1.2c0,0.1-0.1,0.2-0.2,0.3
		c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.3,0.6-0.4,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5-0.2,1-0.2,1.4c0,0.3,0,0.7,0,1
		c0,0.2-0.1,0.4,0,0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2C190.3,103.1,190.5,102.7,190.5,102.5z M190.1,102.7c0-0.4,0-0.8,0-1.2
		c0-0.7,0.3-1.5,0.6-2.2l0,0c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.3-0.8,0.6-1.1l0,0c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.3-0.4,0.4-0.6
		c0.1-0.1,0.3-0.4,0.5-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.5,0.7-1,0.5-1.1c0.2-0.1,0.4-0.1,0.6-0.2c-0.4,0.5-0.7,1.1-1.1,1.6
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.3-0.4,0.7-0.5,1.1
		l0,0c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.8-0.5,1.6-0.5,2.4c0,0.2,0,0.4-0.1,0.6c0,0.2,0.1,0.8-0.2,0.8
		C190,103.2,190.1,102.9,190.1,102.7z M189.6,103.3c0-1.6,0.4-3.1,1-4.5c0,0,0,0,0-0.1s0.1-0.2,0.1-0.2c0.2-0.3,0.3-0.6,0.5-1
		c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.3,0.2-0.4l0,0c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.4,0.4-0.8,0.8-1.1c0.1-0.1,0.2-0.1,0.2-0.2
		c0.1-0.1,0.3-0.2,0.4-0.2c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.4-0.5,0.8-0.9,1.1l-0.1,0.1
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.2,0.6
		c-0.5,1.3-0.4,2.6-0.5,3.9C189.8,103.4,189.7,103.4,189.6,103.3z M191,95.9c-0.3,0.3-0.6,0.7-0.8,1.1c-0.1,0.1-0.1,0.2-0.1,0.2
		c-0.4,0.6-0.6,1.3-1,1.9c-0.5,0.9-0.9,2-1,3.1h0.1c-0.1,0.1-0.2,0.6-0.2,0.8h-0.4c0.1-0.1,0.1-0.7,0.1-0.9c0.1-0.3,0.1-0.6,0.2-1
		c0.1-0.7,0.3-1.3,0.6-1.9s0.6-1.2,1.1-1.7c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.1-0.1,0.1-0.2C190.4,96.4,190.6,96.1,191,95.9z
		 M191.6,95.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1,0.1-0.2,0.3-0.3,0.4
		s-0.1,0.2-0.2,0.2c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.5-0.5,1-0.7,1.5c-0.1,0.3-0.4,0.9-0.4,1.3c-0.2,0.1-0.2,1.5-0.2,1.7
		c-0.1,0-0.2-0.1-0.4-0.1c0-0.5,0.1-1.3,0.2-1.7c0.3-0.8,0.6-1.5,0.9-2.2c0.2-0.5,0.5-1,0.8-1.5c0-0.1,0.1-0.2,0.1-0.2
		c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.1,0.2-0.2C191.4,95.8,191.5,95.7,191.6,95.6z M192.4,95.2
		c-0.4,0.4-0.7,0.9-1,1.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.4,0.8-0.7,1.2c0,0.1-0.1,0.2-0.1,0.3c-0.7,1.5-1,3.2-1.1,4.8
		c-0.3-0.1-0.5-0.1-0.5-0.4c0-1.3,0.4-2.6,1-3.8c0.2-0.3,0.4-0.6,0.5-1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1
		c0.2-0.3,0.4-0.6,0.6-0.9c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3C191.6,95.8,192,95.3,192.4,95.2z M203.6,88.5
		c0.4-0.1,0.9-0.1,1.3-0.1c0.5,0,1.1,0,1.6,0c0.2,0,0.5,0.1,0.7,0.2s0.5,0,0.8,0.1c0.4,0.1,0.5,0.3,1,0.3c0.9,0,1.8,0.2,2.7,0.1
		s2.2-0.4,2.9-1c-0.1,0.5-0.7,0.9-1.1,1.1c-0.6,0.1-1.2,0.3-1.8,0.4c-0.9,0.1-1.8,0.2-2.6,0.1v-0.1c-0.2,0.1-0.6,0.1-0.8-0.1
		c0,0-0.5,0.1-0.5,0c-0.2-0.2-0.4-0.1-0.7-0.1c0,0-0.4-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.2,0-0.2-0.1
		c-0.3,0.1-0.5,0-0.8,0c-0.1,0-0.6,0.2-0.7,0.3c0-0.2-0.6,0.3-0.6,0.3c-0.4,0.3-0.8,0.6-1.2,0.9c-1,0.7-1.9,1.4-3,1.9
		c-0.5,0.2-0.8,0.4-1.3,0.5c-0.3,0-0.6,0.2-0.9,0.3c0-0.1-0.7,0.2-0.9,0.2c0-0.2-0.2-0.1-0.2,0c0,0.2-1.7,0.7-2,0.8
		c-0.3,0.1-0.1,0-0.4,0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0.1
		c0.1,0,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.7c0.5-0.3,1.1-0.4,1.6-0.7c1-0.4,2-0.8,3-1.2
		c1.1-0.4,2.1-0.9,3.1-1.5c0.4-0.3,0.8-0.7,1.2-1.1C202.9,89.2,203.2,88.6,203.6,88.5z M205.9,86.3c0.5-0.1,1,0.1,1.6,0.1
		c0.9,0,1.8,0.5,2.7,0.4c1.7,0,3.4-0.4,4.4-1.9c0,0.3,0,0.7,0,1s0.2,0.7,0.1,1c-0.2,0.5-0.9,0.8-1.3,1c-1.2,0.6-2.5,0.6-3.8,0.6
		c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1-0.1-0.4-0.1-0.5-0.1c-0.3-0.1-0.7-0.2-1-0.3c-0.6-0.3-1.2-0.2-1.8-0.1
		c0.2-0.2,0.3-0.5,0.4-0.7C204.9,86.8,205.6,86.4,205.9,86.3z M206.5,84.8c0.2,0,0.4,0,0.6,0c0.4,0,0.9,0.2,1.3,0.4
		c0.5,0.3,1.4,0.5,2,0.3c0.3-0.1,0.7-0.1,1-0.2c0.2-0.1,0.5,0,0.7-0.2s0.5-0.4,0.7-0.6c0.4-0.4,0.8-0.7,1.2-1.2
		c0.3-0.4,0.4-0.8,0.8-1.1c0,1-0.8,2-1.5,2.6c-0.6,0.5-1.4,1-2.2,1c-0.9,0.1-2-0.1-2.8-0.5c-0.7-0.3-1.7-0.5-2.4-0.3
		C206,84.9,206,84.8,206.5,84.8z M214.2,81.8c-0.4,0.7-0.8,1.2-1.4,1.8c-0.4,0.4-1.2,1-1.8,1c-0.7,0.1-1.3,0.2-2-0.1
		c-0.6-0.2-1.3-0.6-2-0.7c0.5-0.5,1.1-1.1,1.8-1.4c0.6-0.3,1.3-0.4,1.9-0.7c1.1-0.5,2.2-0.1,3.2,0.1C214,81.8,214.1,81.8,214.2,81.8
		z M214.6,81.5C214.6,81.6,214.6,81.6,214.6,81.5L214.6,81.5z M214.1,82.7c-0.3,0.4-0.5,0.9-0.9,1.2c-0.6,0.7-1.5,1.4-2.4,1.3
		c-0.4,0-0.7,0.1-1.1,0.1c-0.4,0-0.7-0.2-1-0.2s-0.8-0.3-1.1-0.4c-0.4-0.2-0.9-0.2-1.4-0.1c0.3-0.4,0.5-0.6,1-0.5
		c0.4,0.1,0.9,0.3,1.3,0.4c0.9,0.4,1.7,0.5,2.7,0.3c0.8-0.2,1.6-0.9,2.2-1.5c0.4-0.4,0.6-0.8,0.9-1.2c0.1-0.2,0.3-0.2,0.3-0.5
		c0,0.1,0,0.1,0,0.2C214.9,82,214.2,82.5,214.1,82.7z M214.7,83.3c0,0.6-0.1,1.1-0.4,1.6c-0.3,0.4-0.7,0.8-1.1,1.1
		c-0.8,0.4-1.8,0.4-2.6,0.6c-0.3,0-0.6,0.1-0.8,0c-0.6-0.1-1.1-0.2-1.7-0.4c-0.5-0.1-1-0.2-1.5-0.2c-0.6-0.1-1.1,0.2-1.7,0.5
		c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.1,0.3-0.2,0.5-0.2s0.4,0,0.7,0c0.5,0,0.8,0.1,1.3,0.2c0.4,0.1,0.8,0.3,1.2,0.4
		c0.5,0.1,1,0.1,1.5,0.1C212.4,86.3,213.9,85,214.7,83.3z M214.7,87.4c-0.2,1.1-1.6,1.3-2.6,1.5c-1,0.3-2-0.1-3,0
		c-0.5,0-0.6-0.2-1-0.3c-0.2-0.1-0.5,0-0.8-0.1s-0.5-0.2-0.8-0.2c-0.9-0.1-2-0.1-2.9,0.1c0.1-0.2,0.3-0.4,0.4-0.6
		c0.5,0,0.9-0.1,1.4,0s1,0.3,1.4,0.4c0.1,0.2,0.3,0,0.5,0.1s0.4,0.2,0.6,0.2c0.3,0,0.6,0.2,0.9,0.3c0.4,0.2,0.9,0.1,1.4,0.1
		c0.6,0,1.2,0,1.8-0.1C212.9,88.7,214.2,88.1,214.7,87.4C214.7,87.3,214.7,87.3,214.7,87.4z M233.3,106c0-0.1,0-0.2,0-0.3
		c0-0.1,0-0.2,0.1-0.3c0.1-0.8,0.2-1.6,0.4-2.4c0.1-0.4,0.2-0.8,0.2-1.2c0-0.4,0.1-0.8,0.1-1.3c0-0.8-0.1-1.5-0.3-2.2
		c0.2,0.2,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.1,0.6v0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4
		c0,0.2,0.1,0.5,0.1,0.6l0,0c-0.1,0.1-0.1,0.6-0.1,1.1c0,0.3,0,0.6,0,0.7c-0.1,0.6-0.2,1.2-0.2,1.8c0,0.6-0.2,1.5,0,2.1
		c0.1,0.3,0.2,0.4-0.2,0.4c-0.3,0-0.8,0.2-0.8-0.2C233.1,107.2,233.3,106.6,233.3,106z M232.3,107.8c0-0.3,0-0.6,0.1-0.9
		c0-0.1,0-0.2,0-0.4c0.1-1,0.4-2.1,0.6-3.1c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2,0.1-0.5,0.1-0.7
		c0.1-0.4,0.2-0.9,0.2-1.3c0-0.9-0.1-1.7-0.2-2.7c0.5,0.5,0.5,1.8,0.5,2.5c0,0.3,0,0.6,0,0.9c0,0.4,0,0.7-0.1,1.1
		c0,0.2-0.1,0.4-0.1,0.6c-0.2,1-0.4,2.1-0.6,3.1v0.1c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,0.9c0,0.2,0,0.5,0,0.7
		C233,107.9,232.3,107.9,232.3,107.8z M231.5,107.9c-0.1,0-0.2,0-0.2-0.1l0,0c0-0.2,0-0.1,0-0.2c0-0.6,0.1-1.2,0.3-1.8
		c0-0.2,0.1-0.4,0.1-0.5v-0.1v-0.1c0-0.2,0.1-0.3,0.1-0.5c0.1-0.6,0.3-1.2,0.5-1.8c0.5-1.7,0.9-3.5,0.4-5.3c0.7,0.6,0.6,1.8,0.6,2.7
		c0,1-0.2,2-0.4,3c0,0.1-0.1,0.3-0.1,0.5s-0.1,0.4-0.1,0.6c-0.2,0.9-0.5,1.8-0.5,2.7c0,0,0,0,0,0.1s0,0.3,0,0.4c0,0.2,0,0.4,0,0.7
		C232.1,108,231.8,107.9,231.5,107.9z M230.6,107.6c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0c0.1-0.2,0.1-0.7,0.2-1
		c0.2-0.5,0.4-0.9,0.6-1.4c0.3-0.9,0.6-1.9,0.9-2.9c0.5-1.7,0.9-3.6,0.3-5.3c0.5,0.4,0.5,1.6,0.5,2.2c0,1.1-0.3,2.1-0.6,3.2
		c-0.2,0.8-0.4,1.7-0.6,2.5c0,0.2-0.1,0.3-0.1,0.5v0.1c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.4-0.2,0.9-0.1,1.3
		C230.9,107.7,230.7,107.7,230.6,107.6z M229.4,106.7c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.4,0.4-0.9,0.6-1.3c0.4-0.9,0.7-1.8,1-2.8
		c0.3-0.9,0.6-2,0.7-2.9c0-0.8,0.1-1.9-0.2-2.6c0.6,0.4,0.5,1.5,0.5,2.1c0,0.7,0,1.2-0.2,1.9c-0.6,2.3-1.4,4.5-2.1,6.8
		c-0.1,0-0.1-0.1-0.2-0.1C229.6,107.1,229.3,107,229.4,106.7z M228.7,106.2c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.5,0.5-1.1,0.7-1.6
		c0.4-1,0.8-2,1.1-3c0.3-0.9,0.5-1.9,0.6-2.8c0.1-0.7-0.1-1.4-0.2-2.1c0.8,0.6,0.6,1.7,0.6,2.6s-0.2,1.8-0.5,2.7
		c-0.3,0.9-0.5,1.9-0.9,2.8s-0.8,1.7-1.2,2.6c0,0,0,0-0.1-0.1C229,106.6,228.6,106.5,228.7,106.2z M228.2,106
		c0.2-0.2,0.2-0.4,0.3-0.7c0.2-0.5,0.5-0.9,0.7-1.3c0.4-0.9,0.8-1.9,1.1-2.9c0.3-0.8,0.3-1.7,0.5-2.5c0.2-0.9,0.2-1.7,0-2.6
		c0.3,0.3,0.4,1.5,0.3,2c0,0.9-0.3,1.7-0.5,2.6c-0.2,1-0.7,2-1,3c-0.3,0.9-0.9,1.7-1.2,2.6l-0.1-0.1
		C228.3,106.1,228.3,106.1,228.2,106z M228.5,104.7c0.2-0.4,0.4-0.9,0.6-1.4c0.2-0.8,0.4-1.6,0.7-2.4c0.4-1.7,1.2-3.6,0.5-5.3
		c0.6,0.3,0.6,1.5,0.6,2.1c0,0.8-0.3,1.7-0.4,2.4c-0.2,1.1-0.6,2.2-1.1,3.2c-0.2,0.4-0.4,0.9-0.6,1.3c-0.2,0.4-0.4,0.9-0.6,1.2
		C227.8,105.5,228.3,105,228.5,104.7z M227.7,104.8c0.1-0.3,0.2-0.6,0.4-0.8c0.2-0.5,0.4-1,0.5-1.5c0.2-0.5,0.3-1,0.4-1.5
		c0.1-0.4,0.3-0.9,0.3-1.4c0-0.4,0.2-0.9,0.2-1.3c0.1-0.4,0.1-0.7,0.1-1.1s0.1-0.7,0-1.1c-0.1-0.3,0-0.7-0.1-1
		c1.3,1.4,0.4,3.9,0,5.5c-0.2,0.8-0.5,1.7-0.7,2.5c-0.2,0.9-0.7,1.6-1.1,2.5C227.5,105.2,227.5,105.2,227.7,104.8z M227.5,103.6
		c0.2-0.5,0.3-1,0.5-1.5c0.2-0.9,0.5-1.7,0.7-2.6c0.2-0.7,0.2-1.4,0.3-2.1c0.1-0.5,0.1-1.1,0.1-1.6s-0.2-0.9-0.2-1.3
		c0.3,0.3,0.5,0.4,0.6,0.9c0,0.2,0.1,0.5,0.1,0.6c-0.1,0.1,0.1,0.6,0,0.8c0,0.3,0,0.6-0.1,0.9c-0.1,0.8-0.2,1.6-0.5,2.3
		c-0.5,1.7-0.9,3.6-1.8,5.2c-0.1-0.1-0.1-0.1-0.2-0.2C227,104.7,227.4,103.9,227.5,103.6z M227.2,103.3c0.2-0.5,0.3-1,0.5-1.5
		c0.3-1.1,0.5-2.1,0.7-3.2c0.1-0.3,0.1-0.7,0.2-1c0-0.4,0.2-0.9,0-1.2c-0.1-0.4,0.1-0.9-0.1-1.3c-0.1-0.3-0.1-0.7-0.2-1
		c0.7,0.6,0.5,2.1,0.5,3c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,1-0.5,2-0.8,2.9c-0.1,0.5-0.3,1-0.5,1.5c-0.1,0.3-0.2,0.6-0.3,0.8
		c-0.1,0.2,0-0.3-0.2,0.1C226.6,104.3,227.1,103.5,227.2,103.3z M226.2,103.7c0,0.1,0.1,0.1,0.2,0.1c0.3-0.5,0.5-1,0.7-1.5
		s0.4-0.9,0.5-1.5s0.3-1.1,0.4-1.6c0.1-0.3,0-0.7,0.1-1c0-0.4,0.1-0.7,0.1-1.1s0.1-0.8,0.1-1.3c0-0.9-0.2-1.7-0.6-2.5
		c0.3,0.3,0.5,0.4,0.6,0.8c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4,0.1,0.8,0.1,1.3c0,0.2,0.1,0.4,0.1,0.6s-0.1,0.5-0.1,0.7
		c0,0.3-0.1,0.6-0.1,0.8c0.1,0.5-0.2,1.1-0.2,1.5c-0.1,0.6-0.3,1.1-0.4,1.7c-0.2,1-0.6,2-1,3c-0.1-0.1-0.2-0.2-0.2-0.3
		C226.3,103.8,226.2,103.7,226.2,103.7z M227.5,93.8L227.5,93.8c-0.1-0.1-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.2,0-0.1
		C227.4,93.5,227.4,93.7,227.5,93.8z M227.7,95c0-0.3-0.2-0.7-0.1-1c0.3,0.7,0.3,1.3,0.3,2c0,0.3-0.1,0.6-0.1,0.9s0.1,0.7,0,1v-0.1
		c0,0-0.1,0-0.2,0c0-0.4,0-0.8,0.1-1.2c0-0.2,0-0.3-0.1-0.3c0-0.1,0-0.2,0-0.3C227.7,95.7,227.7,95.3,227.7,95z M225.8,102.9
		c0.2-0.2,0.3-0.4,0.4-0.6c0.3-0.4,0.4-0.8,0.6-1.3c0.3-0.9,0.5-2,0.8-2.9c-0.1,0.3-0.1,0.7-0.1,1c0,0.5-0.3,1-0.3,1.5
		c-0.2,1-0.5,2.2-1.1,3.1C226,103.4,225.7,103.2,225.8,102.9z M225.1,102.3c0,0,0.9-1.3,1-1.5c0.3-0.6,0.7-1.1,1-1.7
		c-0.2,1.3-0.8,2.7-1.6,3.8C225.4,102.7,225.3,102.5,225.1,102.3z M225,101.6c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.4,0.5-0.9,0.8-1.3
		c0.6-0.7,0.8-1.5,1.2-2.3c0,1.1-0.7,1.8-1.1,2.8c-0.2,0.5-0.5,0.9-0.8,1.3c-0.2,0.3-0.4,0.3-0.5,0.6
		C224.7,101.9,224.8,101.9,225,101.6z M224.7,101.1c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.4,0.6-0.9,0.9-1.3c0.6-0.9,1.3-2,1.6-3.1
		c0,0.4,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.5-0.6,0.9-0.9,1.4c-0.3,0.4-0.6,0.8-0.9,1.2
		C224.4,101.5,224.4,101.4,224.7,101.1z M224.2,100.9c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0.3-0.5,0.7-1,1-1.6
		c0.7-1.2,1.4-2.2,1.6-3.6c0.1,1.4-0.7,2.8-1.4,4c-0.3,0.5-0.7,1.2-1.1,1.7c-0.2,0.3-0.5,0.6-0.7,0.9
		C224.1,101.1,224,101.1,224.2,100.9z M223.7,100.6c0,0,1.3-1.8,1.4-2c0.3-0.6,0.6-1.2,1-1.8c0.8-1.4,1-2.9,0.6-4.4
		c0.4,0.5,0.7,1.4,0.6,2.1c-0.1,1-0.6,1.8-1,2.6c-0.4,0.8-1,1.6-1.5,2.4C224.6,99.8,224,101.1,223.7,100.6z M224,99.6
		c0.2-0.3,0.5-0.7,0.7-1.1c0.4-0.7,0.7-1.5,1.1-2.3c0.6-1.4,1-2.9,0.6-4.4c0.4,0.5,0.5,1.6,0.5,2.2c0,0.8-0.4,1.8-0.7,2.4
		c-0.4,0.8-0.7,1.5-1.2,2.2c-0.2,0.4-0.5,0.7-0.7,1.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1,0-0.2-0.2,0
		C223.5,100.1,223.8,99.8,224,99.6z M223.4,100c0-0.1,0.5-0.8,0.5-0.9c0.2-0.3,0.4-0.7,0.5-1.1c0.4-0.9,0.9-1.8,1.2-2.8
		c0.5-1.5,0.4-2.9-0.1-4.4c0.5,0.6,0.7,1.4,0.7,2.2c0,0.5,0,0.9-0.1,1.4c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0.9-0.7,1.8-1.1,2.6
		c-0.2,0.5-0.5,0.9-0.7,1.3C223.8,99.5,223.4,100,223.4,100z M223,99.3c0.6-0.7,1-1.6,1.3-2.4s0.7-1.7,0.8-2.6
		c0.1-0.7,0.3-1.3,0.2-1.9c0.1-0.8-0.3-1.8-0.7-2.5c0.6,0.2,0.7,0.8,0.9,1.3c0.1,0.3,0.2,0.7,0.2,1c0,0.2,0,0.5,0.1,0.6
		c-0.2,0.2-0.1,1-0.1,1.2c-0.1,0.7-0.4,1.5-0.7,2.2s-0.6,1.5-1,2.1c-0.2,0.3-0.4,1.2-0.8,1.3C223.2,99.6,223.1,99.4,223,99.3z
		 M222.9,98.2c0.1-0.1,0.2-0.2,0.3-0.4c0.3-0.4,0.4-1,0.6-1.4c0.3-0.9,0.6-1.8,0.8-2.7c0.1-0.4,0.1-0.7,0.2-1.1
		c0-0.3-0.1-0.5-0.1-0.8c0-0.4-0.1-0.9-0.3-1.2c-0.1-0.4-0.4-0.7-0.6-1c0.7,0,1,1.2,1.2,1.8c0.2,0.8,0.4,1.8,0.1,2.6
		c-0.3,0.9-0.5,1.8-0.8,2.7c-0.3,0.9-0.8,1.7-1.2,2.4c0-0.1-0.1-0.1-0.1-0.2C222.7,98.5,222.7,98.5,222.9,98.2z M223,97
		c0.2-0.4,0.3-0.8,0.4-1.2c0.2-0.5,0.4-1,0.5-1.5c0.1-0.3,0.1-0.7,0.1-1.1c0.1-0.5,0.1-1.4-0.1-1.9c-0.1-0.4-0.3-0.8-0.5-1.1
		c-0.2-0.3-0.1-0.6-0.3-0.8c0.8,0.3,0.9,1.4,1.1,2.1c0.1,0.2,0.1,0.6,0.1,0.8c0.1,0.2,0,0.7-0.1,0.9c-0.2,0.4-0.3,1-0.3,1.5
		c0,0.4-0.3,1-0.4,1.4c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.3-0.4,0.4-0.5,0.5c0,0,0,0,0-0.1C222.3,97.7,222.9,97.3,223,97z M222.2,97.1
		c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.8,0.6-1.7,0.6-2.6c0-0.3,0-0.5,0-0.8l-0.1-0.5c-0.2-0.7-0.2-1.6-0.7-2.2
		c0.4,0,0.5,0.3,0.6,0.6c0.1,0.3,0.3,0.7,0.4,1c0.2,0.5,0.5,1.4,0.3,2c-0.1,0.3-0.1,0.7-0.1,1c-0.1,0.4-0.2,0.9-0.4,1.3
		c-0.2,0.5-0.3,1-0.5,1.4c-0.1,0.3-0.4,0.6-0.6,0.9C222.2,97.4,222,97.3,222.2,97.1z M222.1,95.6c0.2-0.3,0.3-0.5,0.4-0.8
		c0.2-0.6,0.3-1.3,0.5-1.8c0.2-0.6,0.1-1.3,0-1.9s-0.3-1.3-0.8-1.6c0.4-0.1,0.5,0.2,0.6,0.5s0.3,0.7,0.3,1.1c0,0.2,0.1,1.3,0.3,1.4
		c-0.1,0.1-0.1,0.5-0.1,0.7c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.5-0.3,1-0.5,1.5c-0.2,0.4-0.2,0.8-0.5,1.1c0-0.2-0.1-0.4-0.1-0.6
		C221.8,96.1,221.9,95.9,222.1,95.6z M221.8,95.1c0.2-0.3,0.3-0.6,0.4-0.9c0.2-0.7,0.4-1.3,0.4-2c0-0.8-0.2-2.4-1.1-2.7
		c1.1,0,1.3,1.8,1.4,2.6c0,0.6-0.2,1.2-0.4,1.9c-0.1,0.3-0.2,0.7-0.3,1s-0.3,0.6-0.4,0.9C221.5,95.5,221.6,95.4,221.8,95.1z
		 M221.1,94.8c0.3,0.3,0.5-0.7,0.5-0.8c0.3-0.6,0.4-1.1,0.4-1.7c0-1-0.3-1.9-1-2.6c0.9-0.1,1.3,1.6,1.3,2.3c0,1.2-0.3,2.4-0.9,3.4
		C221.3,95.1,221.2,94.9,221.1,94.8z M221.6,92.3c0-0.6,0-1-0.3-1.6c-0.2-0.4-0.4-0.7-0.7-1.1c0.3-0.1,0.8,0.8,0.9,1
		c0.2,0.4,0.2,1,0.3,1.3c-0.2,0.3-0.1,0.9-0.2,1.3c-0.1,0.3-0.2,0.5-0.3,0.8C221.6,93.5,221.6,92.7,221.6,92.3z M221.1,94.5
		c0.1-0.1,0.1-0.2,0.2-0.3C221.3,94.3,221.2,94.4,221.1,94.5C221.2,94.5,221.1,94.5,221.1,94.5z M221.1,94.5c0,0.1,0,0.1,0,0.2
		C221,94.6,221,94.6,221.1,94.5z M220.7,93.9c0.1-0.1,0.1-0.1,0.2-0.2c0-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.1-0.8,0-1.2
		c-0.2-0.7-0.1-1.4-0.5-2c0.3,0.4,0.6,0.9,0.7,1.3c0.1,0.4,0,0.8,0,1.2c0,0.5-0.1,1.5-0.5,1.9C220.8,94.2,220.8,94.1,220.7,93.9z
		 M220.6,92.4c0.1-0.3,0-0.6,0-0.8c0-0.6-0.1-1.1-0.3-1.6c0.5,0.5,0.5,1.4,0.6,2c0.1,0.7,0,1.2-0.2,1.8c-0.1-0.2-0.1-0.4-0.3-0.6
		C220.2,92.9,220.5,92.6,220.6,92.4z M220.4,89.7L220.4,89.7c0.1,0.1,0.1,0.1,0.2,0.2C220.5,89.8,220.5,89.8,220.4,89.7z
		 M220.3,89.8c-0.1-0.1,0-0.1,0.1-0.1C220.3,89.8,220.3,89.8,220.3,89.8z M219.8,92.3c0.1-0.1,0.1-0.2,0.1-0.3
		c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.5-0.1-1-0.2-1.4c0.4,0,0.5,1.2,0.5,1.5c0,0.5,0,0.9-0.2,1.4C220,92.6,219.9,92.5,219.8,92.3z
		 M219.4,91.7c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.7c0-0.3,0.1-0.6,0-0.9c0.3,0.1,0.3,1,0.3,1.2c0,0.4-0.1,0.8-0.3,1.2
		C219.5,92,219.2,91.9,219.4,91.7z M219.1,91.2c0.1-0.2,0.1-0.4,0.1-0.6c0-0.3,0.1-0.6,0-0.9c0.7,0.1,0,1.7,0,2
		C218.8,91.5,219,91.5,219.1,91.2z M218.4,91.2c0.3-0.3,0.4-1.1,0.4-1.5c0.3,0,0.1,0.6,0.2,0.7c-0.2,0.2-0.1,0.7-0.3,1
		C218.6,91.3,218.5,91.3,218.4,91.2z M217.5,90.7c0.2-0.3,0.3-0.7,0.3-1c0.3,0.1,0.6,0,0.8,0c0,0.2,0,0.4,0,0.6
		c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1-0.1-0.2-0.1-0.4-0.2c0.2-0.1,0.4-1,0.4-1.2c-0.5-0.1-0.3,0.8-0.5,1.1
		C217.7,90.8,217.6,90.7,217.5,90.7z M217,90.4c0.2-0.2,0.2-0.5,0.3-0.7c0-0.2,0.2-0.1,0.4-0.1c-0.1,0-0.2,0.8-0.4,1
		C217.2,90.5,217.1,90.4,217,90.4z M216.5,90.2c0.2-0.1,0.2-0.5,0.2-0.6c0.1,0,0.2,0,0.3,0c0,0.3-0.1,0.5-0.2,0.7
		C216.7,90.2,216.6,90.2,216.5,90.2z M215.3,88.6c0.3,0.5,0.9,0.7,1.4,0.9c-0.3-0.1-0.3,0.4-0.2,0.6c-0.1-0.1-0.2-0.1-0.3-0.1
		C215.7,89.7,215.4,89.2,215.3,88.6z M215,85.9c0.3,0.3,0.8,0.4,1.1,0.6s0.6,0.1,0.9,0.3c0.4,0.2,1,0.5,1.4,0.5
		c0.3,0,0.6,0.3,0.9,0.2c0.6-0.2,1.3-0.1,1.9-0.1c0.9,0,1.6,0.1,2.4,0.6c-0.4-0.1-0.8-0.3-1.3-0.4c-0.4-0.1-0.8,0-1.2,0
		c-0.7,0-1.4-0.1-2.1-0.1c-0.8,0-1.6-0.4-2.3-0.7c-0.4-0.2-0.9-0.3-1.3-0.5C215.2,86.2,215,86.1,215,85.9C215,86,215,85.9,215,85.9z
		 M214.9,83.2c0,0.2,0,0.3,0,0.5C214.9,83.6,214.9,83.4,214.9,83.2z M214.9,82.8c0,0.1,0,0.2,0,0.2S214.9,82.6,214.9,82.8z
		 M214.8,84.9C214.8,85,214.8,84.9,214.8,84.9C214.8,84.8,214.9,84.9,214.8,84.9z M214.9,82.7c0.1-0.4,0-0.1,0-0.2s-0.1-0.7,0.2-0.7
		C215.1,82.1,215.1,82.5,214.9,82.7z M221.2,84.2c-0.6,0-1.4,0.1-2,0.2c-0.5,0.1-1.2-0.1-1.7-0.2c-1.2-0.3-1.9-1.2-2.1-2.4h-0.1
		c0-0.3,2.3,0.3,2.5,0.4c1,0.4,1.9,0.9,2.8,1.5C220.8,83.9,221,84,221.2,84.2z M221.7,84.6c-0.7,0-1.3,0.4-2,0.5
		c-0.6,0.1-1.4,0-2-0.2s-1.2-0.7-1.7-1.1c-0.3-0.2-0.5-0.6-0.7-0.9c-0.1-0.2,0-0.5,0-0.7c0.2,0.6,0.4,1.2,0.9,1.6
		c0.3,0.3,0.7,0.3,1.1,0.5c0.5,0.2,1.5,0.4,2,0.3s1.4,0,1.7-0.2c0.1,0.1,0.4,0.1,0.5,0.1C221.6,84.6,221.6,84.6,221.7,84.6z
		 M223.9,87.5c-0.8-0.5-1.4-0.8-2.4-0.8c-1.1,0-2.2-0.2-3.3-0.3c-0.6-0.1-1-0.2-1.6-0.4c-0.4-0.2-0.6-0.5-1-0.7s-0.7-0.5-0.6-1
		c0-0.4,0.1-0.7,0.1-1.1c0.6,1,1.8,1.8,2.9,2.1c0.5,0.2,1.3,0.2,1.8,0c0.3-0.1,1.4-0.3,1.5-0.5c0.1,0.2,0.3,0,0.5,0.1
		c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.5,0.8,1.1,1.2,1.7C223.6,87.1,223.8,87.3,223.9,87.5z M224.3,88.2c-0.6-0.3-1.1-0.6-1.7-0.8
		c-0.4-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.7-0.1-1.1,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0.1-0.5,0.1c-0.5-0.2-1-0.2-1.4-0.5
		c-0.1-0.1-0.9-0.2-0.9-0.3c0-0.1-0.3-0.1-0.4-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.1-0.2-0.2-0.2
		c-0.1-0.2,0-0.2,0.1-0.4c0.5,0.5,1.2,1,1.8,1.3c0.8,0.3,1.7,0.4,2.6,0.5c0.6,0.1,1.2,0.2,1.8,0.2c0.4,0,0.8-0.1,1.2,0.1
		C223.1,87.3,224,87.6,224.3,88.2z M233.4,97.4c-0.1,0-0.1,0-0.2-0.1v0.1c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.1-0.4-0.2-0.5-0.4
		c-0.2-0.1-0.4-0.1-0.5-0.4c0,0-0.3-0.2-0.3-0.1c-0.3-0.2-1.1-0.5-1.1-0.9c-0.3,0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.6-0.4-0.8-0.6
		c-0.6-0.5-1.2-1.1-1.8-1.7c-0.3-0.3-0.8-0.9-0.8-1.3c0,0-0.1,0-0.2,0c-0.2-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-1-0.6-1.4-1.1v0.1
		c-1.3-0.4-2.7,0-3.9,0.2c-0.5,0.1-1-0.1-1.6-0.1c-0.5,0-1-0.1-1.6-0.1s-1.4-0.4-1.7-1c-0.3-0.5-0.3-0.9-0.3-1.5c0-0.1,0-0.2,0-0.3
		c0.3,0.2,0.7,0.2,1,0.4c0.4,0.2,0.8,0.4,1.2,0.5c0.3,0.1,1.5,0.8,1.8,0.5c0.2,0.2,1.1,0.1,1.4,0.1c0.7,0,1.5-0.1,2.2,0.1
		s1.5,0.4,2.1,0.8c0.4,0.7,0.9,1.4,1.4,2.1c1,1.4,2.5,2.5,3.8,3.6c0.6,0.5,1.2,1,1.9,1.4C232.4,96.3,232.9,96.9,233.4,97.4z
		 M234.7,98.8c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.3l-0.1-0.3c-0.1-0.1-0.2,0-0.3-0.1s-0.2-0.3-0.3-0.4
		c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.5,0.5,0.7,0.8C234.6,98.6,234.6,98.7,234.7,98.8z M235,100L235,100c0,0.2,0,0.3,0,0.4l0,0
		c0.1,0.4,0,0.9,0,1.3c0,0.6,0,1.2,0,1.8c0,0.6-0.1,1.2-0.1,1.7c0,0.9-0.2,1.8,0,2.6c-0.4,0-0.5-0.2-0.5-0.6c0-0.9,0-1.7,0.2-2.6
		c0.1-0.6,0.2-1.3,0.2-1.9c0-0.2,0-0.5,0-0.7c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3v-0.1c0-0.2,0-0.3-0.1-0.5
		c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.1-0.5-0.2-0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.2l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2
		C234.9,99.6,234.9,99.8,235,100z M235.5,103.5c0,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.8,0,1.1c0,0.8-0.1,1.7,0.1,2.4
		c-0.4,0.1-0.4,0-0.4-0.4c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.1-0.9,0.1-1.3c0-0.9,0-1.8,0-2.7c0-0.5,0-1-0.1-1.6
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l0.1,0.1c0,0,0.1,0.1,0.1,0.2C235.9,100.8,235.4,102.5,235.5,103.5z M236.1,102.6
		c-0.2,0.6-0.1,1.2-0.1,1.8c0,0.6,0,1.1,0,1.7c0,0.4-0.2,1,0.1,1.4c0,0.1,0,0,0,0.1c-0.2,0-0.3,0.1-0.4-0.2c0-0.1-0.1-0.2-0.1-0.3
		c0-0.3,0-0.6,0-0.9c0-0.7,0-1.4,0-2.1c0.1-1.3,0.4-2.7,0.1-4c0.1,0.2,0.3,0.3,0.3,0.5s0.1,0.3,0.1,0.5
		C236.1,101.8,236.2,102.2,236.1,102.6z M236.6,107.6c-0.1,0-0.2,0-0.4,0c0-0.1,0,0,0-0.1c0-0.4-0.1-0.7-0.1-1.1c0-0.2,0-0.3,0-0.5
		c0-0.5,0-1,0-1.4c0-0.5,0-1,0-1.5c0.1-0.7,0.3-1.4,0.1-2.2c0.3,0.4,0.1,0.9,0.1,1.4c0,0.4,0,0.8,0,1.1c0,0.5,0.1,1,0.1,1.5
		C236.4,105.7,236.2,106.9,236.6,107.6z M236.9,104.6c0,0.9,0.2,2,0.6,2.8c-0.2,0.1-0.5,0.2-0.6,0c-0.1-0.1,0-0.2,0-0.3
		c-0.1-0.3-0.1-0.6-0.1-0.9c0-1.6-0.1-3.2,0-4.9c0.2,0.3,0.1,0.8,0.1,1.2c0,0.3,0,0.7,0,1C236.8,103.9,236.9,104.3,236.9,104.6z
		 M237,102.6c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.5,0.2,1,0.3,1.5c0.2,0.8,0.2,1.7,0.5,2.4c-0.2,0-0.3,0.2-0.4,0
		c-0.3-0.8-0.5-1.8-0.5-2.6c0-0.3,0-0.7-0.1-1c-0.1-0.6-0.1-1.2-0.1-1.8C237.1,102,237,102.5,237,102.6z M238.4,107.2
		c-0.1,0-0.1,0-0.2,0c-0.4-0.7-0.4-1.7-0.5-2.4c-0.2-0.8-0.5-1.7-0.5-2.6c0.1,0.2,0.3,0.5,0.4,0.7h-0.1c-0.1,0.3,0.2,0.7,0.2,1
		c0.1,0.4,0.1,0.8,0.3,1.1c0.2,0.4,0,1.1,0.3,1.5C238.2,106.6,238.3,107,238.4,107.2z"
      />
    </g>
    <path
      class="st0"
      d="M212.9,13.1c2.4,0,4.6,0.6,6.9,0.9c2.2,0.2,4.6,0.9,6.6,2c2,1.2,2.5,2.1,4.9,4.1c2.1,1.8,2,3.6,3.1,5.3
	c0.7,1,1.5,2.1,2.2,3c0.5,0.6,2.2,2,2.2,2.7"
    />
    <path
      class="st0"
      d="M27.7,115.7v-1.4h7.1V96.6h1.6v17.7h15.2v1.4H36.4v48.2h-1.6v-48.2L27.7,115.7L27.7,115.7z"
    />
    <path
      class="st0"
      d="M90.6,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
	V75.7h1.6v51.1C75,117,83.8,113,90.6,113z"
    />
    <path
      class="st0"
      d="M130.8,139c0,14.2,10.3,24.5,24.8,24.5c9.2,0,15.6-3.6,19.9-9.5l1.3,1c-4.6,6.2-11.5,10-21.2,10
	c-15.5,0-26.4-11.1-26.4-25.9c0-14.9,11.4-25.9,25.3-25.9c14.5,0,24.2,10,24.8,25.6h-48.5L130.8,139L130.8,139z M177.8,137.2
	c-1.1-13.8-10-22.8-23.2-22.8c-12.3,0-22.9,9.5-23.6,22.8H177.8z"
    />
    <path class="st0" d="M213.5,114.3h1.6v49.5h-1.6V114.3z" />
    <path
      class="st0"
      d="M265.7,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6
	h-1.6v-49.5h1.6v12.5C250.1,117,258.9,113,265.7,113z"
    />
    <path
      class="st0"
      d="M328,113c11.9,0,20.1,6.5,20.1,20.4v30.4h-1.6v-30.4c0-13.3-7.6-19-18.5-19c-7.6,0-18.5,6-18.5,19.8v29.6h-1.6
	v-49.5h1.6v12.5C312.3,117,321.2,113,328,113z"
    />
  </svg>
);
