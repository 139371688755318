import styles from "../../styles/partials/_cookie-popup.scss";
import { Link } from "../widgets";
import CTA from "../widgets/CTA";
import { _classes } from "../utilities/helpers";
import { useEffect, useState } from "react";
import { useAppState } from "../providers";
import { AnimatePresence, motion } from "../utilities/FramerMotion";

const cl = _classes(styles, "cookie_popup");

CookiePopup.propTypes = {};
export default function CookiePopup() {
  const { byTemplate } = useAppState();

  const [cookiesAllowed, setCookiesAllowed] = useState();

  const checkCookieDate = () => {
    // Clear on startup if expired
    // 168 = 1 week

    let hours = 720;
    let saved = localStorage.getItem("cookie_saved");
    if (saved && new Date().getTime() - saved > hours * 60 * 60 * 1000) {
      localStorage.clear();
      setCookiesAllowed(false);
    } else {
      setCookiesAllowed(localStorage.getItem("cookie_saved"));
    }
    // Increase expiration time after save
    localStorage.setItem("cookie_saved", new Date().getTime());
  };

  const saveCookiePreference = () => {
    setCookiesAllowed(true);
  };
  useEffect(() => {
    checkCookieDate();
  }, []);
  useEffect(() => {
    localStorage.setItem("allow_cookies", cookiesAllowed);
  }, [cookiesAllowed]);

  return (
    <AnimatePresence>
      {!cookiesAllowed && (
        <motion.div
          className={cl("cookie__popup")}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
        >
          <div className={cl("container")}>
            <div className={cl("content__wrapper")}>
              <div className={cl("blurb")}>
                We'd like to use cookies to understand your use of our website
                and to give you a better experience. To find out more about
                cookies and how to change your choices, please to go our{" "}
                <Link path={"https://www.jean-georges.com/legal"}>
                  Privacy Policy
                </Link>
                .
              </div>
              <CTA
                text={"Accept"}
                className={cl("accept__cookie")}
                onClick={saveCookiePreference}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
