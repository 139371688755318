import styles from "../../styles/partials/_popup.scss";
import useAppState from "../providers/AppProvider";
import { useState } from "react";
import PageTitle from "./PageTitle";
import Image from "../widgets/Image";
import CTA from "../widgets/CTA";
import { Scrollbars } from "react-custom-scrollbars";

Popup.propTypes = {};

function Popup() {
  const [open, toggleOpen] = useState(true);
  const { byTemplate } = useAppState();
  const page = byTemplate("home")[0];

  if (page.checkbox1) {
    return (
      <div className={`${styles.popup} ${open ? styles.popup_open : ""}`}>
        <div
          className={styles.popup__scrim}
          onClick={() => toggleOpen(false)}
        />
        <div className={styles.popup__inner}>
          {open && 
               <button
               className={styles.popup__close}
               onClick={() => toggleOpen(false)}
               aria-label="close popup"
             />
          }
          <div className={styles.popup__image}>
            <Image
              src={page.image1}
              alt={page.image1_alt_text}
              align={page.image1_anchor}
            />
          </div>
          <div className={styles.popup__textContainer}>
            <Scrollbars
              hideTracksWhenNotNeeded
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
              thumbSize={150}
            >
              <PageTitle title={page.h2} />
              <div
                className={styles.popup__blurb}
                dangerouslySetInnerHTML={{ __html: page.blurb2 }}
              />
              {page.fieldgroup2 && page.fieldgroup2.length > 0 && (
                <div className={styles.popup__links}>
                  {page.fieldgroup2.map((item) => {
                    return (
                      <CTA
                        className={styles.popup__link}
                        key={item.id}
                        link={item.h2}
                        text={item.h1}
                      />
                    );
                  })}
                </div>
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Popup;
