import Link from "./Link";
import styles from "../../styles/widgets/_cta.scss";
import useAppState from "../providers/AppProvider";

CTA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

CTA.defaultProps = {
  link: "",
  text: "",
  onClick: () => null,
  className: "",
};

function CTA({ link, text, onClick, className }) {
  const { summer } = useAppState();
  return (
    <Link
      className={`${className} ${styles.cta} ${
        summer ? styles.cta_summer : ""
      }`}
      path={link}
      ariaLabel={text}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </Link>
  );
}

export default CTA;
