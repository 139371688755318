import Link from "../widgets/Link";
import styles from "../../styles/partials/_desktop-nav.scss";
import {
  // logo,
  facebook,
  instagram,
  logoSummer,
} from "../widgets/SVG";
import Logo from "../widgets/Logo";
import { Scrollbars } from "react-custom-scrollbars";
import useAppState from "../providers/AppProvider";
import AnimateHeight from "react-animate-height";
import { useState } from "react";
import ReservationDropdown from "./ReservationDropdown";

DesktopNav.propTypes = {
  items: PropTypes.array,
  page: PropTypes.object,
};

function DesktopNav({ items, page }) {
  const [openId, setOpenId] = useState(null);
  const { childrenById, summer } = useAppState();

  const toggleOpen = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  const renderNavItems = () => {
    if (items && items.length > 0) {
      return (
        <ul className={styles.desktopNav__navItems} role="navigation">
          {items.map((item) => {
            const path = item.template === "redirect" ? item.h2 : item.path;
            const active =
              window.location.pathname === item.path ||
              window.location.pathname.includes(item.path);
            const children = childrenById(item.id);

            if (children && children.length > 0) {
              const open = openId && openId === item.id;

              return (
                <li
                  className={`${styles.desktopNav__item} ${
                    active ? styles.desktopNav__item_active : ""
                  }`}
                  key={item.id}
                >
                  <button
                    onClick={() => toggleOpen(item.id)}
                    aria-expanded={openId ? "true" : "false"}
                  >
                    {item.linktitle}
                  </button>
                  <AnimateHeight
                    className={styles.desktopNav__subnav}
                    height={open ? "auto" : 0}
                  >
                    {children.map((child) => {
                      const subnavItemActive =
                        window.location.pathname === child.path;
                      return (
                        <Link
                          className={`${styles.desktopNav__subnavItem} ${
                            subnavItemActive
                              ? styles.desktopNav__subnavItem_active
                              : ""
                          }`}
                          key={child.id}
                          path={child.path}
                        >
                          {/* THIS IS SUBNAV */}
                          {child.linktitle}
                        </Link>
                      );
                    })}
                  </AnimateHeight>
                </li>
              );
            } else {
              return (
                <li
                  className={`${styles.desktopNav__item} ${
                    active ? styles.desktopNav__item_active : ""
                  }`}
                  key={item.id}
                >
                  <Link path={path} onClick={() => setOpenId(null)}>
                    {item.linktitle}
                  </Link>
                </li>
              );
            }
          })}
          <li className={styles.desktopNav__item}>
            <ReservationDropdown />
          </li>
        </ul>
      );
    }
  };

  const renderSocialIcons = () => {
    return (
      <div className={styles.desktopNav__socialIcons}>
        <Link
          className={styles.desktopNav__socialIcon}
          path={page.social_icon1}
          ariaLabel={"instagram"}
        >
          {instagram}
        </Link>
        <Link
          className={styles.desktopNav__socialIcon}
          path={page.social_icon2}
          ariaLabel={"facebook"}
        >
          {facebook}
        </Link>
      </div>
    );
  };

  return (
    <nav className={`${styles.desktopNav}`}>
      <Scrollbars
        hideTracksWhenNotNeeded
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        thumbSize={250}
      >
        <div role="heading">
          <Link
            className={styles.desktopNav__logo}
            path="/"
            ariaLabel={"the inn at Pound Ridge logo"}
          >
            <Logo />
          </Link>
          <div
            className={styles.desktopNav__blurb}
            dangerouslySetInnerHTML={{ __html: page.blurb1 }}
          />
        </div>
        {renderNavItems()}
        {renderSocialIcons()}
      </Scrollbars>
    </nav>
  );
}

export default DesktopNav;
