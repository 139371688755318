import { _classes } from "../utilities/helpers";
import { useState } from "react";
import styles from "../../styles/partials/_reservation-dropdown.scss";

const cl = _classes(styles);

const ReservationDropdown = () => {
  const [showOpenTable, setShowOpenTable] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const sendToGA = () => {
    try {
      ga("send", {
        hitType: "event",
        eventCategory: "Reservation",
        eventAction: "Widget",
        eventLabel: "Completion",
        eventValue: 0,
      });
    } catch (err) {
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleOpenTable = () => {
    setShowOpenTable(!showOpenTable);
    setDropdownOpen(false);
  };

  // useEffect(() => {
  //   // Ensure the container exists and the script hasn't been added yet
  //   const container = document.querySelector(".openTableContainer");
  //   if (container && !document.getElementById("openTableScript")) {
  //     const script = document.createElement("script");
  //     script.id = "openTableScript";
  //     script.type = "text/javascript";
  //     script.src =
  //       "//www.opentable.com/widget/reservation/loader?rid=3948&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant website&cfe=true";
  //     container.appendChild(script);
  //   }
  //   //when component unmounts, remove the script
  //   return () => {
  //     const script = document.getElementById("openTableScript");
  //     if (script) {
  //       script.remove();
  //     }
  //   };
  // }, [showOpenTable]);
  return (
    <div className={cl("header__reserve")}>
      <button
        aria-label="Reserve dropdown"
        onClick={() => toggleDropdown()}
        className={`reserve ${cl("header__reserve")}`}
      >
        Reserve
      </button>
      <div
        className={`dropdown ${cl("header__dropdown")} ${
          dropdownOpen ? cl("open") : ""
        }`}
      >
        <button
          className={`open-table ${cl("header__reserve_btn")}`}
          onClick={() => {
            sendToGA();
            window.open('https://www.opentable.com/r/the-inn-at-pound-ridge-by-jean-georges-pound-ridge','_blank')
            toggleOpenTable();
          }}
        >
          OpenTable
        </button>
        <button
          className={`resy-widget ${cl("header__reserve_btn")}`}
          onClick={() => {
            sendToGA();
            window.open(
              "https://resy.com/cities/pndr/the-inn-at-pound-ridge-by-jean-georges",
              "_blank"
            );
            toggleDropdown();
          }}
        >
          Resy
        </button>
      </div>
      {/* {showOpenTable && (
        <div
          onClick={toggleOpenTable}
          className={cl("header__open_table_scrim")}
        >
          <div className={`openTableContainer ${cl("header__open_table")}`}>
            <button
              aria-label="Close modal"
              onClick={toggleOpenTable}
              className={cl("header__close_btn")}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ReservationDropdown;
