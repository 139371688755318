// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._page-title__title___Jfgzi{color:#ee742f;font-family:\"Book\";font-weight:normal;font-size:24px;line-height:1;margin:0 0 25px 0}", "",{"version":3,"sources":["webpack://./src/styles/partials/_page-title.scss","webpack://./src/styles/global/_variables.scss"],"names":[],"mappings":"AAEA,4BACE,aCOO,CDNP,kBCqCQ,CDpCR,kBAAA,CACA,cAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":["@import \"../global/_parts.scss\";\n\n.title {\n  color: $seasonalColor;\n  font-family: $regular;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 1;\n  margin: 0 0 $mobileGutter 0;\n}\n","// COLORS\n$white: #fff;\n$black: #131313;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n$brown: #7c604d;\n$grey: #6b6b6b;\n$backgroundGrey: #e4e4e4;\n$green: #86981f;\n$darkerGreen: #548a43;\n$orange: #ee742f;\n$spring: $green;\n$summer: $darkerGreen;\n$fall: $orange;\n$winter: $brown;\n$seasonalColor: $fall; ///IMPORTANT: THis is the value that is changed to the season request\n\n// BREAKPOINTS\n$xsmall: 480px;\n$small: 600px;\n$mobile: 768px;\n$tablet: 1025px;\n$medium: 1180px;\n$large: 1300px;\n$xlarge: 1600px;\n\n// SIZES\n$navWidth: 285px;\n$footerHeight: 35px;\n$sidebarWidth: 450px;\n$mobileNavHeight: 80px;\n\n// GUTTERS\n$desktopGutter: 100px;\n$smallGutter: 50px;\n$mobileGutter: 25px;\n\n// TYPOGRAPHY\n$speed1: cubic-bezier(0.4, 1.13, 0.7, 0.95);\n\n// FONTS\n$regular: \"Book\";\n$light: \"Light\";\n\n// FONT SIZES\n$rootFontSize: 16px;\n\n$h1-size: 3.75rem; // 60px\n$h1-size-mobile: 2rem;\n\n$h2-size: 2.6rem;\n$h2-size-mobile: 1.8rem;\n\n$h3-size: 2rem;\n$h3-size-mobile: 1.6rem;\n\n$h4-size: 1.7rem;\n$h4-size-mobile: 1.4rem;\n\n$h5-size: 1.5rem;\n$h5-size-mobile: 1.3rem;\n\n$h6-size: 1.3rem;\n$h6-size-mobile: 1.2rem;\n\n$p-size: 0.9375rem;\n$p-size-mobile: 0.9375rem;\n\n$a-size: 0.9375rem;\n$a-size-mobile: 0.9375rem;\n\n$span-size: 0.9375rem;\n$span-size-mobile: 0.9375rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_page-title__title___Jfgzi"
};
export default ___CSS_LOADER_EXPORT___;
