import styles from "../../styles/partials/_footer.scss";
import useAppState from "../providers/AppProvider";
import Link from "../widgets/Link";

Footer.propTypes = {};

function Footer() {
  const { byTemplate, toggleAccessible } = useAppState();
  const page = byTemplate("footer")[0];

  return (
    <footer className={styles.footer}>
      {page.fieldgroup1 &&
        page.fieldgroup1.length > 0 &&
        page.fieldgroup1.map((item) => {
          return (
            <Link className={styles.footer__link} key={item.id} path={item.h2}>
              {item.h1}
            </Link>
          );
        })}
      <Link onClick={toggleAccessible} className={styles.footer__link}>
        Accessiblity
      </Link>
    </footer>
  );
}

export default Footer;
